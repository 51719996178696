import React, { Component } from "react";

import {
  makeStyles,
  Collapse,
  Paper,
  CircularProgress
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  keyContainer: { position: "absolute", right: "0.5em", bottom: "0.5em" },
  keyPaper: { minWidth: "50px", minHeight: "81px" },
  keyContents: { padding: "8px" },
  loadSpinnerContainer: { width: "100%", position: "absolute", top: "50%" },
  loadSpinnerCollapse: {
    position: "relative",
    top: "50%",
    left: "50%",
    zIndex: "10005"
  },
  loadSpinner: {
    width: "3rem",
    height: "3rem",
    zIndex: "10005"
  },
  optionsButtonCollapse: {
    float: "left",
    position: "relative",
    marginButton: "-0.1em"
  },
  optionsButton: {
    borderRadius: "2px"
  },
  optionsButtonText: {
    fontFamily: "Material Icons"
  },
  invertedOptionsButton: {
    borderRadius: "2px",
    filter: "invert(100%)"
  }
}));

export function MapKey(props) {
  const classes = useStyles();

  return (
    <div className={classes.keyContainer + " over-popover"}>
      <Collapse className="over-popover" in={props.in}>
        <Paper className={classes.keyPaper}>
          <div className={classes.keyContents}>{props.children}</div>
        </Paper>
      </Collapse>
    </div>
  );
}

export function LoadSpinner(props) {
  const classes = useStyles();

  return (
    <div className={classes.loadSpinnerContainer}>
      <Collapse in={props.in} className={classes.loadSpinnerCollapse}>
        <CircularProgress color="secondary" className={classes.loadSpinner} />
      </Collapse>
    </div>
  );
}

export function ZoomInButton(props) {
  const classes = useStyles();

  return (
    <button onClick={props.onClick} title="Zoom in" className="map-zoomIn">
      <div className={classes.optionsButtonText}>zoom_in</div>
    </button>
  );
}

export function ZoomOutButton(props) {
  const classes = useStyles();

  return (
    <button onClick={props.onClick} title="Zoom out" className="map-zoomOut">
      <div className={classes.optionsButtonText}>zoom_out</div>
    </button>
  );
}

export function SatelliteButton(props) {
  const classes = useStyles();

  return (
    <button
      onClick={props.onClick}
      title="Show satellite imagery"
      className={
        props.pressed ? classes.invertedOptionsButton : classes.optionsButton
      }
    >
      <div className={classes.optionsButtonText}>satellite</div>
    </button>
  );
}

export function ResetButton(props) {
  const classes = useStyles();

  return (
    <Collapse in={props.in} className={classes.optionsButtonCollapse}>
      <button
        onClick={props.onClick}
        title="Reset view"
        className={classes.optionsButton}
      >
        <div className={classes.optionsButtonText}>my_location</div>
      </button>
    </Collapse>
  );
}

export function NewTabButton(props) {
  const classes = useStyles();

  return (
    <Collapse in={props.in} className={classes.optionsButtonCollapse}>
      <button
        onClick={props.onClick}
        title="Open in new tab"
        className={classes.optionsButton}
      >
        <div className={classes.optionsButtonText}>open_in_new</div>
      </button>
    </Collapse>
  );
}

export function ShowKeyButton(props) {
  const classes = useStyles();

  return (
    <Collapse in={props.in} className={classes.optionsButtonCollapse}>
      <button
        onClick={props.onClick}
        title="Toggle key"
        className={
          props.pressed ? classes.invertedOptionsButton : classes.optionsButton
        }
      >
        <div className={classes.optionsButtonText}>vpn_key</div>
      </button>
    </Collapse>
  );
}

export function ShowDetailsButton(props) {
  const classes = useStyles();

  return (
    <Collapse in={props.in} className={classes.optionsButtonCollapse}>
      <button
        onClick={props.onClick}
        title="Toggle details"
        className={
          props.pressed ? classes.invertedOptionsButton : classes.optionsButton
        }
      >
        <div className={classes.optionsButtonText}>assignment</div>
      </button>
    </Collapse>
  );
}

export function LegendRow(props) {
  const classes = useStyles();

  return (
    <div className="legend-row">
      <div
        className="map-color-swatch"
        style={{
          backgroundColor: props.color
        }}
      >
        {" "}
      </div>
      <div className="map-color-label">{props.label}</div>
    </div>
  );
}
