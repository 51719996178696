import { combineReducers } from "redux";
import toggleSettingsOpen from "./toggleSettingsOpen";
import setSettingsEnabled from "./setSettingsEnabled";
import setAllDashboards from "./setAllDashboards";
import setLoggedIn from "./setLoggedIn";

export default combineReducers({
  toggleSettingsOpen,
  setSettingsEnabled,
  setAllDashboards,
  setLoggedIn
});
