import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  withStyles,
  withTheme,
  FormControl,
  FormControlLabel,
  Switch,
  InputLabel,
  NativeSelect,
  Input,
  Select,
  Chip,
  MenuItem,
  ListItemText,
  Button,
  Typography,
  Slider,
  TextField
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  HeaderGroup,
  DefaultMenuProps,
  getFilterStyle,
  DeletableChip
} from "../../controls/MenuControls";

import ColorSwatch from "../../controls/ColorSwatch";

import { getCategoryColor } from "../../../helpers/Colors";

const styles = {
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  }
};

class TypeMapPointSettingsComponent extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    pointGroupOpen: !this.props.heatmapMode
  };

  static defaultProps = {
    drawPoints: true,
    pointSelection: []
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.heatmapMode !== prevProps.heatmapMode) {
      this.setState({ pointGroupOpen: !this.props.heatmapMode });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <HeaderGroup
        id="pointGroupOpen"
        onClick={this.toggleHeader}
        in={this.state.pointGroupOpen}
        label="Points"
      >
        <div className="pad-gutter-8px">
          <FormControlLabel
            control={
              <Switch
                checked={this.props.drawPoints}
                onChange={this.togglePoints}
                value="drawPoints"
                color="secondary"
              />
            }
            label="Draw Points"
          />
          <FormControl className="settings-select">
            <InputLabel>Measure</InputLabel>
            <Select
              value={this.props.pointDataType}
              onChange={this.setPointDataType}
              input={<Input name="measure" />}
            >
              {Object.keys(this.props.dataTypes).map(dataType => {
                return (
                  <MenuItem key={dataType} value={dataType} label={dataType}>
                    {dataType}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl className="settings-select">
            <Autocomplete
              id="filter-combo-box"
              options={
                (this.props.dataTypes[this.props.pointDataType] &&
                  Object.keys(
                    this.props.dataTypes[this.props.pointDataType]
                  ).sort()) ||
                []
              }
              getOptionLabel={option => option}
              classes={{}}
              value={this.props.pointSelection}
              handleHomeEndKeys
              clearOnBlur
              autoHighlight
              multiple
              disableCloseOnSelect
              onChange={(event, newValue) => {
                this.props.setSettings({
                  pointSelection: newValue
                });
              }}
              renderInput={params => (
                <TextField {...params} variant="standard" label="Filter" />
              )}
              renderOption={(category, { selected }) => (
                <MenuItem key={category} value={category}>
                  <ColorSwatch menuItem color={getCategoryColor(category)} />
                  <Typography
                    key={category}
                    style={getFilterStyle(
                      category,
                      this.props.pointSelection,
                      this.props.theme
                    )}
                  >
                    {category}
                  </Typography>
                </MenuItem>
              )}
              renderTags={selected => {
                return (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <DeletableChip
                        icon={<ColorSwatch color={getCategoryColor(value)} />}
                        key={value}
                        label={value}
                        className={classes.chip}
                        onDelete={() => {
                          this.removeFromPointSelection(value);
                        }}
                      />
                    ))}
                  </div>
                );
              }}
              aria-label="filter"
            />
          </FormControl>
        </div>
        <Button
          className="fullWidth margin-top-5px margin-bottom-4px"
          onClick={() => {
            this.props.showCategoryColorPicker(
              this.props.dataTypes[this.props.pointDataType]
            );
          }}
          variant="contained"
          color="primary"
        >
          Choose Colors
        </Button>
      </HeaderGroup>
    );
  }

  toggleHeader = () => {
    this.setState({ pointGroupOpen: !this.state.pointGroupOpen });
  };

  togglePoints = () => {
    this.props.setSettings({
      drawPoints: !this.props.drawPoints
    });
  };

  setPointDataType = event => {
    let pointSelection = this.props.pointSelection;
    // This saves us from rebuilding the point vector layer if there was previously no filter selection
    if (pointSelection.length != 0) {
      pointSelection = [];
    }

    this.props.setSettings({
      pointDataType: event.target.value,
      pointSelection: pointSelection
    });
  };

  setPointSelection = event => {
    this.props.setSettings({
      pointSelection: event.target.value
    });
  };

  removeFromPointSelection = value => {
    var newSelection = this.props.pointSelection.slice();
    if (newSelection.indexOf(value) === -1) return;

    newSelection.splice(newSelection.indexOf(value), 1);
    this.props.setSettings({ pointSelection: newSelection });
  };
}

TypeMapPointSettingsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const TypeMapPointSettings = withStyles(styles)(
  withTheme(TypeMapPointSettingsComponent)
);

export default TypeMapPointSettings;
