import React, { useState, useEffect } from "react";
import { Table, Spinner, Row } from "reactstrap";
import api from "../../services/Api";
import CustomContainer from "../CustomContainer";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { setSettingsEnabled } from "../../redux/actions";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { FormControlLabel, Switch } from "@material-ui/core";

const styles = {
  pageTitle: {
    justifyContent: "center",
    marginTop: "30px",
    width: "100%"
  },
  loadingWrapper: {
    display: "flex",
    justifyContent: "center"
  },
  rowData: {
    width: "33%",
    textAlign: "center"
  },
  buttonRow: {
    justifyContent: "flex-end",
    marginLeft: "0px",
    marginRight: "0px"
  }
};

function UsersSettings(props) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const [newUser, setNewUser] = useState(null);

  useEffect(() => {
    props.setSettingsEnabled(false);
    loadUsers();
  }, []);

  async function loadUsers() {
    // Mark that we're loading
    setLoading(true);

    // Clear the users list first
    setUsers([]);

    // Download the users (this will only return the users that this users has access to edit)
    const users = await api.getUsers();

    // If it exist, set it
    if (users != null) {
      setUsers(users);
    }

    // We're no longer loading
    setLoading(false);
  }

  if (loading) {
    return (
      <div style={styles.loadingWrapper}>
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <div>
      <CustomContainer>
        <Row style={styles.pageTitle}>
          <h1>Users</h1>
        </Row>
        <Row style={styles.buttonRow}>
          <Button
            color="primary"
            onClick={() => {
              // Create the new user object
              setNewUser({
                firstName: "",
                lsetName: "",
                email: "",
                password: ""
              });

              setShowAddUserDialog(true);
            }}
          >
            Add User
          </Button>
        </Row>
        <Row style={styles.tableRow}>
          <Table hover>
            <thead>
              <tr>
                <th style={styles.rowData}>First Name</th>
                <th style={styles.rowData}>Last Name</th>
                <th style={styles.rowData}>Email</th>
              </tr>
            </thead>
            <tbody>
              {users.map((value, index) => (
                <tr
                  key={index}
                  onClick={() =>
                    props.history.push({
                      pathname: `/settings/user/${value.id}`
                    })
                  }
                >
                  <td style={styles.rowData}>{value.firstName}</td>
                  <td style={styles.rowData}>{value.lastName}</td>
                  <td style={styles.rowData}>{value.email}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
      </CustomContainer>
      <Dialog
        open={showAddUserDialog}
        onClose={() => setShowAddUserDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the basic user details here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="First Name"
            type="text"
            fullWidth
            onChange={event => {
              // Update the new user object
              let userCopy = JSON.parse(JSON.stringify(newUser));
              userCopy.firstName = event.target.value;

              // Save the new user object
              setNewUser(userCopy);
            }}
          />
          <TextField
            margin="dense"
            label="Last Name"
            type="text"
            fullWidth
            onChange={event => {
              // Update the new user object
              let userCopy = JSON.parse(JSON.stringify(newUser));
              userCopy.lastName = event.target.value;

              // Save the new user object
              setNewUser(userCopy);
            }}
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            onChange={event => {
              // Update the new user object
              let userCopy = JSON.parse(JSON.stringify(newUser));
              userCopy.email = event.target.value;

              // Save the new user object
              setNewUser(userCopy);
            }}
          />
          <TextField
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            onChange={event => {
              // Update the new user object
              let userCopy = JSON.parse(JSON.stringify(newUser));
              userCopy.password = event.target.value;

              // Save the new user object
              setNewUser(userCopy);
            }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={newUser && newUser.ForcePasswordReset}
                onChange={() => {
                  let userCopy = JSON.parse(JSON.stringify(newUser));
                  userCopy.ForcePasswordReset = !newUser.ForcePasswordReset;

                  setNewUser(userCopy);
                }}
                value="forceReset"
                color="secondary"
              />
            }
            label="Force Password Reset"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAddUserDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={async event => {
              event.target.disabled = true;

              await api.registerUser(newUser);

              setShowAddUserDialog(false);

              loadUsers();
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = {
  setSettingsEnabled
};

const UsersSettingsContainer = connect(
  null,
  mapDispatchToProps
)(withRouter(UsersSettings));

export default UsersSettingsContainer;
