import React, { useState, useEffect } from "react";
import { Row, Spinner, Input, Label, Card, CardBody, Button } from "reactstrap";
import api from "../../services/Api";
import CustomContainer from "../CustomContainer";

import { connect } from "react-redux";
import { setSettingsEnabled } from "../../redux/actions";
import { currentUserHasRole, getUser } from "../../helpers/Utilities";

const styles = {
  pageTitle: {
    justifyContent: "center",
    marginTop: "30px"
  },
  dashboardRow: {
    justifyContent: "center"
  },
  dashboardCard: {
    width: "75%"
  },
  actionButton: {
    float: "right",
    marginRight: "5px"
  },
  loadingWrapper: {
    display: "flex",
    justifyContent: "center"
  },
  checkbox: {
    marginLeft: "1.25rem" // Not sure why, but <Input type="checkbox"> will offset the left margin by -1.25rem, so this offsets that
  }
};
function DashboardSettings(props) {
  const [dashboards, setDashboards] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadDashboards();
    props.setSettingsEnabled(false);
  }, []);

  async function loadDashboards() {
    // Mark that we're loading
    setLoading(true);

    // Clear the dashboards list first
    setDashboards([]);

    // Download the selected dashboard
    const dashboards = await api.getAllDashboards();

    // If it exist, set it
    if (dashboards != null) {
      setDashboards(dashboards);
    }

    // We're no longer loading
    setLoading(false);
  }

  if (loading) {
    return (
      <div style={styles.loadingWrapper}>
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <CustomContainer>
      <Row style={styles.pageTitle}>
        <h1>Dashboard Settings</h1>
      </Row>
      {dashboards.map((value, index) => {
        if (value.jurisdictionId != null && !currentUserHasRole("Admin"))
          return null;
        return (
          <div key={index}>
            <Row style={styles.dashboardRow}>
              <Card style={styles.dashboardCard}>
                <CardBody>
                  <Label for="dashboardTitle">Title</Label>
                  <Input
                    id="dashboardTitle"
                    style={styles.textInput}
                    type="text"
                    value={value.title}
                    onChange={event => {
                      // Update the title
                      const dashboardsCopy = [...dashboards];
                      dashboardsCopy[index].title = event.target.value;

                      // Save the updated dashboard
                      setDashboards(dashboardsCopy);
                    }}
                  />
                  {value.userDashboards.length > 0 && (
                    <Label style={styles.checkbox}>
                      <Input
                        type="checkbox"
                        checked={value.userDashboards[0].isHomeDashboard}
                        onChange={event => {
                          // Update the title
                          const dashboardsCopy = [...dashboards];
                          dashboardsCopy[
                            index
                          ].userDashboards[0].isHomeDashboard =
                            event.target.checked;

                          // Save the updated dashboard
                          setDashboards(dashboardsCopy);
                        }}
                      />
                      Home Dashboard
                    </Label>
                  )}
                  <br />
                  {(value.userDashboards.length == 0 ||
                    currentUserHasRole("Admin")) && (
                    <Label style={styles.checkbox}>
                      <Input
                        type="checkbox"
                        checked={value.jurisdictionId != null}
                        onChange={event => {
                          // Update the jurisdictionId to either be the jurisdictionID of this user OR null based on the checkbox value
                          const dashboardsCopy = [...dashboards];
                          dashboardsCopy[index].jurisdictionId = event.target
                            .checked
                            ? getUser().jurisdictionId
                            : null;

                          // Save the updated dashboard
                          setDashboards(dashboardsCopy);
                        }}
                      />
                      Is Jurisdiction Dashboard
                    </Label>
                  )}
                  <hr />
                  <Label for="actionButtonsWrapper">Actions</Label>
                  <div id="actionButtonsWrapper">
                    <Button
                      style={styles.actionButton}
                      color="danger"
                      onClick={async event => {
                        if (
                          window.confirm(
                            `Are you sure you want to delete ${value.title}?`
                          )
                        ) {
                          // Set loading so that we show the loading spinner
                          setLoading(true);

                          // Delete this dashboard
                          await api.deleteDashboard(value.id);

                          // Load the dashboards
                          loadDashboards();

                          // We're no longer loading
                          setLoading(false);
                        }
                      }}
                    >
                      Delete
                    </Button>

                    <Button
                      style={styles.actionButton}
                      color="primary"
                      onClick={async event => {
                        // Set loading so that we show the loading spinner
                        setLoading(true);

                        // Update this dashboard
                        await api.updateDashboard(value);

                        // If this dashboard was set as the home dashboard
                        if (
                          value.userDashboards.length > 0 &&
                          value.userDashboards[0].isHomeDashboard
                        ) {
                          await api.setHomeDashboard(value.id);
                        }

                        // Load the dashboards
                        await loadDashboards();

                        // We're no longer loading
                        setLoading(false);
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Row>

            {index != dashboards.length - 1 ? <hr /> : null}
          </div>
        );
      })}
    </CustomContainer>
  );
}

const mapDispatchToProps = {
  setSettingsEnabled
};

const DashboardSettingsContainer = connect(
  null,
  mapDispatchToProps
)(DashboardSettings);

export default DashboardSettingsContainer;
