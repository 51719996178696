import React from "react";
import { Hidden } from "@material-ui/core";

const styles = {
  container: {
    width: "100%",
    // padding: '0em .5em',
    flex: "1 1 auto",
    flexDirection: "column",
    display: "flex"
  },
  dashboardContainer: {
    width: "100%",
    background: "#FFF9F5",
    flex: "1 1 auto",
    flexDirection: "column",
    display: "flex"
    // padding: '0em .5em',
  }
};

function CustomContainer(props) {
  return (
    <div
      style={
        props.dashboardContainer ? styles.dashboardContainer : styles.container
      }
    >
      {props.children}
    </div>
  );
}

export default CustomContainer;
