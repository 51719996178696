import React, { Component } from "react";
import PropTypes from "prop-types";

// Material UI
import {
  withStyles,
  withTheme,
  FormControl,
  FormControlLabel,
  Switch,
  InputLabel,
  NativeSelect,
  Select,
  Input,
  Button,
  TextField,
  MenuItem,
  ListItemText,
  Grid,
  Collapse
} from "@material-ui/core";

// Other
import uuidv4 from "uuid";

// Firewatch
import api from "../../services/Api";
import { DataContext } from "../DataContext";
import { ComponentContext } from "../DashboardComponent";
import {
  AddDashboardButton,
  SaveDashboardItemChangesButton
} from "../controls/AddDashboard";
import DataSettings from "../controls/DataSettings";
import CategoryColorPicker from "../controls/CategoryColorPicker";
import { getCategoryColor, setCategoryColor } from "../../helpers/Colors";
import { textPositions } from "./BaseChart.js";

import {
  HeaderGroup,
  AddButton,
  buildNativeOptions,
  DefaultMenuProps,
  DeletableChip
} from "../controls/MenuControls";
import { AddXAxisLineDialog, AddYAxisLineDialog } from "./ChartDialogs";

export default class AdvancedChartSettings extends Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    const customize = this.context.customize ? this.context.customize : false;

    return (
      <div className="bm-settings-container">
        <div className="flex-grow-shrink-auto">
          <DataContext.Consumer>
            {({ ...dataSettings }) => (
              <ComponentContext.Consumer>
                {({ setSettings, ...settings }) => (
                  <React.Fragment>
                    <DataSettings
                      showRegions={true}
                      showDataButton={false}
                      {...dataSettings}
                    />
                    <AdvancedChartSettingsComponent
                      setSettings={setSettings}
                      {...settings}
                      {...dataSettings}
                      {...this.props}
                    />
                  </React.Fragment>
                )}
              </ComponentContext.Consumer>
            )}
          </DataContext.Consumer>
        </div>
        {(customize && (
          <SaveDashboardItemChangesButton
            itemId={this.context.itemId}
            dashboardId={this.context.dashboardId}
            context={this.context}
          />
        )) || (
          <AddDashboardButton
            onClick={() => {
              this.context.setSettings({ showAddDashboardDialog: true });
            }}
          />
        )}
      </div>
    );
  };
}
AdvancedChartSettings.contextType = ComponentContext;

const chartTypes = {
  bar: "Bar",
  step: "Step",
  line: "Line",
  spline: "Spline",
  area: "Area",
  "area-spline": "Area Spline",
  "stacked-bar": "Stacked Bar",
  "stacked-area": "Stacked Area",
  "stacked-area-spline": "Stacked Area Spline",
  pie: "Pie",
  donut: "Donut",
  gauge: "Gauge"
};

const axesChartTypes = [
  "bar",
  "step",
  "line",
  "spline",
  "area",
  "area-spline",
  "stacked-bar",
  "stacked-area",
  "stacked-area-spline"
];

const styles = {
  inlineSwitch: {
    width: "5em",
    marginRight: "3em",
    marginBottom: "-.48em"
  },
  axisLineSelect: {
    width: "70%"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  marginBottom25em: {
    marginBottom: ".25em"
  },
  settingsLeftTextField: {
    width: "6em"
  },
  settingsRightTextField: {
    width: "6em"
  },
  chooseColorButton: {
    width: "100%",
    marginBottom: "4px"
  },
  padGutter8px: {
    paddingLeft: "8px",
    paddingRight: "8px"
  }
};

class SettingsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      possibleXAxisLines: [],
      possibleYAxisLines: [],
      metrics: ["Incident Time"],
      calculations: ["Count"],
      aggregations: ["None"],
      columns: [],
      categoryValues: ["None"],
      colorPickerCategories: [],
      showCategoryColorPicker: false
    };
  }

  static defaultProps = {
    loading: true
  };

  componentDidMount = () => {
    this.getAvailableSettings();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.calculation != prevProps.calculation) {
      this.getCalculationParameters();
    }

    if (!this.props.dashboardMode && !this.props.loading) {
      if (
        this.state.metrics != prevState.metrics &&
        this.state.metrics.length > 0
      ) {
        if (!this.props.metric) {
          this.props.setSettings({ metric: this.state.metrics[0] });
        }
      }

      if (
        this.state.calculations != prevState.calculations &&
        this.state.calculations.length > 0
      ) {
        if (!this.props.calculation) {
          this.props.setSettings({
            calculation: this.state.calculations[0]
          });
        }
      }

      if (
        this.props.calculation != prevProps.calculation ||
        this.props.metric != prevProps.metric ||
        this.props.aggregation != prevProps.aggregation
      ) {
        this.props.setSettings({ yAxisMax: "", yAxisMin: "" });
      }

      if (
        this.props.aggregation != prevProps.aggregation ||
        this.props.dataStart != prevProps.dataStart ||
        this.props.dataEnd != prevProps.dataEnd
      ) {
        this.props.setSettings({ selectedColumns: [] });
        this.getColumnValues();
      }

      if (
        this.props.category != prevProps.category ||
        this.props.dataStart != prevProps.dataStart ||
        this.props.dataEnd != prevProps.dataEnd
      ) {
        this.props.setSettings({ selectedCategories: [] });
        this.getCategoryValues();
      }

      if (this.state.categoryValues !== prevState.categoryValues) {
        this.updateColors();
      }

      if (
        this.props.calcParam1Name !== prevProps.calcParam1Name ||
        this.props.calcParam2Name !== prevProps.calcParam2Name
      ) {
        this.setState({
          showCalcParam1: this.props.calcParam1Name != "",
          showCalcParam2: this.props.calcParam2Name != ""
        });
      }
    }
  };

  getAvailableSettings = async () => {
    const gettingMetrics = api.getMetricList();
    const gettingCalculations = api.getCalculationList();
    const gettingAggregations = api.getAggregationList();

    const metrics = await gettingMetrics;
    const calculations = await gettingCalculations;
    const aggregations = await gettingAggregations;

    this.setState({
      metrics: metrics,
      calculations: calculations,
      aggregations: aggregations
    });
  };

  getCalculationParameters = async () => {
    var parameters = await api.getCalculationParameters(this.props.calculation);
    var calcParam1Name = "";
    var calcParam2Name = "";
    if (parameters && parameters.length > 0) calcParam1Name = parameters[0];

    if (parameters && parameters.length > 1) calcParam2Name = parameters[1];

    this.props.setSettings({
      calcParam1Name: calcParam1Name,
      calcParam2Name: calcParam2Name
    });
  };

  getColumnValues = async () => {
    const columns = await api.getAggregationCategories(
      this.props.aggregation,
      this.props.dataStart,
      this.props.dataEnd,
      null,
      null
    );

    this.setState({ columns: columns });
  };

  getCategoryValues = async () => {
    const categories = await api.getAggregationCategories(
      this.props.category,
      this.props.dataStart,
      this.props.dataEnd,
      null,
      null
    );

    this.setState({ categoryValues: categories });
  };

  render() {
    const {
      classes,
      title,
      drawTitle,
      drawDates,
      datePosition,
      type,
      gaugeMax,
      xAxisLines,
      yAxisLines,
      yAxisMin,
      yAxisMax,
      metric,
      calculation,
      calcParam1Name,
      calcParam1,
      calcParam2Name,
      calcParam2,
      aggregation,
      selectedColumns,
      category,
      selectedCategories,
      groupCategories
    } = this.props;
    return (
      <React.Fragment>
        <HeaderGroup
          id="generalGroupOpen"
          onClick={this.toggleHeader}
          in={this.state.generalGroupOpen}
          label="General"
        >
          <div className={classes.padGutter8px}>
            <TextField
              className="settings-246px"
              id="title"
              label="Title"
              value={title}
              onChange={this.settingChanged}
            />
            <FormControl className="margin-bottom-8px fullWidth">
              <InputLabel>Type</InputLabel>
              <NativeSelect
                value={type}
                onChange={this.settingChanged}
                type="select"
                name="selectType"
                id="type"
              >
                {buildNativeOptions(chartTypes)}
              </NativeSelect>
            </FormControl>
            <Collapse in={type == "gauge"}>
              <TextField
                className="settings-246px"
                id="gaugeMax"
                label="100% Value"
                value={gaugeMax}
                onChange={this.settingChanged}
                type="number"
              />
            </Collapse>
          </div>
          <div className={"flex " + classes.padGutter8px}>
            <FormControlLabel
              control={
                <Switch
                  checked={this.props.drawTitle}
                  id="drawTitle"
                  onChange={this.toggleSettingsValue}
                  value="drawTitle"
                  color="secondary"
                />
              }
              label="Title"
              className={classes.inlineSwitch}
            />
            <FormControl className="settings-select">
              <InputLabel>Position</InputLabel>
              <NativeSelect
                value={this.props.titlePosition}
                id="titlePosition"
                onChange={this.settingChanged}
                input={<Input name="titlePosition" />}
                disabled={!drawTitle}
              >
                {Object.keys(textPositions).map(position => {
                  return (
                    <option
                      key={position}
                      value={position}
                      label={textPositions[position]}
                    >
                      {textPositions[position]}
                    </option>
                  );
                })}
              </NativeSelect>
            </FormControl>
          </div>
          <div className={"flex " + classes.padGutter8px}>
            <FormControlLabel
              control={
                <Switch
                  checked={this.props.drawDates}
                  id="drawDates"
                  onChange={this.toggleSettingsValue}
                  value="drawDates"
                  color="secondary"
                />
              }
              label="Dates"
              className={classes.inlineSwitch}
            />
            <FormControl className="settings-select">
              <InputLabel>Position</InputLabel>
              <NativeSelect
                value={datePosition}
                id="datePosition"
                onChange={this.settingChanged}
                input={<Input name="datePosition" />}
                disabled={!drawDates}
              >
                {Object.keys(textPositions).map(position => {
                  return (
                    <option
                      key={position}
                      value={position}
                      label={textPositions[position]}
                    >
                      {textPositions[position]}
                    </option>
                  );
                })}
              </NativeSelect>
            </FormControl>
          </div>
          <Button
            className={classes.chooseColorButton}
            onClick={this.showCategoryColorPicker}
            variant="contained"
            color="primary"
          >
            Choose Colors
          </Button>
        </HeaderGroup>

        <HeaderGroup
          id="measuresGroupOpen"
          onClick={this.toggleHeader}
          in={this.state.measuresGroupOpen}
          label="Measures"
        >
          <div className="pad-gutter-8px">
            <FormControl className="margin-bottom-8px fullWidth">
              <InputLabel>Measure</InputLabel>
              <NativeSelect
                value={metric}
                onChange={this.settingChanged}
                type="select"
                name="selectMeasure"
                id="metric"
              >
                {buildNativeOptions(this.state.metrics)}
              </NativeSelect>
            </FormControl>
            <Collapse in={metric != "Utilization"}>
              <FormControl className="margin-bottom-8px fullWidth">
                <InputLabel>Calculation</InputLabel>
                <NativeSelect
                  value={calculation}
                  onChange={this.settingChanged}
                  type="select"
                  name="selectCalculation"
                  id="calculation"
                >
                  {buildNativeOptions(this.state.calculations)}
                </NativeSelect>
              </FormControl>
              <Collapse in={this.state.showCalcParam1}>
                <TextField
                  className="settings-246px"
                  id="calcParam1"
                  label={calcParam1Name}
                  value={calcParam1}
                  onChange={this.settingChanged}
                />
              </Collapse>
              <Collapse in={this.state.showCalcParam2}>
                <TextField
                  className="settings-246px"
                  id="calcParam2"
                  label={calcParam2Name}
                  value={calcParam2}
                  onChange={this.settingChanged}
                />
              </Collapse>
            </Collapse>
            <Collapse in={axesChartTypes.includes(type)}>
              <FormControl className="margin-bottom-8px fullWidth">
                <InputLabel>X-Axis Grouping</InputLabel>
                <NativeSelect
                  value={aggregation}
                  onChange={this.settingChanged}
                  type="select"
                  name="selectXAxisGrouping"
                  id="aggregation"
                >
                  {buildNativeOptions(this.state.aggregations)}
                </NativeSelect>
              </FormControl>
              <FormControl className="margin-bottom-8px fullWidth">
                <InputLabel>Included Columns</InputLabel>
                <Select
                  multiple
                  value={selectedColumns}
                  onChange={this.selectedColumnsChanged}
                  input={<Input id="selectedColumnsInput" />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(value => (
                        <DeletableChip
                          key={value}
                          label={value}
                          className={classes.chip}
                          onDelete={() => {
                            this.removeFromSelectedColumns(value);
                          }}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={DefaultMenuProps}
                >
                  {this.state.columns &&
                    this.state.columns.map(column => (
                      <MenuItem
                        key={column}
                        value={column}
                        style={this.getFilterStyle(
                          column,
                          selectedColumns,
                          this.props.theme
                        )}
                      >
                        <ListItemText primary={column} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Collapse>
            <FormControl className="margin-bottom-8px fullWidth">
              <InputLabel>Category</InputLabel>
              <NativeSelect
                value={category}
                onChange={this.settingChanged}
                type="select"
                name="selectXAxisCategory"
                id="category"
              >
                {buildNativeOptions(this.state.aggregations)}
              </NativeSelect>
            </FormControl>
            <FormControl className="margin-bottom-8px fullWidth">
              <InputLabel>Included Categories</InputLabel>
              <Select
                multiple
                value={selectedCategories}
                onChange={this.selectedCategoriesChanged}
                input={<Input id="selectedCategoriesInput" />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <DeletableChip
                        key={value}
                        label={value}
                        className={classes.chip}
                        onDelete={() => {
                          this.removeFromSelectedCategories(value);
                        }}
                      />
                    ))}
                  </div>
                )}
                MenuProps={DefaultMenuProps}
              >
                {this.state.categoryValues &&
                  this.state.categoryValues.map(category => (
                    <MenuItem
                      key={category}
                      value={category}
                      style={this.getFilterStyle(
                        category,
                        selectedCategories,
                        this.props.theme
                      )}
                    >
                      <ListItemText primary={category} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </HeaderGroup>
        <Collapse in={axesChartTypes.includes(type)}>
          <HeaderGroup
            id="axesGroupOpen"
            onClick={this.toggleHeader}
            in={this.state.axesGroupOpen}
            label="Axes"
          >
            <div className={classes.padGutter8px}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.marginBottom25em}
              >
                <TextField
                  id="yAxisMin"
                  label="Y-Axis Min"
                  value={yAxisMin}
                  onChange={this.settingChanged}
                  type="number"
                  className={classes.settingsLeftTextField}
                />
                <TextField
                  id="yAxisMax"
                  label="Y-Axis Max"
                  value={yAxisMax}
                  onChange={this.settingChanged}
                  type="number"
                  className={classes.settingsRightTextField}
                />
              </Grid>
            </div>
            <div className="pad-left-8px">
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="baseline"
              >
                <FormControl className={classes.axisLineSelect}>
                  <InputLabel>X-Axis Lines</InputLabel>
                  <Select
                    multiple
                    id="xAxisLines"
                    value={xAxisLines}
                    onChange={this.selectedXAxisLinesChanged}
                    input={<Input id="xAxisLinesInput" />}
                    renderValue={selected => (
                      <div className={classes.chips}>
                        {selected.map(value => (
                          <DeletableChip
                            key={value.text}
                            label={value.text}
                            className={classes.chip}
                            onDelete={() => {
                              this.removeFromXAxisLineSelection(value);
                            }}
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={DefaultMenuProps}
                  >
                    {this.state.possibleXAxisLines.map(line => (
                      <MenuItem
                        key={line}
                        value={line}
                        style={this.getFilterStyle(
                          line,
                          xAxisLines,
                          this.props.theme
                        )}
                      >
                        <ListItemText primary={line.text} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <AddButton
                  id="addXAxisLine"
                  onClick={this.toggleAddXAxisLine}
                />
                {this.state.showAddXAxisLine && (
                  <AddXAxisLineDialog
                    open={this.state.showAddXAxisLine}
                    hide={this.toggleAddXAxisLine}
                    onAdd={this.addXAxisLine}
                  />
                )}
              </Grid>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="baseline"
              >
                <FormControl className={classes.axisLineSelect}>
                  <InputLabel>Y-Axis Lines</InputLabel>
                  <Select
                    multiple
                    id="yAxisLines"
                    value={yAxisLines}
                    onChange={this.selectedYAxisLinesChanged}
                    input={<Input id="yAxisLinesInput" />}
                    renderValue={selected => (
                      <div className={classes.chips}>
                        {selected.map(value => (
                          <DeletableChip
                            key={value.text}
                            label={value.text}
                            className={classes.chip}
                            onDelete={() => {
                              this.removeFromYAxisLineSelection(value);
                            }}
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={DefaultMenuProps}
                  >
                    {this.state.possibleYAxisLines.map(line => (
                      <MenuItem
                        key={line}
                        value={line}
                        style={this.getFilterStyle(
                          line,
                          yAxisLines,
                          this.props.theme
                        )}
                      >
                        <ListItemText primary={line.text} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <AddButton
                  id="addYAxisLine"
                  onClick={this.toggleAddYAxisLine}
                />
                {this.state.showAddYAxisLine && (
                  <AddYAxisLineDialog
                    open={this.state.showAddYAxisLine}
                    hide={this.toggleAddYAxisLine}
                    onAdd={this.addYAxisLine}
                  />
                )}
              </Grid>
            </div>
          </HeaderGroup>
        </Collapse>
        <CategoryColorPicker
          categories={this.state.colorPickerCategories}
          setCategoryColor={setCategoryColor}
          getColorForCategory={getCategoryColor}
          open={this.state.showCategoryColorPicker}
          onClose={this.hideCategoryColorPicker}
        />
      </React.Fragment>
    );
  }

  getFilterStyle = (item, items, theme) => {
    return {
      fontWeight:
        items && items.indexOf(item) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium
    };
  };

  toggleSettingsValue = event => {
    this.props.setSettings({
      [event.target.id]: !this.props[event.target.id]
    });
  };

  settingChanged = event => {
    this.props.setSettings({
      [event.target.id]: event.target.value
    });
  };

  selectedColumnsChanged = event => {
    this.props.setSettings({
      selectedColumns: event.target.value
    });
  };

  removeFromSelectedColumns = value => {
    var newSelection = this.props.selectedColumns.slice();
    if (newSelection.indexOf(value) === -1) return;

    newSelection.splice(newSelection.indexOf(value), 1);
    this.props.setSettings({ selectedColumns: newSelection });
  };

  selectedCategoriesChanged = event => {
    this.props.setSettings({
      selectedCategories: event.target.value
    });
  };

  removeFromSelectedCategories = value => {
    var newSelection = this.props.selectedCategories.slice();
    if (newSelection.indexOf(value) === -1) return;

    newSelection.splice(newSelection.indexOf(value), 1);
    this.props.setSettings({ selectedCategories: newSelection });
  };

  toggleHeader = event => {
    this.setState({
      [event.target.id]: !this.state[event.target.id]
    });
  };

  toggleAddXAxisLine = event => {
    this.setState({ showAddXAxisLine: !this.state.showAddXAxisLine });
  };

  addXAxisLine = lineInfo => {
    var lines = Array.from(this.props.xAxisLines);
    lines.push(lineInfo);
    this.props.setSettings({ xAxisLines: lines });

    var possibleLines = Array.from(this.state.possibleXAxisLines);
    possibleLines.push(lineInfo);
    this.setState({ possibleXAxisLines: possibleLines });
  };

  removeFromXAxisLineSelection = value => {
    var newSelection = this.props.xAxisLines.slice();
    if (newSelection.indexOf(value) === -1) return;

    newSelection.splice(newSelection.indexOf(value), 1);
    this.props.setSettings({ xAxisLines: newSelection });
  };

  selectedXAxisLinesChanged = event => {
    this.props.setSettings({
      xAxisLines: event.target.value
    });
  };

  toggleAddYAxisLine = event => {
    this.setState({ showAddYAxisLine: !this.state.showAddYAxisLine });
  };

  addYAxisLine = lineInfo => {
    var lines = Array.from(this.props.yAxisLines);
    lines.push(lineInfo);
    this.props.setSettings({ yAxisLines: lines });

    var possibleLines = Array.from(this.state.possibleYAxisLines);
    possibleLines.push(lineInfo);
    this.setState({ possibleYAxisLines: possibleLines });
  };

  removeFromYAxisLineSelection = value => {
    var newSelection = this.props.yAxisLines.slice();
    if (newSelection.indexOf(value) === -1) return;

    newSelection.splice(newSelection.indexOf(value), 1);
    this.props.setSettings({ yAxisLines: newSelection });
  };

  selectedYAxisLinesChanged = event => {
    this.props.setSettings({
      yAxisLines: event.target.value
    });
  };

  showCategoryColorPicker = dataType => {
    let categories = Object.keys(this.props.colors).sort();
    this.setState({
      colorPickerCategories: categories,
      showCategoryColorPicker: true
    });
  };

  hideCategoryColorPicker = () => {
    this.setState({ showCategoryColorPicker: false });
    this.updateColors();
  };

  updateColors = () => {
    this.props.setSettings({ colorUpdateUuid: uuidv4() });
  };
}

SettingsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const AdvancedChartSettingsComponent = withStyles(styles)(
  withTheme(SettingsComponent)
);
