import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  withStyles,
  withTheme,
  Grid,
  Paper,
  Typography,
  CircularProgress
} from "@material-ui/core";

import { uuidv4 } from "uuid";

import {
  PerformanceMapItem,
  TypeMapItem,
  FrequencyMapItem,
  ChartItem,
  SimpleReportItem
} from "./DashboardItems";
import { ComponentContext } from "../DashboardComponent";

const styles = {
  containerDiv: {
    padding: "0em 0.5em"
  },
  dashboardTitleDiv: {
    width: "100%",
    textAlign: "center"
  },
  dashboardTitleTypography: {
    marginTop: "0.1em",
    marginBottom: "0.1em"
  },
  spinnerWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    position: "absolute",
    top: "50%"
  },
  dashboardTitle: {
    fontSize: "2.5em",
    fontWeight: "500"
  },
  noDashboardInfo: {
    fontSize: "1.5em",
    fontWeight: "500",
    textAlign: "center"
  },
  dashboardFullWidthRow: {
    margin: "auto",
    paddingTop: "4px",
    paddingBottom: "4px"
  },
  dashboardRow: {
    paddingTop: "4px",
    paddingBottom: "4px"
  },
  dashboardFullWidthColumn: {
    margin: "auto"
  },
  dashboardLeftColumn: {
    marginLeft: "auto",
    paddingRight: "3px"
  },
  dashboardRightColumn: {
    marginRight: "auto",
    paddingLeft: "3px"
  },
  dashboardMiddleColumn: {
    paddingLeft: "3px",
    paddingRight: "3px"
  },
  dashboardPaper: {
    width: "100%"
  }
};

const maxDashboardComponents = 32;

class DashboardRendererComponent extends Component {
  constructor(props) {
    super(props);
  }

  state = {};

  propsRequiringUpdate = [];
  stateRequiringUpdate = ["loading", "dashboard"];

  shouldComponentUpdate = async (nextProps, nextState) => {
    let updateRequired = false;
    let propKeys = Object.keys(this.props);
    let nextPropKeys = Object.keys(nextProps);
    for (var key in nextPropKeys) {
      if (!propKeys.includes(nextPropKeys[key]))
        propKeys.push(nextPropKeys[key]);
    }

    let changedProps = [];
    let value;
    for (var key in propKeys) {
      value = propKeys[key];
      if (this.props[value] !== nextProps[value]) {
        changedProps.push(value);
        if (this.propsRequiringUpdate.includes(value)) {
          updateRequired = true;
        }
      }
    }

    let stateKeys = Object.keys(this.state);
    let nextStateKeys = Object.keys(nextState);
    for (var key in nextStateKeys) {
      value = nextStateKeys[key];
      if (!stateKeys.includes(value)) stateKeys.push(value);
    }

    let changedState = [];
    for (var key in stateKeys) {
      value = stateKeys[key];
      if (this.state[value] !== nextState[value]) {
        changedState.push(value);
        if (this.stateRequiringUpdate.includes(value)) {
          updateRequired = true;
        }
      }
    }

    return updateRequired;
  };

  componentDidUpdate = (prevProps, prevState) => {};

  dashboardRefs = [];

  getDashboardRow = (value, index) => {
    var dashboardItems = this.props.dashboardItems;
    if (!dashboardItems) {
      return <div key={index} />;
    }

    if (index < this.nextIndex) return;

    if (value.desiredWidth == 1 || !value.desiredWidth) {
      return this.getDashboardFullWidthRow(value, index);
    } else {
      return this.getDashboardMultiColumnRow(value, index);
    }
  };

  getDashboardFullWidthRow = (value, index) => {
    var dashboardHeight = this.props.dashboardHeight;
    var dashboardWidth = this.props.dashboardWidth;

    var rowHeight = value.desiredHeight
      ? value.desiredHeight * dashboardHeight
      : 0.5 * dashboardHeight;
    rowHeight = Math.floor(rowHeight);

    var width = value.desiredWidth ? value.desiredWidth * dashboardWidth : 0;
    return (
      <Grid container key={"row" + index} style={styles.dashboardFullWidthRow}>
        <Grid item xs={12}>
          {this.getDashboardComponent(value, index, width, rowHeight)}
        </Grid>
      </Grid>
    );
  };

  getDashboardMultiColumnRow = (value, index) => {
    var dashboard = this.props.dashboard;
    var dashboardHeight = this.props.dashboardHeight;
    var dashboardWidth = this.props.dashboardWidth;

    var orderedItems = this.props.dashboardItems;

    var height = value.desiredHeight
      ? value.desiredHeight * dashboardHeight
      : 0;

    var width = value.desiredWidth ? value.desiredWidth * dashboardWidth : 0;
    var totalWidth = value.desiredWidth;
    var columnValues = [value];
    var desiredWidths = [value.desiredWidth];
    var widths = [width];
    var heights = [height];
    var i = index + 1;
    while (totalWidth < 1 && i < orderedItems.length) {
      var nextValue = orderedItems[i];
      if (totalWidth + nextValue.desiredWidth <= 1) {
        var nextHeight = nextValue.desiredHeight
          ? nextValue.desiredHeight * dashboardHeight
          : 0;
        var nextWidth = nextValue.desiredWidth
          ? nextValue.desiredWidth * dashboardWidth
          : 0;
        columnValues.push(nextValue);
        desiredWidths.push(nextValue.desiredWidth);
        heights.push(nextHeight);
        widths.push(nextWidth);
        totalWidth += nextValue.desiredWidth;
      } else {
        break;
      }
      i = i + 1;
    }

    this.nextIndex = i;

    if (columnValues.length == 1) {
      return (
        <Grid container key={"row" + index} style={styles.dashboardRow}>
          <Grid
            item
            xs={Math.ceil(12 * desiredWidths[0])}
            style={styles.dashboardFullWidthColumn}
          >
            {this.getDashboardComponent(value, index, widths[0], heights[0])}
          </Grid>
        </Grid>
      );
    } else if (columnValues.length == 2) {
      return (
        <Grid
          container
          key={"row" + index + "-" + index + 1}
          style={styles.dashboardRow}
          alignItems="center"
        >
          <Grid
            item
            xs={Math.ceil(12 * desiredWidths[0])}
            style={styles.dashboardLeftColumn}
          >
            {this.getDashboardComponent(
              value,
              index,
              widths[0] - 3,
              heights[0]
            )}
          </Grid>
          <Grid
            item
            xs={Math.ceil(12 * desiredWidths[1])}
            style={styles.dashboardRightColumn}
          >
            {this.getDashboardComponent(
              columnValues[1],
              index + 1,
              widths[1] - 3,
              heights[1]
            )}
          </Grid>
        </Grid>
      );
    } else if (columnValues.length == 3) {
      return (
        <Grid
          container
          key={"row" + index + "-" + index + 1 + "-" + index + 2}
          style={styles.dashboardRow}
          alignItems="center"
        >
          <Grid
            item
            xs={Math.ceil(12 * desiredWidths[0])}
            style={styles.dashboardLeftColumn}
          >
            {this.getDashboardComponent(
              value,
              index,
              widths[0] - 4,
              heights[0]
            )}
          </Grid>
          <Grid
            item
            xs={Math.ceil(12 * desiredWidths[1])}
            style={styles.dashboardMiddleColumn}
          >
            {this.getDashboardComponent(
              columnValues[1],
              index + 1,
              widths[1] - 4,
              heights[1]
            )}
          </Grid>
          <Grid
            item
            xs={Math.ceil(12 * desiredWidths[2])}
            style={styles.dashboardRightColumn}
          >
            {this.getDashboardComponent(
              columnValues[2],
              index + 2,
              widths[2] - 4,
              heights[2]
            )}
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid
          container
          key={
            "row" + index + "-" + index + 1 + "-" + index + 2 + "-" + index + 3
          }
          style={styles.dashboardRow}
          alignItems="center"
        >
          <Grid item xs={3} style={{ paddingRight: "3px" }}>
            {this.getDashboardComponent(
              value,
              index,
              widths[0] - 5,
              heights[0]
            )}
          </Grid>
          <Grid item xs={3} style={{ paddingLeft: "3px", paddingRight: "3px" }}>
            {this.getDashboardComponent(
              columnValues[1],
              index + 1,
              widths[1] - 4,
              heights[1]
            )}
          </Grid>
          <Grid item xs={3} style={{ paddingLeft: "3px", paddingRight: "3px" }}>
            {this.getDashboardComponent(
              columnValues[2],
              index + 2,
              widths[2] - 4,
              heights[2]
            )}
          </Grid>
          <Grid item xs={3} style={{ paddingLeft: "3px" }}>
            {this.getDashboardComponent(
              columnValues[3],
              index + 3,
              widths[3] - 5,
              heights[3]
            )}
          </Grid>
        </Grid>
      );
    }
  };

  getDashboardComponent = (value, index, width, height) => {
    if (value.type == "Chart") {
      return this.getChartComponent(value, index, width, height);
    } else if (value.type == "Report") {
      return this.getReportComponent(value, index, width, height);
    } else if (value.type == "Performance Map") {
      return this.getPerformanceMapComponent(value, index, width, height);
    } else if (value.type == "Type Map") {
      return this.getTypeMapComponent(value, index, width, height);
    } else if (value.type == "Frequency Map") {
      return this.getFrequencyMapComponent(value, index, width, height);
    } else {
      return null;
    }
  };

  getChartComponent = (value, index, width, height) => {
    const dashboardSettings = JSON.parse(value.filters);
    dashboardSettings.title = value.title;
    return (
      <ChartItem
        key={index}
        index={index}
        width={width}
        height={height}
        dashboardSettings={dashboardSettings}
        title={value.title}
      />
    );
  };

  getAdvancedChartComponent = (value, index, width, height) => {
    return <div />;
  };

  getReportComponent = (value, index, width, height) => {
    return (
      <SimpleReportItem
        key={index}
        index={index}
        width={width}
        height={height}
        dashboardSettings={JSON.parse(value.filters)}
        title={value.title}
      />
    );
  };

  getPerformanceMapComponent = (value, index, width, height) => {
    return (
      <PerformanceMapItem
        key={index}
        index={index}
        width={width}
        height={height}
        dashboardSettings={JSON.parse(value.filters)}
        title={value.title}
      />
    );
  };

  getTypeMapComponent = (value, index, width, height) => {
    return (
      <TypeMapItem
        key={index}
        index={index}
        width={width}
        height={height}
        dashboardSettings={JSON.parse(value.filters)}
        title={value.title}
      />
    );
  };

  getFrequencyMapComponent = (value, index, width, height) => {
    return (
      <FrequencyMapItem
        key={index}
        index={index}
        width={width}
        height={height}
        dashboardSettings={JSON.parse(value.filters)}
        title={value.title}
      />
    );
  };

  render = () => {
    const { classes, dashboard, dashboardItems } = this.props;
    this.nextIndex = 0;
    return (
      <React.Fragment>
        <div className={classes.containerDiv}>
          {dashboardItems &&
            dashboardItems.map(item => {
              return this.getDashboardRow(item, dashboardItems.indexOf(item));
            })}
        </div>
      </React.Fragment>
    );
  };
}
DashboardRendererComponent.propTypes = {
  classes: PropTypes.object.isRequired
};

export const DashboardRenderer = withStyles(styles)(DashboardRendererComponent);

export default DashboardRenderer;
