import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { setAllDashboards } from "../../redux/actions";
import { getAllDashboards } from "../../redux/selectors";

import {
  withStyles,
  withTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  NativeSelect,
  Input,
  TextField,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Switch,
  InputLabel,
  Divider,
  Collapse
} from "@material-ui/core";

import api from "../../services/Api";

import {
  dateRanges,
  dashboardItemWidths,
  dashboardItemHeights
} from "../../helpers/DashboardHelpers";
import { currentUserHasRole } from "../../helpers/Utilities";
import {
  buildNativeOptions,
  SaveChangesButton
} from "../controls/MenuControls";

const styles = {
  textInput: {
    width: "100%"
  },
  addDashboardContainer: {
    width: "24em",
    minHeight: "16em"
  },
  loadSpinnerContent: {
    display: "flex",
    justifyContent: "center",
    height: "18em"
  },
  loadSpinner: {
    top: "calc(100% - 32px)",
    position: "relative"
  }
};

export function AddDashboardButton(props) {
  return (
    <div className="pad-bottom-p5em flex-shrink-auto">
      <Divider className="bottom-button-divider" variant="middle" />
      <Button
        className="fullWidth margin-top-5px"
        onClick={props.onClick}
        variant="contained"
        color="primary"
      >
        Add to Dashboard
      </Button>
    </div>
  );
}

class AddDashboardDialogComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingAllDashboards: true,
      allDashboards: [],
      selectedDashboard: {
        id: 0,
        title: ""
      },
      dashboardTitle: "",
      title: props.title,
      selectedDateRange: "Selected Dates",
      desiredOrder: 1,
      desiredWidth: dashboardItemWidths[0].value,
      desiredHeight: dashboardItemHeights[0].value,
      maxOrder: 1,
      isJurisdiction: false,
      addButtonDisabled: true
    };
  }

  componentDidMount() {
    this.loadAllDashboards();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.title != prevProps.title) {
      this.setState({
        title: this.props.title
      });
    }

    if (
      (this.props.open && !prevProps.open) ||
      this.state.selectedDashboard != prevState.selectedDashboard
    ) {
      var info = null;
      var maxOrder = 0;
      if (this.state.selectedDashboard.id) {
        info = await api.getDashboard(this.state.selectedDashboard.id);
        if (info != undefined) {
          for (var i = 0; i < info.dashboardItems.length; i++) {
            maxOrder = Math.max(maxOrder, info.dashboardItems[i].desiredOrder);
          }
        } else {
          maxOrder = 1;
        }
      }

      this.setState({
        selectedDashboardInfo: info,
        desiredOrder: maxOrder + 1,
        maxOrder: maxOrder + 1
      });
    }

    if (
      this.state.loadingAllDashboards !== prevState.loadingAllDashboards ||
      this.state.dashboardTitle !== prevState.dashboardTitle ||
      this.state.selectedDashboard !== prevState.selectedDashboard
    ) {
      if (
        this.state.loadingAllDashboards ||
        !this.state.selectedDashboard ||
        (this.state.selectedDashboard.id == 0 &&
          (!this.state.dashboardTitle || this.state.dashboardTitle == ""))
      ) {
        this.setState({ addButtonDisabled: true });
      } else {
        this.setState({ addButtonDisabled: false });
      }
    }
  }

  loadAllDashboards = async () => {
    this.setState({
      loadingAllDashboards: true
    });
    const dashboards = await api.getAllDashboards();

    // If the api returned a list
    if (dashboards != null) {
      this.setState({
        allDashboards: dashboards
      });

      // If the list had at least one element, set the first dashboard as the selected dashboard
      if (dashboards.length > 0) {
        this.setState({
          selectedDashboard: dashboards[0]
        });
      }
    }

    this.setState({
      loadingAllDashboards: false
    });
  };

  settingChanged = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  selectDashboard = event => {
    this.setState({ dashboard: event.target.value });
    this.setState({ selectedDashboard: JSON.parse(event.target.value) });
  };

  addToDashboard = async event => {
    event.target.disabled = true;

    let dashboardToAddItemTo = this.state.selectedDashboard;

    // If the selected dashboard's ID is 0, then we need to add the dashboard first
    if (this.state.selectedDashboard.id == 0) {
      this.state.selectedDashboard.title = this.state.dashboardTitle;
      dashboardToAddItemTo = await api.addDashboard(
        this.state.selectedDashboard,
        this.state.isJurisdiction
      );
    }

    let dashboardFilters = {};
    dashboardFilters.dateRange =
      this.state.selectedDateRange || "Selected Dates";

    if (this.props.getFilters) {
      var filters = this.props.getFilters();
      let filterKeys = Object.keys(filters);
      for (var i = 0; i < filterKeys.length; i++) {
        dashboardFilters[filterKeys[i]] = filters[filterKeys[i]];
      }
    }

    // Create a dashboard item to add
    const dashboardItem = {
      dashboardId: dashboardToAddItemTo.id,
      filters: JSON.stringify(dashboardFilters),
      type: this.props.type,
      title: this.state.title,
      desiredOrder: this.state.desiredOrder,
      desiredWidth: this.state.desiredWidth,
      desiredHeight: this.state.desiredHeight
    };

    // Add this dashboard item to the selected dashboard
    await api.addDashboardItem(dashboardItem);

    // Refresh dashboards if we created a new one
    if (this.state.selectedDashboard.id == 0) {
      var dashboards = await api.getAllDashboards();

      this.setState({
        allDashboards: dashboards,
        selectedDashboard: dashboards[dashboards.length - 1]
      });
      this.props.setAllDashboards(dashboards);
    }

    // Hide the modal
    this.props.hide();
  };

  hide = () => {
    this.setState();

    if (this.props.hide) {
      this.props.hide();
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog open={this.props.open}>
        <div className={classes.addDashboardContainer}>
          <DialogTitle>Add to Dashboard</DialogTitle>
          {(this.state.loadingAllDashboards && (
            <DialogContent className={classes.loadSpinnerContent}>
              <CircularProgress
                className={classes.loadSpinner}
                size={64}
                color="secondary"
              />
            </DialogContent>
          )) || (
            <DialogContent>
              <FormControl className="margin-bottom-8px fullWidth">
                <InputLabel>Dashboard</InputLabel>
                <NativeSelect
                  value={this.state.dashboard}
                  onChange={this.selectDashboard}
                  id="selectedDashboard"
                  type="select"
                  name="selectDashboard"
                >
                  {this.state.allDashboards.map((value, index) => (
                    <option key={index} value={JSON.stringify(value)}>
                      {value.title}
                    </option>
                  ))}
                  <option value={JSON.stringify({ id: 0, title: "" })}>
                    New Dashboard
                  </option>
                </NativeSelect>
              </FormControl>
              <Collapse
                in={
                  this.state.selectedDashboard != null &&
                  this.state.selectedDashboard.id == 0
                }
              >
                <TextField
                  className="fullWidth"
                  id="dashboardTitle"
                  label="Dashboard Name"
                  value={this.state.dashboardTitle}
                  onChange={this.settingChanged}
                />
                {currentUserHasRole("PowerUser") && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.isJurisdiction}
                        onChange={() => {
                          this.setState({
                            isJurisdiction: !this.state.isJurisdiction
                          });
                        }}
                        value="isJurisdiction"
                        color="secondary"
                      />
                    }
                    label="Share with Jurisdiction"
                  />
                )}
              </Collapse>
              <Divider />
              <TextField
                className="fullWidth"
                id="title"
                label="Title"
                value={this.state.title}
                onChange={this.settingChanged}
              />
              <TextField
                className="fullWidth"
                id="desiredOrder"
                label="Desired Order"
                value={this.state.desiredOrder}
                onChange={this.settingChanged}
                type="number"
                inputProps={{ min: 1, max: this.state.maxOrder, step: 1 }}
              />
              <FormControl className="margin-bottom-8px fullWidth">
                <InputLabel>Width</InputLabel>
                <NativeSelect
                  value={this.state.desiredWidth}
                  onChange={this.settingChanged}
                  id="desiredWidth"
                  type="select"
                  name="selectWidth"
                >
                  {dashboardItemWidths.map((value, index) => (
                    <option key={index} value={value.value}>
                      {value.label}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
              <FormControl className="margin-bottom-8px fullWidth">
                <InputLabel>Height</InputLabel>
                <NativeSelect
                  value={this.state.desiredHeight}
                  onChange={this.settingChanged}
                  id="desiredHeight"
                  type="select"
                  name="selectHeight"
                >
                  {dashboardItemHeights.map((value, index) => (
                    <option key={index} value={value.value}>
                      {value.label}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
              <FormControl className="margin-bottom-8px fullWidth">
                <InputLabel>Date Range</InputLabel>
                <NativeSelect
                  value={this.state.selectedDateRange}
                  onChange={this.settingChanged}
                  id="selectedDateRange"
                  type="select"
                  name="selectDateRange"
                >
                  {dateRanges.map((value, index) => (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
            </DialogContent>
          )}
          <DialogActions>
            <Button
              color="primary"
              disabled={this.state.addButtonDisabled}
              onClick={this.addToDashboard}
            >
              Add
            </Button>
            <Button color="primary" onClick={this.hide}>
              Cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

AddDashboardDialogComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const allDashboards = getAllDashboards(state);
  return { allDashboards };
};

const mapDispatchToProps = {
  setAllDashboards
};

export const AddDashboardDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTheme(AddDashboardDialogComponent)));

export class SaveDashboardItemChangesButton extends Component {
  constructor(props) {
    super(props);
  }

  saveChanges = async () => {
    if (!this.props.itemId || !this.props.context) {
      return;
    }

    var newSettings = this.props.context.getSettings();
    var filters = JSON.stringify(newSettings);
    var response = await api.updateDashboardItem(
      this.props.dashboardId,
      this.props.itemId,
      newSettings.title,
      filters
    );

    if (response.status == 200) {
      window.close();
    }
  };

  render = () => {
    return <SaveChangesButton onClick={this.saveChanges} />;
  };
}
