import React, { useState, useEffect } from "react";
import {
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Spinner,
  Row,
  FormGroup,
  Label
} from "reactstrap";
import api from "../../services/Api";
import CustomContainer from "../CustomContainer";
import { Redirect, withRouter } from "react-router-dom";
import { currentUserHasRole } from "../../helpers/Utilities";

import { connect } from "react-redux";
import { setSettingsEnabled } from "../../redux/actions";

const styles = {
  pageTitle: {
    justifyContent: "center",
    marginTop: "30px"
  },
  loadingWrapper: {
    display: "flex",
    justifyContent: "center"
  },
  userInfoRow: {
    justifyContent: "center",
    marginLeft: "0px",
    marginRight: "0px"
  },
  actionRow: {
    justifyContent: "center",
    marginTop: "10px",
    marginLeft: "0px",
    marginRight: "0px"
  },
  textInput: {
    marginTop: "10px"
  }
};

function UserSettings(props) {
  const [user, setUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [redirectLocation, setRedirectLocation] = useState(null);

  useEffect(() => {
    props.setSettingsEnabled(false);
    loadUser();
  }, []);

  async function loadUser() {
    // Mark that we're loading
    setLoading(true);

    // Clear the user
    setUser(null);

    let downloadedUser;
    let current = await api.getCurrentUser();
    setCurrentUser(current);

    // Download the user information
    if (props.match && props.match.params && props.match.params.userId) {
      downloadedUser = await api.getUser(props.match.params.userId);
    }
    // Download the current user's information
    else {
      downloadedUser = current;
    }

    // Split the roles up into an array so we can check them
    let rolesArray = downloadedUser.roles.split(",");

    // Add the roles as booleans so the UI can manipulate them
    downloadedUser.roleValues = {
      user: rolesArray.includes("User"),
      powerUser: rolesArray.includes("PowerUser"),
      admin: rolesArray.includes("Admin"),
      sysAdmin: rolesArray.includes("SysAdmin")
    };

    setUser(downloadedUser);

    // We're no longer loading
    setLoading(false);
  }

  if (redirectLocation) {
    return (
      <Redirect
        to={{
          pathname: redirectLocation,
          state: { from: props.location }
        }}
      />
    );
  }

  if (loading) {
    return (
      <div style={styles.loadingWrapper}>
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <CustomContainer>
      <div className="container">
        <Row style={styles.pageTitle}>
          <h1>User Settings</h1>
        </Row>
        <Row style={styles.userInfoRow}>
          <InputGroup style={styles.textInput}>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>First Name</InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              value={user.firstName}
              onChange={event => {
                // Update the first name
                let userCopy = JSON.parse(JSON.stringify(user));
                userCopy.firstName = event.target.value;

                // Save the user
                setUser(userCopy);
              }}
            />
          </InputGroup>
          <InputGroup style={styles.textInput}>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Last Name</InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              value={user.lastName}
              onChange={event => {
                // Update the first name
                let userCopy = JSON.parse(JSON.stringify(user));
                userCopy.lastName = event.target.value;

                // Save the user
                setUser(userCopy);
              }}
            />
          </InputGroup>
          <InputGroup style={styles.textInput}>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Email</InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              value={user.email}
              onChange={event => {
                // Update the first name
                let userCopy = JSON.parse(JSON.stringify(user));
                userCopy.email = event.target.value;

                // Save the user
                setUser(userCopy);
              }}
            />
          </InputGroup>
        </Row>
        {currentUserHasRole("SysAdmin") === true && (
          <div>
            <Row style={styles.pageTitle}>
              <h2>Roles</h2>
            </Row>
            <Row>
              <FormGroup check>
                <Label check xs={12}>
                  <Input
                    type="checkbox"
                    checked={user.roleValues.user}
                    onChange={event => {
                      // Update the role
                      let userCopy = JSON.parse(JSON.stringify(user));
                      userCopy.roleValues.user = event.target.checked;

                      // Save the user
                      setUser(userCopy);
                    }}
                  />{" "}
                  User
                </Label>
                <Label check xs={12}>
                  <Input
                    type="checkbox"
                    checked={user.roleValues.powerUser}
                    onChange={event => {
                      // Update the role
                      let userCopy = JSON.parse(JSON.stringify(user));
                      userCopy.roleValues.powerUser = event.target.checked;

                      // Save the user
                      setUser(userCopy);
                    }}
                  />{" "}
                  PowerUser
                </Label>
                <Label check xs={12}>
                  <Input
                    type="checkbox"
                    checked={user.roleValues.admin}
                    onChange={event => {
                      // Update the role
                      let userCopy = JSON.parse(JSON.stringify(user));
                      userCopy.roleValues.admin = event.target.checked;

                      // Save the user
                      setUser(userCopy);
                    }}
                  />{" "}
                  Admin
                </Label>
                <Label check xs={12}>
                  <Input
                    type="checkbox"
                    checked={user.roleValues.sysAdmin}
                    onChange={event => {
                      // Update the role
                      let userCopy = JSON.parse(JSON.stringify(user));
                      userCopy.roleValues.sysAdmin = event.target.checked;

                      // Save the user
                      setUser(userCopy);
                    }}
                  />{" "}
                  SysAdmin
                </Label>
              </FormGroup>
            </Row>
          </div>
        )}
        <Row style={styles.actionRow}>
          <Button
            color="secondary"
            onClick={async event => {
              setRedirectLocation("/settings/resetPassword");
            }}
            block
          >
            Reset Password
          </Button>
          <Button
            color="secondary"
            onClick={async event => {
              event.target.disabled = true;

              // Create the roles string before pushing to the server
              {
                let rolesString = "";

                if (user.roleValues.user == true) rolesString += "User,";
                if (user.roleValues.powerUser == true)
                  rolesString += "PowerUser,";
                if (user.roleValues.admin == true) rolesString += "Admin,";
                if (user.roleValues.sysAdmin == true)
                  rolesString += "SysAdmin,";

                if (rolesString.length > 0) {
                  rolesString.slice(0, -1);
                }

                user.roles = rolesString;
              }

              await api.saveUser(user);

              loadUser();
            }}
            block
          >
            Save
          </Button>
          {currentUser && user && currentUser.id != user.id && (
            <Button
              color="danger"
              onClick={async event => {
                if (window.confirm("Delete this user?")) {
                  event.target.disabled = true;

                  await api.deleteUser(user.id);

                  props.history.goBack();
                }
              }}
              block
            >
              Delete
            </Button>
          )}
        </Row>
      </div>
    </CustomContainer>
  );
}

const mapDispatchToProps = {
  setSettingsEnabled
};

const UserSettingsContainer = connect(
  null,
  mapDispatchToProps
)(withRouter(UserSettings));

export default UserSettingsContainer;
