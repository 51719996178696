import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles, withTheme, Paper } from "@material-ui/core";

import { ComponentContext } from "../../DashboardComponent";

import uuidv4 from "uuid";

const styles = theme => ({
  paperDiv: { padding: "8px", fontSize: "0.9em" }
});

const maxPointLineCount = 10;

class FrequencyMapTooltipComponent extends Component {
  constructor(props) {
    super(props);
  }

  getTooltipContents = () => {
    if (!this.props.features) {
      return <div />;
    }

    var features = this.props.features;
    var locationData = [];
    var data;

    for (var i = 0; i < features.length; i++) {
      data = features[i].get("data");
      if (data) {
        locationData.push(data);
      }
    }

    var firstLocation;
    if (locationData.length > 0) {
      firstLocation = locationData.shift();
    }

    return (
      <div style={{ textAlign: "left" }}>
        {firstLocation && this.getFirstLocationDetails(firstLocation)}
        {locationData.length > 0 &&
          locationData.map(location => this.getNextLocationDetails(location))}
      </div>
    );
  };

  getFirstLocationDetails = data => {
    var length = data.incidents.length;
    return (
      <React.Fragment>
        <b>{data.location}</b>
        <hr className="no-margin" />
        <div>
          {length} {length == 1 ? "incident" : "incidents"}
        </div>
      </React.Fragment>
    );
  };

  getNextLocationDetails = data => {
    var length = data.incidents.length;
    return (
      <div key={uuidv4()} style={{ marginTop: "1em" }}>
        <b>{data.location}</b>
        <hr className="no-margin" />
        <div>
          {length} {length == 1 ? "incident" : "incidents"}
        </div>
      </div>
    );
  };

  render = () => {
    const { classes } = this.props;

    return (
      <Paper>
        <div className={classes.paperDiv}>{this.getTooltipContents()}</div>
      </Paper>
    );
  };
}
FrequencyMapTooltipComponent.contextType = ComponentContext;

export const FrequencyMapTooltip = withStyles(styles)(
  withTheme(FrequencyMapTooltipComponent)
);

export default FrequencyMapTooltip;
