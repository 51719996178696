import React, { Component } from "react";
import { PropTypes } from "prop-types";

import {
  withStyles,
  withTheme,
  InputLabel,
  TextField,
  Button,
  Collapse,
  Switch,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  Input,
  Slider,
  Grid,
  Typography
} from "@material-ui/core";
import MomentUtils from "@date-io/moment";

import Api from "../../../services/Api.js";

import { Style, Circle, Fill, Stroke } from "ol/style.js";

import {
  HeaderGroup,
  UpdateButton,
  GetDataButton
} from "../../controls/MenuControls";
import {
  AddDashboardDialog,
  AddDashboardButton
} from "../../controls/AddDashboard";
import {
  dateRanges,
  getDatesFromFilters,
  getDateRanges,
  getPercentileValues
} from "../../../helpers/DashboardHelpers.js";

import { gradientColors } from "../../../helpers/Colors";

const styles = {
  settingsLeftTextField: {
    width: "3.75em"
  },
  settingsRightTextField: {
    width: "3.75em"
  },
  marginBottom25em: {
    marginBottom: ".25em"
  }
};

class FrequencyMapPointSettingsComponent extends Component {
  state = { pointGroupOpen: true };

  toggleHeader = event => {
    this.setState({
      [event.target.id]: !this.state[event.target.id]
    });
  };

  toggleValue = event => {
    this.props.setSettings({
      [event.target.id]: !this.props[event.target.id]
    });
  };

  componentDidUpdate = (prevProps, prevState) => {};

  cutoffValueChanged = event => {
    if (event.target.value != this.props.cutoffValue) {
      this.props.setSettings({
        cutoffValue: Math.max(1, parseFloat(event.target.value)),
        lowValue: Math.max(
          parseFloat(event.target.value),
          parseFloat(this.props.lowValue)
        )
      });
    }
  };

  setLowValue = event => {
    var value = Math.max(1, event.target.value);
    this.props.setSettings({
      lowValue: value,
      highValue: Math.max(value, this.props.highValue)
    });
  };

  setHighValue = event => {
    var value = Math.max(1, event.target.value);
    this.props.setSettings({
      lowValue: Math.min(this.props.lowValue, value),
      highValue: value
    });
  };

  gradientChanged = event => {
    if (event.target.value != this.props.gradient) {
      this.props.setSettings({ gradient: event.target.value });
    }
  };

  render = () => {
    const { classes } = this.props;
    return (
      <HeaderGroup
        id="pointGroupOpen"
        onClick={this.toggleHeader}
        in={this.state.pointGroupOpen}
        label="Points"
      >
        <div className="pad-gutter-8px margin-top-4px">
          <FormControl className="margin-bottom-8px fullWidth">
            <InputLabel>Colorization</InputLabel>
            <Select
              value={this.props.gradient}
              onChange={this.gradientChanged}
              type="select"
              name="selectGradient"
              id="gradient"
            >
              {Object.keys(gradientColors).map(gradient => {
                return (
                  <MenuItem key={gradient} value={gradient} label={gradient}>
                    {gradient}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <div className="double-switch-settingsRow">
            <FormControlLabel
              control={
                <Switch
                  id="autoscale"
                  checked={this.props.autoscale}
                  onChange={this.toggleValue}
                  value="autoscale"
                  color="secondary"
                />
              }
              label="Autoscale"
            />
            <FormControlLabel
              control={
                <Switch
                  id="autosize"
                  checked={this.props.autosize}
                  onChange={this.toggleValue}
                  value="autosize"
                  color="secondary"
                />
              }
              label="Autosize"
            />
          </div>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.marginBottom25em}
          >
            <TextField
              id="cutoff-value"
              label="Cutoff"
              value={this.props.cutoffValue}
              onChange={this.cutoffValueChanged}
              type="number"
              className={classes.settingsLeftTextField}
            />
            <TextField
              id="low-value"
              label="Low"
              value={this.props.lowValue}
              onChange={this.setLowValue}
              type="number"
              className={classes.settingsLeftTextField}
              disabled={this.props.autoscale}
            />
            <TextField
              id="high-value"
              label="High"
              value={this.props.highValue}
              onChange={this.setHighValue}
              type="number"
              className={classes.settingsRightTextField}
              disabled={this.props.autoscale}
            />
          </Grid>
        </div>
      </HeaderGroup>
    );
  };
}
FrequencyMapPointSettingsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const FrequencyMapPointSettings = withStyles(styles)(
  withTheme(FrequencyMapPointSettingsComponent)
);

export default FrequencyMapPointSettings;
