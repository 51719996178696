import { TOGGLE_SETTINGS_OPEN, CLOSE_SETTINGS } from "../actionTypes";

let settingsOpen = false;

const toggleSettingsOpen = (state, action) => {
  switch (action.type) {
    case TOGGLE_SETTINGS_OPEN:
      settingsOpen = !state;
      return settingsOpen;
    case CLOSE_SETTINGS:
      settingsOpen = false;
      return settingsOpen;
    default:
      return settingsOpen;
  }
};

export default toggleSettingsOpen;
