import React, { Component } from "react";
import { Dropzone } from "./Dropzone";
import { Progress } from "./Progress";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";

import api from "../../services/Api";
import axios from "axios";

const API_BASE_URL =
  process.env.REACT_APP_ENV == "development"
    ? "http://localhost:5000/api"
    : "/api";

export class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullyUploaded: false
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && this.props.open != prevProps.open) {
      this.setState({ files: [], successfullyUploaded: false });
    }
  }

  onFilesAdded = files => {
    this.setState(prevState => ({
      files: prevState.files.concat(files)
    }));
  };

  uploadFiles = async () => {
    this.setState({ uploadProgress: {}, uploading: true });
    this.promises = [];
    this.promiseCancels = [];
    this.state.files.forEach(file => {
      this.promises.push(this.sendRequest(file));
    });
    try {
      await Promise.all(this.promises);

      this.setState({ successfullyUploaded: true, uploading: false });
      if (this.props.hide) {
        this.props.hide();
      }
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullyUploaded: false, uploading: false });
    }
  };

  sendRequest = file => {
    return new Promise(async (resolve, reject) => {
      var progressListener = event => {
        if (event.lengthComputable) {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100
          };
          this.setState({ uploadProgress: copy });
        }
      };
      var loadListener = event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "done", percentage: 100 };
        this.setState({ uploadProgress: copy });
        resolve();
      };

      var errorListener = event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "error", percentage: 0 };
        this.setState({ uploadProgress: copy });
        reject();
      };

      const formData = new FormData();
      formData.append("file", file, file.name);

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      this.promiseCancels.push(source);

      await this.props.uploadData(
        formData,
        file.name,
        progressListener,
        loadListener,
        errorListener,
        source
      );
    });
  };

  renderProgress = file => {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullyUploaded) {
      return (
        <div className="ProgressWrapper">
          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
          <img
            className="CheckIcon"
            alt="done"
            src="baseline-check_circle_outline-24px.svg"
            style={{
              opacity:
                uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
            }}
          />
        </div>
      );
    }
  };

  renderActions = () => {
    if (this.state.successfullyUploaded) {
      return (
        <Button
          onClick={() =>
            this.setState({ files: [], successfullyUploaded: false })
          }
          variant="contained"
          color="primary"
        >
          Clear
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          color="secondary"
          disabled={this.state.files.length <= 0 || this.state.uploading}
          onClick={this.uploadFiles}
        >
          Upload
        </Button>
      );
    }
  };

  cancelUpload = () => {
    if (this.promiseCancels) {
      for (var i = 0; i < this.promiseCancels.length; i++) {
        this.promiseCancels[0].cancel();
      }
    }

    if (this.props.onCancel) {
      this.props.onCancel();
    }

    if (this.props.hide) {
      this.props.hide();
    }
  };

  render() {
    return (
      <Dialog
        style={{ padding: ".75rem", textAlign: "center" }}
        open={this.props.open}
      >
        <DialogTitle>{this.props.title || "Upload Files"}</DialogTitle>
        <DialogContent>
          <div
            className="Content"
            style={{
              marginTop: ".5rem",
              marginBottom: ".75rem",
              width: "400px"
            }}
          >
            <div>
              <Dropzone
                style={{ margin: "auto" }}
                onFilesAdded={this.onFilesAdded}
                disabled={
                  this.state.uploading || this.state.successfullyUploaded
                }
                multiple={this.props.multiple}
              />
            </div>
            <div className="Files" style={{ marginTop: ".5rem" }}>
              {this.state.files.map(file => {
                return (
                  <div key={file.name} className="Row">
                    <span className="Filename">{file.name}</span>
                    {this.renderProgress(file)}
                  </div>
                );
              })}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {this.renderActions()}
          <Button
            variant="contained"
            color="primary"
            onClick={this.cancelUpload}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
