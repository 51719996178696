import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  withStyles,
  withTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  NativeSelect,
  Input,
  TextField,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Switch,
  InputLabel,
  Divider,
  Collapse
} from "@material-ui/core";

import {
  HeaderGroup,
  DeleteButton,
  CustomizeButton,
  buildNativeOptions
} from "../controls/MenuControls";

import {
  dateRanges,
  dashboardItemWidths,
  dashboardItemHeights,
  lzw_encode
} from "../../helpers/DashboardHelpers";

import { ComponentContext } from "../DashboardComponent";
// import { DashboardContext } from "./Dashboard";

const styles = {
  dashboardItemGroup: {
    marginBottom: "4px",
    paddingLeft: "8px",
    paddingRight: "8px"
  }
};

class DashboardItemSettings extends Component {
  render() {
    return (
      <ComponentContext.Consumer>
        {({ setSettings, ...settings }) => (
          <DashboardItemSettingsComponent
            setSettings={setSettings}
            {...settings}
            {...this.props}
          />
        )}
      </ComponentContext.Consumer>
    );
  }
}

class DashboardItemSettingsComponent extends Component {
  constructor(props) {
    super(props);

    var title = (props.dashboardItem && props.dashboardItem.title) || "";

    this.state = { title: title, showDeleteDialog: false };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.dashboardItem !== prevProps.dashboardItem) {
      if (this.props.dashboardItem) {
        this.setState({ title: this.props.dashboardItem.title });
      }
    }
  };

  toggleHeader = event => {
    this.setState({
      [event.target.id]: !this.state[event.target.id]
    });
  };

  deleteDashboardItem = () => {
    var value = this.props.dashboardItem;
    this.props.deleteDashboardItem(value.id);
    this.toggleDelete();
  };

  updateOrder = event => {
    var value = this.props.dashboardItem;
    var orderedDashboardItems = this.props.orderedDashboardItems;
    if (!value || !orderedDashboardItems) {
      return;
    }

    var index = event.target.value - 1;
    var lastOrder = value.desiredOrder;
    var switchedId = orderedDashboardItems[index].id;
    this.props[switchedId].desiredOrder = lastOrder;
    value.desiredOrder = event.target.value;
    orderedDashboardItems.sort((a, b) => {
      return a.desiredOrder - b.desiredOrder;
    });

    this.props.setSettings({
      orderedDashboardItems: orderedDashboardItems
    });
  };

  titleUpdateTimer = undefined;

  startTitleUpdate = event => {
    // This is really crappy but pretty much the only way I could figure out to keep things from lagging when you type due to React updates
    this.setState({ title: event.target.value });
    if (this.titleUpdateTimer) {
      clearTimeout(this.titleUpdateTimer);
    }

    this.titleUpdateTimer = setTimeout(this.finishTitleUpdate, 100);
  };

  finishTitleUpdate = () => {
    var dashboardItem = this.props.dashboardItem;
    dashboardItem.title = this.state.title;
    this.props.setSettings({ dashboardItem: dashboardItem });
  };

  toggleDelete = () => {
    this.setState({ showDeleteDialog: !this.state.showDeleteDialog });
  };

  customizeItem = () => {
    var value = this.props.dashboardItem;
    let settings = JSON.parse(value.filters);
    settings.title = value.title;
    settings.customize = true;
    settings.itemId = this.props.dashboardItem.id;
    settings.dashboardId = this.props.dashboardItem.dashboardId;

    let objJsonStr = JSON.stringify(settings);

    var path = this.getPathForType(value.type);
    var win = window.open(path + lzw_encode(objJsonStr));
    win.focus();

    window.addEventListener("focus", this.showAlert);
  };

  showAlert = () => {
    alert(
      "If you have made changes to a dashboard item, you will need to refresh the page for them to be displayed."
    );
    window.removeEventListener("focus", this.showAlert);
  };

  getPathForType = componentType => {
    if (componentType == "Chart") return "/chart/advanced/";
    else if (componentType == "Report") return "/report/";
    else if (componentType == "Performance Map") return "/map/performance/";
    else if (componentType == "Type Map") return "/map/type/";
    else if (componentType == "Frequency Map") return "/map/frequency/";
    else return null;
  };

  render() {
    const {
      classes,
      dashboard,
      dashboardItem,
      orderedDashboardItems
    } = this.props;
    var value = dashboardItem;
    var id = "dashboardItem" + value.id;

    const filters = value.parsedFilters;

    if (!dashboard) return null;

    var dashboardItems = dashboard.dashboardItems;
    const title =
      (value.title != undefined && value.title != "" && value.title) ||
      "Dashboard Item #" + value.desiredOrder;

    return (
      <HeaderGroup
        id={"header" + value.id}
        label={title}
        onClick={this.toggleHeader}
        in={this.state["header" + value.id]}
        key={id}
      >
        <div>
          <div className={classes.dashboardItemGroup}>
            <TextField
              className="settings-246px"
              id={id + "Title"}
              label="Title"
              value={this.state.title}
              onChange={this.startTitleUpdate}
            />
            <TextField
              className="fullWidth"
              id={id + "desiredOrder"}
              label="Desired Order"
              value={value.desiredOrder}
              onChange={this.updateOrder}
              type="number"
              inputProps={{
                min: 1,
                max: orderedDashboardItems.length,
                step: 1
              }}
            />
            <FormControl className="margin-bottom-8px fullWidth">
              <InputLabel>Width</InputLabel>
              <NativeSelect
                value={value.desiredWidth}
                onChange={event => {
                  value.desiredWidth = Number.parseFloat(event.target.value);
                  var newItems = JSON.parse(JSON.stringify(dashboardItems));
                  this.props.setSettings({ dashboardItems: newItems });
                }}
                type="select"
                name={id + "SelectWidth"}
                id={id + "width"}
              >
                {dashboardItemWidths.map((value, index) => (
                  <option key={index} value={value.value}>
                    {value.label}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
            <FormControl className="margin-bottom-8px fullWidth">
              <InputLabel>Height</InputLabel>
              <NativeSelect
                value={value.desiredHeight}
                onChange={event => {
                  value.desiredHeight = event.target.value;
                  var newItems = JSON.parse(JSON.stringify(dashboardItems));
                  this.props.setSettings({ dashboardItems: newItems });
                }}
                type="select"
                name={id + "SelectHeight"}
                id={id + "height"}
              >
                {dashboardItemHeights.map((value, index) => (
                  <option key={index} value={value.value}>
                    {value.label}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </div>
          <CustomizeButton onClick={this.customizeItem} />
          <div style={{ width: "100%", height: "8px" }} />
          <DeleteButton onClick={this.toggleDelete} />
          <Dialog open={this.state.showDeleteDialog}>
            <DialogTitle>Delete Item?</DialogTitle>
            <DialogContent>
              Dashboard item '{title}' will be permanently deleted. Continue?
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                onClick={this.deleteDashboardItem}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.toggleDelete}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </HeaderGroup>
    );
  }
}

const DashboardItemSettingsContainer = withStyles(styles)(
  withTheme(DashboardItemSettings)
);

export default DashboardItemSettingsContainer;
