import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  withStyles,
  withTheme,
  FormControl,
  FormControlLabel,
  Switch,
  InputLabel,
  NativeSelect,
  Input,
  Select,
  Chip,
  MenuItem,
  ListItemText,
  Collapse,
  TextField,
  Grid,
  Typography,
  Button,
  Slider
} from "@material-ui/core";

import { gradientColors, getCategoryColor } from "../../../helpers/Colors";
import {
  benchmarkNames,
  calculationNames
} from "../../../helpers/DashboardHelpers.js";
import {
  HeaderGroup,
  DefaultMenuProps,
  getFilterStyle,
  DeletableChip
} from "../../controls/MenuControls";
import { regionCalculationTitles } from "./PerformanceMap";
import ColorSwatch from "../../controls/ColorSwatch";

const styles = {
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  settingsSelect: {
    width: "100%",
    marginBottom: "8px"
  },
  settingsSlider: {
    marginBottom: ".25em"
  },
  settingsLeftTextField: {
    width: "6em"
  },
  settingsRightTextField: {
    width: "6em"
  },
  thresholdGrid: {
    marginBottom: ".25em"
  },
  thresholdSlider: {
    marginTop: ".75em"
    //    marginBottom: ".25em"
  },
  marginBottom25em: {
    marginBottom: ".25em"
  },
  selectAboveSlider: {
    width: "100%",
    marginBottom: "16px"
  }
};

class PerformanceMapRegionSettingsComponent extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    regionGroupOpen: false
  };

  static defaultProps = {
    drawRegions: false,
    dataTypes: {}
  };

  componentDidUpdate = (prevProps, prevState) => {};

  render() {
    const { classes } = this.props;

    return (
      <HeaderGroup
        id="regionGroupOpen"
        onClick={this.toggleHeader}
        in={this.state.regionGroupOpen}
        label="Regions"
      >
        <div className="pad-gutter-8px">
          <FormControlLabel
            control={
              <Switch
                checked={this.props.drawRegions}
                onChange={this.toggleRegions}
                value="drawRegions"
                color="secondary"
              />
            }
            label="Draw Regions"
          />
          <Typography id="region-opacity" gutterBottom>
            Opacity
          </Typography>
          <Slider
            value={this.props.regionOpacity}
            onChangeCommitted={this.onOpacityChanged}
            aria-labelledby="region-opacity"
            min={25}
            max={100}
            valueLabelDisplay="auto"
            className={classes.settingsSlider}
          />
          <FormControl className="settings-select">
            <InputLabel>Regions</InputLabel>
            <Select
              multiple
              value={this.props.regionSelection}
              onChange={this.onSelectionChange}
              input={<Input id="select-multiple-placeholder" />}
              renderValue={selected => (
                <div className={classes.chips}>
                  {selected.map(value => (
                    <DeletableChip
                      key={value}
                      label={value}
                      className={classes.chip}
                      onDelete={() => {
                        this.removeFromSelection(value);
                      }}
                    />
                  ))}
                </div>
              )}
              MenuProps={DefaultMenuProps}
            >
              {this.props.regions &&
                this.props.regions.map(region => (
                  <MenuItem key={region.id} value={region.name}>
                    <Typography
                      primary={region}
                      style={getFilterStyle(
                        region,
                        this.props.regionSelection,
                        this.props.theme
                      )}
                    >
                      {region.name}
                    </Typography>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl style={styles.settingsSelect}>
            <InputLabel>Measure</InputLabel>
            <Select
              value={this.props.regionBenchmark}
              onChange={this.setBenchmark}
              input={<Input name="regionBenchmark" />}
            >
              {this.props.availableBenchmarks.map(benchmark => {
                return (
                  <MenuItem
                    key={benchmark}
                    value={benchmark}
                    label={benchmarkNames[benchmark] || benchmark}
                  >
                    {benchmarkNames[benchmark] || benchmark}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl style={styles.settingsSelect}>
            <InputLabel>Statistic</InputLabel>
            <Select
              value={this.props.regionCalculation}
              onChange={this.setCalculation}
              title={regionCalculationTitles[this.props.regionCalculation]}
              input={<Input name="calculation" />}
            >
              {Object.keys(calculationNames).map(calculation => {
                return (
                  <MenuItem
                    key={calculation}
                    value={calculation}
                    label={calculationNames[calculation]}
                    title={regionCalculationTitles[calculation]}
                  >
                    {calculationNames[calculation]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Collapse in={this.props.regionCalculation == "percentile"}>
            <TextField
              id="percentile-value"
              label="Percentile"
              value={this.props.regionPercentile}
              onChange={this.setPercentile}
              type="number"
              className="settings-246px"
            />
          </Collapse>
          <FormControl style={styles.settingsSelect}>
            <InputLabel>Colorization</InputLabel>
            <Select
              value={this.props.regionGradient}
              onChange={this.setGradient}
              type="select"
              name="selectGradient"
              id="gradient"
            >
              {Object.keys(gradientColors).map(gradient => {
                return (
                  <MenuItem key={gradient} value={gradient} label={gradient}>
                    {gradient}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                id="regionAutoscale"
                checked={this.props.regionAutoscale}
                onChange={this.toggleAutoscale}
                value="autoscale"
                color="secondary"
              />
            }
            label="Use Autoscale"
          />
          <Slider
            value={[this.props.regionLowValue, this.props.regionHighValue]}
            onChange={this.scaleSliderChanged}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            max={30}
            min={0}
            step={0.1}
            disabled={this.props.regionAutoscale}
            style={styles.settingsSlider}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={styles.marginBottom25em}
          >
            <TextField
              id="low-value"
              label="Low"
              value={this.props.regionLowValue}
              onChange={this.setLowValue}
              type="number"
              style={styles.settingsLeftTextField}
              disabled={this.props.regionAutoscale}
            />
            <TextField
              id="high-value"
              label="High"
              value={this.props.regionHighValue}
              onChange={this.setHighValue}
              type="number"
              style={styles.settingsRightTextField}
              disabled={this.props.regionAutoscale}
            />
          </Grid>
        </div>
      </HeaderGroup>
    );
  }

  toggleHeader = () => {
    this.setState({ regionGroupOpen: !this.state.regionGroupOpen });
  };

  toggleRegions = () => {
    this.props.setSettings({
      drawRegions: !this.props.drawRegions
    });
  };

  onOpacityChanged = (event, newValue) => {
    this.props.setSettings({ regionOpacity: newValue });
  };

  setupAutoscale = () => {
    var features;
    var count;
    var incidentCounts = [];
    var j, featureLength, featureCount;

    var regions = this.props.regions;
    for (var i = 0; i < regions.length; i++) {
      if (regions[i].incidents) {
        incidentCounts.push(regions[i].incidents.length);
      }
    }

    incidentCounts.sort((a, b) => {
      return a - b;
    });

    var quartile, decile, low, high;
    if (incidentCounts.length == 0) {
      low = 0;
      high = 0;
    } else {
      quartile = Math.ceil(incidentCounts.length / 4);
      decile = Math.ceil(incidentCounts.length / 10);
      low = incidentCounts[quartile];
      high = incidentCounts[incidentCounts.length - decile];
    }

    this.props.setSettings({
      regionLowValue: low,
      regionHighValue: high
    });
  };

  onDataTypeChange = event => {
    let regionMeasureFilter = this.props.regionMeasureFilter;
    if (
      regionMeasureFilter &&
      regionMeasureFilter.length &&
      regionMeasureFilter.length > 0
    ) {
      regionMeasureFilter = [];
    }

    this.props.setSettings({
      regionDataType: event.target.value,
      regionMeasureFilter: regionMeasureFilter
    });
  };

  onSelectionChange = event => {
    this.props.setSettings({
      regionSelection: event.target.value
    });
  };

  removeFromSelection = value => {
    var newSelection = this.props.regionSelection.slice();
    if (newSelection.indexOf(value) === -1) return;

    newSelection.splice(newSelection.indexOf(value), 1);
    this.props.setSettings({ regionSelection: newSelection });
  };

  onFilterChange = event => {
    this.props.setSettings({
      regionMeasureFilter: event.target.value
    });
  };

  removeFromFilter = value => {
    var newSelection = this.props.regionMeasureFilter.slice();
    if (newSelection.indexOf(value) === -1) return;

    newSelection.splice(newSelection.indexOf(value), 1);
    this.props.setSettings({ regionMeasureFilter: newSelection });
  };

  setBenchmark = event => {
    this.props.setSettings({
      regionBenchmark: event.target.value
    });
  };

  setCalculation = event => {
    this.props.setSettings({
      regionCalculation: event.target.value
    });
  };

  setGradient = event => {
    this.props.setSettings({ regionGradient: event.target.value });
  };

  toggleAutoscale = () => {
    this.props.setSettings({
      regionAutoscale: !this.props.regionAutoscale
    });
  };

  scaleSliderChanged = (event, newValue) => {
    this.props.setSettings({
      regionLowValue: Number(newValue[0]).toFixed(1),
      regionHighValue: Number(newValue[1]).toFixed(1)
    });
  };

  setLowValue = event => {
    var value = Math.max(0, event.target.value);
    this.props.setSettings({
      regionLowValue: value,
      regionHighValue: Math.max(value, this.props.regionHighValue)
    });
  };

  setHighValue = event => {
    var value = Math.max(0, event.target.value);
    this.props.setSettings({
      regionLowValue: Math.min(this.props.regionLowValue, value),
      regionHighValue: value
    });
  };
}

PerformanceMapRegionSettingsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const PerformanceMapRegionSettings = withStyles(styles)(
  withTheme(PerformanceMapRegionSettingsComponent)
);

export default PerformanceMapRegionSettings;
