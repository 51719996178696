import React, { Component } from "react";
import { Button, FormControlLabel, Switch, FormGroup } from "@material-ui/core";
import { HeaderGroup } from "../controls/MenuControls";

export class CallDetailsSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggleHeader = event => {
    this.setState({
      [event.target.id]: !this.state[event.target.id]
    });
  };

  // randomDel = () => {
  //   const { columnsReport } = this.props.getSettings();
  //   let colCopy = columnsReport.slice();
  //   let ranNum = Math.floor(Math.random() * colCopy.length);
  //   colCopy.splice(ranNum, 1);
  //   this.props.setSettings({ columnsReport: colCopy });
  // };

  // magicButton = () => {
  //   const { columnsReport } = this.props.getSettings();
  //   if (columnsReport) {
  //     let delCount = columnsReport.length;
  //     this.props.closeSettings();
  //     let timeOut = 0;
  //     for (let i = 0; i < delCount; i++) {
  //       let ranNum = Math.floor(Math.random() * 1001) + 500;
  //       timeOut += ranNum;
  //       window.setTimeout(this.randomDel, timeOut);
  //     }
  //     this.setState({ disButton: true });
  //     //this.props.setSettings({ deleteCount: delCount, columnsReport: colCopy});
  //   } else {
  //     this.props.closeSettings();
  //   }
  // };

  handleSwitch = (e, val) => {
    if (e.target.value === "parser") {
      this.props.setParse(e.target.checked);
      this.props.setSettings({ reFetch: true });
    } else if (e.target.value === "style") {
      this.props.fancyState.setFancy(e.target.checked);
      this.props.setSettings({ reFetch: true });
    }
  };
  handleStyleSwitch = e => {
    // this.props.setSettings({reFetch: true});
  };

  render() {
    let disActive = Boolean(this.state.disButton);
    let useParse = this.props.useParse;
    const { useFancy } = this.props.fancyState;
    let contents = (
      <HeaderGroup
        id="generalGroupHeader"
        onClick={this.toggleHeader}
        in={this.state.generalGroupHeader}
        label={"General"}
        disabled={disActive}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={useParse}
                onChange={this.handleSwitch}
                value="parser"
              />
            }
            label="Remove Empty Dates"
          />
        </FormGroup>
      </HeaderGroup>
    );
    return contents;
  }
}
