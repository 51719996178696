import React, { Component } from "react";
import PropTypes from "prop-types";

import { TypeMap } from "../maps/TypeMap/TypeMap";
import { FrequencyMap } from "../maps/FrequencyMap/FrequencyMap";
import { PerformanceMap } from "../maps/PerformanceMap/PerformanceMap";

import { Paper } from "@material-ui/core";

import DashboardComponent, { ComponentContext } from "../DashboardComponent";
import DataContextComponent, { DataContext } from "../DataContext";
import { AdvancedChart } from "../charts/AdvancedChart";
import { SimpleReport } from "../reports/SimpleReport";
import { ReportPropsPasser } from "../reports/SimpleReportPage";

const styles = {
  dashboardPaper: {
    width: "100%"
  }
};

export class TypeMapItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Paper style={styles.dashboardPaper}>
        <DataContextComponent {...this.props}>
          <DataContext.Consumer>
            {({ getDataSettings, ...dataSettings }) => (
              <DashboardComponent
                getDataSettings={getDataSettings}
                defaultSettings={this.props.dashboardSettings}
                {...this.props}
              >
                <ComponentContext.Consumer>
                  {({ setSettings, ...settings }) => (
                    <TypeMap
                      key={this.props.index}
                      id={this.props.index}
                      setSettings={setSettings}
                      dashboardMode={true}
                      {...settings}
                      {...dataSettings}
                      {...this.props}
                    >
                      {this.props.children}
                    </TypeMap>
                  )}
                </ComponentContext.Consumer>
              </DashboardComponent>
            )}
          </DataContext.Consumer>
        </DataContextComponent>
      </Paper>
    );
  }
}

export class FrequencyMapItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Paper style={styles.dashboardPaper}>
        <DataContextComponent {...this.props}>
          <DataContext.Consumer>
            {({ getDataSettings, ...dataSettings }) => (
              <DashboardComponent
                getDataSettings={getDataSettings}
                defaultSettings={this.props.dashboardSettings}
                {...this.props}
              >
                <ComponentContext.Consumer>
                  {({ setSettings, ...settings }) => (
                    <FrequencyMap
                      key={this.props.index}
                      id={this.props.index}
                      setSettings={setSettings}
                      dashboardMode={true}
                      {...settings}
                      {...dataSettings}
                      {...this.props}
                    >
                      {this.props.children}
                    </FrequencyMap>
                  )}
                </ComponentContext.Consumer>
              </DashboardComponent>
            )}
          </DataContext.Consumer>
        </DataContextComponent>
      </Paper>
    );
  }
}

export class PerformanceMapItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Paper style={styles.dashboardPaper}>
        <DataContextComponent {...this.props}>
          <DataContext.Consumer>
            {({ getDataSettings, ...dataSettings }) => (
              <DashboardComponent
                getDataSettings={getDataSettings}
                defaultSettings={this.props.dashboardSettings}
                {...this.props}
              >
                <ComponentContext.Consumer>
                  {({ setSettings, ...settings }) => (
                    <PerformanceMap
                      key={this.props.index}
                      id={this.props.index}
                      setSettings={setSettings}
                      dashboardMode={true}
                      {...settings}
                      {...dataSettings}
                      {...this.props}
                    >
                      {this.props.children}
                    </PerformanceMap>
                  )}
                </ComponentContext.Consumer>
              </DashboardComponent>
            )}
          </DataContext.Consumer>
        </DataContextComponent>
      </Paper>
    );
  }
}

export class ChartItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Paper style={styles.dashboardPaper}>
        <DataContextComponent {...this.props}>
          <DataContext.Consumer>
            {({ getDataSettings, ...dataSettings }) => (
              <DashboardComponent
                getDataSettings={getDataSettings}
                defaultSettings={this.props.dashboardSettings}
                {...this.props}
              >
                <ComponentContext.Consumer>
                  {({ setSettings, ...settings }) => (
                    <AdvancedChart
                      key={this.props.index}
                      id={this.props.index}
                      setSettings={setSettings}
                      dashboardMode={true}
                      {...settings}
                      {...dataSettings}
                      {...this.props}
                    >
                      {this.props.children}
                    </AdvancedChart>
                  )}
                </ComponentContext.Consumer>
              </DashboardComponent>
            )}
          </DataContext.Consumer>
        </DataContextComponent>
      </Paper>
    );
  }
}

export function SimpleReportItem(props) {
  let reportName = props.dashboardSettings.reportName || "";
  let defaultSettings = { ...props.dashboardSettings, dashboardMode: true };
  let dataDefaults = {
    startDate: defaultSettings.startDate,
    endDate: defaultSettings.endDate
  };
  let contents = (
    <Paper style={styles.dashboardPaper}>
      <DataContextComponent {...props} defaultSettings={dataDefaults}>
        <DataContext.Consumer>
          {({ getDataSettings, ...dataSettings }) => (
            <DashboardComponent
              getDataSettings={getDataSettings}
              defaultSettings={defaultSettings}
              {...props}
            >
              <ComponentContext.Consumer>
                {({ setSettings, ...settings }) => (
                  <ReportPropsPasser
                    {...settings}
                    {...dataSettings}
                    setSettings={setSettings}
                    getDataSettings={getDataSettings}
                    title={props.title}
                    {...props}
                    reportName={reportName}
                  />
                )}
              </ComponentContext.Consumer>
            </DashboardComponent>
          )}
        </DataContext.Consumer>
      </DataContextComponent>
    </Paper>
  );

  return contents;
}
