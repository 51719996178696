import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  withStyles,
  withTheme,
  FormControl,
  FormControlLabel,
  Switch,
  InputLabel,
  NativeSelect,
  Input,
  Select,
  Chip,
  MenuItem,
  ListItemText,
  Collapse,
  TextField,
  Grid,
  Typography,
  Button,
  Slider
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

import { gradientColors, getCategoryColor } from "../../../helpers/Colors";

import {
  HeaderGroup,
  DefaultMenuProps,
  getFilterStyle,
  DeletableChip
} from "../../controls/MenuControls";
import ColorSwatch from "../../controls/ColorSwatch";

export const calculationNames = {
  count: "Count",
  measure: "Most Common"
};

const styles = {
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  settingsSlider: {
    marginBottom: ".25em"
  },
  selectAboveSlider: {
    width: "100%",
    marginBottom: "16px"
  },
  settingsLeftTextField: {
    width: "6em"
  },
  settingsRightTextField: {
    width: "6em"
  },
  marginBottom25em: {
    marginBottom: ".25em"
  }
};

class TypeMapHexagonSettingsComponent extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    hexGroupOpen: false
  };

  static defaultProps = {
    drawHexagons: false,
    hexSelection: [],
    hexCalculation: "count",
    hexLowValue: 2,
    hexHighValue: 10,
    hexAutoscale: true,
    hexagonSize: 0.5
  };

  componentDidUpdate = (prevProps, prevState) => {};

  render() {
    const { classes } = this.props;

    return (
      <HeaderGroup
        id="hexGroupOpen"
        onClick={this.toggleHeader}
        in={this.state.hexGroupOpen}
        label="Hexagons"
      >
        <div className="pad-gutter-8px">
          <FormControlLabel
            control={
              <Switch
                checked={this.props.drawHexagons}
                onChange={this.toggleHexagons}
                value="drawHexagons"
                color="secondary"
              />
            }
            label="Draw Hexagons"
          />

          <Typography id="hexagon-opacity" gutterBottom>
            Opacity
          </Typography>
          <Slider
            value={this.props.hexagonOpacity}
            onChangeCommitted={this.onOpacityChanged}
            aria-labelledby="hexagon-opacity"
            min={25}
            max={100}
            valueLabelDisplay="auto"
            className={classes.settingsSlider}
          />
          <Typography id="hexagon-size" gutterBottom>
            Hexagon Size (miles)
          </Typography>
          <Slider
            value={this.props.hexagonSize}
            onChange={this.onHexagonSizeChange}
            aria-labelledby="hexagon-size"
            min={0.1}
            max={3}
            step={0.1}
            valueLabelDisplay="auto"
            className={classes.settingsSlider}
          />
          <FormControl className="settings-select">
            <InputLabel>Measure</InputLabel>
            <Select
              value={this.props.hexDataType}
              onChange={this.onDataTypeChange}
              input={<Input name="hexagonMeasure" />}
            >
              {Object.keys(this.props.dataTypes).map(dataType => {
                return (
                  <MenuItem key={dataType} value={dataType} label={dataType}>
                    {dataType}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl className="settings-select">
            <Autocomplete
              id="filter-combo-box"
              options={
                (this.props.dataTypes[this.props.hexDataType] &&
                  Object.keys(
                    this.props.dataTypes[this.props.hexDataType]
                  ).sort()) ||
                []
              }
              getOptionLabel={option => option}
              classes={{}}
              value={this.props.hexSelection}
              handleHomeEndKeys
              clearOnBlur
              autoHighlight
              multiple
              disableCloseOnSelect
              onChange={(event, newValue) => {
                this.props.setSettings({
                  hexSelection: newValue
                });
              }}
              renderInput={params => (
                <TextField {...params} variant="standard" label="Filter" />
              )}
              renderOption={(category, { selected }) => (
                <MenuItem key={category} value={category}>
                  <ColorSwatch menuItem color={getCategoryColor(category)} />
                  <Typography
                    key={category}
                    style={getFilterStyle(
                      category,
                      this.props.hexSelection,
                      this.props.theme
                    )}
                  >
                    {category}
                  </Typography>
                </MenuItem>
              )}
              renderTags={selected => {
                return (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <DeletableChip
                        icon={<ColorSwatch color={getCategoryColor(value)} />}
                        key={value}
                        label={value}
                        className={classes.chip}
                        onDelete={() => {
                          this.removeFromHexSelection(value);
                        }}
                      />
                    ))}
                  </div>
                );
              }}
              aria-label="filter"
            />
          </FormControl>
          <FormControl className="settings-select">
            <InputLabel>Statistic</InputLabel>
            <Select
              value={this.props.hexCalculation}
              onChange={this.setCalculation}
              input={<Input name="hexagonCalculation" />}
            >
              {Object.keys(calculationNames).map(calculation => {
                return (
                  <MenuItem
                    key={calculation}
                    value={calculation}
                    label={calculationNames[calculation]}
                  >
                    {calculationNames[calculation]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <Collapse
          in={this.props.hexCalculation == "count"}
          className="pad-gutter-8px"
        >
          <FormControl className={classes.selectAboveSlider}>
            <InputLabel>Colorization</InputLabel>
            <Select
              value={this.props.hexGradient}
              onChange={this.setGradient}
              type="select"
              name="selectHexagonGradient"
              id="hexagonGradient"
            >
              {Object.keys(gradientColors).map(gradient => {
                return (
                  <MenuItem key={gradient} value={gradient} label={gradient}>
                    {gradient}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={this.props.hexAutoscale}
                onChange={this.toggleAutoscale}
                value="autoscale"
                color="secondary"
              />
            }
            label="Use Autoscale"
          />
          <Slider
            value={[this.props.hexLowValue, this.props.hexHighValue]}
            onChange={this.onHexSliderChange}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            max={30}
            min={0}
            step={1}
            disabled={this.props.hexAutoscale}
            className={classes.settingsSlider}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.marginBottom25em}
          >
            <TextField
              id="low-value"
              label="Low"
              value={this.props.hexLowValue}
              onChange={this.onHexLowValueChange}
              type="number"
              className={classes.settingsLeftTextField}
              disabled={this.props.hexAutoscale}
            />
            <TextField
              id="high-value"
              label="High"
              value={this.props.hexHighValue}
              onChange={this.onHexHighValueChange}
              type="number"
              className={classes.settingsRightTextField}
              disabled={this.props.hexAutoscale}
            />
          </Grid>
        </Collapse>
        <Collapse in={this.props.hexCalculation != "count"}>
          <Button
            className="fullWidth margin-top-5px margin-bottom-4px"
            onClick={() => {
              this.props.showCategoryColorPicker(
                this.props.dataTypes[this.props.hexDataType]
              );
            }}
            variant="contained"
            color="primary"
          >
            Choose Colors
          </Button>
        </Collapse>
      </HeaderGroup>
    );
  }

  toggleHeader = () => {
    this.setState({ hexGroupOpen: !this.state.hexGroupOpen });
  };

  toggleHexagons = () => {
    this.props.setSettings({
      drawHexagons: !this.props.drawHexagons
    });
  };

  onOpacityChanged = (event, newValue) => {
    this.props.setSettings({ hexagonOpacity: newValue });
  };

  onHexagonSizeChange = (event, newValue) => {
    this.props.setSettings({ hexagonSize: newValue });
  };

  onDataTypeChange = event => {
    let hexSelection = this.props.hexSelection;
    if (hexSelection && hexSelection.length && hexSelection.length > 0) {
      hexSelection = [];
    }

    this.props.setSettings({
      hexDataType: event.target.value,
      hexSelection: hexSelection
    });
  };

  onSelectionChange = event => {
    this.props.setSettings({
      hexSelection: event.target.value
    });
  };

  removeFromHexSelection = value => {
    var newSelection = this.props.hexSelection.slice();
    if (newSelection.indexOf(value) === -1) return;

    newSelection.splice(newSelection.indexOf(value), 1);
    this.props.setSettings({ hexSelection: newSelection });
  };

  setCalculation = event => {
    this.props.setSettings({
      hexCalculation: event.target.value
    });
  };

  setGradient = event => {
    this.props.setSettings({ hexGradient: event.target.value });
  };

  toggleAutoscale = () => {
    this.props.setSettings({
      hexAutoscale: !this.props.hexAutoscale
    });
  };

  onHexSliderChange = (event, newValue) => {
    this.props.setSettings({
      hexLowValue: newValue[0],
      hexHighValue: newValue[1]
    });
  };

  onHexLowValueChange = event => {
    var value = Math.max(0, event.target.value);
    this.props.setSettings({
      hexLowValue: value,
      hexHighValue: Math.max(value, this.props.hexHighValue)
    });
  };

  onHexHighValueChange = event => {
    var value = Math.max(0, event.target.value);
    this.props.setSettings({
      hexLowValue: Math.min(this.props.hexLowValue, value),
      hexHighValue: value
    });
  };
}

TypeMapHexagonSettingsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const TypeMapHexagonSettings = withStyles(styles)(
  withTheme(TypeMapHexagonSettingsComponent)
);

export default TypeMapHexagonSettings;
