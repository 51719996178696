import React, { useState, useEffect } from "react";

import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { setSettingsEnabled } from "../../redux/actions";
import classnames from "classnames";
import { RegionSettings } from "./RegionSettings";
import Api from "../../services/Api.js";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

const styles = {
  mainPaper: {
    margin: "20px"
  },
  gridRow: {
    display: "flex",
    justifyContent: "center"
  },
  textField: {
    width: "100%",
    maxWidth: "500px",
    margin: "20px"
  },
  loadingWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  }
};

function JurisdictionSettings(props) {
  const [currentTab, setCurrentTab] = useState(0);
  const [jurisdiction, setJurisdiction] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadJurisdiction();
  }, []);

  async function loadJurisdiction() {
    setLoading(true);

    const data = await Api.getJurisdictionSettings();

    setJurisdiction(data);

    setLoading(false);
  }

  async function saveJurisdiction() {
    setLoading(true);

    const data = await Api.saveJurisdictionSettings(jurisdiction);

    if (data != null) {
      setJurisdiction(data);
    } else {
      // TODO: Need to alert user that something went wrong if 400/500 is returned
    }

    setLoading(false);
  }

  // async componentDidMount() {
  //     window.JurisdictionSettings = this;
  //     await this.getSettings();
  //     if (this.state.city)
  //         document.getElementById("city").value = this.state.city;

  //     if (this.state.state)
  //         document.getElementById("state").value = this.state.state;

  //     if (this.state.longitude)
  //         document.getElementById("longitude").value = this.state.longitude;

  //     if (this.state.latitude)
  //         document.getElementById("latitude").value = this.state.latitude;

  //     if (this.state.zoom)
  //         document.getElementById("zoom").value = this.state.zoom;

  //     if (this.state.projection)
  //         document.getElementById("projection").value = this.state.projection;
  // }

  // componentDidUpdate(prevProps, prevState) { }

  // toggle(tab) {
  //     if (this.state.activeTab !== tab) {
  //         this.setState({
  //             activeTab: tab
  //         });
  //     }
  // }

  return (
    <div>
      <Tabs
        value={currentTab}
        onChange={(event, newValue) => setCurrentTab(newValue)}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Jurisdiction Settings" />
        <Tab label="Region Settings" />
      </Tabs>
      {loading && (
        <div style={styles.loadingWrapper}>
          <CircularProgress />
        </div>
      )}
      {!loading && currentTab === 0 && (
        <Paper style={styles.mainPaper}>
          <Grid container>
            <Grid item xs={12} style={styles.gridRow}>
              <TextField
                label="City"
                value={jurisdiction.city}
                style={styles.textField}
                onChange={event => {
                  let jurisdictionCopy = JSON.parse(
                    JSON.stringify(jurisdiction)
                  );
                  jurisdictionCopy.city = event.target.value;

                  setJurisdiction(jurisdictionCopy);
                }}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} style={styles.gridRow}>
              <TextField
                label="State"
                value={jurisdiction.state}
                style={styles.textField}
                onChange={event => {
                  // Limit the number of characters the user can type to 2 (for state codes)
                  if (event.target.value.length > 2) {
                    return;
                  }

                  let jurisdictionCopy = JSON.parse(
                    JSON.stringify(jurisdiction)
                  );
                  jurisdictionCopy.state = event.target.value;

                  setJurisdiction(jurisdictionCopy);
                }}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} style={styles.gridRow}>
              <TextField
                label="Longitude"
                value={jurisdiction.longitude}
                style={styles.textField}
                onChange={event => {
                  let jurisdictionCopy = JSON.parse(
                    JSON.stringify(jurisdiction)
                  );
                  jurisdictionCopy.longitude = event.target.value;

                  setJurisdiction(jurisdictionCopy);
                }}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} style={styles.gridRow}>
              <TextField
                label="Latitude"
                value={jurisdiction.latitude}
                style={styles.textField}
                onChange={event => {
                  let jurisdictionCopy = JSON.parse(
                    JSON.stringify(jurisdiction)
                  );
                  jurisdictionCopy.latitude = event.target.value;

                  setJurisdiction(jurisdictionCopy);
                }}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} style={styles.gridRow}>
              <TextField
                label="Zoom"
                value={jurisdiction.zoom}
                style={styles.textField}
                onChange={event => {
                  let jurisdictionCopy = JSON.parse(
                    JSON.stringify(jurisdiction)
                  );
                  jurisdictionCopy.zoom = event.target.value;

                  setJurisdiction(jurisdictionCopy);
                }}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} style={styles.gridRow}>
              <TextField
                label="Projection"
                value={jurisdiction.projection}
                style={styles.textField}
                onChange={event => {
                  let jurisdictionCopy = JSON.parse(
                    JSON.stringify(jurisdiction)
                  );
                  jurisdictionCopy.projection = event.target.value;

                  setJurisdiction(jurisdictionCopy);
                }}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} style={styles.gridRow}>
              <Button color="primary" onClick={() => saveJurisdiction()}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}
      {!loading && currentTab === 1 && <RegionSettings></RegionSettings>}
    </div>
    //   <div>
    //     <div className="settingsPage">
    //       <Nav tabs>
    //         <NavItem>
    //           <NavLink
    //             className={classnames({ active: this.state.activeTab === "1" })}
    //             onClick={() => {
    //               this.toggle("1");
    //             }}
    //           >
    //             General
    //           </NavLink>
    //         </NavItem>
    //         <NavItem>
    //           <NavLink
    //             className={classnames({ active: this.state.activeTab === "2" })}
    //             onClick={() => {
    //               this.toggle("2");
    //             }}
    //           >
    //             Regions
    //           </NavLink>
    //         </NavItem>
    //       </Nav>
    //       <TabContent activeTab={this.state.activeTab}>
    //         <TabPane tabId="1">
    //           <Form>
    //             <legend>Jurisdiction Settings</legend>
    //             <FormGroup row>
    //               <Label for="City" sm={2}>
    //                 City
    //               </Label>
    //               <Col sm={10}>
    //                 <Input
    //                   type="text"
    //                   name="city"
    //                   id="city"
    //                   placeholder="Your Town"
    //                   onChange={this.cityChanged}
    //                 />
    //               </Col>
    //             </FormGroup>
    //             <FormGroup row>
    //               <Label for="State" sm={2}>
    //                 State
    //               </Label>
    //               <Col sm={10}>
    //                 <Input
    //                   type="text"
    //                   name="state"
    //                   id="state"
    //                   placeholder="USA"
    //                   onChange={this.stateChanged}
    //                 />
    //               </Col>
    //             </FormGroup>
    //             <FormGroup row>
    //               <Label for="Longitude" sm={2}>
    //                 Longitude
    //               </Label>
    //               <Col sm={10}>
    //                 <Input
    //                   type="text"
    //                   name="longitude"
    //                   id="longitude"
    //                   placeholder="-123.2617"
    //                   onChange={this.longitudeChanged}
    //                 />
    //               </Col>
    //             </FormGroup>
    //             <FormGroup row>
    //               <Label for="latitude" sm={2}>
    //                 Latitude
    //               </Label>
    //               <Col sm={10}>
    //                 <Input
    //                   type="text"
    //                   name="latitude"
    //                   id="latitude"
    //                   placeholder="44.5642"
    //                   onChange={this.latitudeChanged}
    //                 />
    //               </Col>
    //             </FormGroup>
    //             <FormGroup row>
    //               <Label for="zoom" sm={2}>
    //                 Zoom
    //               </Label>
    //               <Col sm={10}>
    //                 <Input
    //                   type="number"
    //                   name="zoom"
    //                   id="zoom"
    //                   placeholder="14"
    //                   onChange={this.zoomChanged}
    //                 />
    //               </Col>
    //             </FormGroup>
    //             <FormGroup row>
    //               <Label for="projection" sm={2}>
    //                 Projection
    //               </Label>
    //               <Col sm={10}>
    //                 <Input
    //                   type="textarea"
    //                   name="projection"
    //                   id="projection"
    //                   placeholder=""
    //                   onChange={this.projectionChanged}
    //                 />
    //               </Col>
    //               <br />
    //               <FormText className="center">
    //                 Visit https://spatialreference.org/ or contact Levrum
    //                 support for assistance obtaining your projection.
    //               </FormText>
    //             </FormGroup>
    //           </Form>
    //           <br />
    //           <Button id="Save" onClick={this.saveSettings} block>
    //             Save Settings
    //           </Button>
    //           <br />
    //           <div id="statusDiv" className="center-text"></div>
    //         </TabPane>
    //         <TabPane tabId="2">
    //           <RegionSettings></RegionSettings>
    //         </TabPane>
    //       </TabContent>
    //     </div>
    //   </div>
  );

  // cityChanged = event => {
  //     this.setState({
  //         city: event.target.value
  //     });
  // };

  // stateChanged = event => {
  //     this.setState({
  //         state: event.target.value
  //     });
  // };

  // longitudeChanged = event => {
  //     this.setState({
  //         longitude: event.target.value
  //     });
  // };

  // latitudeChanged = event => {
  //     this.setState({
  //         latitude: event.target.value
  //     });
  // };

  // zoomChanged = event => {
  //     this.setState({
  //         zoom: event.target.value
  //     });
  // };

  // projectionChanged = event => {
  //     this.setState({
  //         projection: event.target.value
  //     });
  // };

  // getSettings = async () => {
  //     var data = await Api.getJurisdictionSettings();

  //     if (!data) {
  //         return;
  //     }

  //     this.setState({
  //         city: data.mapSettings.city,
  //         state: data.mapSettings.state,
  //         longitude: data.mapSettings.longitude,
  //         latitude: data.mapSettings.latitude,
  //         zoom: data.mapSettings.zoom,
  //         projection: data.projection
  //     });
  // };

  // saveSettings = async () => {
  //     var settingsWrapper = this.makeSettingsWrapper();

  //     var statusText =
  //         "[" + moment().format("h:mm:ss a") + "] " + "Saving Settings...";
  //     document.getElementById("statusDiv").innerText = statusText;

  //     var result = await Api.updateJurisdictionSettings(settingsWrapper);
  //     var text = "Unable to save settings!";
  //     if (result) {
  //         text = result.data;
  //     }
  //     statusText = "[" + moment().format("h:mm:ss a") + "] " + text;

  //     document.getElementById("statusDiv").innerText = statusText;
  // };

  // makeSettingsWrapper = () => {
  //     var settingsWrapper = {};
  //     var mapSettings = {};

  //     mapSettings.City = this.state.city;
  //     mapSettings.State = this.state.state;
  //     mapSettings.Longitude = this.state.longitude;
  //     mapSettings.Latitude = this.state.latitude;
  //     mapSettings.Zoom = this.state.zoom;

  //     settingsWrapper.projection = this.state.projection;
  //     settingsWrapper.mapSettings = mapSettings;

  //     return settingsWrapper;
  // };
}

// const mapDispatchToProps = {
//     setSettingsEnabled
// };

// const JurisdictionSettingsContainer = connect(
//     null,
//     mapDispatchToProps
// )(JurisdictionSettings);

export default JurisdictionSettings;
