import React, { Component } from "react";

import {
  Popover,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Spinner,
  Button,
  CustomInput
} from "reactstrap";

import FileSettings from "./FileSettings";
import DataMapSettings from "./DataMapSettings"

import { connect } from "react-redux";
import { setSettingsEnabled } from "../../redux/actions";

import Api from "../../services/Api.js";

import moment from "moment";

const cadTypes = ["Superion", "Tritech", "Emergency Reporting", "Custom SQL"];

const styles = {
  label: {
    display: "inline-flex",
    fontSize: "1.25rem",
    fontWeight: "bold",
    width: "10rem"
  },
  value: {
    display: "inline-flex",
    fontSize: "1.25rem"
  }
};

export class DataSettings extends Component {
  constructor(props) {
    super(props);

    props.setSettingsEnabled(false);

    this.state = {
      dataSource: "cad",
      cadType: 0,
      cacheCadData: true,
      server: "",
      port: "",
      database: "",
      user: "",
      password: "",
      loading: true
    };
  }

  async componentDidMount() {
    await this.getSettings();
    this.updateUi();
    this.setState({ loading: false });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.dataSource != prevState.dataSource ||
      this.state.cadType != prevState.cadType ||
      (!this.state.loading && this.state.loading != prevState.loading)
    ) {
      this.updateUi();
    }
  }

  render() {
    var firstIncident = "Unknown";
    var lastIncident = "Unknown";

    return (
      <div>
        {(this.state.loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              top: "50%",
              left: "50%"
            }}
          >
            <Spinner
              color="secondary"
              style={{ width: "3rem", height: "3rem" }}
            />
          </div>
        )) || (
          <div className="settingsPage">
            <Form>
              <legend>Data Settings</legend>
              <FormGroup>
                <Label for="dataSource">Source</Label>
                <Input
                  type="select"
                  name="dataSource"
                  id="dataSource"
                  value={this.state.dataSource}
                  onChange={this.dataSourceChanged}
                >
                  <option value="file">Incident JSON File</option>
                  <option value="datamap">Levrum DataMap</option>
                  <option value="cad">CAD System (legacy)</option>
                </Input>
              </FormGroup>
            </Form>
            {this.state.dataSource === "cad" && (
              <div>
                <Form>
                  <FormGroup>
                    <Label for="cadType">CAD Type</Label>
                    <Input
                      type="select"
                      name="cadType"
                      id="cadType"
                      value={this.state.cadType}
                      onChange={this.cadTypeChanged}
                    >
                      <option value="0">Superion</option>
                      <option value="1">Tritech</option>
                      <option value="2">Custom SQL</option>
                      <option value="3">Emergency Reporting</option>
                    </Input>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="server" sm={2}>
                      Server
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="text"
                        name="server"
                        id="server"
                        placeholder="ex 192.168.1.234"
                        onChange={this.serverChanged}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="port" sm={2}>
                      Port
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="text"
                        name="port"
                        id="port"
                        placeholder="1433"
                        onChange={this.portChanged}
                      />
                    </Col>
                  </FormGroup>
                  {this.state.cadType != 3 && (
                    <div>
                      <FormGroup row>
                        <Label for="database" sm={2}>
                          Database
                        </Label>
                        <Col sm={10}>
                          <Input
                            type="text"
                            name="database"
                            id="database"
                            placeholder="CadDatabase"
                            onChange={this.databaseChanged}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="user" sm={2}>
                          User
                        </Label>
                        <Col sm={10}>
                          <Input
                            type="text"
                            name="user"
                            id="user"
                            placeholder="CadUser"
                            onChange={this.userChanged}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="password" sm={2}>
                          Password
                        </Label>
                        <Col sm={10}>
                          <Input
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Password"
                            onChange={this.passwordChanged}
                          />
                        </Col>
                      </FormGroup>
                    </div>
                  )}
                  {this.state.cadType == 3 && (
                    <div>
                      <FormGroup row>
                        <Label for="apikey" sm={2}>
                          API Key
                        </Label>
                        <Col sm={10}>
                          <Input
                            type="text"
                            name="apikey"
                            id="apikey"
                            placeholder="XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
                            onChange={this.apikeyChanged}
                          />
                        </Col>
                      </FormGroup>
                    </div>
                  )}
                  <FormGroup id="cacheCad-group" row check>
                    <Label id="cacheCadLabel" check sm={8}>
                      <CustomInput
                        id="cacheCad"
                        type="checkbox"
                        className="custom-switch center"
                        checked={this.state.cacheCadData}
                        onChange={this.cacheCadDataChanged}
                      >
                        {" "}
                        Cache Data (reduces database requests)
                      </CustomInput>
                    </Label>
                  </FormGroup>
                  <br />
                  <Button id="Test" onClick={this.testSettings} block>
                    Test Settings
                  </Button>
                  <Button id="Save" onClick={this.saveSettings} block>
                    Save Settings
                  </Button>
                  <div id="statusDiv" className="center-text"></div>
                  <br />
                  <div style={{ marginTop: "-10px", marginBottom: "-10px" }}>
                    <legend>Current Data</legend>
                  </div>
                  <div style={{ textAlign: "center", marginBottom: "10px" }}>
                    <div style={styles.label}>First Incident:</div>{" "}
                    <div style={styles.value}>{firstIncident}</div>
                    <br />
                    <div style={styles.label}>Last Incident:</div>{" "}
                    <div style={styles.value}>{lastIncident}</div>
                    <br />
                  </div>
                  <Button id="Test" onClick={this.forceCadRefresh} block>
                    Force CAD Refresh
                  </Button>
                </Form>
              </div>
            )}
            {this.state.dataSource === "file" && (
              <div>
                <FileSettings
                  flatfileInfo={this.state.flatfileInfo}
                  update={this.getSettings}
                />
                <br />
                <Button id="Save" onClick={this.saveSettings} block>
                  Save Settings
                </Button>
                <div id="statusDiv" className="center-text"></div>
              </div>
            )}
            {this.state.dataSource == "datamap" && (
              <div>
                <DataMapSettings
                  dataMapInfo={this.state.dataMapInfo}
                  update={this.getSettings}
                  />
                  <br />
                  <Button id="Save" onClick={this.saveSettings} block>
                    Save Settings
                  </Button>
                  <div id="statusDiv" className="center-text"></div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  dataSourceChanged = event => {
    this.setState({
      dataSource: event.target.value
    });
  };

  cadTypeChanged = event => {
    this.setState({
      cadType: event.target.value
    });
  };

  serverChanged = event => {
    this.setState({
      server: event.target.value
    });
  };

  portChanged = event => {
    this.setState({
      port: event.target.value
    });
  };

  databaseChanged = event => {
    this.setState({
      database: event.target.value
    });
  };

  userChanged = event => {
    this.setState({
      user: event.target.value
    });
  };

  passwordChanged = event => {
    this.setState({
      password: event.target.value
    });
  };

  cacheCadDataChanged = event => {
    this.setState({
      cacheCadData: !this.state.cacheCadData
    });
  };

  updateUi = () => {
    if (this.state.dataSource == "cad") {
      if (!document.getElementById("server")) {
        return;
      }

      document.getElementById("server").value = this.state.server;
      document.getElementById("port").value = this.state.port;
      if (this.state.cadType != 3) {
        document.getElementById("database").value = this.state.database;
        document.getElementById("user").value = this.state.user;
        document.getElementById("password").value = this.state.password;
      }
    }
  };

  getSettings = async () => {
    var data = await Api.getDataSettings();
    if (!data) return;

    if (!data.cadSettings) {
      data.cadSettings = {};
    }

    this.setState({
      dataSource: data.dataSource,
      cadType: data.cadSettings.cadType,
      server: data.cadSettings.server,
      port: data.cadSettings.port,
      database: data.cadSettings.database,
      user: data.cadSettings.user,
      password: data.cadSettings.password,
      cacheCadData: data.cacheCadData,
      flatfileInfo: data.fileInfo,
      dataMapInfo: data.dataMapInfo
    });
  };

  testSettings = async () => {
    var settingsWrapper = this.makeSettingsWrapper();

    var statusText =
      "[" + moment().format("h:mm:ss a") + "] " + "Beginning Test...";
    document.getElementById("statusDiv").innerText = statusText;

    var result = await Api.testDataSettings(settingsWrapper);
    var text = "Unable to test settings!";
    if (result) {
      text = result.data;
    }
    statusText = "[" + moment().format("h:mm:ss a") + "] " + text;

    document.getElementById("statusDiv").innerText = statusText;
  };

  saveSettings = async () => {
    var settingsWrapper = this.makeSettingsWrapper();

    var statusText =
      "[" + moment().format("h:mm:ss a") + "] " + "Saving Settings...";
    document.getElementById("statusDiv").innerText = statusText;

    var result = await Api.updateDataSettings(settingsWrapper);
    var text = "Unable to save settings!";
    if (result) {
      text = result.data;
    }
    statusText = "[" + moment().format("h:mm:ss a") + "] " + text;

    document.getElementById("statusDiv").innerText = statusText;
  };

  forceCadRefresh = async () => {
    var result = await Api.forceCadRefresh();
  };

  makeSettingsWrapper = () => {
    var settingsWrapper = {};
    var cadSettings = {};

    if (this.state.dataSource == "cad") {
      settingsWrapper.dataSourceName = cadTypes[this.state.cadType] + " CAD";
    }

    settingsWrapper.dataSource = this.state.dataSource;
    settingsWrapper.cacheCadData = this.state.cacheCadData;

    cadSettings.cadType = this.state.cadType;
    cadSettings.server = this.state.server;
    cadSettings.port = this.state.port;
    cadSettings.database = this.state.database;
    cadSettings.user = this.state.user;
    cadSettings.password = this.state.password;

    settingsWrapper.cadSettings = cadSettings;

    return settingsWrapper;
  };
}

const mapDispatchToProps = {
  setSettingsEnabled
};

const DataSettingsContainer = connect(
  null,
  mapDispatchToProps
)(DataSettings);

export default DataSettingsContainer;
