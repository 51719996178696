import React, { useState, useEffect, useRef } from "react";

import {
  makeStyles,
  Paper,
  TextField,
  Button,
  Typography
} from "@material-ui/core";

import api from "../../services/Api";
import { Redirect } from "react-router-dom";

import { connect } from "react-redux";
import { getLoggedIn, getSettingsOpen } from "../../redux/selectors";
import {
  setLoggedIn,
  toggleSettingsOpen,
  closeSettings,
  setSettingsEnabled
} from "../../redux/actions";
import { isUserLoggedIn } from "../../helpers/Utilities";

const useStyles = makeStyles(theme => ({
  loginColumn: {
    display: "flex",
    justifyContent: "center"
  },
  loginPaper: {
    // width: '18em',
    height: "15em",
    zIndex: "10"
  },
  loginButton: {
    width: "100%"
  },
  loginContainer: {
    padding: "1em",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    textAlign: "center"
  },
  inputContainer: {
    marginTop: ".5em",
    marginBottom: ".5em"
  },
  inputField: {
    width: "100%",
    marginBottom: ".5em"
  },
  levrumLogo: {
    width: "15em"
  },
  logoDiv: {
    position: "fixed",
    bottom: "1em",
    right: "1em"
  }
}));

function Login(props) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [redirectLocation, setRedirectLocation] = useState(null);

  useEffect(() => {
    document.title = "Code3 Firewatch - Login";
    props.setSettingsEnabled(false);

    if (isUserLoggedIn()) {
      props.setLoggedIn(true);
      loginRedirect();
    }

    var loginButton = document.getElementById("loginButton");
    if (loginButton) {
      loginButton.focus();
    }

    return () => {
      document.title = "Code3 Firewatch";
    };
  }, []);

  function loginRedirect() {
    setRedirectLocation(
      (props.location.state && props.location.state.from) || "/"
    );
  }

  async function emailKeyPress(event) {
    if (event.key == "Enter") {
      focusPassword();
    }
  }

  async function focusPassword() {
    var element = document.getElementById("passwordInput");
    if (element) {
      element.focus();
    }
  }

  async function focusEmail() {
    var element = document.getElementById("emailInput");
    if (element) {
      element.focus();
    }
  }

  async function passwordKeyPress(event) {
    if (event.key == "Enter") {
      loginButtonClicked(event);
    }
  }

  async function loginKeyPress(event) {
    if (event.key != "Enter" && email == "") {
      focusEmail();
    }
  }

  async function loginButtonClicked(event) {
    event.preventDefault();
    if (email == "" || password == "") {
      var alertMessage =
        email == ""
          ? "Please enter your email address"
          : "Please enter your password";
      if (email == "") {
        focusEmail();
      } else {
        focusPassword();
      }
      alert(alertMessage);
      return;
    }

    const user = await api.authenticate(email, password);

    if (!user) {
      alert("Failed to login");
      return;
    }

    // Store the user and JWT in local storage
    localStorage.setItem("user", JSON.stringify(user));

    // Now that we know the user is logged in, let's
    // set the default Authorization header for axios
    api.setAuthorizationHeader(user.jwt);

    if (user.forcePasswordReset) {
      setRedirectLocation({
        pathname: "/settings/resetPassword",
        state: {
          from: (props.location.state && props.location.state.from) || "/"
        }
      });
    } else {
      // Navigate to the home page
      loginRedirect();
    }
  }

  if (redirectLocation) {
    return <Redirect to={redirectLocation} />;
  }

  return (
    <div className="container">
      <div className="row" style={{ marginTop: "30px" }}>
        <div className={classes.loginColumn + " col-sm"}>
          <Paper className={classes.loginPaper}>
            <div className={classes.loginContainer}>
              <Typography variant="h6">Login to Continue</Typography>
              <div className={classes.inputContainer}>
                <TextField
                  id="emailInput"
                  label="Email"
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                  onKeyPress={emailKeyPress}
                  className={classes.inputField}
                />
                <TextField
                  id="passwordInput"
                  label="Password"
                  type="password"
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                  onKeyPress={passwordKeyPress}
                  className={classes.inputField}
                />
              </div>
              <div>
                <Button
                  id="loginButton"
                  variant="contained"
                  className={classes.loginButton}
                  onClick={loginButtonClicked}
                  onKeyPress={loginKeyPress}
                  color="primary"
                >
                  Login
                </Button>
              </div>
            </div>
          </Paper>
        </div>
      </div>
      <div className={classes.logoDiv}>
        <Button target="_new" href="https://www.levrum.com/">
          <img className={classes.levrumLogo} src="levrumlogo.svg" />
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  const isLoggedIn = getLoggedIn(state);
  const settingsOpen = getSettingsOpen(state);
  return { isLoggedIn, settingsOpen };
};

const mapDispatchToProps = {
  setLoggedIn,
  toggleSettingsOpen,
  closeSettings,
  setSettingsEnabled
};

const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

export default LoginContainer;
