export const getBenchmarksFromFeature = (feature, desiredBenchmark) => {
  var benchmarks = [];
  var data = feature.get("data");
  if (!data) {
    return benchmarks;
  }

  var incident, response, benchmark;
  for (var h = 0; h < data.length; h++) {
    incident = data[h];
    for (var i = 0; i < incident.responses.length; i++) {
      response = incident.responses[i];
      for (var j = 0; j < response.benchmarks.length; j++) {
        benchmark = response.benchmarks[j];
        if (benchmark.name == desiredBenchmark) {
          benchmarks.push(benchmark.value);
        }
      }
    }
  }

  return benchmarks;
};

export const calculateValueFromBenchmarks = (
  benchmarks,
  calculation,
  percentile
) => {
  if (benchmarks.length == 0) {
    return 0;
  }

  benchmarks.sort(function(a, b) {
    return a - b;
  });

  var value;
  if (benchmarks.length == 1) value = benchmarks[0];
  else if (calculation == "median")
    value = benchmarks[Math.ceil(benchmarks.length / 2)].toFixed(2);
  else if (calculation == "max") value = benchmarks[benchmarks.length - 1];
  else if (calculation == "mean")
    value = benchmarks.reduce((a, b) => a + b, 0) / benchmarks.length;
  else if (calculation == "percentile") {
    var index = Math.min(
      benchmarks.length - 1,
      Math.ceil(benchmarks.length * (percentile / 100))
    );
    value = benchmarks[index];
  } else value = benchmarks[0];

  return value;
};

export const getBenchmarkCalculationFromIncidents = (
  incidents,
  benchmark,
  calculation,
  percentile
) => {
  var incidentBenchmarks;
  var benchmarks = [];
  if (!incidents) {
    return 0;
  }

  for (var i = 0; i < incidents.length; i++) {
    incidentBenchmarks = getBenchmarksFromIncident(incidents[i], benchmark);
    for (var j = 0; j < incidentBenchmarks.length; j++) {
      benchmarks.push(incidentBenchmarks[j]);
    }
  }

  return calculateValueFromBenchmarks(benchmarks, calculation, percentile);
};

export const getBenchmarksFromIncident = (incident, desiredBenchmark) => {
  var benchmarks = [];
  var data = incident;
  if (!data || !data.responses) {
    return benchmarks;
  }

  var response, benchmark;
  for (var i = 0; i < data.responses.length; i++) {
    response = data.responses[i];
    for (var j = 0; j < response.benchmarks.length; j++) {
      benchmark = response.benchmarks[j];
      if (benchmark.name == desiredBenchmark) {
        benchmarks.push(benchmark.value);
      }
    }
  }

  return benchmarks;
};
