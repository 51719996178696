import React from "react";

import {
  makeStyles,
  Button,
  Typography,
  Paper,
  Link,
  Divider
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  aboutContainer: {
    padding: "1em",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  aboutPaper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "40%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "2em"
  },
  textDiv: {
    marginTop: ".5em",
    marginBottom: "1em",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center"
  },
  firewatchButton: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto"
  },
  firewatchLogo: {
    width: "100%"
  },
  levrumButton: {
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto"
  },
  levrumLogo: {
    width: "100%"
  },
  creditsDivider: {
    marginTop: "1em",
    marginBottom: "1em"
  }
}));

export default function About(props) {
  const classes = useStyles();

  return (
    <div className={classes.aboutContainer}>
      <Paper className={classes.aboutPaper}>
        <div className={classes.aboutContainer}>
          <Button
            className={classes.firewatchButton}
            href="https://www.code3firewatch.com/"
          >
            <img className={classes.firewatchLogo} src="firewatchlogo.svg" />
          </Button>
          <div className={classes.textDiv}>
            <Typography variant="h3" style={{ marginBottom: ".25em" }}>
              Unsupported Browser Detected
            </Typography>
            <Divider className={classes.creditsDivider} variant="middle" />
            <Typography variant="h5" style={{ marginTop: ".25em" }}>
              Code3 Firewatch supports{" "}
              <Link color="secondary" href="https://www.google.com/chrome/">
                Google Chrome
              </Link>
              ,{" "}
              <Link
                color="secondary"
                href="https://www.mozilla.org/en-US/firefox/new/"
              >
                Mozilla Firefox
              </Link>
              ,{" "}
              <Link
                color="secondary"
                href="https://www.opera.com/computer/thanks?ni=stable&amp;os=windows"
              >
                Opera
              </Link>
              , and{" "}
              <Link
                color="secondary"
                href={"microsoft-edge:" + window.location}
              >
                Microsoft Edge
              </Link>
              .<br />
              <br />
              Please use one of these browsers to continue.
            </Typography>
          </div>
          <Button
            className={classes.levrumButton}
            href="https://www.levrum.com/"
          >
            <img className={classes.levrumLogo} src="levrumlogo.svg" />
          </Button>
        </div>
      </Paper>
    </div>
  );
}
