import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";

import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter } from "react-router-dom";

import { isIE } from "react-device-detect";

import { Provider } from "react-redux";
import store from "./redux/store";

import App from "./App";
import NoIEPage from "./components/help/NoIEPage";
import { unregister } from "./registerServiceWorker";

import "react-data-grid-multiline-header/style.css";

import defaultTheme from "./helpers/Theme";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

unregister();

if (isIE) {
  ReactDOM.render(<NoIEPage />, rootElement);
} else {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter basename={baseUrl}>
        <ThemeProvider theme={defaultTheme}>
          <App newContentAvailable={window.newContentAvailable} />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>,
    rootElement
  );
}
