import React, { Component, useState, useEffect } from "react";
import SimpleReport from "./SimpleReport";
import Api from "../../services/Api";
import { formatObjectDates } from "../../helpers/Utilities";
import { CallDetailsSettings } from "./CallDetailsReportSettings";
import moment from "moment";
import { TableRow, TableCell } from "@material-ui/core";

const ZeroTicksMoment = moment("0001-01-01");

export function CallDetailsReport(props) {
  const [useParse, setParse] = useState(true);
  const [useFancy, setFancy] = useState(false);
  useEffect(() => {
    props.setSettings({ reportName: "calldetails" });
    //eslint-disable-next-line
  }, []);

  async function getData(dataSettings) {
    const { startDate, endDate } = dataSettings;
    return await Api.getCallDetails(startDate, endDate);
  }

  function customRowRender(data, dataIndex, rowIndex) {
    return (
      <TableRow key={rowIndex} hover>
        {data.map(ele => {
          return (
            <TableCell size="small" style={{ fontWeight: "bold" }}>
              {ele}
            </TableCell>
          );
        })}
      </TableRow>
    );
  }

  function parseData(data) {
    let newData = [];
    data.forEach(ele => {
      let keys = Object.keys(ele);
      keys.forEach(key => {
        let value = ele[key];
        let valMoment = moment(value, moment.ISO_8601);
        if (valMoment.isValid()) {
          if (valMoment.isSame(ZeroTicksMoment, "year") && useParse) {
            delete ele[key];
          } else {
            ele[key] = valMoment.format("MM-DD-YYYY HH:mm:ss");
          }
        } else if (!isNaN(value)) {
          let intValue = Number(value).toFixed(0);
          let fixedValue = Number(value).toFixed(4);
          if (intValue - fixedValue == 0) {
            ele[key] = value;
          } else {
            ele[key] = fixedValue;
          }
        }
      });
      //formatObjectDates(ele);
      newData.push(ele);
    });
    return newData;
  }

  const order = ["CallId"];
  let reportProps = {
    options: {
      customRowRender: null
    }
  };
  if (useFancy) {
    reportProps = {
      options: {
        customRowRender: customRowRender
      }
    };
  }
  return (
    <SimpleReport
      title={"Call Details Report"}
      getData={getData}
      parse={parseData}
      order={order}
      reportProps={reportProps}
    >
      <CallDetailsSettings
        setSettings={props.setSettings}
        getSettings={props.getSettings}
        getDataSettings={props.getDataSettings}
        closeSettings={props.closeSettings}
        useParse={useParse}
        setParse={setParse}
        fancyState={{ useFancy, setFancy }}
      />
    </SimpleReport>
  );
}
