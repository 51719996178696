import React, { Component } from "react";
import { PropTypes } from "prop-types";

import {
  withStyles,
  withTheme,
  InputLabel,
  TextField,
  Button,
  Select,
  MenuItem,
  Collapse,
  Switch,
  FormControl,
  FormControlLabel,
  NativeSelect,
  Input,
  Slider,
  Grid,
  Typography
} from "@material-ui/core";
import MomentUtils from "@date-io/moment";

import Api from "../../../services/Api.js";

import { Style, Circle, Fill, Stroke } from "ol/style.js";

import {
  HeaderGroup,
  UpdateButton,
  GetDataButton
} from "../../controls/MenuControls";
import {
  AddDashboardDialog,
  AddDashboardButton
} from "../../controls/AddDashboard";
import {
  dateRanges,
  getDatesFromFilters,
  getDateRanges,
  getPercentileValues,
  benchmarkNames,
  calculationNames
} from "../../../helpers/DashboardHelpers.js";

import { gradientColors } from "../../../helpers/Colors";
import { calculationTitles } from "./PerformanceMap";

const styles = {
  settingsSelect: {
    width: "100%",
    marginBottom: "8px"
  },
  settingsSlider: {
    marginBottom: ".25em"
  },
  settingsLeftTextField: {
    width: "6em"
  },
  settingsRightTextField: {
    width: "6em"
  },
  thresholdGrid: {
    marginBottom: ".25em"
  },
  thresholdSlider: {
    marginTop: ".75em"
    //    marginBottom: ".25em"
  },
  marginBottom25em: {
    marginBottom: ".25em"
  },
  selectAboveSlider: {
    width: "100%",
    marginBottom: "16px"
  }
};

class PerformanceMapPointSettingsComponent extends Component {
  state = { pointGroupOpen: true };

  componentDidUpdate = (prevProps, prevState) => {};

  toggleDrawPoints = () => {
    this.props.setSettings({ drawPoints: !this.props.drawPoints });
  };

  toggleDrawNullBenchmarks = () => {
    this.props.setSettings({
      drawNullBenchmarks: !this.props.drawNullBenchmarks
    });
  };

  toggleHeader = event => {
    this.setState({
      [event.target.id]: !this.state[event.target.id]
    });
  };

  toggleValue = event => {
    this.props.setSettings({
      [event.target.id]: !this.props[event.target.id]
    });
  };

  setBenchmark = event => {
    this.props.setSettings({ benchmark: event.target.value });
  };

  setCalculation = event => {
    this.props.setSettings({
      calculation: event.target.value
    });
  };

  setPercentile = event => {
    var newValue = Math.min(event.target.value, 100);
    newValue = Math.max(newValue, 0);
    this.props.setSettings({ percentile: newValue });
  };

  thresholdSliderChanged = (event, newValue) => {
    this.props.setSettings({
      lowPointPercentile: newValue[0],
      highPointPercentile: newValue[1]
    });
  };

  scaleSliderChanged = (event, newValue) => {
    this.props.setSettings({
      lowValue: Number(newValue[0]).toFixed(1),
      highValue: Number(newValue[1]).toFixed(1)
    });
  };

  setLowPercentile = event => {
    var value = Math.max(0, event.target.value);
    this.props.setSettings({
      lowPointPercentile: value,
      highPointPercentile: Math.max(value, this.props.highPointPercentile)
    });
  };

  setHighPercentile = event => {
    var value = Math.max(0, event.target.value);
    this.props.setSettings({
      lowPointPercentile: Math.min(this.props.lowPointPercentile, value),
      highPointPercentile: value
    });
  };

  setLowValue = event => {
    var value = Math.max(0, event.target.value);
    this.props.setSettings({
      lowValue: value,
      highValue: Math.max(value, this.props.highValue)
    });
  };

  setHighValue = event => {
    var value = Math.max(0.01, event.target.value);
    this.props.setSettings({
      lowValue: Math.min(this.props.lowValue, value),
      highValue: value
    });
  };

  gradientChanged = event => {
    if (event.target.value != this.props.gradient) {
      this.props.setSettings({ gradient: event.target.value });
    }
  };

  render = () => {
    const { classes } = this.props;
    return (
      <HeaderGroup
        id="pointGroupOpen"
        onClick={this.toggleHeader}
        in={this.state.pointGroupOpen}
        label="Points"
      >
        <div className="pad-gutter-8px">
          <FormControlLabel
            control={
              <Switch
                checked={this.props.drawPoints}
                onChange={this.toggleDrawPoints}
                value="drawPoints"
                color="secondary"
              />
            }
            label="Draw Points"
          />
          <FormControlLabel
            control={
              <Switch
                checked={this.props.drawNullBenchmarks}
                onChange={this.toggleDrawNullBenchmarks}
                value="drawPoints"
                color="secondary"
              />
            }
            label="Draw Null Benchmarks"
          />
          <FormControl style={styles.settingsSelect}>
            <InputLabel>Measure</InputLabel>
            <Select
              value={this.props.benchmark}
              onChange={this.setBenchmark}
              input={<Input name="benchmark" />}
            >
              {this.props.availableBenchmarks.map(benchmark => {
                return (
                  <MenuItem
                    key={benchmark}
                    value={benchmark}
                    label={benchmarkNames[benchmark] || benchmark}
                  >
                    {benchmarkNames[benchmark] || benchmark}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl style={styles.settingsSelect}>
            <InputLabel>Statistic</InputLabel>
            <Select
              value={this.props.calculation}
              onChange={this.setCalculation}
              title={calculationTitles[this.props.calculation]}
              input={<Input name="calculation" />}
            >
              {Object.keys(calculationNames).map(calculation => {
                return (
                  <MenuItem
                    key={calculation}
                    value={calculation}
                    label={calculationNames[calculation]}
                    title={calculationTitles[calculation]}
                  >
                    {calculationNames[calculation]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Collapse in={this.props.calculation == "percentile"}>
            <TextField
              id="percentile-value"
              label="Percentile"
              value={this.props.percentile}
              onChange={this.setPercentile}
              type="number"
              className="settings-246px"
            />
          </Collapse>
          <FormControl style={styles.settingsSelect}>
            <InputLabel shrink>Percentile Thresholds</InputLabel>
            <Slider
              value={[
                this.props.lowPointPercentile,
                this.props.highPointPercentile
              ]}
              onChange={this.thresholdSliderChanged}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              max={100}
              min={0}
              step={5}
              style={styles.thresholdSlider}
            />
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={styles.thresholdGrid}
            >
              <TextField
                id="low-value"
                label="Low"
                value={this.props.lowPointPercentile}
                onChange={this.setLowPercentile}
                type="number"
                style={styles.settingsLeftTextField}
              />
              <TextField
                id="high-value"
                label="High"
                value={this.props.highPointPercentile}
                onChange={this.setHighPercentile}
                type="number"
                style={styles.settingsRightTextField}
              />
            </Grid>
          </FormControl>
          <FormControl style={styles.settingsSelect}>
            <InputLabel>Colorization</InputLabel>
            <Select
              value={this.props.gradient}
              onChange={this.gradientChanged}
              type="select"
              name="selectGradient"
              id="gradient"
            >
              {Object.keys(gradientColors).map(gradient => {
                return (
                  <MenuItem key={gradient} value={gradient} label={gradient}>
                    {gradient}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                id="autoscale"
                checked={this.props.autoscale}
                onChange={this.toggleValue}
                value="autoscale"
                color="secondary"
              />
            }
            label="Use Autoscale"
          />
          <Slider
            value={[this.props.lowValue, this.props.highValue]}
            onChange={this.scaleSliderChanged}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            max={30}
            min={0}
            step={0.1}
            disabled={this.props.autoscale}
            style={styles.settingsSlider}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={styles.marginBottom25em}
          >
            <TextField
              id="low-value"
              label="Low"
              value={this.props.lowValue}
              onChange={this.setLowValue}
              type="number"
              style={styles.settingsLeftTextField}
              disabled={this.props.autoscale}
            />
            <TextField
              id="high-value"
              label="High"
              value={this.props.highValue}
              onChange={this.setHighValue}
              type="number"
              style={styles.settingsRightTextField}
              disabled={this.props.autoscale}
            />
          </Grid>
        </div>
      </HeaderGroup>
    );
  };
}
PerformanceMapPointSettingsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const PerformanceMapPointSettings = withStyles(styles)(
  withTheme(PerformanceMapPointSettingsComponent)
);

export default PerformanceMapPointSettings;
