import React, { Component } from "react";

import {
  slide as SlideMenu,
  push as PushMenu,
  scaleRotate as ScaleRotateMenu
} from "react-burger-menu";

// Redux
import { connect } from "react-redux";
import { getSettingsOpen } from "../../redux/selectors";
import { toggleSettingsOpen } from "../../redux/actions";

import { getAvailableWindowHeight } from "../../helpers/Utilities";

export class SettingsMenu extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeMenus);
    if (this.props.settingsOpen) {
      this.props.toggleSettingsOpen();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settingsOpen) {
      this.resizeMenus();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeMenus);
    if (this.props.settingsOpen) {
      this.props.toggleSettingsOpen();
    }
  }

  resizeMenus = () => {
    var menus = document.getElementsByClassName("bm-settingsMenu");
    var availableHeight = getAvailableWindowHeight();
    var totalHeight = window.innerHeight;
    for (var i = 0; i < menus.length; i++) {
      menus[i].style.height = availableHeight + "px";
    }
  };

  menuStateChange = state => {
    if (!state.isOpen && this.props.settingsOpen) {
      this.props.toggleSettingsOpen();
    }
  };

  render() {
    if (this.props.style === "push") {
      return (
        <PushMenu
          menuClassName={
            this.props.menuClassName
              ? this.props.menuClassName + "bm-settingsMenu"
              : "bm-settingsMenu"
          }
          pageWrapId={this.props.pageWrapId}
          outerContainerId={this.props.outerContainerId}
          noOverlay={this.props.noOverlay}
          disableAutoFocus={this.props.disableAutoFocus}
          disableCloseOnEsc={this.props.disableCloseOnEsc}
          disableOverlayClick={this.props.disableOverlayClick}
          customBurgerIcon={this.props.customBurgerIcon}
          width={this.props.width}
          customCrossIcon={
            this.props.customCrossIcon === undefined
              ? false
              : this.props.customCrossIcon
          }
          isOpen={this.props.settingsOpen}
          onStateChange={this.menuStateChange}
        >
          {this.props.children}
        </PushMenu>
      );
    } else if (this.props.style === "scaleRotate") {
      return (
        <ScaleRotateMenu
          menuClassName={
            this.props.menuClassName
              ? this.props.menuClassName + "bm-settingsMenu"
              : "bm-settingsMenu"
          }
          pageWrapId={this.props.pageWrapId}
          outerContainerId={this.props.outerContainerId}
          noOverlay={this.props.noOverlay}
          disableAutoFocus={this.props.disableAutoFocus}
          disableCloseOnEsc={this.props.disableCloseOnEsc}
          disableOverlayClick={this.props.disableOverlayClick}
          customBurgerIcon={this.props.customBurgerIcon}
          width={this.props.width}
          customCrossIcon={
            this.props.customCrossIcon === undefined
              ? false
              : this.props.customCrossIcon
          }
          isOpen={this.props.settingsOpen}
          onStateChange={this.menuStateChange}
        >
          {this.props.children}
        </ScaleRotateMenu>
      );
    } else {
      return (
        <SlideMenu
          menuClassName={
            this.props.menuClassName
              ? this.props.menuClassName + "bm-settingsMenu"
              : "bm-settingsMenu"
          }
          pageWrapId={this.props.pageWrapId}
          outerContainerId={this.props.outerContainerId}
          noOverlay={this.props.noOverlay}
          disableAutoFocus={this.props.disableAutoFocus}
          disableCloseOnEsc={this.props.disableCloseOnEsc}
          disableOverlayClick={this.props.disableOverlayClick}
          customBurgerIcon={this.props.customBurgerIcon}
          width={this.props.width}
          customCrossIcon={
            this.props.customCrossIcon === undefined
              ? false
              : this.props.customCrossIcon
          }
          isOpen={this.props.settingsOpen}
          onStateChange={this.menuStateChange}
        >
          {this.props.children}
        </SlideMenu>
      );
    }
  }
}

const mapStateToProps = state => {
  const settingsOpen = getSettingsOpen(state);
  return { settingsOpen };
};

const mapDispatchToProps = {
  toggleSettingsOpen
};

const SettingsMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(SettingsMenu);

export default SettingsMenuContainer;
