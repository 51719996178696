import React, { Component, useState, useEffect } from "react";

import {
  makeStyles,
  withStyles,
  withTheme,
  FormControl,
  InputLabel,
  NativeSelect,
  Select,
  Input,
  Grid,
  ListItemText,
  List,
  ListSubheader,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  Chip,
  MenuItem,
  Typography
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

import moment from "moment";
import MomentUtils from "@date-io/moment";

import { dateRanges } from "../../helpers/DashboardHelpers.js";
import { DataFilterDialog } from "../DataFilter.js";

import {
  HeaderGroup,
  GetDataButton,
  AddButton,
  DefaultMenuProps,
  getFilterStyle,
  DeletableChip
} from "./MenuControls";
import { DataContext } from "../DataContext";
import Api from "../../services/Api";

const styles = {
  filterGrid: {
    paddingLeft: "8px",
    paddingBottom: "8px"
  },
  filterList: {
    flex: "auto"
  },
  filterListSubheader: {
    lineHeight: "32px",
    paddingLeft: "0px"
  },
  filterListItem: {
    padding: "0px",
    marginTop: "0px",
    marginBottom: "0px"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  }
};

export default class DataSettings extends Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <DataContext.Consumer>
        {({ ...dataSettings }) => (
          <DataSettingsContainer {...dataSettings} {...this.props} />
        )}
      </DataContext.Consumer>
    );
  };
}

class DataSettingsComponent extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    dataGroupOpen: true,
    possibleDataFilters: [],
    showAddDataFilter: false,
    showEditDataFilter: false
  };

  static defaultProps = {
    regionSelection: []
  };

  componentDidMount = async () => {
    if (this.props.showRegions) {
      await this.getRegions();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.showRegions && !prevProps.showRegions) {
      this.getRegions();
    }
  }

  getRegions = async () => {
    const regions = await Api.getRegions();
    this.setState({ regions: regions });
  };

  render() {
    var changesMade =
      this.props.loadedStartDate != this.props.startDate ||
      this.props.loadedEndDate != this.props.endDate ||
      this.props.loadedDataFilters != this.props.dataFilters;

    const { classes, dataFilters } = this.props;

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <HeaderGroup
          id="dataGroupOpen"
          onClick={this.toggleHeader}
          in={this.state.dataGroupOpen}
          label="Data"
        >
          <div className="pad-gutter-8px margin-top-4px">
            <FormControl className="settings-select">
              <InputLabel>Date Range</InputLabel>
              <NativeSelect
                value={this.props.dateRange}
                onChange={this.setDateRange}
                input={<Input name="measure" />}
              >
                {dateRanges.map(dateRange => {
                  return (
                    <option key={dateRange} value={dateRange} label={dateRange}>
                      {dateRange}
                    </option>
                  );
                })}
              </NativeSelect>
            </FormControl>
            <KeyboardDatePicker
              clearable
              label="Start Date"
              value={this.props.startDate}
              onChange={this.setStartDate}
              minDate={this.props.minDate}
              maxDate={this.props.endDate}
              format="YYYY/MM/DD"
              className="settings-datePicker"
            />
            <KeyboardDatePicker
              clearable
              label="End Date"
              value={this.props.endDate}
              onChange={this.setEndDate}
              maxDate={this.props.maxDate}
              format="YYYY/MM/DD"
              className="settings-datePicker"
            />
            {this.props.showRegions === true && (
              <FormControl className="settings-select">
                <InputLabel>Regions</InputLabel>
                <Select
                  multiple
                  value={this.props.regionSelection}
                  onChange={this.onSelectionChange}
                  input={<Input id="select-multiple-placeholder" />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(value => (
                        <DeletableChip
                          key={value.id}
                          label={value.name}
                          className={classes.chip}
                          onDelete={() => {
                            this.removeFromSelection(value);
                          }}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={DefaultMenuProps}
                >
                  {this.state.regions &&
                    this.state.regions.map(region => (
                      <MenuItem key={region.id} value={region}>
                        <Typography
                          primary={region}
                          style={getFilterStyle(
                            region,
                            this.props.regionSelection,
                            this.props.theme
                          )}
                        >
                          {region.name}
                        </Typography>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          </div>
          {this.props.showFilters !== false && (
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="baseline"
              className={classes.filterGrid}
            >
              <List
                aria-labelledby="filters-subheader"
                subheader={
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="baseline"
                  >
                    <ListSubheader
                      component="div"
                      id="filters-subheader"
                      className={classes.filterListSubheader}
                    >
                      Filters
                    </ListSubheader>
                    <AddButton
                      id="addFilter"
                      onClick={this.toggleAddDataFilter}
                    />
                  </Grid>
                }
                dense={true}
                className={classes.filterList}
              >
                {dataFilters.map(value => (
                  <ListItem key={value.name} className={classes.filterListItem}>
                    <ListItemText primary={value.name} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => {
                          this.editFilter(value);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => {
                          this.removeFilter(value);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <DataFilterDialog
                incidentsByLocation={this.props.incidentsByLocation}
                open={this.state.showAddDataFilter}
                hide={this.toggleAddDataFilter}
                onSave={this.addDataFilter}
              />
              <DataFilterDialog
                incidentsByLocation={this.props.incidentsByLocation}
                open={this.state.showEditDataFilter}
                filter={this.state.filterToEdit}
                hide={this.hideEditFilter}
                edit={true}
                onSave={this.saveEditedFilter}
              />
            </Grid>
          )}
          {this.props.showDataButton !== false && (
            <GetDataButton onClick={this.getData} changed={changesMade} />
          )}
        </HeaderGroup>
      </MuiPickersUtilsProvider>
    );
  }

  toggleHeader = () => {
    this.setState({ dataGroupOpen: !this.state.dataGroupOpen });
  };

  setDateRange = event => {
    this.props.setDataSettings({ dateRange: event.target.value });
  };

  setStartDate = date => {
    if (date == null) date = moment();

    this.props.setDataSettings({
      startDate: date.format("YYYY-MM-DD"),
      dateRange: "Selected Dates"
    });
  };

  setEndDate = date => {
    if (date == null) date = moment();

    this.props.setDataSettings({
      endDate: date.format("YYYY-MM-DD"),
      dateRange: "Selected Dates"
    });
  };

  getData = () => {
    if (this.props.updateData) {
      this.props.updateData();
    }
  };

  selectedDataFiltersChanged = event => {
    this.props.setDataSettings({ dataFilters: event.target.value });
  };

  onSelectionChange = event => {
    this.props.setDataSettings({ regionSelection: event.target.value });
  };

  removeFromSelection = value => {
    var newSelection = this.props.regionSelection.slice();
    if (newSelection.indexOf(value) === -1) return;

    newSelection.splice(newSelection.indexOf(value), 1);
    this.props.setDataSettings({ regionSelection: newSelection });
  };

  editFilter = value => {
    this.setState({
      filterToEdit: value,
      showEditDataFilter: true
    });
  };

  hideEditFilter = () => {
    this.setState({ showEditDataFilter: false });
  };

  removeFilter = value => {
    var newSelection = this.props.dataFilters.slice();
    if (newSelection.indexOf(value) === -1) return;

    newSelection.splice(newSelection.indexOf(value), 1);
    this.props.setDataSettings({ dataFilters: newSelection });
  };

  getDataFilterStyle = (item, items, theme) => {
    return {
      fontWeight:
        items && items.indexOf(item) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium
    };
  };

  toggleAddDataFilter = () => {
    this.setState({ showAddDataFilter: !this.state.showAddDataFilter });
  };

  addDataFilter = filterInfo => {
    var filters = Array.from(this.props.dataFilters);
    filters.push(filterInfo);
    this.props.setDataSettings({ dataFilters: filters });

    var possibleDataFilters = Array.from(this.state.possibleDataFilters);
    possibleDataFilters.push(filterInfo);
    this.setState({ possibleDataFilters: possibleDataFilters });
  };

  saveEditedFilter = filterInfo => {
    var lastFilter = this.state.filterToEdit;
    var newFilters = this.props.dataFilters.slice();
    var index = newFilters.indexOf(lastFilter);

    if (index === -1) return;

    newFilters.splice(index, 1, filterInfo);
    this.props.setDataSettings({ dataFilters: newFilters });
  };
}

const DataSettingsContainer = withStyles(styles)(
  withTheme(DataSettingsComponent)
);
