import React, { Component } from "react";

import Api from "../services/Api";
import { getDatesFromFilters, lzw_decode } from "../helpers/DashboardHelpers";
import { AppContext } from "../AppContext";

import { filterIncidentsByLocation } from "./DataFilter";

export const DataContext = React.createContext({});

export default class DataContextComponent extends Component {
  constructor(props) {
    super(props);

    let dataFilters = [];
    if (props.dashboardSettings && props.dashboardSettings.dataFilters) {
      dataFilters = props.dashboardSettings.dataFilters;
    }

    this.state = {
      data: {},
      dataFilters: dataFilters,
      setData: this.setData,
      updateData: this.updateData,
      setDataSettings: this.setDataSettings,
      getDataSettings: this.getDataSettings,
      getFilterFields: this.getFilterFields,
      getIncidentsById: this.getIncidentsById,
      ...props.defaultSettings
    };
  }

  incidentsById = {};

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.dateRange !== prevState.dateRange) {
      this.onDateRangeChanged();
    }
  };

  onDateRangeChanged = () => {
    var filters = {};
    filters.startDate = this.state.startDate;
    filters.endDate = this.state.endDate;
    filters.dateRange = this.state.dateRange;
    var dates = getDatesFromFilters(filters);

    this.setState({
      startDate: dates[0],
      endDate: dates[1]
    });
  };

  setData = data => {
    this.setState({ data: data });
  };

  getData = () => {
    return this.state.data;
  };

  getIncidentsById = incidentList => {
    var output = [];
    var toFetch = [];
    var cachedIds = Object.keys(this.incidentsById);
    for (var i = 0; i < incidentList.length; i++) {
      if (cachedIds.includes(incidentList[i])) {
        output.push(this.incidentsById[incidentList[i]]);
      } else {
        toFetch.push(incidentList[i]);
      }
    }

    var locations = Object.keys(this.state.incidentsByLocation);
    for (var i = 0; i < locations.length; i++) {
      var incidents = this.state.incidentsByLocation[locations[i]];
      for (var j = 0; j < incidents.length; j++) {
        if (toFetch.includes(incidents[j].id)) {
          output.push(incidents[j]);
          this.incidentsById[incidents[j].id] = incidents[j];
        }
      }
    }

    return output;
  };

  updateData = async () => {
    if (this.state.usingIncidentsByLocation) {
      let result = {};
      this.setState({ loadingData: true });
      this.context
        .getIncidentsByLocation(
          this.state.startDate,
          this.state.endDate,
          result
        )
        .then(() => {
          let incidentsByLocation = filterIncidentsByLocation(
            this.state.dataFilters,
            result["incidentsByLocation"]
          );
          this.setState({ incidentsByLocation: incidentsByLocation });
        });
    }

    if (this.state.usingRegions) {
      let result = {};
      this.getRegions(result).then(() => {
        this.setState({ regions: result["regions"] });
      });
    }

    if (this.state.usingMetric) {
    }

    this.setState({
      loadingData: false,
      loadedStartDate: this.state.startDate,
      loadedEndDate: this.state.endDate,
      loadedDataFilters: this.state.dataFilters
    });
  };

  getRegions = async result => {
    let regions = await Api.getRegionsWithIncidentIDs(
      this.state.startDate,
      this.state.endDate
    );
    result["regions"] = regions;
  };

  getDataSettings = () => {
    var settings = Object.assign({}, this.state);
    delete settings.setData;
    delete settings.data;
    delete settings.updateData;
    delete settings.setDataSettings;
    delete settings.getDataSettings;

    return settings;
  };

  setDataSettings = newSettings => {
    this.setState(newSettings);
  };

  getReportData = type => {
    // TODO ??
    //respond with appropriate data;
    //update report components to use getReportData in data context
  };

  render() {
    return (
      <DataContext.Provider value={this.state}>
        {this.props.children}
      </DataContext.Provider>
    );
  }
}
DataContextComponent.contextType = AppContext;
