import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  withStyles,
  withTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  NativeSelect,
  Input,
  TextField,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Switch,
  InputLabel,
  Divider,
  Collapse
} from "@material-ui/core";

import {
  HeaderGroup,
  DeleteButton,
  buildNativeOptions,
  SaveChangesButton
} from "../controls/MenuControls";
import { currentUserHasRole } from "../../helpers/Utilities";

import { ComponentContext } from "../DashboardComponent";
// import { DashboardContext } from "./Dashboard";
import DashboardItemSettingsContainer from "./DashboardItemSettings";

const styles = {
  dashboardItemGroup: {
    marginBottom: "4px",
    paddingLeft: "8px",
    paddingRight: "8px"
  }
};

export default class DashboardSettingsMenu extends Component {
  render() {
    return (
      <ComponentContext.Consumer>
        {({ setSettings, ...settings }) => (
          <DashboardSettingsMenuComponent
            setSettings={setSettings}
            {...settings}
            {...this.props}
          />
        )}
      </ComponentContext.Consumer>
    );
  }
}

class DashboardSettingsMenuComponent extends Component {
  constructor(props) {
    super(props);

    var dashboardTitle =
      (this.props.dashboard && this.props.dashboard.title) || "";
    this.state = { generalGroupOpen: true, title: dashboardTitle };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.dashboard != prevProps.dashboard)
      if (this.props.dashboard && this.props.dashboard.title) {
        this.setState({ title: this.props.dashboard.title });
      } else {
        this.setState({ title: "" });
      }
  }

  toggleState = event => {
    this.setState({ [event.target.id]: !this.state[event.target.id] });
  };

  titleUpdateTimer = undefined;

  startTitleUpdate = event => {
    // This is really crappy but pretty much the only way I could figure out to keep things from lagging when you type due to React updates
    this.setState({ title: event.target.value });
    if (this.titleUpdateTimer) {
      clearTimeout(this.titleUpdateTimer);
    }

    this.titleUpdateTimer = setTimeout(this.finishTitleUpdate, 50);
  };

  finishTitleUpdate = () => {
    var dashboard = this.props.dashboard;
    dashboard.title = this.state.title;
    this.props.setSettings({ dashboard: dashboard });
  };

  toggleJurisdiction = () => {
    var dashboard = this.props.dashboard;
    dashboard.isJurisdiction = !dashboard.isJurisdiction;
    this.props.setSettings({ dashboard: dashboard });
  };

  toggleSaveDialog = () => {
    this.setState({ showSaveDialog: !this.state.showSaveDialog });
  };

  toggleDeleteDialog = () => {
    this.setState({ showDeleteDialog: !this.state.toggleDeleteDialog });
  };

  saveDashboard = value => {
    this.props.saveDashboard && this.props.saveDashboard();
    this.toggleSaveDialog();
  };

  deleteDashboard = value => {
    this.props.deleteDashboard && this.props.deleteDashboard();
    this.toggleDeleteDialog();
  };

  getDashboardGroup = (value, index) => {
    return (
      <DashboardItemSettingsContainer
        dashboardItem={value}
        key={index}
        deleteDashboardItem={this.props.deleteDashboardItem}
      />
    );
  };

  render() {
    const { classes, dashboard, orderedDashboardItems } = this.props;
    return (
      <div className="bm-settings-container">
        <div className="flex-grow-shrink-auto">
          <HeaderGroup
            id="generalGroupOpen"
            label="General"
            onClick={this.toggleState}
            in={this.state.generalGroupOpen}
          >
            <div className={classes.dashboardItemGroup}>
              <TextField
                className="settings-246px"
                id="title"
                label="Title"
                value={this.state.title}
                onChange={this.startTitleUpdate}
              />
              {currentUserHasRole("PowerUser") && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={dashboard && dashboard.isJurisdiction}
                      onChange={this.toggleJurisdiction}
                      value="isJurisdiction"
                      color="secondary"
                    />
                  }
                  label="Share with Jurisdiction"
                />
              )}
            </div>
            <DeleteButton onClick={this.toggleDeleteDialog} />
          </HeaderGroup>
          {this.state.showDeleteDialog && (
            <Dialog open={this.state.showDeleteDialog}>
              <DialogTitle>Delete Dashboard?</DialogTitle>
              <DialogContent>
                This dashboard and its contents will be permanently deleted.
                Continue?
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.deleteDashboard}
                >
                  Delete
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.toggleDeleteDialog}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {this.state.showSaveDialog && (
            <Dialog open={this.state.showSaveDialog}>
              <DialogTitle>Save Dashboard?</DialogTitle>
              <DialogContent>
                This dashboard and any changes to its contents will be saved.
                Continue?
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.saveDashboard}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.toggleSaveDialog}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {orderedDashboardItems &&
            orderedDashboardItems.map(item => {
              return this.getDashboardGroup(
                item,
                orderedDashboardItems.indexOf(item)
              );
            })}
        </div>
        <SaveChangesButton onClick={this.toggleSaveDialog} />
      </div>
    );
  }
}
