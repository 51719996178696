import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  withStyles,
  withTheme,
  FormControl,
  FormControlLabel,
  Switch,
  InputLabel,
  NativeSelect,
  Input,
  Select,
  Chip,
  MenuItem,
  ListItemText,
  Typography,
  Slider,
  TextField
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  gradientColors,
  heatmapGradientColors,
  categoryColors,
  getHexColor,
  getCategoryColor
} from "../../../helpers/Colors";

import {
  HeaderGroup,
  DefaultMenuProps,
  getFilterStyle,
  DeletableChip
} from "../../controls/MenuControls";
import ColorSwatch from "../../controls/ColorSwatch";

const calculationNames = {
  count: "Count",
  measure: "Most Common"
};

const styles = {
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  settingsSlider: {
    marginBottom: ".25em"
  },
  selectAboveSlider: {
    width: "100%",
    marginBottom: "16px"
  },
  settingsLeftTextField: {
    width: "6em"
  },
  settingsRightTextField: {
    width: "6em"
  },
  marginBottom25em: {
    marginBottom: ".25em"
  }
};

class TypeMapHeatmapSettingsComponent extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    heatmapGroupOpen: this.props.heatmapMode
  };

  static defaultProps = {
    drawHeatmap: false,
    heatmapOpacity: 80,
    heatmapHeat: 5,
    heatmapBlur: 5,
    heatmapRadius: 5,
    heatmapGradient: "Green-Yellow-Red",
    heatmapSelection: []
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.heatmapMode !== prevProps.heatmapMode) {
      this.setState({ heatmapGroupOpen: this.props.heatmapMode });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <HeaderGroup
        id="heatmapGroupOpen"
        onClick={this.toggleHeader}
        in={this.state.heatmapGroupOpen}
        label="Heatmap"
      >
        <div className="pad-gutter-8px">
          <FormControlLabel
            control={
              <Switch
                checked={this.props.drawHeatmap}
                onChange={this.toggleHeatmap}
                value="drawHeatmap"
                color="secondary"
              />
            }
            label="Draw Heatmap"
          />
          <Typography id="heatmap-opacity" gutterBottom>
            Opacity
          </Typography>
          <Slider
            value={this.props.heatmapOpacity}
            onChangeCommitted={this.onOpacityChanged}
            aria-labelledby="heatmap-opacity"
            min={25}
            max={100}
            valueLabelDisplay="auto"
            className={classes.settingsSlider}
          />
          <Typography id="heatmap-heat" gutterBottom>
            Heat Per Incident
          </Typography>
          <Slider
            value={this.props.heatmapHeat}
            onChangeCommitted={this.onHeatChanged}
            aria-labelledby="heatmap-heat"
            min={1}
            max={9}
            valueLabelDisplay="auto"
            className={classes.settingsSlider}
          />
          <Typography id="heatmap-blur" gutterBottom>
            Blur
          </Typography>
          <Slider
            value={this.props.heatmapBlur}
            onChangeCommitted={this.onBlurChanged}
            aria-labelledby="heatmap-blur"
            min={1}
            max={9}
            valueLabelDisplay="auto"
            className={classes.settingsSlider}
          />
          <Typography id="heatmap-radius" gutterBottom>
            Radius
          </Typography>
          <Slider
            value={this.props.heatmapRadius}
            onChangeCommitted={this.onRadiusChanged}
            aria-labelledby="heatmap-radius"
            min={1}
            max={9}
            valueLabelDisplay="auto"
            className={classes.settingsSlider}
          />

          <FormControl className="settings-select">
            <InputLabel>Colorization</InputLabel>
            <Select
              value={this.props.heatmapGradient}
              onChange={this.onGradientChanged}
              type="select"
              name="selectHeatmapGradient"
              id="heatmapGradient"
            >
              {Object.keys(heatmapGradientColors).map(gradient => {
                return (
                  <MenuItem key={gradient} value={gradient} label={gradient}>
                    {gradient}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl className="settings-select">
            <InputLabel>Measure</InputLabel>
            <Select
              value={this.props.heatmapDataType}
              onChange={this.onDataTypeChanged}
              input={<Input name="measure" />}
            >
              {Object.keys(this.props.dataTypes).map(dataType => {
                return (
                  <MenuItem key={dataType} value={dataType} label={dataType}>
                    {dataType}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl className="settings-select">
            <Autocomplete
              id="filter-combo-box"
              options={
                (this.props.dataTypes[this.props.heatmapDataType] &&
                  Object.keys(
                    this.props.dataTypes[this.props.heatmapDataType]
                  ).sort()) ||
                []
              }
              getOptionLabel={option => option}
              classes={{}}
              value={this.props.heatmapSelection}
              handleHomeEndKeys
              clearOnBlur
              autoHighlight
              multiple
              disableCloseOnSelect
              onChange={(event, newValue) => {
                this.props.setSettings({
                  heatmapSelection: newValue
                });
              }}
              renderInput={params => (
                <TextField {...params} variant="standard" label="Filter" />
              )}
              renderOption={(category, { selected }) => (
                <MenuItem key={category} value={category}>
                  <ColorSwatch menuItem color={getCategoryColor(category)} />
                  <Typography
                    key={category}
                    style={getFilterStyle(
                      category,
                      this.props.heatmapSelection,
                      this.props.theme
                    )}
                  >
                    {category}
                  </Typography>
                </MenuItem>
              )}
              renderTags={selected => {
                return (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <DeletableChip
                        icon={<ColorSwatch color={getCategoryColor(value)} />}
                        key={value}
                        label={value}
                        className={classes.chip}
                        onDelete={() => {
                          this.removeFromHeatmapSelection(value);
                        }}
                      />
                    ))}
                  </div>
                );
              }}
              aria-label="filter"
            />
          </FormControl>
        </div>
      </HeaderGroup>
    );
  }

  toggleHeader = () => {
    this.setState({ heatmapGroupOpen: !this.state.heatmapGroupOpen });
  };

  toggleHeatmap = () => {
    this.props.setSettings({ drawHeatmap: !this.props.drawHeatmap });
  };

  onOpacityChanged = (event, newValue) => {
    this.props.setSettings({ heatmapOpacity: newValue });
  };

  onHeatChanged = (event, newValue) => {
    this.props.setSettings({ heatmapHeat: newValue });
  };

  onBlurChanged = (event, newValue) => {
    this.props.setSettings({ heatmapBlur: newValue });
  };

  onRadiusChanged = (event, newValue) => {
    this.props.setSettings({ heatmapRadius: newValue });
  };

  onGradientChanged = event => {
    this.props.setSettings({
      heatmapGradient: event.target.value
    });
  };

  onDataTypeChanged = event => {
    let selection = this.props.heatmapSelection;
    if (selection.length != 0) {
      selection = [];
    }

    this.props.setSettings({
      heatmapDataType: event.target.value,
      heatmapSelection: selection
    });
  };

  onSelectionChange = event => {
    this.props.setSettings({
      heatmapSelection: event.target.value
    });
  };

  removeFromHeatmapSelection = value => {
    var newSelection = this.props.heatmapSelection.slice();
    if (newSelection.indexOf(value) === -1) return;

    newSelection.splice(newSelection.indexOf(value), 1);
    this.props.setSettings({ heatmapSelection: newSelection });
  };
}

TypeMapHeatmapSettingsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const TypeMapHeatmapSettings = withStyles(styles)(
  withTheme(TypeMapHeatmapSettingsComponent)
);

export default TypeMapHeatmapSettings;
