import React, { Component } from "react";

import { withTheme, withStyles, Button } from "@material-ui/core";

import { DataContext } from "../../DataContext";
import { ComponentContext } from "../../DashboardComponent";
import DataSettings from "../../controls/DataSettings";
import {
  AddDashboardButton,
  SaveDashboardItemChangesButton
} from "../../controls/AddDashboard";

import TypeMapPointSettings from "./TypeMapPointSettings";
import TypeMapHexagonSettings from "./TypeMapHexagonSettings";
import TypeMapHeatmapSettings from "./TypeMapHeatmapSettings";
import TypeMapRegionSettings from "./TypeMapRegionSettings";
import Api from "../../../services/Api";
import { SaveChangesButton } from "../../controls/MenuControls";

export default class TypeMapSettings extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const customize = this.context.customize ? this.context.customize : false;

    return (
      <div className="bm-settings-container">
        <div className="flex-grow-shrink-auto">
          <DataContext.Consumer>
            {({ ...dataSettings }) => (
              <React.Fragment>
                <DataSettings {...dataSettings} />
                <ComponentContext.Consumer>
                  {({ ...settings }) => (
                    <React.Fragment>
                      <TypeMapPointSettings {...settings} />
                      <TypeMapHexagonSettings {...settings} />
                      <TypeMapRegionSettings {...settings} {...dataSettings} />
                      <TypeMapHeatmapSettings {...settings} />
                    </React.Fragment>
                  )}
                </ComponentContext.Consumer>
              </React.Fragment>
            )}
          </DataContext.Consumer>
        </div>
        {(customize && (
          <SaveDashboardItemChangesButton
            itemId={this.context.itemId}
            dashboardId={this.context.dashboardId}
            context={this.context}
          />
        )) || (
          <AddDashboardButton
            onClick={() => {
              this.context.setSettings({ showAddDashboardDialog: true });
            }}
          />
        )}
      </div>
    );
  }
}

const styles = {};

TypeMapSettings.contextType = ComponentContext;
