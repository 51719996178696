import { SET_LOGGED_IN } from "../actionTypes";

let loggedIn = false;

const setLoggedIn = (state, action) => {
  switch (action.type) {
    case SET_LOGGED_IN:
      loggedIn = action.payload.loggedIn;
      return loggedIn;
    default:
      return loggedIn;
  }
};

export default setLoggedIn;
