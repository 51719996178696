import { connect } from "react-redux";
import { setAllDashboards } from "../redux/actions";
import moment from "moment";
import $ from "jquery";

function isUserLoggedIn() {
  // Check if the user object exists
  const userJson = localStorage.getItem("user");

  // If it does not exist, then return false
  if (!userJson) return false;

  const user = JSON.parse(userJson);

  if (!user || !user.jwt) return false;

  // Check the expiration date of the JWT
  const tokenData = JSON.parse(atob(user.jwt.split(".")[1]));

  // If the token is expired
  if (tokenData.exp * 1000 - Date.now() < 0) {
    return false;
  }

  // If we've reached this point, then the token is still valid
  return true;
}

function getUser() {
  // Get the user object
  const userJson = localStorage.getItem("user");

  // If it does not exist, then return false
  if (userJson == null) return null;

  const user = JSON.parse(userJson);

  // Return the user
  return user;
}

function currentUserHasRole(role) {
  // Get the user object
  const userJson = localStorage.getItem("user");

  // If it does not exist, then return false
  if (userJson == null) return null;

  const user = JSON.parse(userJson);

  // Loop through all user roles and return true if the user has the specified role
  for (let i = 0; i < user.userRoles.length; i++) {
    if (user.userRoles[i].role.name == role) return true;
  }

  // If we reach here, return false, because the user does not have the specified role
  return false;
}

function hasTouch() {
  const touch = "ontouchstart" in window;
  return touch;
}

function getAvailableWindowHeight() {
  var navMenuHeight = 0;
  const navMenu = document.getElementById("navMenu");
  if (navMenu) navMenuHeight = navMenu.offsetHeight;

  return window.innerHeight - navMenuHeight;
}

//thanks stack overflow
function unCamelCase(toFix) {
  return toFix.replace(/([A-Z][a-z])/g, " $1").replace(/^./, function(str) {
    return str.trim().toUpperCase();
  });
}

function capitalize(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
}

//takes an object, iterates through all keys, formats any dates!
function formatObjectDates(data) {
  $.each(data, (propertyName, value) => {
    if (moment(value, moment.ISO_8601).isValid()) {
      data[propertyName] = moment(value, moment.ISO_8601).format(
        "MM-DD-YYYY HH:mm:ss"
      );
    } else {
      data[propertyName] = value;
    }
  });
  return data;
}

function roundObjectNumbers(data, num = 4) {
  $.each(data, (propertyName, value) => {
    if (!isNaN(value)) {
      data[propertyName] = Number(Number(value).toFixed(num));
    } else {
      data[propertyName] = value;
    }
  });
  return data;
}

export {
  isUserLoggedIn,
  getUser,
  hasTouch,
  getAvailableWindowHeight,
  currentUserHasRole,
  unCamelCase,
  capitalize,
  formatObjectDates,
  roundObjectNumbers
};
