import React, { Component } from "react";

import { withStyles } from "@material-ui/core";

import { getCategoryColor, gradientColors } from "../../../helpers/Colors";
import { LegendRow } from "../MapElements";
import { withTheme } from "@material-ui/styles";

// import { calculationNames } from "./TypeMapHexagonSettings";
import { ComponentContext } from "../../DashboardComponent";

let styles = {
  legendDiv: {
    maxWidth: "240px",
    maxHeight: "400px",
    overflow: "auto",
    minHeight: "28px",
    minWidth: "150px"
  },
  sectionSpacer: {
    height: "8px"
  }
};

class PerformanceMapKey extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { classes } = this.props;

    let pointCategories = this.context.pointSelection;
    if (!pointCategories || pointCategories.length == 0) {
      pointCategories =
        this.context.dataTypes && this.context.pointDataType
          ? Object.keys(this.context.dataTypes[this.context.pointDataType])
          : [];
    }

    let pointFeaturesDisplayed = this.context.displayedFeatures || [];
    let displayedIncidents = 0;
    let data;
    for (var i = 0; i < pointFeaturesDisplayed.length; i++) {
      data = pointFeaturesDisplayed[i].get("data");
      if (data) {
        displayedIncidents += data.length;
      }
    }

    let incidentString = displayedIncidents + " Incidents";
    if (displayedIncidents === 1) {
      incidentString = "1 Incident";
    }

    let low = Number(this.context.lowValue || 0).toFixed(2);
    let high = Number(this.context.highValue || 0).toFixed(2);
    let diff = (high - low) / 2;
    let mid = (Number(low) + diff).toFixed(2);
    let colors = gradientColors[this.context.gradient];

    return (
      <React.Fragment>
        <div className={classes.legendDiv}>
          <div className="legend-header">{incidentString}</div>
          {this.context.drawPoints && (
            <React.Fragment>
              <div className="legend-header">
                Incident Count: {this.context.pointDataType}
              </div>
              <hr className="legend-seperator" />
              <LegendRow
                key="low"
                color={`rgb(${colors[0]})`}
                label={"Low: " + low}
              />
              <LegendRow
                key="mid"
                color={`rgb(${colors[1]})`}
                label={"Mid: " + mid}
              />
              <LegendRow
                key="high"
                color={`rgb(${colors[2]})`}
                label={"High: " + high}
              />
            </React.Fragment>
          )}
          {!this.context.drawPoints && (
            <React.Fragment>
              <div class="legend-header">Nothing Displayed</div>
              <hr class="legend-seperator" />
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}
PerformanceMapKey.contextType = ComponentContext;

const PerformanceMapKeyContainer = withStyles(styles)(
  withTheme(PerformanceMapKey)
);

export default PerformanceMapKeyContainer;
