import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  makeStyles,
  Button,
  Chip,
  Collapse,
  MenuItem,
  ListItemText,
  Divider
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Cancel";

const defaultStyles = makeStyles({
  chip: {
    margin: 2
  },
  addButton: {
    marginRight: "4px"
  },
  deleteButton: {
    width: "100%",
    marginBottom: "4px"
  },
  getDataButton: {
    width: "100%",
    marginBottom: "4px"
  },
  customizeButton: {
    width: "100%"
  },
  optionsButtonCollapse: {
    float: "left",
    position: "relative",
    marginButton: "-0.1em"
  },
  optionsButton: {
    backgroundColor: "white",
    color: "black",
    height: "1.5em",
    width: "1.5em",
    fontSize: "1.25em",
    margin: "1px",
    display: "block",
    padding: "0",
    fontWeight: "bold",
    textDecoration: "none",
    textAlign: "center",
    borderRadius: "2px",
    border: "black solid 1px",
    borderRadius: "2px"
  },
  optionsButtonText: {
    fontFamily: "Material Icons"
  },
  invertedOptionsButton: {
    borderRadius: "2px",
    filter: "invert(100%)"
  }
});

export function HeaderGroup(props) {
  const onClick = event => {
    event.preventDefault();
    if (props.onClick) {
      props.onClick(event);
    }
  };

  return (
    <div>
      <button className="header-button" id={props.id} onClick={onClick}>
        <span id={props.id} className="header-button-icon">
          {props.in ? "expand_more" : "chevron_right"}
        </span>
        <span id={props.id} className="header-button-text">
          {props.label}
        </span>
      </button>
      <Collapse in={props.in}>{props.children}</Collapse>
    </div>
  );
}

// If you ever feel like you've run out of options, just read this function!
export function buildNativeOptions(options) {
  if (Array.isArray(options)) {
    return options.map(option => {
      return (
        <option key={option} value={option} label={option}>
          {option}
        </option>
      );
    });
  } else {
    return Object.keys(options).map(option => {
      return (
        <option key={option} value={option} label={options[option]}>
          {options[option]}
        </option>
      );
    });
  }
}

export function UpdateButton(props) {
  return (
    <Button
      variant="contained"
      color="primary"
      id="update-button"
      className="settings-fullWidthButton"
      {...props}
    >
      Update
    </Button>
  );
}

export function GetDataButton(props) {
  const classes = defaultStyles();
  const { changed } = props;
  return (
    <Button
      variant="contained"
      color={changed ? "secondary" : "primary"}
      id="getdata-button"
      className={classes.getDataButton}
      onClick={props.onClick}
    >
      Get Data
    </Button>
  );
}

export function AddButton(props) {
  const classes = defaultStyles();
  return (
    <Button className={classes.addButton} {...props}>
      Add
    </Button>
  );
}

export function DeleteButton(props) {
  const classes = defaultStyles();
  return (
    <Button
      className={classes.deleteButton}
      variant="contained"
      color="secondary"
      {...props}
    >
      Delete
    </Button>
  );
}

export function CustomizeButton(props) {
  const classes = defaultStyles();
  return (
    <Button
      className={classes.customizeButton}
      variant="contained"
      color="primary"
      {...props}
    >
      Customize
    </Button>
  );
}
export function SaveChangesButton(props) {
  return (
    <div className={"pad-bottom-p5em flex-shrink-auto"}>
      <Divider className="bottom-button-divider" variant="middle" />
      <Button
        className="fullWidth margin-top-5px"
        onClick={props.onClick}
        variant="contained"
        color="primary"
      >
        Save Changes
      </Button>
    </div>
  );
}

export function DeletableChip(props) {
  const { onDelete, value } = props;
  return Boolean(onDelete) ? (
    <Chip
      value={value}
      deleteIcon={
        <div
          onMouseDown={event => {
            if (!props.disabled && event.button === 0) {
              event.stopPropagation();
              onDelete(value);
            }
          }}
        >
          <DeleteIcon
            className="MuiChip-deleteIcon"
            style={{ margin: "0px 5px 0px 0px" }}
          />
        </div>
      }
      {...props}
    />
  ) : (
    <Chip {...props} />
  );
}

export function NewTabButton(props) {
  const classes = defaultStyles();

  return (
    <Collapse in={props.in} className={classes.optionsButtonCollapse}>
      <button
        onClick={props.onClick}
        title="Open in new tab"
        className={classes.optionsButton}
      >
        <div className={classes.optionsButtonText}>open_in_new</div>
      </button>
    </Collapse>
  );
}

export function ShowDetailsButton(props) {
  const classes = defaultStyles();

  return (
    <Collapse in={props.in} className={classes.optionsButtonCollapse}>
      <button
        onClick={props.onClick}
        title="Toggle details"
        className={
          props.pressed ? classes.invertedOptionsButton : classes.optionsButton
        }
      >
        <div className={classes.optionsButtonText}>assignment</div>
      </button>
    </Collapse>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const DefaultMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export const getFilterStyle = (item, items, theme) => {
  if (items === null || items === undefined) {
    return { fontWeight: theme.typography.fontWeightRegular };
  }

  return {
    fontWeight:
      items.indexOf(item) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  };
};
