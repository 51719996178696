export const gradientColors = {
  "Yellow-Orange-Red": [
    [255, 255, 0],
    [255, 140, 0],
    [223, 0, 0]
  ],
  "Green-Yellow-Red": [
    [34, 139, 34],
    [255, 223, 0],
    [255, 0, 0]
  ],
  "Green-Yellow-Orange": [
    [50, 205, 50],
    [255, 255, 102],
    [255, 140, 0]
  ],
  "Blue-Green-Yellow": [
    [0, 0, 205],
    [34, 139, 34],
    [255, 223, 0]
  ],
  "Blue-Gray-Yellow": [
    [0, 0, 205],
    [128, 128, 128],
    [255, 223, 0]
  ],
  "Blue-White-Red": [
    [0, 0, 205],
    [255, 255, 255],
    [255, 0, 0]
  ],
  "Black-White": [
    [0, 0, 0],
    [128, 128, 128],
    [255, 255, 255]
  ],
  "White-Red": [
    [255, 255, 255],
    [255, 128, 128],
    [255, 0, 0]
  ],
  "White-Black": [
    [255, 255, 255],
    [128, 128, 128],
    [0, 0, 0]
  ]
};

export const heatmapGradientColors = {
  "Yellow-Orange-Red": [
    [255, 255, 0],
    [255, 255, 0],
    [255, 255, 0],
    [255, 255, 0],
    [255, 202, 0],
    [255, 255, 0],
    [255, 255, 0],
    [255, 231, 0],
    [255, 202, 0],
    [255, 173, 0],
    [255, 140, 0],
    [247, 103, 0],
    [238, 64, 0],
    [223, 0, 0]
  ],
  "Green-Yellow-Red": [
    [98, 250, 250],
    [98, 250, 250],
    [98, 250, 250],
    [98, 250, 250],
    [98, 250, 250],
    [98, 250, 250],
    [98, 250, 250],
    [98, 250, 250],
    [98, 250, 250],
    [98, 250, 250],
    [98, 250, 250],
    [55, 250, 77],
    [33, 250, 0],
    [33, 250, 0],
    [90, 252, 2],
    [135, 253, 4],
    [160, 253, 5],
    [194, 254, 6],
    [255, 255, 9],
    [248, 216, 8],
    [242, 183, 7],
    [236, 146, 6],
    [228, 103, 5],
    [218, 49, 4],
    [208, 0, 3]
  ],
  "Green-Yellow-Orange": [
    [42, 176, 42],
    [42, 176, 42],
    [42, 176, 42],
    [81, 207, 49],
    [114, 224, 54],
    [149, 224, 74],
    [255, 255, 102],
    [255, 232, 58],
    [255, 140, 0]
  ],
  "Blue-Green-Yellow": [
    [68, 1, 84],
    [68, 1, 84],
    [68, 1, 84],
    [68, 1, 84],
    [70, 8, 92],
    [71, 16, 99],
    [72, 23, 105],
    [72, 29, 111],
    [72, 36, 117],
    [71, 42, 122],
    [70, 48, 126],
    [69, 55, 129],
    [67, 61, 132],
    [65, 66, 135],
    [63, 72, 137],
    [61, 78, 138],
    [67, 83, 139],
    [56, 89, 140],
    [53, 94, 141],
    [51, 99, 141],
    [49, 104, 142],
    [46, 109, 142],
    [44, 113, 142],
    [42, 118, 142],
    [41, 123, 142],
    [39, 128, 142],
    [37, 132, 142],
    [35, 137, 142],
    [33, 142, 141],
    [32, 146, 140],
    [31, 151, 139],
    [30, 156, 137],
    [31, 161, 136],
    [33, 165, 133],
    [36, 170, 131],
    [40, 174, 128],
    [46, 179, 124],
    [53, 183, 121],
    [61, 188, 116],
    [70, 192, 111],
    [80, 196, 106],
    [90, 200, 100],
    [101, 203, 94],
    [112, 207, 87],
    [124, 210, 80],
    [137, 213, 72],
    [149, 216, 64],
    [162, 218, 55],
    [176, 221, 47],
    [189, 223, 38],
    [202, 225, 31],
    [216, 226, 25],
    [229, 228, 25],
    [241, 229, 29],
    [253, 231, 37],
    [251, 214, 0]
  ],
  "Blue-Teal-Red": [
    [0, 0, 160],
    [0, 0, 160],
    [0, 0, 160],
    [0, 0, 160],
    [0, 0, 160],
    [0, 85, 188],
    [0, 155, 211],
    [0, 234, 196],
    [0, 255, 192],
    [84, 234, 184],
    [201, 120, 98],
    [255, 32, 32]
  ],
  "Blue-Gray-Yellow": [
    [32, 32, 186],
    [32, 32, 186],
    [32, 32, 186],
    [32, 32, 186],
    [84, 84, 155],
    [128, 128, 128],
    [149, 139, 103],
    [184, 166, 40],
    [255, 240, 0]
  ],
  "Blue-White-Red": [
    [0, 0, 205],
    [0, 0, 164],
    [0, 0, 164],
    [31, 75, 234],
    [248, 248, 254],
    [255, 255, 255],
    [255, 225, 225],
    [255, 0, 0]
  ],
  "Purple-Red-Yellow": [
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 4],
    [2, 2, 12],
    [5, 4, 23],
    [10, 7, 34],
    [16, 4, 45],
    [22, 11, 57],
    [30, 12, 69],
    [38, 12, 81],
    [47, 10, 91],
    [56, 9, 98],
    [64, 10, 103],
    [73, 11, 106],
    [81, 14, 108],
    [89, 16, 110],
    [97, 19, 110],
    [105, 22, 110],
    [113, 25, 110],
    [120, 28, 109],
    [128, 31, 108],
    [136, 34, 106],
    [144, 37, 104],
    [152, 39, 102],
    [160, 42, 99],
    [168, 46, 95],
    [176, 49, 91],
    [183, 53, 87],
    [191, 57, 82],
    [198, 61, 77],
    [204, 66, 72],
    [211, 71, 67],
    [217, 77, 61],
    [223, 83, 55],
    [228, 90, 49],
    [233, 97, 43],
    [237, 105, 37],
    [241, 113, 31],
    [244, 121, 24],
    [247, 130, 18],
    [249, 139, 11],
    [250, 148, 7],
    [251, 157, 7],
    [252, 166, 12],
    [252, 176, 20],
    [251, 186, 31],
    [250, 196, 42],
    [248, 205, 55],
    [246, 215, 70],
    [244, 225, 86],
    [242, 234, 105],
    [242, 242, 125],
    [245, 249, 146],
    [252, 255, 164]
  ],
  "Black-White": [
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
    [64, 64, 64],
    [128, 128, 128],
    [192, 192, 192],
    [255, 255, 255]
  ],
  "White-Red": [
    [255, 255, 255],
    [255, 255, 255],
    [255, 184, 184],
    [255, 128, 128],
    [255, 65, 65],
    [255, 0, 0],
    [184, 0, 0]
  ],
  "White-Black": [
    [255, 255, 255],
    [255, 255, 255],
    [255, 255, 255],
    [222, 222, 222],
    [148, 148, 148],
    [84, 84, 84],
    [0, 0, 0]
  ]
};
/* These are pastel colors red - yellow - green - cyan - blue - purple with varying brightness
        oldColors = [[255, 102, 102, 0.5], [255, 255, 102, 0.5], [102, 255, 102, 0.5], 
                  [102, 255, 255, 0.5], [102, 102, 255, 0.5], [255, 102, 255, 0.5],
                  [255, 56, 56, 0.5], [255, 255, 56, 0.5], [56, 255, 56, 0.5],
                  [56, 255, 255, 0.5], [56, 56, 255, 0.5], [255, 56, 255, 0.5],
                  [255, 0, 0, 0.5], [255, 255, 0, 0.5], [0, 255, 0, 0.5],
                  [0, 255, 255, 0.5], [0, 0, 255, 0.5], [255, 0, 255, 0.5]];
        */

// Distinct colors courtesy https://sashat.me/2017/01/11/list-of-20-simple-distinct-colors/
export const categoryColors = [
  [170, 255, 195, 0.6],
  [0, 128, 128, 0.6],
  [60, 180, 75, 0.6],
  [255, 225, 25, 0.6],
  [245, 130, 48, 0.6],
  [145, 30, 180, 0.6],
  [70, 240, 240, 0.6],
  [240, 50, 230, 0.6],
  [210, 245, 60, 0.6],
  [250, 190, 190, 0.6],
  [170, 110, 40, 0.6],
  [128, 128, 128, 0.6]
  //  [230, 190, 255, 0.6],
  //  [255, 250, 200, 0.6],
  //  [255, 215, 180, 0.6],
];

export const specificColors = {
  ems: [0, 48, 235, 0.6],
  fire: [217, 16, 3, 0.6],
  service: [48, 192, 64, 0.6],
  rescue: [255, 225, 25, 0.6]
};

export const blueShades = [
  [88, 139, 174, 0.6],
  [137, 207, 240, 0.6],
  [87, 160, 211, 0.6],
  [114, 194, 251, 0.6],
  [101, 147, 245, 0.6],
  [15, 82, 186, 0.6],
  [0, 128, 255, 0.6],
  [16, 52, 166, 0.6],
  [0, 0, 128, 0.6],
  [17, 30, 108, 0.6]
];

export const redShades = [
  [141, 2, 31, 0.6],
  [184, 15, 10, 0.6],
  [237, 41, 57, 0.6],
  [210, 31, 60, 0.6],
  [255, 8, 0, 0.6],
  [126, 25, 27, 0.6],
  [128, 0, 0, 0.6],
  [150, 0, 24, 0.6],
  [178, 34, 34, 0.6],
  [194, 24, 7, 0.6]
];

export const orangeShades = [
  [252, 102, 0, 0.6],
  [249, 129, 42, 0.6],
  [249, 166, 2, 0.6],
  [235, 150, 5, 0.6],
  [239, 114, 21, 0.6],
  [253, 165, 15, 0.6],
  [255, 191, 0, 0.6],
  [203, 92, 13, 0.6],
  [190, 85, 4, 0.6],
  [177, 86, 15, 0.6]
];

const undefinedColor = [254, 166, 199, 0.6];

export function getHexColor(colorArray, useAlpha) {
  var colorStr = "#";
  var value;
  for (var i = 0; i < colorArray.length; i++) {
    if (!useAlpha && i == 3) continue;

    value = colorArray[i];
    if (value < 1) value = Math.ceil(value * 255);
    var str = Math.abs(value).toString(16);
    if (str.length == 1) {
      str = "0" + str;
    }
    colorStr += str;
  }

  return colorStr;
}

let nextColor = 0;
let nextBlue = 0;
let nextRed = 0;
let nextOrange = 0;

let savedColors = undefined;

export const getCategoryColor = category => {
  if (!category) {
    return undefinedColor;
  }

  if (savedColors == undefined) {
    var lastColors = localStorage.getItem("savedColors");
    if (lastColors == undefined) {
      savedColors = {};
    } else {
      savedColors = JSON.parse(lastColors);
    }
  }

  if (!savedColors[category]) {
    savedColors[category] = getNextColor(category.toLowerCase());
    localStorage.setItem("savedColors", JSON.stringify(savedColors));
  }

  return savedColors[category];
};

const getNextColor = category => {
  let specificColor = specificColors[category.toLowerCase()];
  if (specificColor) {
    return specificColor;
  }

  let outputColor;
  if (categoryIsEms(category)) {
    if (nextBlue >= blueShades.length) {
      nextBlue = 0;
    }
    outputColor = blueShades[nextBlue];
    nextBlue++;
  } else if (categoryIsAlarm(category)) {
    if (nextOrange >= orangeShades.length) {
      nextOrange = 0;
    }
    outputColor = orangeShades[nextOrange];
    nextOrange++;
  } else if (categoryIsFire(category)) {
    if (nextRed >= redShades.length) {
      nextRed = 0;
    }
    outputColor = redShades[nextRed];
    nextRed++;
  } else {
    if (nextColor >= categoryColors.length) {
      nextColor = 0;
    }
    outputColor = categoryColors[nextColor];
    nextColor++;
  }

  return outputColor;
};

export const categoryIsEms = category => {
  var categoryNumber = Number.parseInt(category);
  if (!isNaN(categoryNumber)) {
    if (categoryNumber >= 300 && categoryNumber < 330) {
      // NFIRS category - anything over 330 is a rescue
      return true;
    }
  }

  var lowerCase = category.toLowerCase();

  return lowerCase.includes("ems") || lowerCase.includes("medic");
};

export const categoryIsFire = category => {
  var categoryNumber = Number.parseInt(category);
  if (!isNaN(categoryNumber)) {
    if (categoryNumber >= 100 && categoryNumber < 200) {
      // NFIRS category
      return true;
    }
  }

  var lowerCase = category.toLowerCase();

  return lowerCase.includes("fire");
};

export const categoryIsAlarm = category => {
  var categoryNumber = Number.parseInt(category);
  if (!isNaN(categoryNumber)) {
    if (categoryNumber >= 700 && categoryNumber < 800) {
      // NFIRS category
      return true;
    }
  }

  var lowerCase = category.toLowerCase();

  return lowerCase.includes("alarm");
};

export const setCategoryColor = (category, color) => {
  if (!category || !color) {
    return;
  }

  savedColors[category] = color;
  localStorage.setItem("savedColors", JSON.stringify(savedColors));
};

export const resetNextCategory = () => {
  nextColor = 0;
  nextBlue = 0;
  nextOrange = 0;
  nextRed = 0;
};

export const resetCategoryColor = category => {
  if (!savedColors) return;

  savedColors[category] = undefined;
  return getCategoryColor(category);
};

export const resetCategoryColors = () => {
  savedColors = {};
  localStorage.setItem("savedColors", JSON.stringify(savedColors));
};
