import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles, withTheme, Popper, Collapse } from "@material-ui/core";

const styles = theme => ({
  popper: {
    top: "-.5em !important",
    zIndex: 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "100%",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.common.white} transparent`
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "100%",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.common.white} transparent transparent transparent`
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.common.white} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.common.white}`
      }
    }
  },
  arrow: {
    position: "absolute",
    fontSize: 7,
    left: "calc(50% - 8px)",
    width: "16px",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid"
    }
  },
  tooltipBody: {
    padding: "8px",
    fontSize: "14px"
  }
});

class BaseTooltipComponent extends Component {
  constructor(props) {
    super(props);

    this.tooltipAnchorRef = React.createRef();
    this.arrowRef = React.createRef();
  }

  static defaultProps = {
    open: false
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Collapse in={this.props.open}>
          <Popper
            placement="top"
            disablePortal={true}
            id={"tooltip" + this.props.id}
            open={this.props.open}
            anchorEl={this.props.anchorEl}
            modifiers={{
              flip: { enabled: false },
              preventOverflow: {
                enabled: false,
                boundariesElement: "scrollParent"
              },
              hide: {
                enabled: false
              },
              arrow: {
                enabled: true
              }
            }}
            className={classes.popper}
          >
            <span
              ref={this.arrowRef}
              className={classes.arrow + " popper__arrow"}
            />
            {this.props.children}
          </Popper>
        </Collapse>
      </React.Fragment>
    );
  }
}

BaseTooltipComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export const BaseTooltip = withStyles(styles)(withTheme(BaseTooltipComponent));

export default BaseTooltip;
