import React, { Component } from "react";

import { withStyles, withTheme, Button } from "@material-ui/core";

import moment from "moment";
import { Upload } from "../upload/Upload";
import Api from "../../services/Api";

const styles = (theme) => ({
  uploadButton: { width: "100%", marginBottom: "-8px" },
  infoDiv: { width: "410px", margin: "auto", marginBottom: "16px" },
  label: {
    display: "inline-flex",
    fontSize: "1.25rem",
    fontWeight: "bold",
    width: "10rem"
  },
  value: {
    display: "inline-flex",
    fontSize: "1.25rem"
  },
  sourcesDiv: { display: "inline-flex", }
});

class DataMapSettingsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadOpen: false,
    };
  }

  componentDidMount = () => {};

  componentDidUpdate = (prevProps, prevState) => {};

  hideUpload = () => {
    this.setState({ uploadOpen: false });
  };

  render = () => {
    var dataMapInfo = this.props.dataMapInfo;
    var dataMapDate = dataMapInfo
      ? moment(dataMapInfo.dataMapDate).format("MMM Do YYYY, h:mm:ssa")
      : "Unknown";
    var dataMapSources = dataMapInfo ? dataMapInfo.sources : ["Unknown"];

    const { classes } = this.props;
    return (
      <div className={classes.containerDiv}>
        <div className={classes.infoDiv}>
          <div className={classes.label}>Creation Date:</div>
          <div className={classes.value}>{dataMapDate}</div>
          <br />
          <div className={classes.label}>Data Sources:</div>
          <div className={classes.sourcesDiv}>
            {dataMapSources.map((sourceName) => {
              return <div className={classes.value}>{sourceName}</div>;
            })}
          </div>
        </div>
        <div className={classes.uploadButtonDiv}>
          <Button
            className={classes.uploadButton}
            variant="contained"
            color="primary"
            onClick={() => {
              this.setState({ uploadOpen: true });
            }}
          >
            Upload New DataMap
          </Button>
        </div>
        <Upload open={this.state.uploadOpen} hide={this.hideUpload} title="Upload DMAP File" multiple={false} uploadData={Api.uploadDataMap} />
      </div>
    );
  };
}

export const DataMapSettings = withStyles(styles)(
  withTheme(DataMapSettingsComponent)
);

export default DataMapSettings;
