import React, { useState, useEffect } from "react";
import api from "../../services/Api";
import { Redirect } from "react-router-dom";

const style = {
  title: {
    textAlign: "center"
  },
  loginButton: {
    width: "100%"
  }
};

function Logout(props) {
  const [redirectLocation, setRedirectLocation] = useState(null);

  useEffect(() => {
    api.logout();
    setRedirectLocation("/");
  });

  if (redirectLocation) {
    return <Redirect to={redirectLocation} />;
  }

  return <div className="container">Logging out...</div>;
}

export default Logout;
