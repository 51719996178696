import { createMuiTheme } from "@material-ui/core";
import { deepOrange, grey } from "@material-ui/core/colors";

export const lightTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#282d2f"
    },
    secondary: {
      main: "#fb7713"
    }
  }
});

export default lightTheme;
