import React, { Component } from "react";
import moment from "moment";
// MaterialUI
import { withStyles, withTheme } from "@material-ui/core";
import { connect } from "react-redux";
import { getSettingsOpen } from "../../redux/selectors";
import {
  toggleSettingsOpen,
  closeSettings,
  setSettingsEnabled
} from "../../redux/actions";
import { DataContext } from "../DataContext";
import { ComponentContext } from "../DashboardComponent";

import SettingsMenu from "../controls/SettingsMenu";
import ReportBase from "./ReportBase";
import SimpleReportSettings from "./SimpleSettings";

import { getDateRanges } from "../../helpers/DashboardHelpers";
import {
  CreateMuiColumnsFromObject,
  CreateDataObject
} from "../../helpers/ReportUtils";

const styles = {};
const defaultSettings = {};

export default class StandaloneSimpleReport extends Component {
  render() {
    let dashboardMode = this.props.dashboardMode || false;
    return (
      <DataContext.Consumer>
        {({ setDataSettings, getDataSettings, ...dataSettings }) => (
          <ComponentContext.Consumer>
            {({ setSettings, ...settings }) => (
              <SimpleReport
                setSettings={setSettings}
                setDataSettings={setDataSettings}
                getDataSettings={getDataSettings}
                dashboardMode={dashboardMode}
                {...settings}
                {...dataSettings}
                {...this.props}
              >
                <SimpleReportSettings>
                  {this.props.children}
                </SimpleReportSettings>
              </SimpleReport>
            )}
          </ComponentContext.Consumer>
        )}
      </DataContext.Consumer>
    );
  }
}

class SimpleReportComponent extends Component {
  constructor(props) {
    super(props);
    props.setSettings({ componentType: "Report", reFetch: false });
    this.state = { loading: true, columns: [], setColumns: false };
  }

  componentDidMount() {
    if (!this.props.dashboardMode) {
      this.props.setSettingsEnabled(true);
      this.props.closeSettings();
      document.title = "Code3 Firewatch - " + this.props.title;
      this.setState({
        options: { rowsPerPage: 10, rowsPerPageOptions: [5, 10, 15, 20] }
      });
      this.setupInitialDates();
    } else {
      if (this.props.columnsReport) {
        //console.log("Cols set");
        //console.log(this.props.columnsReport);
        this.setState({ columns: this.props.columnsReport, setColumns: true });
      }
      this.setState({ loading: false, options: { elevation: 0 } });
    }
  }

  setupInitialDates = async () => {
    var dateRanges = await getDateRanges();
    var startMoment = moment(this.props.startDate);
    var maxMoment = moment(dateRanges.maxDate);
    var startDate = this.props.startDate || dateRanges.startDate;
    var endDate = this.props.endDate || dateRanges.endDate;
    if (startMoment > maxMoment) {
      endDate = maxMoment.format("YYYY-MM-DD");
      startDate = maxMoment.subtract(1, "months").format("YYYY-MM-DD");
    }

    this.props.setDataSettings({
      startDate: startDate,
      endDate: endDate,
      minDate: dateRanges.minDate,
      maxDate: dateRanges.maxDate
    });

    this.setState({ loading: false });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.startDate !== prevProps.startDate ||
      this.props.endDate !== prevProps.endDate ||
      this.state.loading !== prevState.loading ||
      this.props.dataFilters !== prevProps.dataFilters ||
      (this.props.reFetch && this.props.reFetch !== prevProps.reFetch)
    ) {
      if (this.state.loading === false) {
        this.fetchData();
        if (this.props.reFetch) {
          this.props.setSettings({ reFetch: false });
        }
      }
    }

    if (
      this.props.columnsReport != prevProps.columnsReport &&
      this.props.columnsReport != this.state.columns
    ) {
      let cols = this.props.columnsReport;
      //console.log("cols update");
      this.setState({ columns: cols });
    }
    // if (this.props.data !== prevProps.data){
    //     this.createColumns();
    // }

    //if data not = prev data
    //parse data
  };

  fetchData = async () => {
    //make api call here
    let dataSettings = this.props.getDataSettings();
    let { startDate, endDate, minDate, maxDate } = dataSettings;
    let gettingData;
    if (this.props.getData) {
      gettingData = this.props.getData(dataSettings); //Api.getIncidents(startDate, endDate);
    } else {
    }

    let data = await gettingData;
    if (data) {
      //maybe some like error checking or something
      let parsedData;
      if (this.props.parse) {
        parsedData = this.props.parse(data);
      } else {
        parsedData = data;
      }
      let columnsList;
      if (!this.state.setColumns) {
        columnsList = Boolean(this.props.columns)
          ? this.props.columns(parsedData)
          : this.createColumnsBetter(parsedData);
        this.setState({ data: parsedData, columns: columnsList });
        this.props.setSettings({ columnsReport: columnsList });
      } else {
        this.setState({ data: parsedData });
      }
    }
  };

  parseData = dataList => {
    const toReturn = [];
    dataList.forEach(ele => {
      delete ele.responses;
      let newDatum = CreateDataObject(ele, true);
      toReturn.push(newDatum);
    });
    return toReturn;
  };

  createColumnsBetter = data => {
    if (Array.isArray(data) && data.length > 0) {
      let sampleData = data[0];
      const order = Boolean(this.props.order) ? this.props.order : [];
      const toExclude = Boolean(this.props.exclude) ? this.props.exclude : [];
      let cols = CreateMuiColumnsFromObject(sampleData, order, toExclude);
      //this.props.setSettings({columns: cols});
      return cols;
    }
  };

  //parse data: make columns here

  render() {
    //console.log(this.props);
    const { reportProps } = this.props;
    let repOptions = this.state.options;
    let repProps;
    if (reportProps && reportProps.options) {
      let { options, ...rProps } = reportProps;
      repOptions = { ...this.state.options, ...options };
      repProps = { ...rProps };
    } else {
      repProps = reportProps;
    }
    return (
      <div
        id="outer-container"
        style={
          (this.props.dashboardMode && {
            height: this.props.height ? this.props.height + "px" : "450px"
          }) ||
          {}
        }
      >
        {!this.props.dashboardMode && (
          <SettingsMenu
            pageWrapId={this.id}
            outerContainerId={"outer-container"}
            customBurgerIcon={false}
            onStateChange={this.menuStateChange}
            style={"push"}
            noOverlay={false}
            width={280}
          >
            {this.props.children}
          </SettingsMenu>
        )}
        {this.state.loading || (
          <ReportBase
            data={this.state.data}
            columns={this.state.columns}
            options={repOptions}
            title={this.props.title}
            {...repProps}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const settingsOpen = getSettingsOpen(state);
  return { settingsOpen };
};

const mapDispatchToProps = {
  toggleSettingsOpen,
  closeSettings,
  setSettingsEnabled
};

export const SimpleReport = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTheme(SimpleReportComponent)));
