import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  withStyles,
  withTheme,
  FormControl,
  FormControlLabel,
  Switch,
  InputLabel,
  NativeSelect,
  Input,
  Select,
  Chip,
  MenuItem,
  ListItemText,
  Collapse,
  TextField,
  Grid,
  Typography,
  Button,
  Slider
} from "@material-ui/core";

import { gradientColors, getCategoryColor } from "../../../helpers/Colors";
import {
  benchmarkNames,
  calculationNames
} from "../../../helpers/DashboardHelpers";

import {
  HeaderGroup,
  DefaultMenuProps,
  getFilterStyle
} from "../../controls/MenuControls";

import { hexCalculationTitles } from "./PerformanceMap";

import ColorSwatch from "../../controls/ColorSwatch";

const styles = {
  settingsSelect: {
    width: "100%",
    marginBottom: "8px"
  },
  settingsSlider: {
    marginBottom: ".25em"
  },
  settingsLeftTextField: {
    width: "6em"
  },
  settingsRightTextField: {
    width: "6em"
  },
  thresholdGrid: {
    marginBottom: ".25em"
  },
  thresholdSlider: {
    marginTop: ".75em"
    //    marginBottom: ".25em"
  },
  marginBottom25em: {
    marginBottom: ".25em"
  },
  selectAboveSlider: {
    width: "100%",
    marginBottom: "16px"
  }
};

class PerformanceMapHexagonSettingsComponent extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    hexGroupOpen: false
  };

  componentDidUpdate = (prevProps, prevState) => {};

  toggleHeader = () => {
    this.setState({ hexGroupOpen: !this.state.hexGroupOpen });
  };

  toggleHexagons = () => {
    this.props.setSettings({ drawHexagons: !this.props.drawHexagons });
  };

  setBenchmark = event => {
    this.props.setSettings({
      hexBenchmark: event.target.value
    });
  };

  setCalculation = event => {
    this.props.setSettings({
      hexCalculation: event.target.value
    });
  };

  setPercentile = event => {
    var newValue = Math.min(event.target.value, 100);
    newValue = Math.max(newValue, 0);
    this.props.setSettings({ hexPercentile: newValue });
  };

  setGradient = event => {
    this.props.setSettings({ hexGradient: event.target.value });
  };

  setHexagons = (event, newValue) => {
    this.props.setSettings({ hexagonSize: newValue });
  };

  toggleAutoscale = () => {
    this.props.setSettings({
      hexAutoscale: !this.props.hexAutoscale
    });
  };

  setHexValue = (event, newValue) => {
    var newLowValue = Number(newValue[0]).toFixed(1);
    var newHighValue = Number(newValue[1]).toFixed(1);
    if (
      newLowValue == this.props.hexLowValue &&
      newHighValue == this.props.hexHighValue
    )
      return;

    this.props.setSettings({
      hexLowValue: newLowValue,
      hexHighValue: newHighValue
    });
  };

  thresholdSliderChanged = (event, newValue) => {
    this.props.setSettings({
      lowHexPercentile: newValue[0],
      highHexPercentile: newValue[1]
    });
  };

  setLowPercentile = event => {
    var value = Math.max(0, event.target.value);
    this.props.setSettings({
      lowHexPercentile: value,
      highHexPercentile: Math.max(value, this.props.highHexPercentile)
    });
  };

  setHighPercentile = event => {
    var value = Math.max(0, event.target.value);
    this.props.setSettings({
      lowHexPercentile: Math.min(this.props.lowHexPercentile, value),
      highHexPercentile: value
    });
  };

  setLowValue = event => {
    var value = Math.max(0, event.target.value);
    this.props.setSettings({
      hexLowValue: value,
      hexHighValue: Math.max(value, this.props.hexHighValue)
    });
  };

  setHighValue = event => {
    var value = Math.max(0, event.target.value);
    this.props.setSettings({
      hexLowValue: Math.min(this.props.hexLowValue, value),
      hexHighValue: value
    });
  };

  render = () => {
    return (
      <HeaderGroup
        id="hexGroupOpen"
        onClick={this.toggleHeader}
        in={this.state.hexGroupOpen}
        label="Hexagons"
      >
        <div className="pad-gutter-8px">
          <FormControlLabel
            control={
              <Switch
                checked={this.state.drawHexagons}
                onChange={this.toggleHexagons}
                value="drawHexagons"
                color="secondary"
              />
            }
            label="Draw Hexagons"
          />
          <FormControl style={styles.settingsSelect}>
            <InputLabel>Measure</InputLabel>
            <Select
              value={this.props.hexBenchmark}
              onChange={this.setBenchmark}
              input={<Input name="hexagonBenchmark" />}
            >
              {this.props.availableBenchmarks.map(benchmark => {
                return (
                  <MenuItem
                    key={benchmark}
                    value={benchmark}
                    label={benchmarkNames[benchmark] || benchmark}
                  >
                    {benchmarkNames[benchmark] || benchmark}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl style={styles.settingsSelect}>
            <InputLabel>Statistic</InputLabel>
            <Select
              value={this.props.hexCalculation}
              onChange={this.setCalculation}
              title={hexCalculationTitles[this.props.hexCalculation]}
              input={<Input name="hexagonCalculation" />}
            >
              {Object.keys(calculationNames).map(calculation => {
                return (
                  <MenuItem
                    key={calculation}
                    value={calculation}
                    label={calculationNames[calculation]}
                    title={hexCalculationTitles[calculation]}
                  >
                    {calculationNames[calculation]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Collapse in={this.props.hexCalculation == "percentile"}>
            <TextField
              id="percentile-value"
              label="Percentile"
              value={this.props.hexPercentile}
              onChange={this.setPercentile}
              type="number"
              className="settings-246px"
            />
          </Collapse>
          <FormControl style={styles.settingsSelect}>
            <InputLabel shrink>Percentile Thresholds</InputLabel>
            <Slider
              value={[
                this.props.lowHexPercentile,
                this.props.highHexPercentile
              ]}
              onChange={this.thresholdSliderChanged}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              max={100}
              min={0}
              step={5}
              style={styles.thresholdSlider}
            />
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={styles.thresholdGrid}
            >
              <TextField
                id="low-value"
                label="Low"
                value={this.props.lowHexPercentile}
                onChange={this.setLowPercentile}
                type="number"
                style={styles.settingsLeftTextField}
              />
              <TextField
                id="high-value"
                label="High"
                value={this.props.highHexPercentile}
                onChange={this.setHighPercentile}
                type="number"
                style={styles.settingsRightTextField}
              />
            </Grid>
          </FormControl>
          <FormControl style={styles.selectAboveSlider}>
            <InputLabel>Colorization</InputLabel>
            <Select
              value={this.props.hexGradient}
              onChange={this.setGradient}
              type="select"
              name="selectHexagonGradient"
              id="hexagonGradient"
            >
              {Object.keys(gradientColors).map(gradient => {
                return (
                  <MenuItem key={gradient} value={gradient} label={gradient}>
                    {gradient}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Typography id="hexagon-size" gutterBottom>
            Hexagon Size (miles)
          </Typography>
          <Slider
            value={this.props.hexagonSize}
            onChange={this.setHexagons}
            aria-labelledby="hexagon-size"
            min={0.1}
            max={3}
            step={0.1}
            valueLabelDisplay="auto"
            style={styles.settingsSlider}
          />

          <FormControlLabel
            control={
              <Switch
                checked={this.props.hexAutoscale}
                onChange={this.toggleAutoscale}
                value="autoscale"
                color="secondary"
              />
            }
            label="Use Autoscale"
          />
          <Slider
            value={[this.props.hexLowValue, this.props.hexHighValue]}
            onChange={this.setHexValue}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            max={30}
            min={0}
            step={0.1}
            disabled={this.props.hexAutoscale}
            style={styles.settingsSlider}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={styles.marginBottom25em}
          >
            <TextField
              id="low-value"
              label="Low"
              value={this.props.hexLowValue}
              onChange={this.setLowValue}
              type="number"
              style={styles.settingsLeftTextField}
              disabled={this.props.hexAutoscale}
            />
            <TextField
              id="high-value"
              label="High"
              value={this.props.hexHighValue}
              onChange={this.setHighValue}
              type="number"
              style={styles.settingsRightTextField}
              disabled={this.props.hexAutoscale}
            />
          </Grid>
        </div>
      </HeaderGroup>
    );
  };
}

PerformanceMapHexagonSettingsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const PerformanceMapHexagonSettings = withStyles(styles)(
  withTheme(PerformanceMapHexagonSettingsComponent)
);

export default PerformanceMapHexagonSettings;
