import React, { Component } from "react";

import { withStyles } from "@material-ui/core";

import { getCategoryColor, gradientColors } from "../../../helpers/Colors";
import { LegendRow } from "../MapElements";
import { withTheme } from "@material-ui/styles";

import { calculationNames } from "./TypeMapHexagonSettings";
import { ComponentContext } from "../../DashboardComponent";

let styles = {
  legendDiv: {
    maxWidth: "240px",
    maxHeight: "400px",
    overflow: "auto",
    minHeight: "28px",
    minWidth: "150px"
  },
  sectionSpacer: {
    height: "8px"
  }
};

class TypeMapKey extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { classes } = this.props;

    let pointCategories = this.context.pointSelection;
    if (!pointCategories || pointCategories.length == 0) {
      let dataTypes =
        this.context.dataTypes &&
        this.context.pointDataType &&
        this.context.dataTypes[this.context.pointDataType]
          ? this.context.dataTypes[this.context.pointDataType]
          : {};
      pointCategories = Object.keys(dataTypes);
    }

    let pointFeaturesDisplayed = this.context.displayedFeatures || [];
    let displayedIncidents = 0;
    let data;
    for (var i = 0; i < pointFeaturesDisplayed.length; i++) {
      data = pointFeaturesDisplayed[i].get("data");
      if (data) {
        displayedIncidents += data.length;
      }
    }

    let incidentString = displayedIncidents + " Incidents";
    if (displayedIncidents === 1) {
      incidentString = "1 Incident";
    }

    let low = Number(this.context.hexLowValue || 0).toFixed(2);
    let high = Number(this.context.hexHighValue || 0).toFixed(2);
    let diff = (high - low) / 2;
    let mid = (Number(low) + diff).toFixed(2);
    let colors = gradientColors[this.context.hexGradient];

    let hexCategories = this.context.hexSelection;
    if (!hexCategories || hexCategories.length == 0) {
      let hexDataTypes =
        this.context.dataTypes &&
        this.context.hexDataType &&
        this.context.dataTypes[this.context.hexDataType]
          ? this.context.dataTypes[this.context.hexDataType]
          : {};
      hexCategories = Object.keys(hexDataTypes);
    }

    return (
      <React.Fragment>
        <div className={classes.legendDiv}>
          <div className="legend-header">{incidentString}</div>
          {this.context.drawPoints && (
            <React.Fragment>
              <div className="legend-header">
                Points: {this.context.pointDataType}
              </div>
              <hr className="legend-seperator" />
              {pointCategories.map(category => {
                return (
                  <LegendRow
                    key={category}
                    color={`rgba(${getCategoryColor(category)})`}
                    label={category}
                  />
                );
              })}
            </React.Fragment>
          )}
          {this.context.drawHexagons && (
            <React.Fragment>
              {this.context.drawPoints && (
                <div className={classes.sectionSpacer} />
              )}
              <div className="legend-header">
                Hexagons: {calculationNames[this.context.hexCalculation]}
              </div>
              <hr className="legend-seperator" />
              {this.context.hexCalculation == "count" && (
                <React.Fragment>
                  <LegendRow
                    key="low"
                    color={`rgb(${colors[0]})`}
                    label={"Low: " + low}
                  />
                  <LegendRow
                    key="mid"
                    color={`rgb(${colors[1]})`}
                    label={"Mid: " + mid}
                  />
                  <LegendRow
                    key="high"
                    color={`rgb(${colors[2]})`}
                    label={"High: " + high}
                  />
                </React.Fragment>
              )}
              {this.context.hexCalculation == "measure" && (
                <React.Fragment>
                  {hexCategories.map(category => {
                    return (
                      <LegendRow
                        key={category}
                        color={`rgba(${getCategoryColor(category)})`}
                        label={category}
                      />
                    );
                  })}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {!this.context.drawPoints && !this.context.drawHexagons && (
            <React.Fragment>
              <div className="legend-header">Nothing Displayed</div>
              <hr className="legend-seperator" />
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}
TypeMapKey.contextType = ComponentContext;

const TypeMapKeyContainer = withStyles(styles)(withTheme(TypeMapKey));

export default TypeMapKeyContainer;
