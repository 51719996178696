import React, { Component } from "react";
import PropTypes from "prop-types";

// MaterialUI
import { withStyles, withTheme } from "@material-ui/core";

// Redux
import { connect } from "react-redux";
import { getSettingsOpen } from "../../redux/selectors";
import {
  toggleSettingsOpen,
  closeSettings,
  setSettingsEnabled
} from "../../redux/actions";

// Other
import uuidv4 from "uuid";
import moment from "moment";

// Firewatch
import api from "../../services/Api";
import DataContextComponent, { DataContext } from "../DataContext";
import DashboardComponent, { ComponentContext } from "../DashboardComponent";
import SettingsMenu from "../controls/SettingsMenu";
import BaseChart from "./BaseChart";
import BasicChartSettings from "./BasicChartSettings";
import ChartDetails from "./ChartDetails";

import {
  getHexColor,
  getCategoryColor,
  resetCategoryColors,
  resetNextCategory
} from "../../helpers/Colors";
import { getDateRanges, lzw_encode } from "../../helpers/DashboardHelpers";

export const defaultType = "Responses by Weekday";

export const basicChartSettings = {
  "Incidents by Category": {
    type: "stacked-bar",
    metric: "Incident Time",
    calculation: "Count",
    aggregation: "Type",
    category: "Category"
  },
  "Incidents by Type": {
    type: "pie",
    metric: "Incident Time",
    calculation: "Count",
    category: "Type"
  },
  "Incidents by Weekday": {
    type: "stacked-bar",
    metric: "Incident Time",
    calculation: "Count",
    aggregation: "Day of Week",
    category: "Category"
  },
  "Responses by Category": {
    type: "stacked-bar",
    metric: "Travel Time",
    calculation: "Count",
    aggregation: "Type",
    category: "Category"
  },
  "Responses by Type": {
    type: "pie",
    metric: "Travel Time",
    calculation: "Count",
    category: "Type"
  },
  "Responses by Unit": {
    type: "pie",
    metric: "Travel Time",
    calculation: "Count",
    category: "Unit"
  },
  "Responses by Weekday": {
    type: "stacked-bar",
    metric: "Travel Time",
    calculation: "Count",
    aggregation: "Day of Week",
    category: "Category"
  },
  "Median Turnout by Hour": {
    type: "spline",
    metric: "Turnout Time",
    calculation: "Median",
    aggregation: "Hour of Day"
  },
  "Committed Time by Unit": {
    type: "stacked-bar",
    metric: "Committed Time",
    calculation: "Sum",
    aggregation: "Unit",
    category: "Category"
  },
  "Utilization by Unit": {
    type: "stacked-bar",
    metric: "Utilization",
    aggregation: "Unit",
    category: "Type"
  },
  "90th PCTL Call Handling": {
    type: "bar",
    metric: "Committed Time",
    calculation: "Percentile",
    calcParam1Name: "Percentile",
    calcParam1: "90",
    aggregation: "Code",
    category: undefined
  },
  "90th PCTL Turnout Time": {
    type: "bar",
    metric: "Turnout Time",
    calculation: "Percentile",
    calcParam1Name: "Percentile",
    calcParam1: "90",
    aggregation: "Code",
    category: undefined
  },
  "90th PCTL Travel Time": {
    type: "bar",
    metric: "Travel Time",
    calculation: "Percentile",
    calcParam1Name: "Percentile",
    calcParam1: "90",
    aggregation: "Code",
    category: undefined
  },
  "90th PCTL Total Response": {
    type: "bar",
    metric: "Full Complement",
    calculation: "Percentile",
    calcParam1Name: "Percentile",
    calcParam1: "90",
    aggregation: "Code",
    category: undefined
  }
};

const presetNames = {
  "90callhandlingbycode": "90th PCTL Call Handling",
  "90turnoutbycode": "90th PCTL Turnout Time",
  "90travelbycode": "90th PCTL Travel Time",
  "90totalresponsebycode": "90th PCTL Total Response"
};

const defaultSettings = {
  basicChartType: defaultType,
  drawTitle: true,
  title: defaultType,
  titlePosition: "top",
  drawDates: true,
  datePosition: "top",
  xAxisCategories: [],
  xAxisLines: [],
  yAxisText: "",
  yAxisLines: [],
  colors: {}
};

export default class StandaloneBasicChart extends Component {
  render() {
    return (
      <DataContextComponent>
        <DataContext.Consumer>
          {({ setDataSettings, getDataSettings, ...dataSettings }) => (
            // DashboardComponent is a DataContext consumer in this scenario because it needs to save data settings with dashboard items
            <DashboardComponent
              setDataSettings={setDataSettings}
              getDataSettings={getDataSettings}
              defaultSettings={defaultSettings}
              {...this.props}
            >
              <ComponentContext.Consumer>
                {({ setSettings, ...settings }) => (
                  <BasicChart
                    setSettings={setSettings}
                    setDataSettings={setDataSettings}
                    dashboardMode={false}
                    {...settings}
                    {...dataSettings}
                    {...this.props}
                  >
                    <BasicChartSettings />
                  </BasicChart>
                )}
              </ComponentContext.Consumer>
            </DashboardComponent>
          )}
        </DataContext.Consumer>
      </DataContextComponent>
    );
  }
}

const styles = {};

class BasicChartComponent extends Component {
  constructor(props) {
    super(props);

    this.props.setSettings({
      componentType: "Chart"
    });

    this.id = uuidv4();
  }

  state = { loading: true };

  componentDidMount = () => {
    if (!this.props.dashboardMode) {
      this.props.setSettingsEnabled(true);
      document.title = "Code3 Firewatch - Basic Chart";

      if (this.props.basicChartType) {
        this.props.setSettings(basicChartSettings[this.props.basicChartType]);
        this.props.setSettings({ title: this.props.basicChartType });
      } else {
        this.props.setSettings(basicChartSettings[defaultType]);
      }

      this.setupInitialDates();
    } else {
      this.setState({ loading: false });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    var uuid = uuidv4();
    if (this.props.basicChartType !== prevProps.basicChartType) {
      this.props.setSettings(basicChartSettings[this.props.basicChartType]);
      this.props.setDataSettings(basicChartSettings[this.props.basicChartType]);
      this.props.setSettings({ title: this.props.basicChartType });
    }

    if (
      this.props.startDate !== prevProps.startDate ||
      this.props.endDate !== prevProps.endDate ||
      this.props.metric !== prevProps.metric ||
      this.props.calculation !== prevProps.calculation ||
      this.props.aggregation !== prevProps.aggregation ||
      this.props.category !== prevProps.category ||
      this.props.groupCategories !== prevProps.groupCategories ||
      this.props.calcParam1Name !== prevProps.calcParam1Name ||
      this.props.calcParam1 !== prevProps.calcParam1 ||
      this.props.calcParam2Name !== prevProps.calcParam2Name ||
      this.props.calcParam2 !== prevProps.calcParam2 ||
      this.props.selectedCategories !== prevProps.selectedCategories ||
      this.state.loading !== prevState.loading
    ) {
      if (
        this.props.startDate === undefined ||
        this.props.endDate === undefined
      ) {
        this.setupInitialDates();
      } else if (this.state.loading === false) {
        this.updateData();
      }
    }

    if (this.props.data !== prevProps.data) {
      this.processData();
    }
  };

  componentWillUnmount = () => {
    if (!this.props.dashboardMode) {
      this.props.setSettingsEnabled(false);
      this.props.closeSettings();
      document.title = "Code3 Firewatch";
    }
  };

  setupInitialDates = async () => {
    var dateRanges = await getDateRanges();
    var startMoment = moment(this.props.startDate);
    var maxMoment = moment(dateRanges.maxDate);
    var startDate = this.props.startDate || dateRanges.startDate;
    var endDate = this.props.endDate || dateRanges.endDate;
    if (startMoment > maxMoment) {
      endDate = maxMoment.format("YYYY-MM-DD");
      startDate = maxMoment.subtract(1, "months").format("YYYY-MM-DD");
    }

    this.props.setDataSettings({
      startDate: startDate,
      endDate: endDate,
      minDate: dateRanges.minDate,
      maxDate: dateRanges.maxDate
    });

    this.setState({ loading: false });
  };

  updateData = async () => {
    let calculationParameters = "";
    if (this.props.calcParam1Name && this.props.calcParam1Name != "") {
      calculationParameters += `${this.props.calcParam1Name}=${this.props.calcParam1}`;
    }

    if (this.props.calcParam2Name && this.props.calcParam2Name != "") {
      calculationParameters += `${this.props.calcParam2Name}=${this.props.calcParam2}`;
    }

    let categoryValues = "";
    if (this.props.selectedCategories)
      categoryValues = this.props.selectedCategories.join(",");

    var regionIds = [];
    if (this.props.regionSelection !== undefined) {
      for (var i = 0; i < this.props.regionSelection.length; i++) {
        regionIds.push(this.props.regionSelection[i].id);
      }
    }

    const gettingData = api.getChartData(
      this.props.startDate,
      this.props.endDate,
      this.props.metric,
      this.props.calculation,
      this.props.aggregation,
      this.props.category,
      categoryValues,
      this.props.groupCategories,
      calculationParameters,
      this.props.dataFilters,
      regionIds
    );

    const gettingIncidents = api.getChartIncidents(
      this.props.startDate,
      this.props.endDate,
      this.props.metric,
      this.props.calculation,
      this.props.aggregation,
      this.props.category,
      categoryValues,
      this.props.groupCategories,
      calculationParameters,
      this.props.dataFilters,
      regionIds
    );

    gettingIncidents.then(incidents => {
      this.props.setDataSettings({ incidents: incidents });
    });

    gettingData.then(data => {
      this.props.setDataSettings({
        dataStart: this.props.startDate,
        dataEnd: this.props.endDate,
        data: data
      });
    });
  };

  processData = async () => {
    var data = this.props.data;

    resetNextCategory();

    let pivot = [];
    let columns = [];

    let column, propertyData, columnKeys, pivotElement;
    let groups = [];
    let colors = {};

    for (let categoryName in data) {
      colors[categoryName] = getHexColor(getCategoryColor(categoryName));
      propertyData = data[categoryName];
      columnKeys = Object.keys(propertyData);
      column = [];
      column.push(categoryName.toString());

      for (var i = 0; i < columnKeys.length; i++) {
        if (!groups.includes(columnKeys[i])) {
          pivot.push([columnKeys[i]]);
          groups.push(columnKeys[i]);
        }
      }

      for (var i = 0; i < groups.length; i++) {
        pivotElement = pivot[i];
        if (columnKeys.includes(groups[i])) {
          pivotElement.push(propertyData[groups[i]]);
          column.push(propertyData[groups[i]]);
        } else {
          pivotElement.push(0);
          column.push(0);
        }
      }

      columns.push(column);
    }

    var yAxisText;
    if (this.props.metric == "Utilization") {
      yAxisText = "Percent";
    } else if (this.props.calculation == "Count") {
      yAxisText = "Count";
    } else {
      yAxisText = "Minutes";
    }

    var dateText = this.props.startDate + " - " + this.props.endDate;

    this.props.setSettings({
      xAxisCategories: groups,
      pivot: pivot,
      columns: columns,
      colors: colors,
      yAxisText: yAxisText,
      loading: false,
      dateText: dateText
    });
  };

  render = () => {
    return (
      <div
        id="outer-container"
        style={
          (this.props.dashboardMode && {
            height: this.props.height ? this.props.height + "px" : "450px"
          }) ||
          {}
        }
      >
        {!this.props.dashboardMode && (
          <SettingsMenu
            pageWrapId={this.id}
            outerContainerId={"outer-container"}
            customBurgerIcon={false}
            onStateChange={this.menuStateChange}
            style={"slide"}
            noOverlay={false}
            width={280}
          >
            {this.props.children}
          </SettingsMenu>
        )}
        <BaseChart
          id={this.id}
          dashboardMode={this.props.dashboardMode}
          height={this.props.height}
          width={this.props.width}
          showNewTabButton={this.props.dashboardMode}
          newTabFunction={this.openNewTab}
          showDetailsButton={!this.props.dashboardMode}
          drawerContents={<ChartDetails />}
        />
      </div>
    );
  };

  openNewTab = () => {
    let settings = this.props.dashboardSettings;
    settings.title = this.props.title;
    let objJsonStr = JSON.stringify(settings);
    let lzw = lzw_encode(objJsonStr);

    var win = window.open("/chart/advanced/" + lzw);
    win.focus();
  };
}

BasicChartComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const settingsOpen = getSettingsOpen(state);
  return { settingsOpen };
};

const mapDispatchToProps = {
  toggleSettingsOpen,
  closeSettings,
  setSettingsEnabled
};

export const BasicChart = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(withStyles(styles)(withTheme(BasicChartComponent)));
