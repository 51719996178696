import React, { Component } from "react";
import PropTypes from "prop-types";

import { TableCell, TableRow } from "@material-ui/core";

import MUIDataTable from "mui-datatables";
import ReportBase from "../../reports/ReportBase";

import DashboardComponent, { ComponentContext } from "../../DashboardComponent";
import moment from "moment";

import { doubleClickSpeed } from "../../../helpers/DashboardHelpers";

const columns = [
  "ID",
  "Time",
  "Location",
  "Latitude",
  "Longitude",
  "Units",
  "Category",
  "Type",
  "Code",
  "Priority",
  "Cancelled",
  "Jurisdiction",
  {
    name: "Feature",
    options: {
      display: "excluded"
    }
  }
];

export default class FrequencyMapDetails extends Component {
  render = () => {
    return (
      <ComponentContext.Consumer>
        {({ ...settings }) => (
          <FrequencyMapDetailsComponent {...settings} {...this.props} />
        )}
      </ComponentContext.Consumer>
    );
  };
}

function FrequencyMapDetailsRow(props) {
  const {
    onRowClick,
    id,
    time,
    location,
    latitude,
    longitude,
    units,
    category,
    type,
    code,
    priority,
    cancelled,
    jurisdiction,
    feature,
    focused
  } = props;

  const onClick = () => {
    if (onRowClick) {
      onRowClick(
        [
          id,
          time,
          location,
          latitude,
          longitude,
          units,
          category,
          type,
          code,
          priority,
          cancelled,
          jurisdiction,
          feature
        ],
        feature
      );
    }
  };

  return (
    (focused && (
      <TableRow hover key={id} onClick={onClick}>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {id}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {time}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {location}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {latitude}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {longitude}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {units}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {category}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {type}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {code}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {priority}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {cancelled}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {jurisdiction}
        </TableCell>
      </TableRow>
    )) || (
      <TableRow hover key={id} onClick={onClick}>
        <TableCell size="small">{id}</TableCell>
        <TableCell size="small">{time}</TableCell>
        <TableCell size="small">{location}</TableCell>
        <TableCell size="small">{latitude}</TableCell>
        <TableCell size="small">{longitude}</TableCell>
        <TableCell size="small">{units}</TableCell>
        <TableCell size="small">{category}</TableCell>
        <TableCell size="small">{type}</TableCell>
        <TableCell size="small">{code}</TableCell>
        <TableCell size="small">{priority}</TableCell>
        <TableCell size="small">{cancelled}</TableCell>
        <TableCell size="small">{jurisdiction}</TableCell>
      </TableRow>
    )
  );
}

export class FrequencyMapDetailsComponent extends Component {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  state = {
    data: [],
    title: "Displayed Incidents"
  };

  componentDidMount = () => {};

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.displayedFeatures !== prevProps.displayedFeatures ||
      this.props.selectedFeatures !== prevProps.selectedFeatures
    ) {
      this.updateData();
    }
  };

  componentWillUnmount = () => {};

  updateData = () => {
    let pointFeaturesDisplayed, title;

    if (this.props.selectedFeatures && this.props.selectedFeatures.length > 0) {
      pointFeaturesDisplayed = this.props.selectedFeatures;
      title = "Selected Incidents";
    } else {
      pointFeaturesDisplayed = this.props.displayedFeatures || [];
      title = "Displayed Incidents";
    }

    let data = [];
    let featureData,
      incidents,
      datum,
      units,
      response,
      category,
      type,
      code,
      priority,
      cancelled,
      jurisdiction;

    var renderTime = moment.now();
    let focusId, focusIndex;
    if (renderTime - this.lastClickTime < doubleClickSpeed) {
      focusId = this.clickedData[0];
    }

    let nextDataIndex = 0;
    for (var i = 0; i < pointFeaturesDisplayed.length; i++) {
      featureData = pointFeaturesDisplayed[i].get("data");
      if (featureData) {
        incidents = featureData.incidents;
        for (var j = 0; j < incidents.length; j++) {
          datum = incidents[j];
          units = "";
          if (focusId && focusId === datum.id) {
            focusIndex = nextDataIndex;
          }
          for (var k = 0; k < datum.responses.length; k++) {
            response = datum.responses[k];
            if (k == 0) {
              units = response.data["Unit"];
            } else if (response.data["Unit"]) {
              units = units + ", " + response.data["Unit"];
            }
          }
          category = datum.data["Category"];
          type = datum.data["Type"];
          code = datum.data["Code"];
          priority = datum.data["Priority"];
          cancelled = datum.data["Cancelled"];
          jurisdiction = datum.data["Jurisdiction"];
          let time = moment(datum.time);
          data.push([
            datum.data["Id"],
            time.format("MMM DD YYYY, HH:mm:ss"),
            datum.location,
            datum.latitude,
            datum.longitude,
            units,
            category,
            type,
            code,
            priority,
            cancelled,
            jurisdiction,
            pointFeaturesDisplayed[i]
          ]);
          nextDataIndex++;
        }
      }
    }

    this.setState({
      data: data,
      title: title,
      focusIndex: focusIndex,
      focusId: focusId
    });
  };

  lastClickTime = 0;

  onRowClick = (data, feature) => {
    var clickTime = moment.now();
    if (clickTime - this.lastClickTime <= doubleClickSpeed) {
      this.props.setSettings({ focusedFeature: feature });
      this.clickedData = data;
    }
    this.lastClickTime = moment.now();
  };

  render = () => {
    let page = 0;
    if (this.state.focusIndex) {
      page = Math.floor(this.state.focusIndex / 5);
    }

    if (this.tableRef.current) {
      this.tableRef.current.changePage(page);
    }

    const options = {
      page: page,
      filterType: "dropdown",
      responsive: "stacked",
      selectableRows: "none",
      rowsPerPage: 5,
      rowsPerPageOptions: [5, 10, 15],
      customRowRender: data => {
        const [
          id,
          time,
          location,
          latitude,
          longitude,
          units,
          category,
          type,
          code,
          priority,
          cancelled,
          jurisdiction,
          feature
        ] = data;
        let focused = id == this.state.focusId;

        return (
          <FrequencyMapDetailsRow
            id={id}
            time={time}
            location={location}
            latitude={latitude}
            longitude={longitude}
            units={units}
            category={category}
            type={type}
            code={code}
            priority={priority}
            cancelled={cancelled}
            jurisdiction={jurisdiction}
            feature={feature}
            onRowClick={this.onRowClick}
            focused={focused}
          />
        );
      }
    };

    let opts = {
      customRowRender: data => {
        const [
          id,
          time,
          location,
          latitude,
          longitude,
          units,
          category,
          type,
          code,
          priority,
          cancelled,
          jurisdiction,
          feature
        ] = data;
        let focused = id == this.state.focusId;

        return (
          <FrequencyMapDetailsRow
            id={id}
            time={time}
            location={location}
            latitude={latitude}
            longitude={longitude}
            units={units}
            category={category}
            type={type}
            code={code}
            priority={priority}
            cancelled={cancelled}
            jurisdiction={jurisdiction}
            feature={feature}
            onRowClick={this.onRowClick}
            focused={focused}
          />
        );
      }
    };

    /*
      <ReportBase
        innerRef={this.tableRef}
        title={this.state.title}
        data={this.state.data}
        columns={columns}
        options={opts}
      />
      <MUIDataTable
        innerRef={this.tableRef}
        title={this.state.title}
        data={this.state.data}
        columns={columns}
        options={options}
      />
    */

    return (
      <ReportBase
        innerRef={this.tableRef}
        title={this.state.title}
        data={this.state.data}
        columns={columns}
        options={opts}
      />
    );
  };
}
