import React, { Component } from "react";
import PropTypes from "prop-types";

import { slide as Menu } from "react-burger-menu";

import {
  withStyles,
  Paper,
  Popper,
  Button,
  Fade,
  Typography,
  Zoom,
  Tooltip
} from "@material-ui/core";

import Api from "../services/Api";

import Map from "ol/Map";
import VectorSource from "ol/source/Vector";
import View from "ol/View";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import { fromLonLat, toLonLat } from "ol/proj.js";
import XYZ from "ol/source/XYZ.js";
import { defaults as defaultControls } from "ol/control.js";
import {
  Tile as TileLayer,
  Vector as VectorLayer,
  Heatmap as HeatmapLayer
} from "ol/layer.js";

import { hasTouch, getAvailableWindowHeight } from "../helpers/Utilities";

var ATTRIBUTION =
  '<div style="margin-bottom: 4px">' +
  "&#169; " +
  '<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> ' +
  "contributors." +
  "</div>";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  scrollContainer: {
    height: 400,
    overflow: "auto",
    marginBottom: theme.spacing(3)
  },
  scroll: {
    position: "relative",
    width: "230%",
    backgroundColor: theme.palette.background.paper,
    height: "230%"
  },
  legend: {
    marginTop: theme.spacing(2),
    maxWidth: 300
  },
  paper: {
    maxWidth: 400,
    overflow: "auto"
  },
  select: {
    width: 200
  },
  popper: {
    zIndex: 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.common.white} transparent`
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.common.white} transparent transparent transparent`
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.common.white} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.common.white}`
      }
    }
  },
  arrow: {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid"
    }
  }
});

export class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 450,
      latitude: 0.0,
      longitude: 0.0,
      tooltipOpen: true
    };

    this.mapRef = React.createRef();
    this.arrowRef = React.createRef();
    this.tooltipAnchorRef = React.createRef();
  }

  async componentDidMount() {
    await this.setupMap();
    this.resizeComponents();
    window.addEventListener("resize", this.resizeComponents);
  }

  async componentWillUnmount() {
    window.removeEventListener("resize", this.resizeComponents);
  }

  render() {
    const { classes } = this.props;

    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popper" : undefined;

    const handleArrowRef = node => {
      this.setState({ arrowRef: node });
    };

    return (
      <div className="flex-fullHeight-container no-overflowy">
        <div
          id="map"
          className={this.props.dashboardMode ? "dashboardMapDiv" : ""}
          ref={this.mapRef}
          style={
            this.props.dashboardMode
              ? {
                  position: "relative",
                  height: this.props.height || "446px",
                  borderRadius: "4px"
                }
              : {
                  position: "relative",
                  flex: "1 1 auto",
                  height: this.state.innerHeight + "px"
                }
          }
        >
          <div
            className={
              (hasTouch() ? "ol-touch " : "") +
              classes.optionsButtonsOuterContainer
            }
          >
            <div
              className={
                hasTouch()
                  ? classes.optionsButtonsInnerContainerTouch
                  : classes.optionsButtonsInnerContainer
              }
            >
              <div className="over-popover map-control ol-unselectable"></div>
            </div>
          </div>
          <div className={classes.descriptionContainer}>
            <div className="map-description-text">{this.props.description}</div>
          </div>
        </div>
      </div>
    );
  }

  setupMap = async () => {
    let mapSettings = await Api.getMapSettings();

    var lonLat = [mapSettings.longitude, mapSettings.latitude];
    var mercCoords = fromLonLat(lonLat);

    this.tileLayer = new TileLayer({
      source: new XYZ({
        url: "https://tileserver.levrum.com/{z}/{x}/{y}.png",
        attributions: [ATTRIBUTION]
      }),
      opacity: 1
    });

    this.view = new View({
      center: mercCoords,
      zoom: mapSettings.zoom,
      maxZoom: 18,
      minZoom: 2
    });

    this.map = new Map({
      controls: defaultControls({
        attribution: true,
        attributionOptions: {
          collapsible: false
        },
        rotate: false,
        zoom: false
      }).extend([]),
      renderer: "webgl",
      layers: [this.tileLayer],
      target: this.mapRef.current,
      view: this.view
    });
  };

  resizeComponents = () => {
    var containerHeight = window.innerHeight;
    var navMenu = document.getElementById("navMenu");
    if (navMenu) {
      containerHeight -= navMenu.offsetHeight;
    }

    this.setState({ height: containerHeight });
  };
}

Test.propTypes = {
  classes: PropTypes.object.isRequired
};

const TestContainer = withStyles(styles)(Test);

export default TestContainer;
