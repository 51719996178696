import React, { Component } from "react";
import PropTypes from "prop-types";

import { DataContext } from "../../DataContext";
import { ComponentContext } from "../../DashboardComponent";
import DataSettings from "../../controls/DataSettings";
import {
  AddDashboardButton,
  SaveDashboardItemChangesButton
} from "../../controls/AddDashboard";

import FrequencyMapPointSettings from "./FrequencyMapPointSettings";

const styles = {};

export default class FrequencyMapSettings extends Component {
  constructor(props) {
    super(props);
  }

  state = {};

  toggleHeader = () => {
    this.setState({ pointGroupOpen: !this.state.pointGroupOpen });
  };

  toggleValue = event => {
    this.props.setSettings({
      [event.target.id]: !this.props[event.target.id]
    });
  };

  render() {
    const customize = this.context.customize ? this.context.customize : false;

    const { classes } = this.props;
    return (
      <div className="bm-settings-container">
        <div className="flex-grow-shrink-auto">
          <DataContext.Consumer>
            {({ ...dataSettings }) => (
              <React.Fragment>
                <DataSettings {...dataSettings} />
                <ComponentContext.Consumer>
                  {({ ...settings }) => (
                    <React.Fragment>
                      <FrequencyMapPointSettings {...settings} />
                    </React.Fragment>
                  )}
                </ComponentContext.Consumer>
              </React.Fragment>
            )}
          </DataContext.Consumer>
        </div>
        {(customize && (
          <SaveDashboardItemChangesButton
            itemId={this.context.itemId}
            dashboardId={this.context.dashboardId}
            context={this.context}
          />
        )) || (
          <AddDashboardButton
            onClick={() => {
              this.context.setSettings({ showAddDashboardDialog: true });
            }}
          />
        )}
      </div>
    );
  }
}
FrequencyMapSettings.contextType = ComponentContext;
