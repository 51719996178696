import React from "react";
import { Redirect } from "react-router-dom";
import { isUserLoggedIn } from "../helpers/Utilities";
import { Route } from "react-router";

import { connect } from "react-redux";
import { setLoggedIn } from "../redux/actions";

function AuthorizedRoute({
  setLoggedIn: setLoggedIn,
  component: Component,
  ...otherProps
}) {
  setLoggedIn(isUserLoggedIn());

  return (
    <Route
      {...otherProps}
      render={props => {
        return !isUserLoggedIn() ? (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
}

const mapDispatchToProps = {
  setLoggedIn
};

const AuthorizedRouteContainer = connect(
  null,
  mapDispatchToProps
)(AuthorizedRoute);

export default AuthorizedRouteContainer;
