import React, { Component } from "react";

import { connect } from "react-redux";
import { setSettingsEnabled } from "../../redux/actions";

export class DashboardSetupHelp extends Component {
  constructor(props) {
    super(props);

    props.setSettingsEnabled(false);
  }

  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <div></div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setSettingsEnabled
};

const DashboardSetupHelpContainer = connect(
  null,
  mapDispatchToProps
)(DashboardSetupHelp);

export default DashboardSetupHelpContainer;
