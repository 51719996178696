import React, { Component, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import {
  withStyles,
  withTheme,
  Grid,
  MenuList,
  MenuItem,
  ListItemText,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  FormControlLabel,
  Switch
} from "@material-ui/core";

import proj4 from "proj4";

import RegionSettingsMap from "./RegionSettingsMap.js";

import { Dropzone } from "../upload/Dropzone";

import Api from "../../services/Api.js";
import { AppContext } from "../../AppContext";

import shp from "shpjs";

import reproject from "reproject";

var shapefile = require("shapefile");
var geojson_validator = require("geojson-validation");
// var reproject = require("reproject");

const crss = {
  "EPSG:4326": proj4.defs("EPSG:4326"),
  "EPSG:3857": proj4.defs("EPSG:3857")
};

const styles = {
  regionGrid: {
    marginTop: ".5em"
  },
  regionList: {
    width: "15em",
    height: "25em",
    overflowY: "auto",
    overflowX: "auto"
  }
};

class RegionSettingsComponent extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    regions: [],
    showAddRegion: false,
    showEditRegion: false,
    showDeleteRegion: false
  };

  async componentDidMount() {
    await this.refreshRegions();
  }

  componentDidUpdate(prevProps, prevState) {
    let refreshRegions = false;
    refreshRegions =
      refreshRegions ||
      (this.state.showDeleteRegion !== prevState.showDeleteRegion &&
        !this.state.showDeleteRegion);
    refreshRegions =
      refreshRegions ||
      (this.state.showEditRegion !== prevState.showEditRegion &&
        !this.state.showEditRegion);
    refreshRegions =
      refreshRegions ||
      (this.state.showAddRegion !== prevState.showAddRegion &&
        !this.state.showAddRegion);

    if (refreshRegions) {
      this.refreshRegions();
    }
  }

  render() {
    const { classes } = this.props;
    let regions = this.state.regions;

    let geoJSONString =
      this.state.selectedRegion && this.state.selectedRegion.geoJSON
        ? this.state.selectedRegion.geoJSON
        : "";

    let geoJSON, invalidGeoJSON;
    try {
      geoJSON = JSON.parse(geoJSONString);
      invalidGeoJSON = !geojson_validator.valid(geoJSON);
    } catch {
      invalidGeoJSON = geoJSONString && geoJSONString != "";
    }

    return (
      <React.Fragment>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={6}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={6}
              className={classes.regionGrid}
            >
              <Grid item>
                <div style={{ marginRight: "8px" }}>
                  <Typography variant="h6">Regions</Typography>
                  <Divider></Divider>
                  <MenuList className={classes.regionList}>
                    {regions.map(region => (
                      <MenuItem
                        className={classes.menuItem}
                        title={region.name}
                        key={region.id}
                        selected={this.state.selectedRegion == region}
                        onClick={() => {
                          this.setState({ selectedRegion: region });
                        }}
                      >
                        <ListItemText>{region.name}</ListItemText>
                      </MenuItem>
                    ))}
                  </MenuList>
                </div>
              </Grid>
              <Grid item>
                <RegionSettingsMap
                  height={"25em"}
                  width={"25em"}
                  invalidGeoJSON={invalidGeoJSON}
                  geoJSON={geoJSONString}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Button onClick={this.toggleAdd}>Add Region</Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={!this.state.selectedRegion}
                  onClick={this.toggleEdit}
                >
                  Edit Region
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={!this.state.selectedRegion}
                  onClick={this.toggleDelete}
                >
                  Delete Region
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <AddEditRegionDialog
          open={this.state.showAddRegion}
          onClose={this.toggleAdd}
        />
        <AddEditRegionDialog
          open={this.state.showEditRegion}
          editing={true}
          region={this.state.selectedRegion}
          onClose={this.toggleEdit}
        />
        <DeleteRegionDialog
          open={this.state.showDeleteRegion}
          region={this.state.selectedRegion}
          onClose={this.toggleDelete}
        />
      </React.Fragment>
    );
  }

  toggleAdd = () => {
    this.setState({ showAddRegion: !this.state.showAddRegion });
  };

  toggleEdit = () => {
    this.setState({ showEditRegion: !this.state.showEditRegion });
  };

  toggleDelete = () => {
    this.setState({ showDeleteRegion: !this.state.showDeleteRegion });
  };

  refreshRegions = async () => {
    const regions = await Api.getRegions();

    this.setState({ regions: regions });
    this.context.updateAppSettings({ regions: regions });

    let foundRegion = false;
    for (let i = 0; i < regions.length; i++) {
      if (
        this.state.selectedRegion &&
        regions[i].id == this.state.selectedRegion.id
      ) {
        foundRegion = true;
        this.setState({ selectedRegion: regions[i] });
      }
    }

    if (!foundRegion) {
      this.setState({ selectedRegion: regions[0] });
    }
  };
}
RegionSettingsComponent.contextType = AppContext;

RegionSettingsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export const RegionSettings = withStyles(styles)(
  withTheme(RegionSettingsComponent)
);

export default RegionSettings;

class AddEditRegionDialog extends Component {
  constructor(props) {
    super(props);

    this.fileInputRef = React.createRef();
    this.projInputRef = React.createRef();

    this.state = {
      geoJSON: "",
      name: "",
      splitShapes: false
    };
  }

  componentDidMount = () => {
    if (this.props.editing && this.props.region) {
      this.setState({
        name: this.props.region.name,
        geoJSON: this.props.region.geoJSON
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.editing &&
      this.props.region &&
      this.props.region != prevProps.region
    ) {
      this.setState({
        name: this.props.region.name,
        geoJSON: this.props.region.geoJSON
      });
    }
  };

  openRegion = async () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };

  saveRegion = async () => {
    if (this.props.editing) {
      this.saveEdits();
    } else {
      this.addRegion();
    }
  };

  saveEdits = async () => {
    await Api.updateRegion(
      this.props.region.id,
      this.state.name,
      this.state.geoJSON
    );
    this.close();
  };

  addRegion = async () => {
    await Api.addRegion(
      this.state.name || "",
      this.state.geoJSON || "",
      this.state.splitShapes
    );
    this.close();
  };

  close = async () => {
    this.setState({ name: "", geoJSON: "" });
    this.props.onClose();
  };

  onFileSelected = async evt => {
    const file = evt.target.files[0];

    if (!file) {
      return;
    }

    if (file.name.toLowerCase().endsWith(".geojson")) {
      var reader = new FileReader();
      reader.onload = e => {
        var contents = e.target.result;
        this.setState({ geoJSON: contents });
      };
      reader.readAsText(file);
    } else if (file.name.toLowerCase().endsWith(".zip")) {
      var reader = new FileReader();
      reader.onload = e => {
        try {
          shp(e.target.result).then(geoJSON => {
            var shape = reproject.reproject(
              geoJSON,
              "EPSG:4326",
              "EPSG:3857",
              crss
            );
            this.setState({ geoJSON: JSON.stringify(shape) });
          });
        } catch {
          alert("Unable to convert SHP file ZIP to GeoJSON!");
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      alert("Unsupported file type!");
    }

    if (!this.state.name || this.state.name == "") {
      this.setState({ name: file.name });
    }
  };

  toggleSplit = () => {
    this.setState({ splitShapes: !this.state.splitShapes });
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-title"
      >
        <DialogContent
          style={
            this.props.editing
              ? { height: "18em", width: "20em" }
              : { height: "21.5em", width: "20em" }
          }
        >
          <Typography variant="h6" id="alert-dialog-title">
            {(this.props.editing && "Edit Region") || "Add Region"}
          </Typography>
          <TextField
            value={this.state.name}
            onChange={event => {
              this.setState({ name: event.target.value });
            }}
            label="Name"
            style={{ width: "17em", marginTop: "0.2em", marginBottom: "0.2em" }}
          />
          {!this.props.editing && (
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.splitShapes}
                  onChange={this.toggleSplit}
                  value="splitShapes"
                  color="secondary"
                />
              }
              label="Split Multi-Features"
              style={{ marginTop: "4px" }}
            />
          )}
          <TextField
            value={this.state.geoJSON}
            onChange={event => {
              this.setState({ geoJSON: event.target.value });
            }}
            multiline
            label="GeoJSON"
            placeholder="Paste GeoJSON here"
            style={{ width: "17em", marginTop: "0.2em" }}
            rows="8"
          />
          <input
            ref={this.fileInputRef}
            style={{ display: "none" }}
            type="file"
            onChange={this.onFileSelected}
            accept=".geojson,.zip"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.openRegion}
            variant="contained"
            color="primary"
            style={{ marginRight: "3.4em" }}
          >
            Open...
          </Button>
          <Button
            onClick={this.saveRegion}
            variant="contained"
            color="secondary"
          >
            {(this.props.editing && "Save") || "Add"}
          </Button>
          <Button onClick={this.close} variant="contained" color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

function EditRegionDialog() {
  return <div />;
}

function DeleteRegionDialog(props) {
  let name = props.region ? props.region.name : "Unnamed Region";

  async function deleteRegion() {
    if (props.region) {
      await Api.deleteRegion(props.region.id);
    }
    props.onClose();
  }

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`Delete region "${name}"?`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`Are you sure you want to permanently delete the region "${name}"?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={deleteRegion} variant="contained" color="secondary">
          Delete
        </Button>
        <Button onClick={props.onClose} variant="contained" color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
