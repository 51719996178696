import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles, withTheme, Typography, Collapse } from "@material-ui/core";

// Openlayers
import "ol";
import "ol/ol.css";
import { DEVICE_PIXEL_RATIO } from "ol/has.js";
import { easeOut } from "ol/easing.js";
import Feature from "ol/Feature.js";
import Map from "ol/Map.js";
import Overlay from "ol/Overlay.js";
import View from "ol/View.js";
import Point from "ol/geom/Point.js";
import Polygon from "ol/geom/Polygon.js";
import {
  Tile as TileLayer,
  Vector as VectorLayer,
  Heatmap as HeatmapLayer
} from "ol/layer.js";
import VectorSource from "ol/source/Vector.js";
import { Style, Circle, Fill, Stroke, RegularShape } from "ol/style.js";
import { fromLonLat, toLonLat } from "ol/proj.js";
import XYZ from "ol/source/XYZ.js";
import { defaults as defaultControls } from "ol/control.js";
import GeoJSON from "ol/format/GeoJSON.js";
import HexBin from "ol-ext/source/HexBin";

// Firewatch
import Api from "../../services/Api";
import { red } from "@material-ui/core/colors";

var ATTRIBUTION =
  '<div style="margin-bottom: 4px">' +
  "&#169; " +
  '<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> ' +
  "contributors." +
  "</div>";

const styles = {};

class RegionSettingsMapComponent extends Component {
  constructor(props) {
    super(props);

    this.mapRef = React.createRef();
  }

  state = {};

  componentDidMount = async () => {
    await this.setupMap();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.geoJSON !== prevProps.geoJSON) {
      this.setupVectorDisplay();
    }
  };

  render() {
    return (
      <div style={{ width: this.props.width, height: this.props.height }}>
        <div
          id="map"
          ref={this.mapRef}
          style={{ width: this.props.width, height: this.props.height }}
        />
        <Collapse in={this.props.invalidGeoJSON === true}>
          <Typography variant="h6" style={{ color: "red" }}>
            Invalid GeoJSON
          </Typography>
        </Collapse>
      </div>
    );
  }

  setupVectorDisplay = () => {
    if (!this.vectorSource) {
      this.vectorSource = new VectorSource();
    }
    this.vectorSource.clear();
    if (
      !this.props.invalidGeoJSON &&
      this.props.geoJSON &&
      this.props.geoJSON != ""
    ) {
      var features = new GeoJSON().readFeatures(this.props.geoJSON);
      this.vectorSource.addFeatures(features);
      if (this.view) {
        var vectorFeatures = this.vectorSource.getFeatures();
        var extent;
        var maxExtent = [NaN, NaN, NaN, NaN];
        var geometry;
        for (var i = 0; i < vectorFeatures.length; i++) {
          geometry = vectorFeatures[i].getGeometry();
          extent = geometry.getExtent();
          if (!extent) {
            continue;
          }
          maxExtent[0] = i == 0 ? extent[0] : Math.min(extent[0], maxExtent[0]);
          maxExtent[1] = i == 0 ? extent[1] : Math.min(extent[1], maxExtent[1]);
          maxExtent[2] = i == 0 ? extent[2] : Math.max(extent[2], maxExtent[2]);
          maxExtent[3] = i == 0 ? extent[3] : Math.max(extent[3], maxExtent[3]);
        }
        var coordinates = [
          [
            [maxExtent[0], maxExtent[1]],
            [maxExtent[2], maxExtent[1]],
            [maxExtent[2], maxExtent[3]],
            [maxExtent[0], maxExtent[3]],
            [maxExtent[0], maxExtent[1]]
          ]
        ];
        var polygon = new Polygon(coordinates);
        this.view.fit(polygon, { padding: [30, 30, 30, 30] });
      }
    }

    if (!this.vectorLayer) {
      this.vectorLayer = new VectorLayer({
        source: this.vectorSource,
        style: this.stylePolygon
      });
      if (this.map) {
        this.map.addLayer(this.vectorLayer);
      }
    }
  };

  stylePolygon = () => {
    const style = new Style({
      stroke: new Stroke({
        color: "blue",
        lineDash: [4],
        width: 3
      }),
      fill: new Fill({
        color: "rgba(0, 0, 255, 0.35)"
      })
    });

    return style;
  };

  setupMap = async () => {
    let mapSettings = await Api.getMapSettings();

    var lonLat = [mapSettings.longitude, mapSettings.latitude];
    var mercCoords = fromLonLat(lonLat);

    this.tileLayer = new TileLayer({
      source: new XYZ({
        url: "https://tileserver.levrum.com/{z}/{x}/{y}.png",
        attributions: [ATTRIBUTION]
      }),
      opacity: 1
    });

    this.view = new View({
      center: mercCoords,
      zoom: mapSettings.zoom,
      maxZoom: 18,
      minZoom: 2
    });

    this.map = new Map({
      controls: defaultControls({
        attribution: true,
        attributionOptions: {
          collapsible: false
        },
        rotate: false,
        zoom: false
      }).extend([]),
      renderer: "webgl",
      layers: [this.tileLayer],
      target: this.mapRef.current,
      view: this.view
    });
  };
}

RegionSettingsMapComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export const RegionSettingsMap = withStyles(styles)(
  withTheme(RegionSettingsMapComponent)
);

export default RegionSettingsMap;
