import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  withStyles,
  withTheme,
  Collapse,
  FormControl,
  InputLabel,
  NativeSelect,
  Input,
  FormControlLabel,
  Switch,
  Button,
  Paper
} from "@material-ui/core";

import { DataContext } from "../DataContext";
import { ComponentContext } from "../DashboardComponent";
import DataSettings from "../controls/DataSettings";
import {
  AddDashboardButton,
  SaveDashboardItemChangesButton
} from "../controls/AddDashboard";
import { HeaderGroup } from "../controls/MenuControls";
import { basicChartSettings } from "./BasicChart.js";
import { textPositions } from "./BaseChart.js";
import { lzw_encode } from "../../helpers/DashboardHelpers";

const styles = {
  inlineSwitch: {
    width: "5em",
    marginRight: "3em",
    marginBottom: "-.48em"
  },
  customizeButton: {
    width: "100%"
  }
};

export default class BasicChartSettings extends Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    const customize = this.context.customize ? this.context.customize : false;

    const { classes } = this.props;
    return (
      <div className="bm-settings-container">
        <div className="flex-grow-shrink-auto">
          <DataContext.Consumer>
            {({ ...dataSettings }) => (
              <ComponentContext.Consumer>
                {({ setSettings, ...settings }) => (
                  <React.Fragment>
                    <DataSettings
                      showRegions={true}
                      showDataButton={false}
                      {...dataSettings}
                    />
                    <BasicChartSettingsComponent
                      setSettings={setSettings}
                      {...settings}
                      {...dataSettings}
                    />
                  </React.Fragment>
                )}
              </ComponentContext.Consumer>
            )}
          </DataContext.Consumer>
        </div>
        {(customize && (
          <SaveDashboardItemChangesButton
            itemId={this.context.itemId}
            dashboardId={this.context.dashboardId}
            context={this.context}
          />
        )) || (
          <AddDashboardButton
            onClick={() => {
              this.context.setSettings({ showAddDashboardDialog: true });
            }}
          />
        )}
      </div>
    );
  };
}
BasicChartSettings.contextType = ComponentContext;

class BasicChartSettingsMenu extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    chartGroupOpen: true
  };

  toggleStateValue = event => {
    this.setState({ [event.target.id]: !this.state[event.target.id] });
  };

  toggleSettingsValue = event => {
    this.context.setSettings({
      [event.target.id]: !this.props[event.target.id]
    });
  };

  updateSettingsValue = event => {
    this.context.setSettings({
      [event.target.id]: event.target.value
    });
  };

  showAddDashboard = () => {
    this.context.setSettings({
      showAddDashboardDialog: true
    });
  };

  customizeChart = () => {
    let settings = this.props.getSettings();
    settings.title = this.props.title;
    let objJsonStr = JSON.stringify(settings);
    let lzw = lzw_encode(objJsonStr);

    let win = window.open("/chart/advanced/" + lzw);
    win.focus();
  };

  render = () => {
    const {
      classes,
      drawTitle,
      titlePosition,
      drawDates,
      datePosition
    } = this.props;

    return (
      <React.Fragment>
        <HeaderGroup
          id="chartGroupOpen"
          onClick={this.toggleStateValue}
          in={this.state.chartGroupOpen}
          label="Chart"
        >
          <div className="pad-gutter-8px margin-top-4px">
            <FormControl className="settings-select">
              <InputLabel>Preset</InputLabel>
              <NativeSelect
                value={this.props.basicChartType}
                id="basicChartType"
                onChange={this.updateSettingsValue}
                input={<Input name="preset" />}
              >
                {Object.keys(basicChartSettings).map(basicChart => {
                  return (
                    <option
                      key={basicChart}
                      value={basicChart}
                      label={basicChart}
                    >
                      {basicChart}
                    </option>
                  );
                })}
              </NativeSelect>
            </FormControl>
            <div className="flex">
              <FormControlLabel
                control={
                  <Switch
                    checked={this.props.drawTitle}
                    id="drawTitle"
                    onChange={this.toggleSettingsValue}
                    value="drawTitle"
                    color="secondary"
                  />
                }
                label="Title"
                className={classes.inlineSwitch}
              />
              <FormControl className="settings-select">
                <InputLabel>Position</InputLabel>
                <NativeSelect
                  value={this.props.titlePosition}
                  id="titlePosition"
                  onChange={this.updateSettingsValue}
                  input={<Input name="titlePosition" />}
                  disabled={!drawTitle}
                >
                  {Object.keys(textPositions).map(position => {
                    return (
                      <option
                        key={position}
                        value={position}
                        label={textPositions[position]}
                      >
                        {textPositions[position]}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
            </div>
            <div className="flex">
              <FormControlLabel
                control={
                  <Switch
                    checked={this.props.drawDates}
                    id="drawDates"
                    onChange={this.toggleSettingsValue}
                    value="drawDates"
                    color="secondary"
                  />
                }
                label="Dates"
                className={classes.inlineSwitch}
              />
              <FormControl className="settings-select">
                <InputLabel>Position</InputLabel>
                <NativeSelect
                  value={datePosition}
                  id="datePosition"
                  onChange={this.updateSettingsValue}
                  input={<Input name="datePosition" />}
                  disabled={!drawDates}
                >
                  {Object.keys(textPositions).map(position => {
                    return (
                      <option
                        key={position}
                        value={position}
                        label={textPositions[position]}
                      >
                        {textPositions[position]}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
            </div>
          </div>
          <Button
            id="customize-button"
            className={classes.customizeButton}
            onClick={this.customizeChart}
            variant="contained"
            color="primary"
          >
            Customize
          </Button>
        </HeaderGroup>
      </React.Fragment>
    );
  };
}
BasicChartSettingsMenu.contextType = ComponentContext;
BasicChartSettingsMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const BasicChartSettingsComponent = withStyles(styles)(
  withTheme(BasicChartSettingsMenu)
);
