import { SETTINGS_ENABLED } from "../actionTypes";

let enabled = true;

const setSettingsEnabled = (state, action) => {
  switch (action.type) {
    case SETTINGS_ENABLED:
      enabled = action.payload.enabled;
      return enabled;
    default:
      return enabled;
  }
};

export default setSettingsEnabled;
