import React, { Component } from "react";
import PropTypes from "prop-types";

import { TableCell, TableRow } from "@material-ui/core";

import MUIDataTable from "mui-datatables";

import DashboardComponent, { ComponentContext } from "../../DashboardComponent";
import moment from "moment";

import { doubleClickSpeed } from "../../../helpers/DashboardHelpers";

const timeFormat = "MM-DD-YY HH:mm:ss";

const columns = [
  "ID",
  "Time",
  "Location",
  "Latitude",
  "Longitude",
  "Unit",
  "Assigned",
  "Responding",
  "Arrived",
  "Scene Cleared",
  "Turnout",
  "Travel",
  "Time On Scene",
  "Committed Hours",
  {
    name: "Feature",
    options: {
      display: "excluded",
      download: false
    }
  }
];

export default class PerformanceMapDetails extends Component {
  render = () => {
    return (
      <ComponentContext.Consumer>
        {({ ...settings }) => (
          <PerformanceMapDetailsComponent {...settings} {...this.props} />
        )}
      </ComponentContext.Consumer>
    );
  };
}

function PerformanceMapDetailsRow(props) {
  const {
    onRowClick,
    id,
    time,
    location,
    latitude,
    longitude,
    unit,
    assigned,
    responseTime,
    turnoutTime,
    travelTime,
    arrivalTime,
    sceneCleared,
    timeOnScene,
    committedHours,
    feature,
    focused
  } = props;

  const onClick = () => {
    if (onRowClick) {
      onRowClick(
        [
          id,
          time,
          location,
          latitude,
          longitude,
          unit,
          assigned,
          responseTime,
          turnoutTime,
          travelTime,
          arrivalTime,
          sceneCleared,
          timeOnScene,
          committedHours,
          feature
        ],
        feature
      );
    }
  };

  return (
    (focused && (
      <TableRow hover key={id} onClick={onClick}>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {id}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {time}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {location}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {latitude}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {longitude}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {unit}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {assigned}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {responseTime}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {arrivalTime}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {sceneCleared}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {turnoutTime}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {travelTime}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {timeOnScene}
        </TableCell>
        <TableCell size="small" style={{ fontWeight: "bold" }}>
          {committedHours}
        </TableCell>
      </TableRow>
    )) || (
      <TableRow hover key={id} onClick={onClick}>
        <TableCell size="small">{id}</TableCell>
        <TableCell size="small">{time}</TableCell>
        <TableCell size="small">{location}</TableCell>
        <TableCell size="small">{latitude}</TableCell>
        <TableCell size="small">{longitude}</TableCell>
        <TableCell size="small">{unit}</TableCell>
        <TableCell size="small">{assigned}</TableCell>
        <TableCell size="small">{responseTime}</TableCell>
        <TableCell size="small">{arrivalTime}</TableCell>
        <TableCell size="small">{sceneCleared}</TableCell>
        <TableCell size="small">{turnoutTime}</TableCell>
        <TableCell size="small">{travelTime}</TableCell>
        <TableCell size="small">{timeOnScene}</TableCell>
        <TableCell size="small">{committedHours}</TableCell>
      </TableRow>
    )
  );
}

export class PerformanceMapDetailsComponent extends Component {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  state = {
    data: [],
    title: "Displayed Incidents"
  };

  componentDidMount = () => {};

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.displayedFeatures !== prevProps.displayedFeatures ||
      this.props.selectedFeatures !== prevProps.selectedFeatures
    ) {
      this.updateData();
    }
  };

  componentWillUnmount = () => {};

  updateData = () => {
    let pointFeaturesDisplayed, title;

    if (this.props.selectedFeatures && this.props.selectedFeatures.length > 0) {
      pointFeaturesDisplayed = this.props.selectedFeatures;
      title = "Selected Responses";
    } else {
      pointFeaturesDisplayed = this.props.displayedFeatures || [];
      title = "Displayed Responses";
    }

    let data = [];
    let featureData, datum, unit, response, benchmarks;

    var renderTime = moment.now();
    let focusId, focusIndex;
    if (renderTime - this.lastClickTime < doubleClickSpeed) {
      focusId = this.clickedData[0];
    }

    let nextDataIndex = 0;
    for (var i = 0; i < pointFeaturesDisplayed.length; i++) {
      featureData = pointFeaturesDisplayed[i].get("data");
      if (featureData) {
        for (var j = 0; j < featureData.length; j++) {
          datum = featureData[j];
          if (focusId && focusId === datum.id) {
            focusIndex = nextDataIndex;
          }
          let time = moment(datum.time);
          let timeString = time.format(timeFormat);
          for (var k = 0; k < datum.responses.length; k++) {
            response = datum.responses[k];
            unit = response.data["Unit"];
            benchmarks = this.getBenchmarksFromResponse(time, response);
            data.push([
              datum.data["Id"],
              timeString,
              datum.location,
              datum.latitude,
              datum.longitude,
              unit,
              benchmarks["assigned"],
              benchmarks["responseTime"],
              benchmarks["arrivalTime"],
              benchmarks["sceneCleared"],
              benchmarks["turnoutTime"],
              benchmarks["travelTime"],
              benchmarks["timeOnScene"],
              benchmarks["committedHours"],
              pointFeaturesDisplayed[i]
            ]);

            nextDataIndex++;
          }
        }
      }
    }

    this.setState({
      data: data,
      title: title,
      focusIndex: focusIndex,
      focusId: focusId
    });
  };

  getBenchmarksFromResponse = (time, response) => {
    let benchmarks = {};
    let benchmark, responseMinutes, travelMinutes;
    for (var i = 0; i < response.benchmarks.length; i++) {
      benchmark = response.benchmarks[i];
      if (benchmark.name == "Assigned") {
        benchmarks["assigned"] = moment(time)
          .add(benchmark.value, "minutes")
          .format(timeFormat);
      } else if (benchmark.name == "Responding") {
        responseMinutes = benchmark.value;
        benchmarks["responseTime"] = moment(time)
          .add(benchmark.value, "minutes")
          .format(timeFormat);
      } else if (benchmark.name == "TurnoutTime") {
        let minutes = Number.parseFloat(benchmark.value);
        if (!isNaN(minutes)) {
          benchmarks["turnoutTime"] = minutes.toFixed(2);
        }
      } else if (benchmark.name == "TravelTime") {
        travelMinutes = Number.parseFloat(benchmark.value);
        benchmarks["travelTime"] = travelMinutes.toFixed(2);
      } else if (benchmark.name == "ClearScene") {
        benchmarks["sceneCleared"] = moment(time)
          .add(benchmark.value, "minutes")
          .format(timeFormat);
      } else if (benchmark.name == "SceneTime") {
        let minutes = Number.parseFloat(benchmark.value);
        if (!isNaN(minutes)) {
          benchmarks["timeOnScene"] = minutes.toFixed(2);
        }
      } else if (benchmark.name == "CommittedHours") {
        let hours = Number.parseFloat(benchmark.value);
        if (!isNaN(hours)) {
          benchmarks["committedHours"] = hours.toFixed(2);
        }
      }
    }

    if (responseMinutes && travelMinutes) {
      benchmarks["arrivalTime"] = moment(time)
        .add(responseMinutes, "minutes")
        .add(travelMinutes, "minutes")
        .format(timeFormat);
    }

    return benchmarks;
  };

  lastClickTime = 0;

  onRowClick = (data, feature) => {
    var clickTime = moment.now();
    if (clickTime - this.lastClickTime <= doubleClickSpeed) {
      this.props.setSettings({ focusedFeature: feature });
      this.clickedData = data;
    }
    this.lastClickTime = moment.now();
  };

  render = () => {
    let page = 0;
    if (this.state.focusIndex) {
      page = Math.floor(this.state.focusIndex / 5);
    }

    if (this.tableRef.current) {
      this.tableRef.current.changePage(page);
    }

    const options = {
      page: page,
      filterType: "dropdown",
      responsive: "stacked",
      selectableRows: "none",
      rowsPerPage: 5,
      rowsPerPageOptions: [5, 10, 15],
      customRowRender: data => {
        const [
          id,
          time,
          location,
          latitude,
          longitude,
          unit,
          assigned,
          responseTime,
          arrivalTime,
          sceneCleared,
          turnoutTime,
          travelTime,
          timeOnScene,
          committedHours,
          feature
        ] = data;
        let focused = id == this.state.focusId;

        return (
          <PerformanceMapDetailsRow
            id={id}
            time={time}
            location={location}
            latitude={latitude}
            longitude={longitude}
            unit={unit}
            assigned={assigned}
            responseTime={responseTime}
            arrivalTime={arrivalTime}
            sceneCleared={sceneCleared}
            turnoutTime={turnoutTime}
            travelTime={travelTime}
            timeOnScene={timeOnScene}
            committedHours={committedHours}
            feature={feature}
            onRowClick={this.onRowClick}
            focused={focused}
          />
        );
      }
    };

    return (
      <MUIDataTable
        innerRef={this.tableRef}
        title={this.state.title}
        data={this.state.data}
        columns={columns}
        options={options}
      />
    );
  };
}
