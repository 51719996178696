import React, { Component } from "react";

import Api from "./services/Api.js";

import moment from "moment";

export const AppContext = React.createContext({});

export default class AppContextComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      getSettings: this.getSettings,
      updateAppSetting: this.updateAppSetting,
      updateAppSettings: this.updateAppSettings,
      getIncidentsWithResponses: this.getIncidentsWithResponses,
      getIncidentsByLocation: this.getIncidentsByLocation
    };

    this.startDate = null;
    this.endDate = null;
  }

  getSettings = () => {
    var settings = Object.assign({}, this.state);
    delete settings.getSettings;
    delete settings.updateAppSetting;
    delete settings.updateAppSettings;
    delete settings.getIncidentsWithResponses;
    delete settings.getIncidentsByLocation;

    return settings;
  };

  updateAppSetting = (setting, value) => {
    if (this.state[setting] == value) return;

    this.setState({ [setting]: value });
  };

  updateAppSettings = settings => {
    this.setState(settings);
  };

  lock = () => {
    this.unlockTime = Date.now() + 30000;
  };

  unlock = () => {
    this.unlockTime = undefined;
  };

  locked = () => {
    return this.unlockTime && Date.now() < this.unlockTime;
  };

  poll = (resolve, reject) => {
    if (!this.locked()) {
      this.unlock();
      resolve(this);
    }
    setTimeout(this.poll.bind(this, resolve, reject), 250);
  };

  promise = () => {
    return new Promise(this.poll);
  };

  getIncidentsByLocation = async (startDate, endDate, result) => {
    var data;
    await this.promise()
      .then(async () => {
        this.lock();
        let newStart = moment(startDate);
        let newEnd = moment(endDate)
          .hour(23)
          .minute(59)
          .second(59)
          .millisecond(999);
        if (this.iblStartDate == null) {
          data = await Api.getIncidentsByLocation(
            newStart.format(),
            newEnd.format()
          );

          this.incidentsByLocation = data || [];
          this.iblStartDate = newStart;
          this.iblEndDate = newEnd;
        } else {
          if (newStart < this.iblStartDate) {
            let newData = await Api.getIncidentsByLocation(
              newStart.format(),
              this.iblStartDate.format()
            );

            this.addNewIncidentsByLocation(newData);
            this.iblStartDate = newStart;
          }

          if (newEnd > this.iblEndDate) {
            let newData = await Api.getIncidentsByLocation(
              this.iblEndDate.format(),
              newEnd.format()
            );

            this.addNewIncidentsByLocation(newData);
            this.iblEndDate = newEnd;
          }

          data = this.getIncidentsByLocationBetweenDates(newStart, newEnd);
        }
      })
      .then(res => {
        this.unlock();
        return res;
      });
    result["incidentsByLocation"] = data;
  };

  addNewIncidentsByLocation = newData => {
    var locations = Object.keys(newData);
    var location;
    for (var i = 0; i < locations.length; i++) {
      location = locations[i];
      if (this.incidentsByLocation[location]) {
        this.incidentsByLocation[location] = this.incidentsByLocation[
          location
        ].concat(newData[location]);
      } else {
        this.incidentsByLocation[location] = newData[location];
      }
    }
  };

  getIncidentsByLocationBetweenDates = (newStart, newEnd) => {
    var data = {};
    let time;
    var locations = Object.keys(this.incidentsByLocation);
    var location, data, incident;
    for (var i = 0; i < locations.length; i++) {
      location = this.incidentsByLocation[locations[i]];
      for (var j = 0; j < location.length; j++) {
        incident = location[j];
        time = moment(incident.time);
        if (time >= newStart && time <= newEnd) {
          if (data[locations[i]]) {
            data[locations[i]].push(incident);
          } else {
            data[locations[i]] = [incident];
          }
        }
      }
    }

    return data;
  };

  getIncidentsWithResponses = async (startDate, endDate) => {
    var data;
    await this.promise()
      .then(async () => {
        this.lock();
        let newStart = moment(startDate);
        let newEnd = moment(endDate)
          .hour(23)
          .minute(59)
          .second(59)
          .millisecond(999);
        if (this.iwrStartDate == null) {
          data = await Api.getIncidentsWithResponses(
            newStart.format(),
            newEnd.format(),
            "",
            "",
            "",
            ""
          );

          this.incidentsWithResponses = data;
          this.iwrStartDate = newStart;
          this.iwrEndDate = newEnd;
        } else {
          if (newStart < this.iwrStartDate) {
            let newData = await Api.getIncidentsWithResponses(
              newStart.format(),
              this.iwrStartDate.format(),
              "",
              "",
              "",
              ""
            );

            this.incidentsWithResponses = newData.concat(
              this.incidentsWithResponses
            );
            this.iwrStartDate = newStart;
          }

          if (newEnd > this.iwrEndDate) {
            let newData = await Api.getIncidentsWithResponses(
              this.iwrEndDate.format(),
              newEnd.format(),
              "",
              "",
              "",
              ""
            );

            this.incidentsWithResponses = this.incidentsWithResponses.concat(
              newData
            );
            this.iwrEndDate = newEnd;
          }

          data = [];
          let time;
          for (var i = 0; i < this.incidentsWithResponses.length; i++) {
            time = moment(this.incidentsWithResponses[i].time);
            if (time >= newStart && time <= newEnd) {
              data.push(this.incidentsWithResponses[i]);
            }
          }
        }
      })
      .then(res => {
        this.unlock();
        return res;
      });
    return data;
  };

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
