import React from "react";

import { makeStyles, Grid, Typography, Button, Paper } from "@material-ui/core";

const useStyles = makeStyles({
  welcomeContainer: {
    padding: "1em",
    minHeight: "300px"
  },
  welcomePaper: {
    padding: "1.5em",
    minHeight: "300px",
    zIndex: "10"
  },
  welcomeTextDiv: {
    paddingTop: "1em",
    minHeight: "200px",
    maxWidth: "800px",
    fontSize: "1.5em"
  },
  levrumLogo: {
    width: "15em"
  },
  logoDiv: {
    position: "fixed",
    bottom: "1em",
    right: "1em"
  },
  inlineMaterialIcon: {
    fontFamily: "Material Icons",
    verticalAlign: "Middle",
    fontSize: "1.25em"
  }
});

export default function WelcomePage() {
  const classes = useStyles();
  return (
    <Grid
      className={classes.welcomeContainer}
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Paper className={classes.welcomePaper}>
        <Typography variant="h3">Welcome to Code3 Firewatch!</Typography>
        <div className={classes.welcomeTextDiv}>
          Code3 Firewatch allows you to analyze your system's metrics and view
          incident history using combinations of dynamically generated maps and
          charts.
          <br />
          <br />
          To get started with analyzing your system, open a Map{" "}
          <span className={classes.inlineMaterialIcon}>map</span> or a Chart{" "}
          <span className={classes.inlineMaterialIcon}>bar_chart</span> from the
          menu.
          <br />
          <br />
          For help configuring your system and creating a dashboard, please see
          the relevant documentation within the Help{" "}
          <span className={classes.inlineMaterialIcon}>help</span> menu.
        </div>
      </Paper>
      <div className={classes.logoDiv}>
        <Button target="_new" href="https://levrum.com">
          <img className={classes.levrumLogo} src="/levrumlogo.svg" />
        </Button>
      </div>
    </Grid>
  );
}
