import { SET_ALL_DASHBOARDS } from "../actionTypes";

let dashboards = [];

const setAllDashboards = (state, action) => {
  switch (action.type) {
    case SET_ALL_DASHBOARDS:
      dashboards = action.payload.dashboards;
      return dashboards;
    default:
      return dashboards;
  }
};

export default setAllDashboards;
