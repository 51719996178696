import React from "react";

import { makeStyles, Grid, Typography, Button, Paper } from "@material-ui/core";

import platform from "platform";

const useStyles = makeStyles({
  welcomeContainer: {
    padding: "1em",
    minHeight: "300px"
  },
  welcomePaper: {
    padding: "1.5em",
    minHeight: "300px",
    zIndex: "10"
  },
  welcomeTextDiv: {
    paddingTop: "1em",
    minHeight: "200px",
    maxWidth: "800px",
    fontSize: "1.5em"
  },
  levrumLogo: {
    width: "15em"
  },
  logoDiv: {
    position: "fixed",
    bottom: "1em",
    right: "1em"
  },
  inlineMaterialIcon: {
    fontFamily: "Material Icons",
    verticalAlign: "Middle",
    fontSize: "1.25em"
  }
});

export default function Placeholder() {
  const classes = useStyles();
  return (
    <Grid
      className={classes.welcomeContainer}
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Paper className={classes.welcomePaper}>
        <Typography variant="h3">Under Construction!</Typography>
        <div className={classes.welcomeTextDiv}>
          A brand new version of this component is on the way!
        </div>
      </Paper>
      <div className={classes.logoDiv}>
        <Button target="_new" href="https://levrum.com">
          <img className={classes.levrumLogo} src="/levrumlogo.svg" />
        </Button>
      </div>
    </Grid>
  );
}
