import React, { Component } from "react";
import PropTypes from "prop-types";

// Moment
import moment from "moment";

// OpenLayers
import { Style, Circle, Fill, Stroke, Text } from "ol/style.js";

// Material UI
import { withStyles, withTheme } from "@material-ui/core";

// Redux
import { connect } from "react-redux";
import { getSettingsOpen } from "../../../redux/selectors";
import {
  toggleSettingsOpen,
  closeSettings,
  setSettingsEnabled
} from "../../../redux/actions";

// Utilities
import uuidv4 from "uuid";

// Code3Firewatch Components
import Api from "../../../services/Api";
import {
  dateRanges,
  getDatesFromFilters,
  getDateRanges,
  excludedData,
  getPercentileValues,
  benchmarkNames,
  calculationNames,
  lzw_encode
} from "../../../helpers/DashboardHelpers.js";
import {
  gradientColors,
  heatmapGradientColors,
  getCategoryColor,
  resetNextCategory,
  getHexColor
} from "../../../helpers/Colors";
import DataContextComponent, { DataContext } from "../../DataContext";
import DashboardComponent, { ComponentContext } from "../../DashboardComponent";
import SettingsMenu from "../../controls/SettingsMenu";
import PerformanceMapSettings from "./PerformanceMapSettings";
import PerformanceMapKey from "./PerformanceMapKey";
import PerformanceMapTooltip from "./PerformanceMapTooltip";
import BaseMap, { MapPoint } from "../BaseMap";
import {
  getBenchmarksFromFeature,
  getBenchmarkCalculationFromIncidents,
  calculateValueFromBenchmarks
} from "../MapFunctions";
import PerformanceMapDetails from "./PerformanceMapDetails";

export const calculationTitles = {
  min: "Minimum at Location",
  max: "Maximum at Location",
  mean: "Mean of values at Location",
  median: "Median value at Location",
  percentile: "Percentile at Location"
};

export const regionCalculationTitles = {
  min: "Minimum in Region",
  max: "Maximum in Region",
  mean: "Mean of values in Region",
  median: "Median value in Region",
  percentile: "Percentile in Region"
};

export const hexCalculationTitles = {
  min: "Minimum in Hexagon",
  max: "Maximum in Hexagon",
  mean: "Mean of values in Hexagon",
  median: "Median value in Hexagon",
  percentile: "Percentile in Hexagon"
};

const defaultSettings = {
  zoom: 13,
  longitude: 0.0,
  latitude: 0.0,
  drawPoints: true,
  benchmark: "FirstArrival",
  benchmarkName: "First Arrival",
  calculation: "median",
  availableBenchmarks: ["FirstArrival"],
  autoscale: true,
  gradient: "Green-Yellow-Red",
  lowPointPercentile: 0,
  highPointPercentile: 100,
  percentile: 49,
  lowValue: 0.0,
  highValue: 10.0,
  drawHexagons: false,
  hexBenchmark: "FirstArrival",
  hexCalculation: "median",
  hexStyle: "Color",
  hexGradient: "Green-Yellow-Red",
  hexAutoscale: true,
  hexLowValue: 0,
  hexHighValue: 0,
  hexagonSize: 0.5,
  hexPercentile: 49,
  lowHexPercentile: 0,
  highHexPercentile: 100,
  hexLowValue: 0.0,
  hexHighValue: 10.0,
  drawRegions: false,
  regions: [],
  regionSelection: [],
  regionBenchmark: "FirstArrival",
  regionCalculation: "median",
  regionPercentile: 49,
  regionGradient: "Green-Yellow-Red",
  regionAutoscale: true,
  regionLowValue: 0.0,
  regionHighValue: 10.0,
  lowRegionPercentile: 0,
  highRegionPercentile: 100,
  regionOpacity: 50
};

export default class StandalonePerformanceMap extends Component {
  constructor(props) {
    super(props);
  }

  state = {};

  render() {
    return (
      <DataContextComponent>
        <DataContext.Consumer>
          {({ setDataSettings, getDataSettings, ...dataSettings }) => (
            // DashboardComponent is a DataContext consumer in this scenario because it needs to save data settings with dashboard items
            <DashboardComponent
              setDataSettings={setDataSettings}
              getDataSettings={getDataSettings}
              defaultSettings={defaultSettings}
              {...this.props}
            >
              <ComponentContext.Consumer>
                {({ setSettings, ...settings }) => (
                  <PerformanceMap
                    setSettings={setSettings}
                    setDataSettings={setDataSettings}
                    dashboardMode={false}
                    {...settings}
                    {...dataSettings}
                    {...this.props}
                  >
                    <PerformanceMapSettings />
                  </PerformanceMap>
                )}
              </ComponentContext.Consumer>
            </DashboardComponent>
          )}
        </DataContext.Consumer>
      </DataContextComponent>
    );
  }
}

const styles = {};

export class PerformanceMapComponent extends Component {
  constructor(props) {
    super(props);

    if (!this.props.dashboardMode) {
    }

    this.gradients = {};
    this.colorStyles = {};
    this.hexStyles = {};

    this.props.setDataSettings({
      usingIncidentsByLocation: true,
      usingRegions: true
    });
    this.props.setSettings({ componentType: "Performance Map" });

    this.id = uuidv4();
  }

  state = {};

  componentDidMount = () => {
    this.setupMap();
    if (!this.props.dashboardMode) {
      this.props.setSettingsEnabled(true);
      document.title = "Code3 Firewatch - Performance Map";
    }
  };

  componentWillUnmount = () => {
    if (!this.props.dashboardMode) {
      this.props.setSettingsEnabled(false);
      this.props.closeSettings();
      document.title = "Code3 Firewatch";
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.props.incidentsByLocation !== prevProps.incidentsByLocation) {
      await this.loadData();
      this.pointBuildRequired = true;
      this.hexagonBuildRequired = true;
      this.regionBuildRequired = true;
    }

    if (this.props.showSatellite !== prevProps.showSatellite) {
      this.colorStyles = {};
      this.gradients = {};
      this.hexStyles = {};
      this.setupStyles();
      this.setupHexagonStyles();
      this.setupRegionStyles();
      this.setState({
        pointUpdateUuid: uuidv4(),
        hexagonUpdateUuid: uuidv4(),
        regionUpdateUuid: uuidv4()
      });
    }

    if (this.props.gradient !== prevProps.gradient) {
      this.pointStyles = {};
      this.setupStyles();
      this.setState({ pointUpdateUuid: uuidv4() });
    }

    if (
      (this.props.benchmarks !== prevProps.benchmarks ||
        this.props.autoscale !== prevProps.autoscale) &&
      this.props.autoscale
    ) {
      this.setupAutoscale();
    }

    if (
      this.props.benchmark !== prevProps.benchmark ||
      this.props.lowPointPercentile !== prevProps.lowPointPercentile ||
      this.props.highPointPercentile !== prevProps.highPointPercentile ||
      this.props.drawNullBenchmarks !== prevProps.drawNullBenchmarks
    ) {
      this.pointBuildRequired = true;
    }

    if (
      this.props.calculation !== prevProps.calculation ||
      this.props.percentile !== prevProps.percentile ||
      this.props.lowValue !== prevProps.lowValue ||
      this.props.highValue !== prevProps.highValue
    ) {
      this.setState({ pointUpdateUuid: uuidv4() });
    }

    if (this.props.drawPoints && this.pointBuildRequired) {
      this.pointBuildRequired = false;
      this.buildPoints();
    }

    if (
      (this.props.hexBenchmarks !== prevProps.hexBenchmarks ||
        this.props.hexAutoscale !== prevProps.hexAutoscale) &&
      this.props.hexAutoscale
    ) {
      this.setupHexAutoscale();
    }

    if (this.props.hexGradient !== prevProps.hexGradient) {
      this.hexStyles = {};
      this.setupHexagonStyles();
      this.setState({ hexagonUpdateUuid: uuidv4() });
    }

    if (
      this.props.hexSelection !== prevProps.hexSelection ||
      this.props.lowHexPercentile !== prevProps.lowHexPercentile ||
      this.props.highHexPercentile !== prevProps.highHexPercentile
    ) {
      this.hexagonBuildRequired = true;
    }

    if (this.props.drawHexagons && this.hexagonBuildRequired) {
      this.hexagonBuildRequired = false;
      this.buildHexPoints();
    }

    if (
      this.props.hexLowValue !== prevProps.hexLowValue ||
      this.props.hexHighValue !== prevProps.hexHighValue ||
      this.props.hexPercentile !== prevProps.hexPercentile ||
      this.props.hexCalculation !== prevProps.hexCalculation ||
      this.props.hexBenchmark !== prevProps.hexBenchmark
    ) {
      this.setState({ hexagonUpdateUuid: uuidv4() });
    }

    if (
      ((this.props.drawRegions &&
        this.props.drawRegions !== prevProps.drawRegions) ||
        this.props.regionBenchmark !== prevProps.regionBenchmark ||
        this.props.regionAutoscale !== prevProps.regionAutoscale) &&
      this.props.regionAutoscale
    ) {
      this.setupRegionAutoscale();
    }

    if (this.props.regionGradient !== prevProps.regionGradient) {
      this.regionStyles = {};
      this.setupRegionStyles();
      this.setState({ regionUpdateUuid: uuidv4() });
    }

    if (
      this.props.regionLowValue !== prevProps.regionLowValue ||
      this.props.regionHighValue !== prevProps.regionHighValue ||
      this.props.regionPercentile !== prevProps.regionPercentile ||
      this.props.regionCalculation !== prevProps.regionCalculation ||
      this.props.regionBenchmark !== prevProps.regionBenchmark
    ) {
      this.setState({ regionUpdateUuid: uuidv4() });
    }
  };

  render() {
    return (
      <div
        id="outer-container"
        style={
          (this.props.dashboardMode && {
            height: this.props.height ? this.props.height + "px" : "450px"
          }) ||
          {}
        }
      >
        {!this.props.dashboardMode && (
          <SettingsMenu
            pageWrapId={this.id}
            outerContainerId={"outer-container"}
            customBurgerIcon={false}
            onStateChange={this.menuStateChange}
            style={"slide"}
            noOverlay={false}
            width={280}
          >
            {this.props.children}
          </SettingsMenu>
        )}
        <BaseMap
          id={this.id}
          dashboardMode={this.props.dashboardMode}
          height={this.props.height}
          styleFunction={this.styleResponse}
          hexagonStyleFunction={this.styleHexagon}
          regionStyleFunction={this.styleRegion}
          showNewTabButton={this.props.dashboardMode}
          showDetailsButton={!this.props.dashboardMode}
          newTabFunction={this.openNewTab}
          onPointerMove={this.onPointerMove}
          pointUpdateUuid={this.state.pointUpdateUuid}
          hexagonUpdateUuid={this.state.hexagonUpdateUuid}
          regionUpdateUuid={this.state.regionUpdateUuid}
          heatmapUpdateUuid={this.state.heatmapUpdateUuid}
          keyContents={<PerformanceMapKey />}
          drawerContents={<PerformanceMapDetails />}
          tooltipComponent={<PerformanceMapTooltip />}
        />
      </div>
    );
  }

  setupMap = async () => {
    this.props.setSettings({ loading: true });

    if (!this.props.latitude || this.props.latitude == 0) {
      await this.resetMap();
    }

    var dateRanges = await getDateRanges();
    var startMoment = moment(this.props.startDate);
    var maxMoment = moment(dateRanges.maxDate);
    var startDate = this.props.startDate || dateRanges.startDate;
    var endDate = this.props.endDate || dateRanges.endDate;
    if (startMoment > maxMoment) {
      endDate = maxMoment.format("YYYY-MM-DD");
      startDate = maxMoment.subtract(1, "months").format("YYYY-MM-DD");
    }

    this.props.setDataSettings({
      startDate: startDate,
      endDate: endDate,
      minDate: dateRanges.minDate,
      maxDate: dateRanges.maxDate
    });

    this.setupStyles();
    this.setupHexagonStyles();
    this.setupRegionStyles();
    await this.props.updateData();
    this.loadData();
    this.props.setSettings({ loading: false });
  };

  resetMap = async () => {
    let mapSettings = await Api.getMapSettings();
    if (mapSettings && !this.props.dashboardMode) {
      this.props.setSettings({
        longitude: mapSettings.longitude,
        latitude: mapSettings.latitude,
        zoom: mapSettings.zoom
      });
    }
  };

  loadData = async () => {
    var data = this.props.incidentsByLocation;
    if (!data) {
      return;
    }

    var availableBenchmarks = this.getAvailableBenchmarks(data);
    var benchmarks = this.getBenchmarksFromData(this.props.benchmark);
    this.props.setSettings({
      benchmarks: benchmarks,
      availableBenchmarks: availableBenchmarks
    });
  };

  getAvailableBenchmarks = data => {
    var availableBenchmarks = [];

    var incidents, incident, response, benchmark;
    var locations = Object.keys(data);
    for (var i = 0, len = locations.length; i < len; i++) {
      incidents = data[locations[i]];
      for (var j = 0; j < incidents.length; j++) {
        incident = incidents[j];
        for (var k = 0; k < incident.responses.length; k++) {
          response = incident.responses[k];
          for (var l = 0; l < response.benchmarks.length; l++) {
            benchmark = response.benchmarks[l];
            if (!availableBenchmarks.includes(benchmark.name)) {
              availableBenchmarks.push(benchmark.name);
            }
          }
        }
      }
    }

    return availableBenchmarks;
  };

  getBenchmarksFromData = searchBenchmark => {
    if (!this.props.incidentsByLocation) {
      return;
    }

    var incidents, incident, response, benchmark;
    var benchmarks = [];
    var locations = Object.keys(this.props.incidentsByLocation);

    for (var i = 0, len = locations.length; i < len; i++) {
      incidents = this.props.incidentsByLocation[locations[i]];
      for (var j = 0; j < incidents.length; j++) {
        incident = incidents[j];
        for (var k = 0; k < incident.responses.length; k++) {
          response = incident.responses[k];
          for (var l = 0; l < response.benchmarks.length; l++) {
            benchmark = response.benchmarks[l];
            if (benchmark.name === searchBenchmark) {
              benchmarks.push(benchmark.value);
            }
          }
        }
      }
    }

    benchmarks.sort(function(a, b) {
      return a - b;
    });

    return benchmarks;
  };

  buildPoints = () => {
    if (!this.props.incidentsByLocation) {
      return;
    }

    var benchmarks = this.getBenchmarksFromData(this.props.benchmark);

    var percentileValues = getPercentileValues(
      benchmarks,
      this.props.lowPointPercentile,
      this.props.highPointPercentile
    );
    this.lowPercentileValue = percentileValues.low;
    this.highPercentileValue = percentileValues.high;

    let locations = Object.keys(this.props.incidentsByLocation);
    let incidents, incident, point, data, longitude, latitude;
    let points = [];
    for (var i = 0, len = locations.length; i < len; i++) {
      incidents = this.props.incidentsByLocation[locations[i]];
      data = [];
      for (var j = 0; j < incidents.length; j++) {
        incident = incidents[j];
        if (
          this.includePointBenchmark(
            incident,
            percentileValues.low,
            percentileValues.high
          )
        ) {
          data.push(incident);
          longitude = incident.longitude;
          latitude = incident.latitude;
        }
      }
      if (data.length > 0) {
        points.push(new MapPoint(longitude, latitude, 0.0, data));
      }
    }

    this.props.setSettings({
      benchmarks: benchmarks,
      points: points
    });
  };

  buildHexPoints = () => {
    if (!this.props.incidentsByLocation) {
      return;
    }

    var benchmarks = this.getBenchmarksFromData(this.props.hexBenchmark);

    var percentileValues = getPercentileValues(
      benchmarks,
      this.props.lowHexPercentile,
      this.props.highHexPercentile
    );
    this.hexLowPercentileValue = percentileValues.low;
    this.hexHighPercentileValue = percentileValues.high;

    let locations = Object.keys(this.props.incidentsByLocation);
    let incidents, incident, point, data, longitude, latitude;
    let points = [];
    for (var i = 0, len = locations.length; i < len; i++) {
      incidents = this.props.incidentsByLocation[locations[i]];
      data = [];
      for (var j = 0; j < incidents.length; j++) {
        incident = incidents[j];
        if (
          this.includePointBenchmark(
            incident,
            percentileValues.low,
            percentileValues.high
          )
        ) {
          data.push(incident);
          longitude = incident.longitude;
          latitude = incident.latitude;
        }
      }
      if (data.length > 0) {
        points.push(new MapPoint(longitude, latitude, 0.0, data));
      }
    }

    this.props.setSettings({
      hexBenchmarks: benchmarks,
      hexagonPoints: points
    });
  };

  includePointBenchmark = (incident, lowPercentile, highPercentile) => {
    if (!incident || !incident.responses || incident.responses.length == 0) {
      return false;
    }

    var response, benchmark;

    for (var j = 0; j < incident.responses.length; j++) {
      response = incident.responses[j];
      for (var k = 0; k < response.benchmarks.length; k++) {
        benchmark = response.benchmarks[k];
        if (benchmark.name === this.props.benchmark) {
          if (
            benchmark.value >= lowPercentile &&
            benchmark.value <= highPercentile
          ) {
            return true;
          }
        }
      }
    }

    return this.props.drawNullBenchmarks;
  };

  setupAutoscale = () => {
    if (!this.props.benchmarks) {
      return;
    }

    var autoscaleValues = getPercentileValues(this.props.benchmarks);

    this.props.setSettings({
      lowValue: autoscaleValues.low,
      medianValue: autoscaleValues.median,
      highValue: autoscaleValues.high
    });
  };

  pointStyles = {};
  pointFills = {};

  setupStyles = () => {
    if (this.pointStyles) {
      for (var i = 0; i < this.pointStyles.length; i++) {
        delete this.pointStyles[i];
      }
    }

    if (this.pointFiles) {
      for (var i = 0; i < this.pointFills.length; i++) {
        delete this.pointFills[i];
      }
    }

    const stroke = new Stroke({
      color: [64, 64, 64, 1],
      width: 1.5
    });

    const satelliteStroke = new Stroke({
      color: [192, 192, 192, 1],
      width: 1.5
    });

    var interval1, interval2, interval3;
    var colorScheme = gradientColors[this.props.gradient];

    interval1 = (colorScheme[1][0] - colorScheme[0][0]) / 50;
    interval2 = (colorScheme[1][1] - colorScheme[0][1]) / 50;
    interval3 = (colorScheme[1][2] - colorScheme[0][2]) / 50;

    var alpha = this.props.showSatellite ? 0.95 : 0.65;

    for (var i = 0; i < 50; i++) {
      this.pointFills[i] = new Fill({
        color: [
          colorScheme[0][0] + interval1 * i,
          colorScheme[0][1] + interval2 * i,
          colorScheme[0][2] + interval3 * i,
          alpha
        ]
      });
      this.pointStyles[i] = new Style({
        image: new Circle({
          radius: 5,
          fill: this.pointFills[i],
          stroke: this.props.showSatellite ? satelliteStroke : stroke
        }),
        zIndex: i
      });
    }

    interval1 = (colorScheme[2][0] - colorScheme[1][0]) / 50;
    interval2 = (colorScheme[2][1] - colorScheme[1][1]) / 50;
    interval3 = (colorScheme[2][2] - colorScheme[1][2]) / 50;

    var k;
    for (var j = 50; j < 100; j++) {
      k = j - 50;
      this.pointFills[j] = new Fill({
        color: [
          colorScheme[1][0] + interval1 * k,
          colorScheme[1][1] + interval2 * k,
          colorScheme[1][2] + interval3 * k,
          alpha
        ]
      });
      this.pointStyles[j] = new Style({
        image: new Circle({
          radius: 5,
          fill: this.pointFills[j],
          stroke: this.props.showSatellite ? satelliteStroke : stroke
        }),
        zIndex: j
      });
    }
  };

  styleResponse = feature => {
    let response, styleIndex, pointStyle;
    let incidents = feature.get("data");

    var selected = false;

    if (
      this.props.selectedFeatures &&
      this.props.selectedFeatures.includes(feature)
    ) {
      selected = true;
    }

    if (!incidents) {
      return;
    }

    let lowSec, highSec, interval;
    lowSec = this.props.lowValue * 60;
    highSec = this.props.highValue * 60;

    interval = (highSec - lowSec) / 100;

    let valueInSeconds =
      getBenchmarkCalculationFromIncidents(
        incidents,
        this.props.benchmark,
        this.props.calculation,
        this.props.percentile
      ) * 60; // Not actually in seconds for all benchmarks...
    styleIndex = Math.max(
      0,
      Math.min(99, Math.floor((valueInSeconds - lowSec) / interval))
    );

    if (selected) {
      let color = this.pointFills[styleIndex].getColor();

      const stroke = new Stroke({
        color: color,
        width: 5
      });

      const fill = new Fill({
        color: [64, 64, 64, 1]
      });

      const satelliteFill = new Fill({
        color: [192, 192, 192, 1]
      });

      pointStyle = new Style({
        image: new Circle({
          radius: 5,
          fill: this.props.showSatellite ? satelliteFill : fill,
          stroke: stroke
        })
      });
    } else {
      pointStyle = this.pointStyles[styleIndex];
    }
    feature.setStyle(pointStyle);
  };

  setupHexAutoscale = () => {
    if (!this.props.hexBenchmarks) {
      return;
    }

    var autoscaleValues = getPercentileValues(this.props.hexBenchmarks);

    this.props.setSettings({
      hexLowValue: autoscaleValues.low,
      hexMedianValue: autoscaleValues.median,
      hexHighValue: autoscaleValues.high
    });
  };

  styleHexagon = (f, res) => {
    switch (this.state.hexStyle) {
      case "gradient":
        return this.hexagonGradientStyle(f, res);
      case "dynamic":
        return this.hexagonDynamicStyle(f, res);
      case "color":
      default:
        return this.hexagonColorStyle(f, res);
    }
  };

  hexStyles = {};

  setupHexagonStyles = () => {
    const stroke = new Stroke({
      color: [64, 64, 64, 0.8],
      width: 1.5
    });

    const satelliteStroke = new Stroke({
      color: [192, 192, 192, 1],
      width: 1.5
    });

    var interval1, interval2, interval3;
    var colorScheme = gradientColors[this.props.hexGradient];

    interval1 = (colorScheme[1][0] - colorScheme[0][0]) / 50;
    interval2 = (colorScheme[1][1] - colorScheme[0][1]) / 50;
    interval3 = (colorScheme[1][2] - colorScheme[0][2]) / 50;

    for (var i = 0; i < 50; i++) {
      this.hexStyles[i] = new Style({
        fill: new Fill({
          color: [
            colorScheme[0][0] + interval1 * i,
            colorScheme[0][1] + interval2 * i,
            colorScheme[0][2] + interval3 * i,
            1
          ]
        }),
        stroke: this.props.showSatellite ? satelliteStroke : stroke
      });
    }

    interval1 = (colorScheme[2][0] - colorScheme[1][0]) / 50;
    interval2 = (colorScheme[2][1] - colorScheme[1][1]) / 50;
    interval3 = (colorScheme[2][2] - colorScheme[1][2]) / 50;

    var k;
    for (var j = 50; j < 100; j++) {
      k = j - 50;
      this.hexStyles[j] = new Style({
        fill: new Fill({
          color: [
            colorScheme[1][0] + interval1 * k,
            colorScheme[1][1] + interval2 * k,
            colorScheme[1][2] + interval3 * k,
            1
          ]
        }),
        stroke: this.props.showSatellite ? satelliteStroke : stroke
      });
    }
  };

  hexagonColorStyle = (f, res) => {
    var benchmarks = [];
    var features = f.get("features");

    if (!features) {
      return;
    }

    var featureBenchmarks;
    for (var i = 0; i < features.length; i++) {
      featureBenchmarks = getBenchmarksFromFeature(
        features[i],
        this.props.hexBenchmark
      );
      for (var j = 0; j < featureBenchmarks.length; j++) {
        benchmarks.push(featureBenchmarks[j]);
      }
    }

    var value = calculateValueFromBenchmarks(
      benchmarks,
      this.props.hexCalculation,
      this.props.hexPercentile
    );

    var lowSec, highSec, interval;
    lowSec = this.props.hexLowValue * 60;
    highSec = this.props.hexHighValue * 60;

    interval = (highSec - lowSec) / 100;

    var valueInSeconds = value * 60; // Not actually in seconds for all benchmarks...
    var styleIndex = Math.max(
      0,
      Math.min(99, Math.floor((valueInSeconds - lowSec) / interval))
    );

    return this.hexStyles[styleIndex];
  };

  setupRegionAutoscale = () => {
    if (!this.props.regions) {
      return;
    }

    let benchmarks = this.getBenchmarksFromData(this.props.regionBenchmark);

    var autoscaleValues = getPercentileValues(benchmarks);

    this.props.setSettings({
      regionLowValue: autoscaleValues.low,
      regionMedianValue: autoscaleValues.median,
      regionHighValue: autoscaleValues.high
    });
  };

  regionStyles = {};

  setupRegionStyles = () => {
    const stroke = new Stroke({
      color: [64, 64, 64, 0.8],
      width: 1.5
    });

    const satelliteStroke = new Stroke({
      color: [192, 192, 192, 1],
      width: 1.5
    });

    var interval1, interval2, interval3;
    var colorScheme = gradientColors[this.props.regionGradient];

    interval1 = (colorScheme[1][0] - colorScheme[0][0]) / 50;
    interval2 = (colorScheme[1][1] - colorScheme[0][1]) / 50;
    interval3 = (colorScheme[1][2] - colorScheme[0][2]) / 50;

    for (var i = 0; i < 50; i++) {
      this.regionStyles[i] = new Style({
        fill: new Fill({
          color: [
            colorScheme[0][0] + interval1 * i,
            colorScheme[0][1] + interval2 * i,
            colorScheme[0][2] + interval3 * i,
            1
          ]
        }),
        stroke: this.props.showSatellite ? satelliteStroke : stroke
      });
    }

    interval1 = (colorScheme[2][0] - colorScheme[1][0]) / 50;
    interval2 = (colorScheme[2][1] - colorScheme[1][1]) / 50;
    interval3 = (colorScheme[2][2] - colorScheme[1][2]) / 50;

    var k;
    for (var j = 50; j < 100; j++) {
      k = j - 50;
      this.regionStyles[j] = new Style({
        fill: new Fill({
          color: [
            colorScheme[1][0] + interval1 * k,
            colorScheme[1][1] + interval2 * k,
            colorScheme[1][2] + interval3 * k,
            1
          ]
        }),
        stroke: this.props.showSatellite ? satelliteStroke : stroke
      });
    }
  };

  styleRegion = (f, res) => {
    let incidents = f.get("data");

    var value = getBenchmarkCalculationFromIncidents(
      incidents,
      this.props.regionBenchmark,
      this.props.regionCalculation,
      this.props.regionPercentile
    );

    var lowSec, highSec, interval;
    lowSec = this.props.regionLowValue * 60;
    highSec = this.props.regionHighValue * 60;

    interval = (highSec - lowSec) / 100;

    var valueInSeconds = value * 60; // Not actually in seconds for all benchmarks...
    var styleIndex = Math.max(
      0,
      Math.min(99, Math.floor((valueInSeconds - lowSec) / interval))
    );

    return this.regionStyles[styleIndex];
  };

  openNewTab = () => {
    let settings = this.props.dashboardSettings;
    settings.title = this.props.title;
    let objJsonStr = JSON.stringify(settings);
    let lzw = lzw_encode(objJsonStr);

    var win = window.open("/map/performance/" + lzw);
    win.focus();
  };
}

PerformanceMapComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const settingsOpen = getSettingsOpen(state);
  return { settingsOpen };
};

const mapDispatchToProps = {
  toggleSettingsOpen,
  closeSettings,
  setSettingsEnabled
};

export const PerformanceMap = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(withStyles(styles)(withTheme(PerformanceMapComponent)));
