import React, { useState } from "react";

import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  TextField,
  NativeSelect,
  Input,
  InputLabel
} from "@material-ui/core";
import { buildNativeOptions } from "../controls/MenuControls";

const positions = ["Start", "Middle", "End"];

const useStyles = makeStyles({
  dialogContent: {
    width: "16em"
  },
  dialogTextField: {
    width: "100%",
    marginBottom: "8px"
  },
  positionSelect: {
    width: "100%"
  }
});

export function AddXAxisLineDialog(props) {
  const classes = useStyles();
  const { open, hide, onAdd } = props;
  const [label, setLabel] = useState("");
  const [value, setValue] = useState(0);
  const [position, setPosition] = useState("Middle");

  const handleAdd = () => {
    var line = {
      text: label,
      label: label,
      value: value,
      position: position.toLowerCase()
    };
    onAdd(line);
    hide();
  };

  return (
    <Dialog open={open} onClose={hide} aria-labelledby="xAxisLineDialogTitle">
      <DialogTitle id="xAxisLineDialogTitle">Add X-Axis Line</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          className={classes.dialogTextField}
          id="label"
          label="Label"
          value={label}
          onChange={event => {
            setLabel(event.target.value);
          }}
        />
        <TextField
          className={classes.dialogTextField}
          id="value"
          label="Value"
          value={value}
          onChange={event => {
            setValue(event.target.value);
          }}
        />
        <FormControl className={classes.positionSelect}>
          <InputLabel>Position</InputLabel>
          <NativeSelect
            id="position"
            value={position}
            onChange={event => {
              setPosition(event.target.value);
            }}
            input={<Input name="linePosition" />}
          >
            {buildNativeOptions(positions)}
          </NativeSelect>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={hide} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAdd} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function AddYAxisLineDialog(props) {
  const classes = useStyles();
  const { open, hide, onAdd } = props;
  const [label, setLabel] = useState("");
  const [value, setValue] = useState(0);
  const [position, setPosition] = useState("Middle");

  const handleAdd = () => {
    var line = {
      text: label,
      label: label,
      value: value,
      position: position.toLowerCase()
    };
    onAdd(line);
    hide();
  };

  return (
    <Dialog
      open={props.open}
      onClose={hide}
      aria-labelledby="yAxisLineDialogTitle"
    >
      <DialogTitle id="yAxisLineDialogTitle">Add Y-Axis Line</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          className={classes.dialogTextField}
          id="label"
          label="Label"
          value={label}
          onChange={event => {
            setLabel(event.target.value);
          }}
        />
        <TextField
          className={classes.dialogTextField}
          id="value"
          label="Value"
          value={value}
          type="number"
          onChange={event => {
            setValue(event.target.value);
          }}
        />
        <FormControl className={classes.positionSelect}>
          <InputLabel>Position</InputLabel>
          <NativeSelect
            id="position"
            value={position}
            onChange={event => {
              setPosition(event.target.value);
            }}
            input={<Input name="linePosition" />}
          >
            {buildNativeOptions(positions)}
          </NativeSelect>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={hide} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAdd} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
