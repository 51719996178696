import moment from "moment";
import Api from "../services/Api.js";
import LZString from "lz-string";

export const dateRanges = [
  "Selected Dates",
  "Last Month",
  "This Month",
  "Last Week",
  "This Week",
  "Last 7 Days",
  "Last 30 Days",
  "Last 3 Months",
  "Last 6 Months",
  "Last Year"
];

export const dashboardItemWidths = [
  { value: 1.0, label: "100%" },
  { value: 0.5, label: "50%" },
  { value: 0.333, label: "33%" },
  { value: 0.25, label: "25%" }
];

export const dashboardItemHeights = [
  { value: 0.333, label: "33.3%" },
  { value: 0.5, label: "50%" },
  { value: 0.75, label: "75%" },
  { value: 1.0, label: "100%" }
];

export const excludedData = [
  "X",
  "Y",
  "Priority",
  "FirstEffAction",
  "FirstArrival",
  "FirstResponding",
  "Case ID",
  "Responses",
  "Latitude",
  "Longitude",
  "Location",
  "Time",
  "Id",
  "First Dispatched",
  "First Responding",
  "First Arrival"
];

export const benchmarkNames = {
  ClearScene: "Elapsed Until Cleared",
  FirstArrival: "First Arrival",
  FullComplement: "Full Complement",
  Responding: "Response Time",
  TurnoutTime: "Turnout Time",
  OnScene: "Elapsed Until Arrival",
  CommittedHours: "Committed Hours",
  FirstResponding: "First Responding",
  SceneTime: "Time On Scene",
  TravelTime: "Travel Time"
};

export const calculationNames = {
  min: "Min",
  max: "Max",
  mean: "Mean",
  median: "Median",
  percentile: "Percentile"
};

export const doubleClickSpeed = 500; // This is the Windows default, apparently

export async function getDateRanges() {
  var dateRange = await Api.getDateRange();
  if (!dateRange) {
    var minDate = moment().set({ year: 1, month: 1, day: 1 });
    var maxDate = moment().set({ year: 2099, month: 12, day: 31 });
    return {
      startDate: minDate,
      endDate: maxDate,
      minDate: minDate,
      maxDate: maxDate
    };
  }

  var endDate = moment(dateRange[1]).format("YYYY-MM-DD");
  var startDate = moment(dateRange[1])
    .subtract(1, "month")
    .format("YYYY-MM-DD");

  return {
    startDate: startDate,
    endDate: endDate,
    minDate: moment(dateRange[0]).format("YYYY-MM-DD"),
    maxDate: moment(dateRange[1]).format("YYYY-MM-DD")
  };
}

export function getDatesFromFilters(dashboardModeFilters) {
  var startDate, endDate;

  if (
    !dashboardModeFilters.dateRange ||
    dashboardModeFilters.dateRange == "Selected Dates"
  ) {
    startDate = dashboardModeFilters.startDate;
    endDate = dashboardModeFilters.endDate;
  } else if (dashboardModeFilters.dateRange == "Last Month") {
    startDate = moment()
      .subtract(1, "months")
      .startOf("month")
      .format("YYYY-MM-DD");
    endDate = moment()
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY-MM-DD");
  } else if (dashboardModeFilters.dateRange == "This Month") {
    startDate = moment()
      .startOf("month")
      .format("YYYY-MM-DD");
    endDate = moment()
      .endOf("month")
      .format("YYYY-MM-DD");
  } else if (dashboardModeFilters.dateRange == "Last Week") {
    startDate = moment()
      .subtract(1, "weeks")
      .startOf("week")
      .format("YYYY-MM-DD");
    endDate = moment()
      .subtract(1, "weeks")
      .endOf("week")
      .format("YYYY-MM-DD");
  } else if (dashboardModeFilters.dateRange == "This Week") {
    startDate = moment()
      .startOf("week")
      .format("YYYY-MM-DD");
    endDate = moment()
      .endOf("week")
      .format("YYYY-MM-DD");
  } else if (dashboardModeFilters.dateRange == "Last 7 Days") {
    startDate = moment()
      .subtract(7, "days")
      .format("YYYY-MM-DD");
    endDate = moment().format("YYYY-MM-DD");
  } else if (dashboardModeFilters.dateRange == "Last 30 Days") {
    startDate = moment()
      .subtract(30, "days")
      .format("YYYY-MM-DD");
    endDate = moment().format("YYYY-MM-DD");
  } else if (dashboardModeFilters.dateRange == "Last 3 Months") {
    startDate = moment()
      .subtract(3, "months")
      .format("YYYY-MM-DD");
    endDate = moment().format("YYYY-MM-DD");
  } else if (dashboardModeFilters.dateRange == "Last 6 Months") {
    startDate = moment()
      .subtract(6, "months")
      .format("YYYY-MM-DD");
    endDate = moment().format("YYYY-MM-DD");
  } else if (dashboardModeFilters.dateRange == "Last Year") {
    startDate = moment()
      .subtract(1, "years")
      .format("YYYY-MM-DD");
    endDate = moment().format("YYYY-MM-DD");
  }

  return [startDate, endDate];
}

export function getPercentileValues(data, lowPercentile, highPercentile) {
  data = data.sort(function(a, b) {
    return a - b;
  });

  var low = 0;
  var median = 0;
  var high = 0;
  lowPercentile = lowPercentile != undefined ? lowPercentile : 25;
  highPercentile = highPercentile != undefined ? 100 - highPercentile : 10;
  if (data && data.length > 0) {
    var top = Math.ceil(data.length / (100 / highPercentile));
    var bottom = Math.ceil(data.length / (100 / lowPercentile));
    if (data[bottom]) low = data[bottom].toFixed(1);
    if (data[data.length - top - 1])
      high = data[data.length - top - 1].toFixed(1);
    if (data[Math.ceil(data.length / 2)])
      median = data[Math.ceil(data.length / 2)].toFixed(1);
  }

  var percentileValues = {
    low: low,
    median: median,
    high: high
  };

  return percentileValues;
}

export function getNumberPrefix(input) {
  var value = Number(input);
  if (value == Number.NaN) {
    return `${input}`;
  }

  if (value % 10 == 1) {
    return `${value}st`;
  } else if (value % 10 == 2) {
    return `${value}nd`;
  } else if (value % 10 == 3) {
    return `${value}rd`;
  } else {
    return `${value}th`;
  }
}

let charTable =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-=_!#$^*(){}[]:;,.<>~`ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúüûýþÿĀāĂăĄąĆćĈĉĊċČčĎďĐđĒēĔĕĖėĘęĚěĜĝĞğĠġĢģĤĥĦħĨĩĪīĬĭĮįİıĲĳĴĵĶķĸĹĺĻļĽľĿŀŁłŃńŅņŇňŉŊŋŌōŎŏŐőŒœŔŕŖŗŘřŚśŜŝŞşŠšŢţŤťŦŧŨũŪūŬŭŮůŰűŲųŴŵŶŷŸŹźŻżŽžſƀƁƂƄƃƅƆƇƈƉƊƋƌƍƎƏƐƑƒƓƔƕƖƗƘƙƚƛƜƝƞƟƠơƢƣƤƥƦƧƨƩƪƫƬƭƮƯưƱƲƳƴƵƶƷƸƹƺƻƼƽƾƿǀǁǂǃǄǅǆǇǈǉǊǋǌǍǎǏǐǑǒǓǔǕǖǗǘǙǚǛǜǝǞǟǠǡǢǣǤǥǦǧǨǩǪǫǬǭǮǯǰǱǲǳǴǵǶǷǸǹǺǻǼǽǾǿȀȁȂȃȄȅȆȇȈȉȊȋȌȍȎȏȐȑȒȓȔȕȖȗȘșȚțȜȝȞȟȠȡȢȣȤȥȦȧȨȩȪȫȬȭȮȯȰȱȲȳȴȵȶȷȸȹȺȻȼȽȾȿɀɁɂɃɄɅɆɇɈɉɊɋɌɍɎɏɐɑɒɓɔɕɖɗɘəɚɛɜɝɞɟɠɡɢɣɤɥɦɧɨɩɪɫɬɭɮɯɰɱɲɳɴɵɶɷɸɹɺɻɼɽɾɿʀʁʂʃʄʅʆʇʈʉʊʋʌʍʎʏʐʑʒʓʔʕʖʗʘʙʚʛʜʝʞʟʠʡʢʣʤʥʦʧʨʩʪʫʬʭʮʯʰʱʲʳʴʵʶʷʸʹʺʻʼʽʾʿˀˁ˂˃˄˅ˆˇˈˉˊˋˌˍˎˏːˑ˒˓˔˕˖˗˘˙˚˛˜˝˞˟ˠˡˢˣˤ";

// LZW-compress a string
export function lzw_encode(s) {
  return LZString.compressToEncodedURIComponent(s);
}

// Decompress an LZW-encoded string
export function lzw_decode(s) {
  return LZString.decompressFromEncodedURIComponent(s);
}
