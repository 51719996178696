import React from "react";

import {
  makeStyles,
  Button,
  Typography,
  Paper,
  Link,
  Divider
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  aboutContainer: {
    padding: "1em",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  aboutPaper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "40%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "2em"
  },
  textDiv: {
    marginTop: ".5em",
    marginBottom: "1em",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center"
  },
  firewatchButton: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto"
  },
  firewatchLogo: {
    width: "100%"
  },
  levrumButton: {
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto"
  },
  levrumLogo: {
    width: "100%"
  },
  creditsDivider: {
    marginTop: "1em",
    marginBottom: "1em"
  }
}));

export default function About(props) {
  const classes = useStyles();

  return (
    <div className={classes.aboutContainer}>
      <Paper className={classes.aboutPaper}>
        <div className={classes.aboutContainer}>
          <Button
            className={classes.firewatchButton}
            href="https://www.code3firewatch.com/"
          >
            <img className={classes.firewatchLogo} src="firewatchlogo.svg" />
          </Button>
          <div className={classes.textDiv}>
            <Typography variant="h4">Version 0.1 (Beta)</Typography>
            <Typography variant="h6">
              Copyright © 2019{" "}
              <Link color="secondary" href="https://www.levrum.com/">
                Levrum Data Technologies
              </Link>
            </Typography>
            <Divider className={classes.creditsDivider} variant="middle" />
            <Typography>
              Code3 Firewatch was developed with assistance from the following
              partnering agencies:
              <br />
              <Link color="secondary" href="https://fire.georgetown.org/">
                Georgetown Fire Department
              </Link>
              ,{" "}
              <Link
                color="secondary"
                href="http://www.virginiamn.us/fireindex.php"
              >
                Virginia Fire and Ambulance
              </Link>
            </Typography>
            <Divider className={classes.creditsDivider} variant="middle" />
            <Typography>
              Code3 Firewatch contains open source software used under license.
              <br />
              Click{" "}
              <Link color="secondary" href="license.md" target="_blank">
                here
              </Link>{" "}
              for full license information.
            </Typography>
          </div>
          <Button
            className={classes.levrumButton}
            href="https://www.levrum.com/"
          >
            <img className={classes.levrumLogo} src="levrumlogo.svg" />
          </Button>
        </div>
      </Paper>
    </div>
  );
}
