import React, { Component } from "react";
import PropTypes from "prop-types";
import Chrome from "react-color";

import {
  withStyles,
  withTheme,
  Button,
  Dialog,
  DialogActions,
  Typography,
  MenuList,
  MenuItem,
  Grid,
  ListItemText
} from "@material-ui/core";

import ColorSwatch from "./ColorSwatch";

import {
  getCategoryColor,
  setCategoryColor,
  resetCategoryColor,
  resetCategoryColors,
  resetNextCategory
} from "../../helpers/Colors";

const styles = {
  container: {
    width: "33em",
    height: "24.6em",
    overflowX: "hidden"
  },
  grid: {
    marginTop: "0px",
    marginBottom: "4px"
  },
  title: {
    marginTop: "8px",
    textAlign: "center"
  },
  menu: {
    width: "15em",
    height: "17em",
    overflowY: "auto",
    overflowX: "auto"
  },
  menuItem: {
    minHeight: "24px",
    lineHeight: "1.25"
  },
  picker: {
    margin: "8px",
    marginRight: "4px",
    padding: "16px"
  }
};

export class CategoryColorPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMenuItem: null,
      selectedMenuColor: [200, 32, 176, 0.6],
      colorsForCategories: {}
    };
  }

  componentDidMount = () => {
    this.getColorsForCategories();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.selectedMenuItem !== prevState.selectedMenuItem &&
      this.state.selectedMenuItem
    ) {
      this.updateSelectedColor(getCategoryColor(this.state.selectedMenuItem));
    }

    if (this.props.categories !== prevProps.categories) {
      if (
        this.state.selectedMenuItem == null &&
        this.props.categories &&
        this.props.categories.length > 0
      ) {
        var category = this.props.categories[0];
        this.updateSelectedColor(getCategoryColor(category));
        this.setState({ selectedMenuItem: category });
      }
      this.getColorsForCategories();
    }
  }

  updateSelectedColor = color => {
    this.setState({
      selectedMenuColor: {
        r: color[0],
        g: color[1],
        b: color[2],
        a: color[3]
      }
    });
  };

  render() {
    const { classes } = this.props;
    let categories = this.props.categories || [];
    return (
      <Dialog
        open={this.props.open || false}
        onClose={this.props.onClose}
        disableBackdropClick
      >
        <div className={classes.container}>
          <Typography className={classes.title} variant="h6" gutterBottom>
            Choose Colors
          </Typography>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
            className={classes.grid}
          >
            <MenuList className={classes.menu}>
              {categories.map(category => (
                <MenuItem
                  className={classes.menuItem}
                  style={this.getMenuItemStyle(category)}
                  title={category}
                  selected={this.state.selectedMenuItem == category}
                  onClick={() => {
                    this.setState({ selectedMenuItem: category });
                  }}
                >
                  <ColorSwatch
                    menuItem
                    color={this.state.colorsForCategories[category]}
                  />
                  <ListItemText>{category}</ListItemText>
                </MenuItem>
              ))}
            </MenuList>
            <Chrome
              className={classes.picker}
              color={this.state.selectedMenuColor}
              onChange={this.handleColorChange}
            />
          </Grid>
          <DialogActions>
            <Button className={classes.button} onClick={this.resetAll}>
              Reset All
            </Button>
            <Button className={classes.button} onClick={this.reset}>
              Reset
            </Button>
            <Button className={classes.button} onClick={this.close}>
              Close
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }

  getMenuItemStyle = category => {
    return {
      fontWeight:
        this.state.selectedMenuItem == category
          ? this.props.theme.typography.fontWeightRegular
          : this.props.theme.typography.fontWeightMedium
    };
  };

  handleColorChange = color => {
    var rgba = [color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a];
    if (this.state.selectedMenuItem) {
      setCategoryColor(this.state.selectedMenuItem, rgba);
    }

    var colorsForCategories = this.state.colorsForCategories;
    colorsForCategories[this.state.selectedMenuItem] = rgba;

    this.setState({
      colorsForCategories: colorsForCategories,
      selectedMenuColor: color.rgb
    });
  };

  getColorsForCategories = () => {
    var colorsForCategories = {};
    this.props.categories &&
      this.props.categories.map(category => {
        colorsForCategories[category] = getCategoryColor(category);
      });

    this.setState({ colorsForCategories: colorsForCategories });
  };

  resetAll = () => {
    if (!this.props.categories) {
      return;
    }

    resetNextCategory();

    for (var i = 0; i < this.props.categories.length; i++) {
      resetCategoryColor(this.props.categories[i]);
    }

    this.getColorsForCategories();
    if (this.state.selectedMenuItem) {
      this.updateSelectedColor(getCategoryColor(this.state.selectedMenuItem));
    }
  };

  reset = () => {
    if (this.state.selectedMenuItem) {
      var color = resetCategoryColor(this.state.selectedMenuItem);
      var colorsForCategories = this.state.colorsForCategories;
      colorsForCategories[this.state.selectedMenuItem] = color;
      this.updateSelectedColor(color);
      this.setState({ colorsForCategories: colorsForCategories });
    }
  };

  close = () => {
    this.setState({
      selectedMenuItem: null,
      selectedMenuColor: {},
      colorsForCategories: {}
    });
    this.props.onClose && this.props.onClose();
  };
}

CategoryColorPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const CategoryColorPickerContainer = withStyles(styles)(
  withTheme(CategoryColorPicker)
);

export default CategoryColorPickerContainer;
