import React, { Component } from "react";
import PropTypes from "prop-types";

import moment from "moment";

import { Style, Circle, Fill, Stroke } from "ol/style.js";

import { withStyles, withTheme } from "@material-ui/core";

// Redux
import { connect } from "react-redux";
import { getSettingsOpen } from "../../../redux/selectors";
import {
  toggleSettingsOpen,
  closeSettings,
  setSettingsEnabled
} from "../../../redux/actions";

import uuidv4 from "uuid";

import Api from "../../../services/Api";
import {
  dateRanges,
  getDatesFromFilters,
  getDateRanges,
  excludedData,
  getPercentileValues,
  lzw_encode
} from "../../../helpers/DashboardHelpers";

import {
  gradientColors,
  getCategoryColor,
  resetNextCategory
} from "../../../helpers/Colors";

import DataContextComponent, { DataContext } from "../../DataContext";
import DashboardComponent, { ComponentContext } from "../../DashboardComponent";
import SettingsMenu from "../../controls/SettingsMenu";
import FrequencyMapSettings from "./FrequencyMapSettings";
import FrequencyMapKey from "./FrequencyMapKey";
import FrequencyMapTooltip from "./FrequencyMapTooltip";
import BaseMap, { MapPoint } from "../BaseMap";
import FrequencyMapDetails from "./FrequencyMapDetails";

const defaultSettings = {
  autoscale: true,
  autosize: true,
  cutoffValue: 3,
  gradient: "Green-Yellow-Red",
  description: ">2 Incidents",
  lowValue: 0,
  highValue: 10,
  zoom: 13,
  longitude: 0.0,
  latitude: 0.0
};

export default class StandaloneFrequencyMap extends Component {
  render() {
    return (
      <DataContextComponent>
        <DataContext.Consumer>
          {({ setDataSettings, getDataSettings, ...dataSettings }) => (
            // DashboardComponent is a DataContext consumer in this scenario because it needs to save data settings with dashboard items
            <DashboardComponent
              setDataSettings={setDataSettings}
              getDataSettings={getDataSettings}
              defaultSettings={defaultSettings}
              {...this.props}
            >
              <ComponentContext.Consumer>
                {({ setSettings, ...settings }) => (
                  <FrequencyMap
                    setSettings={setSettings}
                    setDataSettings={setDataSettings}
                    dashboardMode={false}
                    {...settings}
                    {...dataSettings}
                    {...this.props}
                  >
                    <FrequencyMapSettings />
                  </FrequencyMap>
                )}
              </ComponentContext.Consumer>
            </DashboardComponent>
          )}
        </DataContext.Consumer>
      </DataContextComponent>
    );
  }
}

const styles = {};

export class FrequencyMapComponent extends Component {
  constructor(props) {
    super(props);

    this.gradients = {};
    this.colorStyles = {};

    this.props.setDataSettings({
      usingIncidentsByLocation: true,
      usingRegions: true
    });
    this.props.setSettings({ componentType: "Frequency Map" });

    this.id = uuidv4();
  }

  state = {};

  componentDidMount = () => {
    if (!this.props.dashboardMode) {
      document.title = "Code3 Firewatch - Frequency Map";
      this.props.setSettingsEnabled(true);
    }
    this.setupMap();
    this.setupStyles();
  };

  componentWillUnmount = () => {
    if (!this.props.dashboardMode) {
      this.props.setSettingsEnabled(false);
      this.props.closeSettings();
      document.title = "Code3 Firewatch";
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.props.incidentsByLocation !== prevProps.incidentsByLocation) {
      await this.loadData();
      this.buildPoints();
    }

    if (
      (this.props.frequencies !== prevProps.frequencies &&
        this.props.autoscale) ||
      this.props.autoscale !== prevProps.autoscale ||
      this.props.cutoffValue !== prevProps.cutoffValue
    ) {
      this.setupAutoscale();
    }

    if (
      this.props.showSatellite !== prevProps.showSatellite ||
      this.props.gradient !== prevProps.gradient ||
      this.props.autosize !== prevProps.autosize ||
      this.props.lowValue !== prevProps.lowValue ||
      this.props.highValue !== prevProps.highValue ||
      this.props.cutoffValue !== prevProps.cutoffValue
    ) {
      if (
        this.props.lowValue !== prevProps.lowValue ||
        this.props.highValue !== prevProps.highValue ||
        this.props.cutoffValue !== prevProps.cutoffValue
      ) {
        this.buildPoints();
      }
      this.setupStyles(this.state.zoom);
      this.setState({ pointUpdateUuid: uuidv4() });
    }
  };

  render() {
    return (
      <div
        id="outer-container"
        style={
          (this.props.dashboardMode && {
            height: this.props.height ? this.props.height + "px" : "450px"
          }) ||
          {}
        }
      >
        {!this.props.dashboardMode && (
          <SettingsMenu
            pageWrapId={this.id}
            outerContainerId={"outer-container"}
            customBurgerIcon={false}
            onStateChange={this.menuStateChange}
            style={"slide"}
            noOverlay={false}
            width={280}
          >
            {this.props.children}
          </SettingsMenu>
        )}
        <BaseMap
          id={this.id}
          dashboardMode={this.props.dashboardMode}
          height={this.props.height}
          styleFunction={this.styleFeature}
          showNewTabButton={this.props.dashboardMode}
          showDetailsButton={!this.props.dashboardMode}
          newTabFunction={this.openNewTab}
          onPointerMove={this.onPointerMove}
          pointUpdateUuid={this.state.pointUpdateUuid}
          keyContents={<FrequencyMapKey />}
          drawerContents={<FrequencyMapDetails />}
          tooltipComponent={<FrequencyMapTooltip />}
          updateZoomFunction={this.updateStylesForZoom}
        />
      </div>
    );
  }

  setupMap = async () => {
    this.props.setSettings({ loading: true });

    if (!this.props.latitude || this.props.latitude == 0) {
      await this.resetMap();
    }

    var dateRanges = await getDateRanges();
    var startMoment = moment(this.props.startDate);
    var maxMoment = moment(dateRanges.maxDate);
    var startDate = this.props.startDate || dateRanges.startDate;
    var endDate = this.props.endDate || dateRanges.endDate;
    if (startMoment > maxMoment) {
      endDate = maxMoment.format("YYYY-MM-DD");
      startDate = maxMoment.subtract(1, "months").format("YYYY-MM-DD");
    }

    this.props.setDataSettings({
      startDate: startDate,
      endDate: endDate,
      minDate: dateRanges.minDate,
      maxDate: dateRanges.maxDate
    });

    await this.props.updateData();
    this.loadData();
    this.buildPoints();
    this.props.setSettings({ loading: false, drawPoints: true });
  };

  resetMap = async () => {
    let mapSettings = await Api.getMapSettings();
    if (mapSettings && !this.props.dashboardMode) {
      this.props.setSettings({
        longitude: mapSettings.longitude,
        latitude: mapSettings.latitude,
        zoom: mapSettings.zoom
      });
    }
  };

  loadData = async () => {
    let data = this.props.incidentsByLocation;

    if (!data) {
      return;
    }

    var dataTypes = {};
    var dictionaryKeys = [];
    var dictionary, key;
    var location;
    var locations = Object.keys(data);
    for (var i = 0; i < locations.length; i++) {
      location = data[locations[i]];
      for (var j = 0; j < location.length; j++) {
        dictionary = location[j].data;
        if (!dictionary) {
          continue;
        }
        dictionaryKeys = Object.keys(dictionary);
        for (var k = 0; k < dictionaryKeys.length; k++) {
          key = dictionaryKeys[k];
          if (excludedData.includes(key)) {
            continue;
          }

          if (!dataTypes[key]) {
            dataTypes[key] = {};
          }

          dataTypes[key][dictionary[key]] = true;
        }
      }
    }

    this.dataTypes = dataTypes;

    this.props.setSettings({
      dataTypes: dataTypes,
      categoryColorsSet: true
    });
  };

  getPointsFromData = () => {
    var frequencies = this.getFrequenciesFromData(
      this.props.incidentsByLocation
    );

    this.props.setSettings({ frequencies: frequencies });

    var location;
    var points = [];
    var pointCount = 0;
    var incidentCount = 0;
    for (var i = 0, len = frequencies.length; i < len; i++) {
      incidentCount += frequencies[i][0];
      location = frequencies[i][1];
      points.push(
        new MapPoint(location.longitude, location.latitude, 0.0, location)
      );
      pointCount++;
    }

    console.log(`Incidents: ${incidentCount} Features: ${pointCount}`);
    return points;
  };

  includeIncident = (selection, type, incident) => {
    return true;
  };

  getFrequenciesFromData = data => {
    if (!data) {
      return [];
    }

    var selection, type;

    var incident, locationData;
    var locations = Object.keys(data);
    var locationDetails = {};
    var key;
    for (var i = 0, len = locations.length; i < len; i++) {
      key = locations[i];
      locationData = data[key];
      for (var j = 0; j < locationData.length; j++) {
        incident = locationData[j];
        if (this.includeIncident(selection, type, incident)) {
          if (!locationDetails[key]) {
            locationDetails[key] = {
              location: incident.location,
              longitude: incident.longitude,
              latitude: incident.latitude,
              count: 0,
              incidents: []
            };
          }

          locationDetails[key].count++;
          locationDetails[key].incidents.push(incident);
        }
      }
    }

    var frequencies = [];
    var keys = Object.keys(locationDetails);
    var details;
    for (var j = 0, keysLen = keys.length; j < keysLen; j++) {
      details = locationDetails[keys[j]];
      if (details.count >= this.props.cutoffValue) {
        frequencies.push([details.count, details]);
      }
    }

    frequencies.sort(function(a, b) {
      if (a[0] < b[0]) {
        return -1;
      } else if (a[0] > b[0]) {
        return 1;
      } else {
        return 0;
      }
    });

    return frequencies;
  };

  buildPoints = () => {
    if (!this.props.incidentsByLocation) {
      return;
    }

    console.log(`Getting FrequencyMap point data:`);
    var points = this.getPointsFromData();

    this.props.setDataSettings({
      points: points
    });
  };

  pointStyles = {};
  pointFills = {};

  setupAutoscale = () => {
    var frequencyList = this.props.frequencies;

    var frequencies = [];
    for (var i = 0; i < frequencyList.length; i++) {
      frequencies.push(frequencyList[i][0]);
    }
    var autoscaleValues = getPercentileValues(frequencies);

    this.props.setSettings({
      lowValue: autoscaleValues.low,
      medianValue: autoscaleValues.median,
      highValue: autoscaleValues.high
    });
  };

  setupStyles = zoom => {
    const stroke = new Stroke({
      color: [64, 64, 64, 1],
      width: 1.5
    });

    const satelliteStroke = new Stroke({
      color: [192, 192, 192, 1],
      width: 1.5
    });

    const alpha = this.props.showSatellite ? 0.85 : 0.65;

    var interval1, interval2, interval3;
    var colorScheme =
      gradientColors[this.props.gradient] || gradientColors["Green-Yellow-Red"];

    interval1 = (colorScheme[1][0] - colorScheme[0][0]) / 10;
    interval2 = (colorScheme[1][1] - colorScheme[0][1]) / 10;
    interval3 = (colorScheme[1][2] - colorScheme[0][2]) / 10;

    zoom = zoom ? zoom : 13;

    var radiusFunc;

    if (zoom < 3) {
      radiusFunc = function(i) {
        return i / 4;
      };
    } else if (zoom < 5) {
      radiusFunc = function(i) {
        return i / 3;
      };
    } else if (zoom < 10) {
      radiusFunc = function(i) {
        return i / 2;
      };
    } else if (zoom < 13) {
      radiusFunc = function(i) {
        return i;
      };
    } else {
      radiusFunc = function(i) {
        return 3 + i * 1.75;
      };
    }

    for (var i = 0; i < 10; i++) {
      this.pointFills[i] = new Fill({
        color: [
          colorScheme[0][0] + interval1 * i,
          colorScheme[0][1] + interval2 * i,
          colorScheme[0][2] + interval3 * i,
          alpha
        ]
      });

      this.pointStyles[i] = new Style({
        image: new Circle({
          radius: this.props.autosize ? radiusFunc(i) : 5,
          fill: this.pointFills[i],
          stroke: this.props.showSatellite ? satelliteStroke : stroke
        }),
        zIndex: 20 - i
      });
    }

    interval1 = (colorScheme[2][0] - colorScheme[1][0]) / 10;
    interval2 = (colorScheme[2][1] - colorScheme[1][1]) / 10;
    interval3 = (colorScheme[2][2] - colorScheme[1][2]) / 10;

    var k;
    for (var j = 10; j < 20; j++) {
      k = j - 10;
      this.pointFills[j] = new Fill({
        color: [
          colorScheme[1][0] + interval1 * k,
          colorScheme[1][1] + interval2 * k,
          colorScheme[1][2] + interval3 * k,
          alpha
        ]
      });

      this.pointStyles[j] = new Style({
        image: new Circle({
          radius: this.props.autosize ? radiusFunc(j) : 5,
          fill: this.pointFills[j],
          stroke: this.props.showSatellite ? satelliteStroke : stroke
        }),
        zIndex: 20 - j
      });
    }
  };

  styleFeature = feature => {
    var data = feature.get("data");

    if (!data) {
      return;
    }

    var selected = false;

    if (
      this.props.selectedFeatures &&
      this.props.selectedFeatures.includes(feature)
    ) {
      selected = true;
    }

    var lowValue, highValue, interval;
    lowValue = this.props.lowValue * 100;
    highValue = this.props.highValue * 100;

    interval = Math.max(0.0001, (highValue - lowValue) / 20);

    var inflatedValue = data.count * 100;
    var styleIndex = Math.max(
      0,
      Math.min(19, Math.floor((inflatedValue - lowValue) / interval))
    );

    let pointStyle = this.pointStyles[styleIndex];
    if (selected) {
      let image = pointStyle.getImage();
      let radius = image.getRadius();
      let fill = image.getFill();
      let color = fill.getColor();

      let stroke = new Stroke({
        color: color,
        width: 3
      });

      let style = new Style({
        image: new Circle({
          radius: radius,
          fill: new Fill({ color: [64, 64, 64, 0.85] }),
          stroke: stroke
        }),
        zIndex: 20 - styleIndex
      });

      feature.setStyle(style);
    } else {
      feature.setStyle(pointStyle);
    }
  };

  lastZoom = 0;

  updateStylesForZoom = (basemap, event) => {
    var zoom = basemap.map.getView().getZoom();
    if (Math.floor(zoom) != this.lastZoom) {
      this.lastZoom = Math.floor(zoom);
      this.setupStyles(this.lastZoom);
      this.setState({ pointUpdateUuid: uuidv4(), zoom: this.lastZoom });
    }
  };

  openNewTab = () => {
    let settings = this.props.dashboardSettings;
    settings.title = this.props.title;
    let objJsonStr = JSON.stringify(settings);
    let lzw = lzw_encode(objJsonStr);

    var win = window.open("/map/frequency/" + lzw);
    win.focus();
  };
}

FrequencyMapComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const settingsOpen = getSettingsOpen(state);
  return { settingsOpen };
};

const mapDispatchToProps = {
  toggleSettingsOpen,
  closeSettings,
  setSettingsEnabled
};

export const FrequencyMap = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(withStyles(styles)(withTheme(FrequencyMapComponent)));
