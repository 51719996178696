import {
  unCamelCase,
  formatObjectDates,
  roundObjectNumbers
} from "./Utilities";
import $ from "jquery";
import moment from "moment";

function orderColumnArray(columns, order) {
  if (order.length === 0) {
    return columns;
  }
  let newOrder = Array(order.length).fill(null);
  columns.forEach(element => {
    let orderIdx = order.findIndex(name => {
      return element.name.toLowerCase() === name.toLowerCase();
    });
    if (orderIdx >= 0) {
      newOrder[orderIdx] = element;
    } else {
      newOrder.push(element);
    }
  });
  if (newOrder.length !== columns.length) {
    newOrder = newOrder.filter(ele => ele !== null);
  }
  return newOrder;
}

export function CreateMuiColumnsFromObject(obj, order, toExclude) {
  toExclude = toExclude || [];
  order = order || [];
  let cols = [];
  let keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];
    if (!toExclude.includes(key)) {
      cols.push({ label: unCamelCase(key), name: key });
    }
  }
  let toReturn = orderColumnArray(cols, order);
  return toReturn;
}

//Each object in list has relevant fields, as well as an inner data object
//The inner data object has relevant fields
//For the object, method creates new object with relevant fields from orig object
//  Adds from inner data object to new object
//  Formats dates appropriately
export function CreateDataObject(
  datum,
  dateFormat = false,
  roundNumbers = false
) {
  if (typeof datum !== "object" || datum === null) {
    return {};
  }
  let newDatum;
  if (datum.data === null || typeof datum.data !== "object") {
    newDatum = datum;
  } else {
    let { data } = datum;
    newDatum = { ...datum, data: false, ...data };
    delete newDatum.data;
    delete newDatum.responses;
    delete newDatum.benchmarks;
    delete newDatum.Responses;
    delete newDatum.Benchmarks;
    delete newDatum.Parent;
    delete newDatum.parent;
  }
  if (dateFormat) {
    formatObjectDates(newDatum);
  }
  if (roundNumbers) {
    roundObjectNumbers(newDatum);
  }
  return newDatum;
}
