import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  withStyles,
  withTheme,
  FormControl,
  FormControlLabel,
  Switch,
  InputLabel,
  NativeSelect,
  Input,
  Select,
  Chip,
  MenuItem,
  ListItemText,
  Collapse,
  TextField,
  Grid,
  Typography,
  Button,
  Slider
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

import { gradientColors, getCategoryColor } from "../../../helpers/Colors";

import {
  HeaderGroup,
  DefaultMenuProps,
  getFilterStyle,
  DeletableChip
} from "../../controls/MenuControls";
import ColorSwatch from "../../controls/ColorSwatch";

export const calculationNames = {
  count: "Count",
  measure: "Most Common"
};

const styles = {
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  settingsSlider: {
    marginBottom: ".25em"
  },
  selectAboveSlider: {
    width: "100%",
    marginBottom: "16px"
  },
  settingsLeftTextField: {
    width: "6em"
  },
  settingsRightTextField: {
    width: "6em"
  },
  marginBottom25em: {
    marginBottom: ".25em"
  }
};

class TypeMapRegionSettingsComponent extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    regionGroupOpen: false
  };

  static defaultProps = {
    drawRegions: false
  };

  componentDidUpdate = (prevProps, prevState) => {};

  render() {
    const { classes } = this.props;

    return (
      <HeaderGroup
        id="regionGroupOpen"
        onClick={this.toggleHeader}
        in={this.state.regionGroupOpen}
        label="Regions"
      >
        <div className="pad-gutter-8px">
          <FormControlLabel
            control={
              <Switch
                checked={this.props.drawRegions}
                onChange={this.toggleRegions}
                value="drawRegions"
                color="secondary"
              />
            }
            label="Draw Regions"
          />
          <Typography id="region-opacity" gutterBottom>
            Opacity
          </Typography>
          <Slider
            value={this.props.regionOpacity}
            onChangeCommitted={this.onOpacityChanged}
            aria-labelledby="region-opacity"
            min={25}
            max={100}
            valueLabelDisplay="auto"
            className={classes.settingsSlider}
          />
          <FormControl className="settings-select">
            <InputLabel>Regions</InputLabel>
            <Select
              multiple
              value={this.props.regionSelection}
              onChange={this.onSelectionChange}
              input={<Input id="select-multiple-placeholder" />}
              renderValue={selected => (
                <div className={classes.chips}>
                  {selected.map(value => (
                    <DeletableChip
                      key={value}
                      label={value}
                      className={classes.chip}
                      onDelete={() => {
                        this.removeFromSelection(value);
                      }}
                    />
                  ))}
                </div>
              )}
              MenuProps={DefaultMenuProps}
            >
              {this.props.regions &&
                this.props.regions.map(region => (
                  <MenuItem key={region.id} value={region.name}>
                    <Typography
                      primary={region}
                      style={getFilterStyle(
                        region,
                        this.props.regionSelection,
                        this.props.theme
                      )}
                    >
                      {region.name}
                    </Typography>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl className="settings-select">
            <InputLabel>Measure</InputLabel>
            <Select
              value={this.props.regionDataType}
              onChange={this.onDataTypeChange}
              input={<Input name="regionMeasure" />}
            >
              {Object.keys(this.props.dataTypes).map(dataType => {
                return (
                  <MenuItem key={dataType} value={dataType} label={dataType}>
                    {dataType}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl className="settings-select">
            <Autocomplete
              id="filter-combo-box"
              options={
                (this.props.dataTypes[this.props.regionDataType] &&
                  Object.keys(
                    this.props.dataTypes[this.props.regionDataType]
                  ).sort()) ||
                []
              }
              getOptionLabel={option => option}
              classes={{}}
              value={this.props.regionMeasureFilter}
              handleHomeEndKeys
              clearOnBlur
              autoHighlight
              multiple
              disableCloseOnSelect
              onChange={(event, newValue) => {
                this.props.setSettings({
                  regionMeasureFilter: newValue
                });
              }}
              renderInput={params => (
                <TextField {...params} variant="standard" label="Filter" />
              )}
              renderOption={(category, { selected }) => (
                <MenuItem key={category} value={category}>
                  <ColorSwatch menuItem color={getCategoryColor(category)} />
                  <Typography
                    key={category}
                    style={getFilterStyle(
                      category,
                      this.props.regionMeasureFilter,
                      this.props.theme
                    )}
                  >
                    {category}
                  </Typography>
                </MenuItem>
              )}
              renderTags={selected => {
                return (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <DeletableChip
                        icon={<ColorSwatch color={getCategoryColor(value)} />}
                        key={value}
                        label={value}
                        className={classes.chip}
                        onDelete={() => {
                          this.removeFromFilter(value);
                        }}
                      />
                    ))}
                  </div>
                );
              }}
              aria-label="filter"
            />
          </FormControl>
          <FormControl className="settings-select">
            <InputLabel>Statistic</InputLabel>
            <Select
              value={this.props.regionCalculation}
              onChange={this.setCalculation}
              input={<Input name="regionCalculation" />}
            >
              {Object.keys(calculationNames).map(calculation => {
                return (
                  <MenuItem
                    key={calculation}
                    value={calculation}
                    label={calculationNames[calculation]}
                  >
                    {calculationNames[calculation]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <Collapse
          in={this.props.regionCalculation == "count"}
          className="pad-gutter-8px"
        >
          <FormControl className={classes.selectAboveSlider}>
            <InputLabel>Colorization</InputLabel>
            <Select
              value={this.props.regionGradient}
              onChange={this.setGradient}
              type="select"
              name="selectRegionGradient"
              id="regionGradient"
            >
              {Object.keys(gradientColors).map(gradient => {
                return (
                  <MenuItem key={gradient} value={gradient} label={gradient}>
                    {gradient}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={this.props.regionAutoscale}
                onChange={this.toggleAutoscale}
                value="autoscale"
                color="secondary"
              />
            }
            label="Use Autoscale"
          />
          <Slider
            value={[this.props.regionLowValue, this.props.regionHighValue]}
            onChange={this.onHexSliderChange}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            max={30}
            min={0}
            step={1}
            disabled={this.props.regionAutoscale}
            className={classes.settingsSlider}
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.marginBottom25em}
          >
            <TextField
              id="low-value"
              label="Low"
              value={this.props.regionLowValue}
              onChange={this.onRegionLowValueChange}
              type="number"
              className={classes.settingsLeftTextField}
              disabled={this.props.regionAutoscale}
            />
            <TextField
              id="high-value"
              label="High"
              value={this.props.regionHighValue}
              onChange={this.onRegionHighValueChange}
              type="number"
              className={classes.settingsRightTextField}
              disabled={this.props.regionAutoscale}
            />
          </Grid>
        </Collapse>
        <Collapse in={this.props.regionCalculation != "count"}>
          <Button
            className="fullWidth margin-top-5px margin-bottom-4px"
            onClick={() => {
              this.props.showCategoryColorPicker(
                this.props.dataTypes[this.props.regionDataType]
              );
            }}
            variant="contained"
            color="primary"
          >
            Choose Colors
          </Button>
        </Collapse>
      </HeaderGroup>
    );
  }

  toggleHeader = () => {
    this.setState({ regionGroupOpen: !this.state.regionGroupOpen });
  };

  toggleRegions = () => {
    this.props.setSettings({
      drawRegions: !this.props.drawRegions
    });
  };

  onOpacityChanged = (event, newValue) => {
    this.props.setSettings({ regionOpacity: newValue });
  };

  onDataTypeChange = event => {
    let regionMeasureFilter = this.props.regionMeasureFilter;
    if (
      regionMeasureFilter &&
      regionMeasureFilter.length &&
      regionMeasureFilter.length > 0
    ) {
      regionMeasureFilter = [];
    }

    this.props.setSettings({
      regionDataType: event.target.value,
      regionMeasureFilter: regionMeasureFilter
    });
  };

  onSelectionChange = event => {
    this.props.setSettings({
      regionSelection: event.target.value
    });
  };

  removeFromSelection = value => {
    var newSelection = this.props.regionSelection.slice();
    if (newSelection.indexOf(value) === -1) return;

    newSelection.splice(newSelection.indexOf(value), 1);
    this.props.setSettings({ regionSelection: newSelection });
  };

  onFilterChange = event => {
    this.props.setSettings({
      regionMeasureFilter: event.target.value
    });
  };

  removeFromFilter = value => {
    var newSelection = this.props.regionMeasureFilter.slice();
    if (newSelection.indexOf(value) === -1) return;

    newSelection.splice(newSelection.indexOf(value), 1);
    this.props.setSettings({ regionMeasureFilter: newSelection });
  };

  setCalculation = event => {
    this.props.setSettings({
      regionCalculation: event.target.value
    });
  };

  setGradient = event => {
    this.props.setSettings({ regionGradient: event.target.value });
  };

  toggleAutoscale = () => {
    this.props.setSettings({
      regionAutoscale: !this.props.regionAutoscale
    });
  };

  onHexSliderChange = (event, newValue) => {
    this.props.setSettings({
      regionLowValue: newValue[0],
      regionHighValue: newValue[1]
    });
  };

  onRegionLowValueChange = event => {
    var value = Math.max(0, event.target.value);
    this.props.setSettings({
      regionLowValue: value,
      regionHighValue: Math.max(value, this.props.regionHighValue)
    });
  };

  onRegionHighValueChange = event => {
    var value = Math.max(0, event.target.value);
    this.props.setSettings({
      regionLowValue: Math.min(this.props.regionLowValue, value),
      regionHighValue: value
    });
  };
}

TypeMapRegionSettingsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const TypeMapRegionSettings = withStyles(styles)(
  withTheme(TypeMapRegionSettingsComponent)
);

export default TypeMapRegionSettings;
