import React, { Component } from "react";
import PropTypes from "prop-types";

// MaterialUI
import { withStyles, withTheme, Container } from "@material-ui/core";
import { connect } from "react-redux";
import { getSettingsOpen } from "../../redux/selectors";
import {
  toggleSettingsOpen,
  closeSettings,
  setSettingsEnabled
} from "../../redux/actions";

import DataContextComponent, { DataContext } from "../DataContext";
import DashboardComponent, { ComponentContext } from "../DashboardComponent";
import SettingsMenu from "../controls/SettingsMenu";
import MUIDataTable from "mui-datatables";

export default class ReportBase extends Component {
  render() {
    return (
      <DataContext.Consumer>
        {({ getDataSettings }) => (
          <ComponentContext.Consumer>
            {({ getSettings }) => (
              <ReportBaseComponent
                getDataSettings={getDataSettings}
                getSettings={getSettings}
                {...this.props}
              />
            )}
          </ComponentContext.Consumer>
        )}
      </DataContext.Consumer>
    );
  }
}

class ReportBaseComponent extends Component {
  optionsDefault = {
    filterType: "dropdown",
    responsive: "stacked",
    selectableRows: "none",
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15]
  };

  // constructor(props){
  //     super(props);

  //     this.state = {options: {...this.options, ...props.options}}
  // }

  render() {
    const { data, columns, options, ...otherProps } = this.props;
    let opts = { ...this.optionsDefault, ...options };
    if (data && data.length > 0 && columns && columns.length > 0) {
      return (
        <div style={{ padding: ".5em" }}>
          <MUIDataTable
            data={data}
            columns={columns}
            options={opts}
            {...otherProps}
          />
        </div>
      );
    } else {
      return null;
    }
  }
}
