import { TOGGLE_SETTINGS_OPEN } from "./actionTypes";
import { CLOSE_SETTINGS } from "./actionTypes";
import { SETTINGS_ENABLED } from "./actionTypes";
import { SET_ALL_DASHBOARDS } from "./actionTypes";
import { SET_LOGGED_IN } from "./actionTypes";

export const toggleSettingsOpen = open => ({
  type: TOGGLE_SETTINGS_OPEN,
  payload: { open }
});

export const closeSettings = unused => ({
  type: CLOSE_SETTINGS,
  payload: { unused }
});

export const setSettingsEnabled = enabled => ({
  type: SETTINGS_ENABLED,
  payload: { enabled }
});

export const setAllDashboards = dashboards => ({
  type: SET_ALL_DASHBOARDS,
  payload: { dashboards }
});

export const setLoggedIn = loggedIn => ({
  type: SET_LOGGED_IN,
  payload: { loggedIn }
});
