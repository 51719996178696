import React from "react";
import { makeStyles } from "@material-ui/core";

const swatchStyles = makeStyles({
  chipSwatch: {
    height: "16px",
    width: "16px",
    borderRadius: "50%",
    marginLeft: "8px",
    marginRight: "-4px"
  },
  menuSwatch: {
    height: "16px",
    width: "16px",
    borderRadius: "50%",
    marginRight: "8px",
    minWidth: "16px",
    minHeight: "16px"
  }
});

export default function ColorSwatch(props) {
  const classes = swatchStyles();
  var rgba = props.color
    ? [props.color[0], props.color[1], props.color[2], 1]
    : [0, 0, 0, 0];
  return (
    <div
      className={props.menuItem ? classes.menuSwatch : classes.chipSwatch}
      style={{ background: `rgba(${rgba})` }}
    />
  );
}
