import React, { Component } from "react";
import api from "../../services/Api";
import { Redirect } from "react-router-dom";

import {
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Button
} from "reactstrap";

const style = {
  title: {
    textAlign: "center"
  },
  loginButton: {
    width: "100%"
  }
};

export class ResetPassword extends Component {
  constructor(props) {
    super(props);

    const userJson = localStorage.getItem("user");
    let user;
    if (userJson) user = JSON.parse(userJson);

    this.state = {
      oldPass: "",
      password: "",
      confPass: "",
      redirectLocation: "",
      passwordsMatch: true,
      oldPassOkay: true,
      email: user ? user.email : "",
      redirectLocation: ""
    };
  }

  componentDidMount = () => {};

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevState.oldPass != this.state.oldPass ||
      prevState.password != this.state.password ||
      prevState.confPass != this.state.confPass
    ) {
      var newPassValid =
        this.state.oldPass != this.state.password &&
        this.state.password == this.state.confPass &&
        this.state.password != "";
      this.setState({ newPassValid: newPassValid });
    }
  };

  acceptPassword = async () => {
    var resetOkay = await api.resetPassword(
      this.state.oldPass,
      this.state.password
    );
    if (resetOkay) {
      if (window.PasswordCredential) {
        var c = await navigator.credentials.create({
          password: { id: this.state.email, password: this.state.password }
        });
        navigator.credentials.store(c);
      }
      this.setState({
        redirectLocation:
          (this.props.location.state && this.props.location.state.from) || "/"
      });
    } else {
      this.setState({ oldPassOkay: false });
    }
  };

  cancelReset = async () => {
    this.setState({
      redirectLocation:
        (this.props.location.state && this.props.location.state.from) || "/"
    });
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  render() {
    return (
      (this.state.redirectLocation != "" && (
        <Redirect to={this.state.redirectLocation} />
      )) || (
        <div className="container" style={{ marginTop: "20px" }}>
          <h1>Reset Password</h1>
          <Form>
            <FormGroup id="username">
              <Label>User</Label>
              <Input
                autoComplete="username"
                name="email"
                type="email"
                value={this.state.email}
                disabled
              ></Input>
            </FormGroup>
            <FormGroup id="oldPassword">
              <Label>Old Password</Label>
              <Input
                name="oldPassword"
                autoComplete="current-password"
                id="oldPass"
                type="password"
                value={this.state.oldPass}
                onChange={this.handleChange}
                invalid={!this.state.oldPassOkay}
              ></Input>
              <FormFeedback>Password incorrect</FormFeedback>
            </FormGroup>
            <FormGroup id="password">
              <Label>New Password</Label>
              <Input
                name="password"
                autoComplete="new-password"
                type="password"
                id="password"
                value={this.state.password}
                onChange={this.handleChange}
              ></Input>
            </FormGroup>
            <FormGroup id="confirmPassword">
              <Label>Confirm New Password</Label>
              <Input
                name="confirmPassword"
                autoComplete="new-password"
                type="password"
                id="confPass"
                value={this.state.confPass}
                onChange={this.handleChange}
                valid={this.state.newPassValid}
                invalid={this.state.password != "" && !this.state.newPassValid}
              ></Input>
              <FormFeedback valid>New passwords match!</FormFeedback>
              {this.state.password != "" &&
              this.state.password == this.state.oldPass ? (
                <FormFeedback>
                  New password must be different from old password!
                </FormFeedback>
              ) : (
                <FormFeedback>New passwords do not match!</FormFeedback>
              )}
            </FormGroup>
            <Button
              onClick={this.acceptPassword}
              block
              disabled={
                this.state.password != this.state.confPass ||
                this.state.password == this.state.oldPass
              }
            >
              Accept
            </Button>
            {this.props.disableCancel ? null : (
              <Button onClick={this.cancelReset} block>
                Cancel
              </Button>
            )}
          </Form>
        </div>
      )
    );
  }
}
