import React, { Component } from "react";
import DataContextComponent, { DataContext } from "../DataContext";
import DashboardComponent, { ComponentContext } from "../DashboardComponent";

import { withStyles, withTheme } from "@material-ui/core";
import { connect } from "react-redux";
import { getSettingsOpen } from "../../redux/selectors";
import {
  toggleSettingsOpen,
  closeSettings,
  setSettingsEnabled
} from "../../redux/actions";

import {
  IncidentReport,
  ResponsesReport,
  UnitReport
} from "./PredefinedSimpleReports";

import { CallDetailsReport } from "./CallDetailsReport";

const styles = {};

export class SimpleReportContainer extends Component {
  constructor(props) {
    super(props);
    let report;
    if (this.props.reportName) {
      report = this.props.reportName;
    } else {
      report = this.props.match.params.reportName
        ? this.props.match.params.reportName
        : "";
    }
    this.state = { reportName: report };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.match.params.reportName !== this.props.match.params.reportName
    ) {
      this.setState({ reportName: this.props.match.params.reportName });
    }
  }

  render() {
    let contents = (
      <DataContextComponent>
        <DataContext.Consumer>
          {({ setDataSettings, getDataSettings, ...dataSettings }) => (
            <DashboardComponent
              setDataSettings={setDataSettings}
              getDataSettings={getDataSettings}
              defaultSettings={{}}
              {...this.props}
            >
              <ComponentContext.Consumer>
                {({ setSettings, getSettings, ...settings }) => (
                  <ReportPropsPasser
                    setDataSettings={setDataSettings}
                    getDataSettings={getDataSettings}
                    setSettings={setSettings}
                    getSettings={getSettings}
                    {...dataSettings}
                    {...settings}
                    {...this.props}
                    reportName={this.state.reportName.toLowerCase()}
                  />
                )}
              </ComponentContext.Consumer>
            </DashboardComponent>
          )}
        </DataContext.Consumer>
      </DataContextComponent>
    );

    return contents;
  }
}

export function ReportPropsPasser({ reportName, ...props }) {
  if (reportName == "calldetails") {
    return <CallDetailsReport {...props} />;
  } else if (reportName === "incident") {
    return <IncidentReport {...props} />;
  } else if (reportName === "responses") {
    return <ResponsesReport {...props} />;
  } else if (reportName === "units") {
    return <UnitReport {...props} />;
  } else {
    return false;
  }
}

const mapStateToProps = state => {
  const settingsOpen = getSettingsOpen(state);
  return { settingsOpen };
};

const mapDispatchToProps = {
  toggleSettingsOpen,
  closeSettings,
  setSettingsEnabled
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTheme(SimpleReportContainer)));
