import React, { Component } from "react";

import { DataContext } from "../DataContext";
import { ComponentContext } from "../DashboardComponent";
import {
  AddDashboardButton,
  SaveDashboardItemChangesButton
} from "../controls/AddDashboard";

import DataSettings from "../controls/DataSettings";

export default class SimpleSettings extends Component {
  render() {
    const customize = false;
    return (
      <div className="bm-settings-container">
        <div className="flex-grow-shrink-auto">
          <DataContext.Consumer>
            {({ ...dataSettings }) => (
              <ComponentContext.Consumer>
                {({ setSettings, ...settings }) => (
                  <React.Fragment>
                    <DataSettings
                      showRegions={false}
                      showDataButton={false}
                      {...dataSettings}
                    ></DataSettings>
                    {this.props.children}
                  </React.Fragment>
                )}
              </ComponentContext.Consumer>
            )}
          </DataContext.Consumer>
        </div>
        {(customize && (
          <SaveDashboardItemChangesButton
            itemId={this.context.itemId}
            dashboardId={this.context.dashboardId}
            context={this.context}
          />
        )) || (
          <AddDashboardButton
            onClick={() => {
              this.context.setSettings({ showAddDashboardDialog: true });
            }}
          />
        )}
      </div>
    );
  }
}

SimpleSettings.contextType = ComponentContext;
