import React, { Component, useState, useEffect, useContext } from "react";

import {
  makeStyles,
  FormControl,
  InputLabel,
  NativeSelect,
  Input,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  List,
  ListSubheader,
  ListItem,
  ListItemSecondaryAction,
  IconButton
} from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";

import DeleteIcon from "@material-ui/icons/Delete";

import { DataContext } from "./DataContext.js";
import { benchmarkNames, excludedData } from "../helpers/DashboardHelpers";
import { buildNativeOptions, AddButton } from "./controls/MenuControls";
import Api from "../services/Api";

import uuidv4 from "uuid";

const useStyles = makeStyles({
  dialogContent: {
    width: "37em"
  },
  dialogTextField: {
    width: "50%",
    marginBottom: "8px"
  },
  conditionGrid: {
    paddingLeft: "8px",
    paddingBottom: "8px"
  },
  conditionList: {
    flex: "auto"
  },
  conditionListSubheader: {
    lineHeight: "32px",
    paddingLeft: "0px"
  },
  conditionListItem: {
    padding: "0px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: "0px",
    marginBottom: "0px"
  },
  conditionListItemGrid: {
    paddingRight: "30px"
  },
  conditionOperationSelect: {
    marginBottom: "8px",
    width: "100%"
  },
  conditionTextField: {
    width: "100%",
    marginBottom: "8px"
  },
  conditionDeleteAction: {
    marginRight: "-20px",
    marginTop: "10px"
  },
  logicalOperationContainer: {
    marginBottom: "8px",
    width: "32.4%"
  },
  logicalOperationSelect: {
    width: "100%"
  }
});

/*
  filter = {
    name: "",
    conditions: [
      condition
    ]
  }

  condition = {
      field: {
        type: "incident" || "incidentData" || "responseData" || "benchmark",
        name: ""
      },
    value: "" || 0,
    operation: operationName,
    logic: and, or, xor, not
  }
*/

export const baseDataFields = [
  // { name: "ID", id: "Id", type: "incident" },
  // { name: "Location", id: "Location", type: "incident" },
  // { name: "Latitude", id: "Latitude", type: "incident" },
  // { name: "Longitude", id: "Longitude", type: "incident" },
  // { name: "Time", id: "Time", type: "incident" }
];

export const comparisonOperationNames = {
  lessthan: "Is Less Than",
  greaterthan: "Is Greater Than",
  equals: "Equals",
  notequal: "Does Not Equal",
  includes: "Contains",
  excludes: "Does Not Contain",
  null: "Is Null",
  notnull: "Is Not Null"
};

export const logicalOperationNames = {
  and: "And",
  not: "Not",
  or: "Or",
  xor: "One but not both"
};

export const getFilterFields = async () => {
  return Api.getFilterFields();
};

export const getFilterFieldValues = async (field, startDate, endDate) => {
  return Api.getFilterFieldValues(field, startDate, endDate);
};

export const filterIncidentsByLocation = (dataFilters, incidentsByLocation) => {
  let newIncidentsByLocation = {};
  if (incidentsByLocation === undefined || incidentsByLocation === null)
    return newIncidentsByLocation;

  let includedIncidents;
  let locations = Object.keys(incidentsByLocation);
  for (var i = 0; i < locations.length; i++) {
    var incidents = incidentsByLocation[locations[i]];
    includedIncidents = [];
    for (var j = 0; j < incidents.length; j++) {
      let excludeIncident = false;
      for (var k = 0; k < dataFilters.length; k++) {
        excludeIncident = !filterIncludesIncident(dataFilters[k], incidents[j]);
      }
      if (!excludeIncident) {
        includedIncidents.push(incidents[j]);
      }
    }
    if (includedIncidents.length > 0) {
      newIncidentsByLocation[locations[i]] = includedIncidents;
    }
  }

  return newIncidentsByLocation;
};

const filterIncludesIncident = (dataFilter, incident) => {
  if (!dataFilter || dataFilter.conditions.length === 0) {
  }

  let condition = undefined;
  let lastCondition = undefined;
  let lastResult = undefined;
  for (var i = 0; i < dataFilter.conditions.length; i++) {
    condition = dataFilter.conditions[i];
    let field = condition.field;
    let result;
    if (field.type == "incident") {
      let data = incident[field.id];
      result = includeData(condition.operation, condition.value, data);
    } else if (field.type == "incidentData") {
      let data = incident.data[field.id];
      result = includeData(condition.operation, condition.value, data);
    } else if (field.type == "responseData") {
      let excludeResponse = false;
      for (var j = 0; j < incident.responses.length; j++) {
        let data = incident.responses[j].data[field.id];
        if (
          condition.operation == "excludes" ||
          condition.operation == "notequal" ||
          condition.operation == "null"
        ) {
          excludeResponse =
            excludeResponse ||
            !includeData(condition.operation, condition.value, data);
        } else {
          result =
            result || includeData(condition.operation, condition.value, data);
        }
      }
      if (excludeResponse) {
        result = false;
      } else if (result === undefined) {
        result = true;
      }
    } else if (field.type == "benchmark") {
      let excludeResponse = false;
      result = false;
      for (var j = 0; j < incident.responses.length; j++) {
        let response = incident.responses[j];
        if (condition.operation == "null" || condition.operation == "notnull") {
          let hasBenchmark = false;
          for (var k = 0; k < response.benchmarks.length; k++) {
            if (response.benchmarks[k].name == field.id) {
              hasBenchmark = true;
            }
          }
          if (result == false)
            result =
              condition.operation == "null" ? !hasBenchmark : hasBenchmark;
        } else {
          for (var k = 0; k < response.benchmarks.length; k++) {
            if (response.benchmarks[k].name == field.id) {
              result =
                result ||
                includeData(
                  condition.operation,
                  condition.value,
                  response.benchmarks[k].value
                );
            }
          }
        }
      }
    }
    if (lastCondition) {
      var logicOperation = lastCondition.logic;
      if (logicOperation === "and") {
        if (lastResult === false || result === false) {
          lastResult = false;
        }
      } else if (logicOperation === "not") {
        if (lastResult === true && result === false) {
        } else {
          lastResult = false;
        }
      } else if (logicOperation === "or") {
        if (lastResult === true || result === true) {
          lastResult = true;
        } else {
          lastResult = false;
        }
      } else if (logicOperation === "xor") {
        if (
          (lastResult === true && result === false) ||
          (lastResult === false && result === true)
        ) {
          lastResult = true;
        } else {
          lastResult = false;
        }
      }
      lastCondition = condition;
    } else {
      lastCondition = condition;
      lastResult = result;
    }
  }

  return lastResult;
};

const oldFilterIncidentsByLocation = (dataFilters, incidentsByLocation) => {
  let locations = Object.keys(incidentsByLocation);
  let newIncidentsByLocation = {};

  let includedIncidents,
    responses,
    includedResponses,
    benchmarks,
    includedBenchmarks;
  for (var i = 0; i < locations.length; i++) {
    var incidents = incidentsByLocation[locations[i]];
    includedIncidents = [];
    for (var j = 0; j < incidents.length; j++) {
      var includeIncident = undefined;
      for (var k = 0; k < dataFilters.length; k++) {
        var filter = dataFilters[k];
        let field = filter.field;
        if (field.type == "incident" || field.type == "incidentData") {
          includeIncident =
            includeIncident || filterIncludes(filter, incidents[j]);
        }
      }
      if (includeIncident || includeIncident === undefined) {
        includedResponses = [];
        responses = incidents[j].responses;
        for (var k = 0; k < responses.length; k++) {
          var includeResponse = undefined;
          for (var l = 0; l < dataFilters.length; l++) {
            var filter = dataFilters[l];
            let field = filter.field;
            if (field.type == "responseData") {
              includeResponse =
                includeResponse || filterIncludes(filter, responses[k]);
            }
          }
          if (includeResponse || includeResponse === undefined) {
            benchmarks = responses[k].benchmarks;
            includedBenchmarks = [];
            for (var l = 0; l < benchmarks.length; l++) {
              var includeBenchmark = undefined;
              for (var m = 0; m < dataFilters.length; m++) {
                var field = dataFilters[m].field;
                if (field.type == "benchmark") {
                  includeBenchmark =
                    includeBenchmark || filterIncludes(filter, benchmarks[l]);
                }
              }
              if (includeBenchmark || includeBenchmark === undefined) {
                includedBenchmarks.push(benchmarks[l]);
              }
            }
            responses[k].benchmarks = includedBenchmarks;
            includedResponses.push(responses[k]);
          }
        }
        incidents[j].responses = includedResponses;
        includedIncidents.push(incidents[j]);
      }
    }
    if (includedIncidents.length > 0) {
      newIncidentsByLocation[locations[i]] = includedIncidents;
    }
  }

  return newIncidentsByLocation;
};

export const filterIncludes = (filter, object) => {
  let field = filter.field;
  if (field.type == "incident") {
    let data = object[field.id];
    return this.includeData(filter.operation, filter.value, data);
  } else if (field.type == "incidentData" || field.type == "responseData") {
    let data = object.data[field.id];
    return this.includeData(filter.operation, filter.value, data);
  } else if (field.type == "benchmark") {
    if (object.name == field.id) {
      return this.includeData(filter.operation, filter.value, object.value);
    } else {
      return false;
    }
  }
};

export const includeData = (operation, value, data) => {
  if (operation == "lessthan") {
    return data < value;
  } else if (operation == "greaterthan") {
    return data > value;
  } else if (operation == "equals") {
    return data == value;
  } else if (operation == "notequal") {
    return data != value;
  } else if (operation == "includes") {
    return data.includes(value);
  } else if (operation == "excludes") {
    return !data.includes(value);
  } else if (operation == "null") {
    return data == null || data == undefined;
  } else if (operation == "notnull") {
    return data != null && data != undefined;
  }
};

export const includeBenchmarks = (operation, value, benchmarks) => {};

export function ConditionListItem(props) {
  const { condition, displayLogic, fields, removeCondition } = props;
  const dataSettings = useContext(DataContext);
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [operation, setOperation] = useState("equals");
  const [logic, setLogic] = useState("and");
  const [field, setField] = useState(null);
  const [fieldId, setFieldId] = useState("ID");
  const [possibleValues, setPossibleValues] = useState([]);

  useEffect(() => {
    if (condition.operation) {
      setValue(condition.value);
      setLogic(condition.logic);
      setOperation(condition.operation);
      setField(condition.field);
    }
    if (condition.field) {
      if (fields !== undefined && fields !== null && fields.length === 0) {
        fields.push(condition.field);
      }
      setFieldId(condition.field.id);
    }
  }, []);

  useEffect(() => {
    const updatePossibleValues = async field => {
      if (field) {
        var values = await getFilterFieldValues(
          field,
          dataSettings.startDate,
          dataSettings.endDate
        );
        setPossibleValues(values);
      }
    };
    updatePossibleValues(field);
    condition.field = field;
  }, [field]);

  return (
    <ListItem className={classes.conditionListItem}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <FormControl className={classes.operationSelect}>
            <InputLabel>Field</InputLabel>
            <NativeSelect
              id="field"
              value={fieldId}
              onChange={event => {
                for (var i = 0; i < fields.length; i++) {
                  if (fields[i].id == event.target.value) {
                    setField(fields[i]);
                    setFieldId(event.target.value);
                  }
                }
              }}
              input={<Input name="filterField" />}
            >
              {fields.map(field => {
                return (
                  <option key={field.name} value={field.id} label={field.name}>
                    {field.name}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.conditionOperationSelect}>
            <InputLabel>Operation</InputLabel>
            <NativeSelect
              id="operation"
              value={operation}
              onChange={event => {
                condition.operation = event.target.value;
                setOperation(event.target.value);
              }}
              input={<Input name="filterOperation" />}
            >
              {buildNativeOptions(comparisonOperationNames)}
            </NativeSelect>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            freeSolo
            options={possibleValues}
            disabled={operation == "null" || operation == "notnull"}
            value={condition.value}
            onChange={(event, newValue) => {
              condition.value = newValue;
            }}
            onInputChange={(event, value) => {
              condition.value = value;
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Value"
                className={classes.conditionTextField}
              />
            )}
          />
        </Grid>
        <Grid item xs={1} style={{ marginRight: "-20px" }}>
          <ListItemSecondaryAction style={{ right: "-6px", marginTop: "4px" }}>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => {
                if (removeCondition) {
                  removeCondition(condition);
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </Grid>
      </Grid>
      {displayLogic && (
        <div className={classes.logicalOperationContainer}>
          <NativeSelect
            className={classes.logicalOperationSelect}
            value={logic}
            onChange={event => {
              condition.logic = event.target.value; // setLogic(event.target.value);
            }}
          >
            {Object.keys(logicalOperationNames).map(name => {
              return (
                <option
                  key={name}
                  value={name}
                  label={logicalOperationNames[name]}
                >
                  {name}
                </option>
              );
            })}
          </NativeSelect>
        </div>
      )}
    </ListItem>
  );
}

export function DataFilterDialog(props) {
  const classes = useStyles();
  const { open, hide, onSave, incidentsByLocation, edit, filter } = props;
  const [name, setName] = useState("");
  const [conditions, setConditions] = useState([]);
  const [fields, setFields] = useState([]);

  useEffect(() => {}, []);

  useEffect(() => {
    if (open) {
      if (edit && filter) {
        var filterCopy = JSON.parse(JSON.stringify(filter));
        setName(filterCopy.name);
        setConditions(filterCopy.conditions);
        setFields([]);
        updateFilterFields(false);
      } else {
        setName("");
        setConditions([]);
        setFields([]);
        updateFilterFields(true);
      }
    }
  }, [open]);

  const updateFilterFields = async resetField => {
    if (getFilterFields) {
      let filterFields = await getFilterFields();
      if (filterFields === undefined) {
        return;
      }
      setFields(filterFields);
    }
  };

  const handleSave = () => {
    var newFilter = {
      name: name || "Unnamed Filter",
      conditions: conditions.slice()
    };
    if (onSave) {
      onSave(newFilter);
    }
    hide();
  };

  const addCondition = () => {
    var newConditions = conditions.slice(0);
    var field = { name: "ID", id: "Id", type: "incident" };
    newConditions.push({ field: field, operation: "equals" });
    setConditions(newConditions);
  };

  const removeCondition = condition => {
    var newConditions = conditions.slice();
    newConditions.splice(newConditions.indexOf(condition), 1);
    setConditions(newConditions);
  };

  return (
    <Dialog open={open} onClose={hide} aria-labelledby="dataFilterDialogTitle">
      {(edit && (
        <DialogTitle id="dataFilterDialogTitle">Edit Data Filter</DialogTitle>
      )) || (
        <DialogTitle id="dataFilterDialogTitle">Add Data Filter</DialogTitle>
      )}
      <DialogContent className={classes.dialogContent}>
        <TextField
          className={classes.dialogTextField}
          id="name"
          label="Name"
          value={name}
          onChange={event => {
            setName(event.target.value);
          }}
        />
        <List
          aria-labelledby="conditions-subheader"
          subheader={
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="baseline"
            >
              <ListSubheader
                component="div"
                id="conditions-subheader"
                className={classes.conditionListSubheader}
              >
                Conditions
              </ListSubheader>
              <AddButton id="addCondition" onClick={addCondition} />
            </Grid>
          }
          dense={true}
          className={classes.conditionList}
        >
          {conditions.map(condition => (
            <ConditionListItem
              condition={condition}
              fields={fields}
              displayLogic={
                conditions.indexOf(condition) != conditions.length - 1
              }
              removeCondition={removeCondition}
              key={condition}
            />
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={hide} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
