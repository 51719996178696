import React, { Component } from "react";
import CustomContainer from "./CustomContainer";
import WelcomePage from "./help/WelcomePage";

// Redux
import { connect } from "react-redux";
import { getSettingsOpen, getSettingsEnabled } from "../redux/selectors";
import { closeSettings, setSettingsEnabled } from "../redux/actions";

class Home extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    document.title = "Code3 Firewatch - Home";
    this.props.setSettingsEnabled(false);
  };

  componentWillUnmount = () => {
    document.title = "Code3 Firewatch";
  };

  render = () => {
    return (
      <CustomContainer>
        <WelcomePage />
      </CustomContainer>
    );
  };
}

const mapStateToProps = state => {
  const settingsOpen = getSettingsOpen(state);
  const settingsEnabled = getSettingsEnabled(state);
  return { settingsOpen, settingsEnabled };
};

const mapDispatchToProps = {
  setSettingsEnabled,
  closeSettings
};

const HomeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);

export default HomeContainer;
