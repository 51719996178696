import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles, withTheme, Paper } from "@material-ui/core";

import { ComponentContext } from "../../DashboardComponent";

import {
  getNumberPrefix,
  benchmarkNames
} from "../../../helpers/DashboardHelpers";

import {
  hexCalculationTitles,
  regionCalculationTitles
} from "./PerformanceMap";

import {
  getBenchmarkCalculationFromIncidents,
  getBenchmarksFromFeature,
  calculateValueFromBenchmarks
} from "../MapFunctions";

import uuidv4 from "uuid";

const styles = theme => ({
  paperDiv: { padding: "8px", fontSize: "0.9em" }
});

const maxPointLineCount = 15;

class PerformanceMapTooltipComponent extends Component {
  constructor(props) {
    super(props);
  }

  lineCount = 0;
  hiddenIncidents = 0;
  hiddenLocations = 0;

  getTooltipContents = () => {
    if (!this.props.features) {
      return <div />;
    }

    var features = this.props.features;

    if (features.length == 0) {
      return <div />;
    }

    var pointFeatures = [];
    var regionFeatures = [];
    var hexagonDetails, regionDetails;

    for (var i = 0; i < features.length; i++) {
      var type = features[i].get("type");
      if (type === "point") {
        pointFeatures.push(features[i]);
      } else if (type === "region") {
        regionFeatures.push(features[i]);
      } else {
        var subFeatures = features[i].get("features");
        var isHexagon = false;
        for (var j = 0; j < subFeatures.length; j++) {
          var type = subFeatures[j].get("type");
          if (type == "hexagon") {
            isHexagon = true;
            break;
          }
        }
        if (isHexagon) {
          hexagonDetails = this.getHexagonDetails(subFeatures);
          continue;
        }
      }
    }

    regionDetails = this.getRegionDetails(regionFeatures);

    var firstPointFeature;
    if (pointFeatures.length > 0) {
      firstPointFeature = pointFeatures.shift();
    }

    return (
      <div style={{ textAlign: "left" }}>
        {regionDetails !== undefined ? regionDetails : null}
        {regionDetails !== undefined &&
        (hexagonDetails !== undefined || firstPointFeature) ? (
          <div style={{ marginBottom: "1em" }} />
        ) : null}
        {hexagonDetails !== undefined ? hexagonDetails : null}
        {hexagonDetails !== undefined && firstPointFeature ? (
          <div style={{ marginBottom: "1em" }} />
        ) : null}
        {firstPointFeature && this.getFirstFeatureDetails(firstPointFeature)}
        {pointFeatures.length > 0 &&
          pointFeatures.map(feature => this.getNextFeatureDetails(feature))}
        {this.hiddenIncidents > 0 && (
          <React.Fragment>
            <br />
            <div style={{ marginTop: "-1em" }}>
              <b>
                Plus {this.hiddenIncidents}{" "}
                {this.hiddenIncidents == 1 ? "incident" : "incidents"} at
                <br />
                {this.hiddenLocations + 1}{" "}
                {this.hiddenLocations + 1 == 1 ? "location" : "locations"} not
                displayed
              </b>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  getRegionDetails = regions => {
    if (regions.length == 0) {
      return undefined;
    }

    var additionalRegions = [];
    for (var i = 1; i < regions.length; i++) {
      additionalRegions.push(regions[i]);
    }

    var label = regionCalculationTitles[this.context.regionCalculation];
    return (
      <React.Fragment>
        <b>{label}</b>
        <hr className="no-margin" />
        {this.getFirstRegionDetails(regions[0])}
        {additionalRegions.map(region => {
          this.getAdditionalRegionDetails(region);
        })}
      </React.Fragment>
    );
  };

  getFirstRegionDetails = region => {
    var name = region.get("name");
    name = name || "Unnamed Region";
    var incidents = region.get("data");
    var value = getBenchmarkCalculationFromIncidents(
      incidents,
      this.context.regionBenchmark,
      this.context.regionCalculation,
      this.context.regionPercentile
    );

    return (
      <div>
        <b>{name}</b>: {value}
      </div>
    );
  };

  getAdditionalRegionDetails = region => {
    var name = region.get("name");
    name = name || "Unnamed Region";
    var incidents = region.get("data");
    var value = getBenchmarkCalculationFromIncidents(
      incidents,
      this.context.regionBenchmark,
      this.context.regionCalculation,
      this.context.regionPercentile
    );

    return (
      <div style={{ marginTop: "1em" }}>
        <b>{name}</b>: {value}
      </div>
    );
  };

  getHexagonDetails = features => {
    var featureBenchmarks;
    var benchmarks = [];

    for (var i = 0; i < features.length; i++) {
      featureBenchmarks = getBenchmarksFromFeature(
        features[i],
        this.context.hexBenchmark
      );
      for (var j = 0; j < featureBenchmarks.length; j++) {
        benchmarks.push(featureBenchmarks[j]);
      }
    }

    var value = calculateValueFromBenchmarks(
      benchmarks,
      this.context.hexCalculation,
      this.context.hexPercentile
    );
    value = Number(value);
    value = value.toFixed(2);

    var label = hexCalculationTitles[this.context.hexCalculation];
    if (this.context.hexCalculation === "percentile") {
      var prefix = getNumberPrefix(this.context.hexPercentile);
      label = prefix + " " + label;
    }
    var benchmark = benchmarkNames[this.context.hexBenchmark];
    return (
      <React.Fragment>
        <b>{label}</b>
        <hr className="no-margin" />
        <div>
          <b>{benchmark}</b>: {value}
        </div>
      </React.Fragment>
    );
  };

  getFirstFeatureDetails = feature => {
    var data = feature.get("data");
    if (!data || data.length == 0) {
      return <div />;
    }

    var locationName = data[0].location || "Unknown Location";
    var details = [];
    for (var i = 0; i < data.length; i++) {
      var responses = data[i].responses;
      for (var j = 0; j < responses.length; j++) {
        for (var k = 0; k < responses[j].benchmarks.length; k++) {
          if (responses[j].benchmarks[k].name == this.context.benchmark) {
            details.push([responses[j], responses[j].benchmarks[k].value]);
          }
        }
      }
    }

    this.lineCount = this.lineCount + 2;
    return (
      <React.Fragment>
        <b>{locationName}</b>
        <hr className="no-margin" />
        {details.map(response => this.getResponseDetails(response))}
      </React.Fragment>
    );
  };

  getNextFeatureDetails = feature => {
    var data = feature.get("data");
    if (!data || data.length == 0) {
      return <div />;
    }

    var locationName = data[0].location || "Unknown Location";
    var details = [];
    for (var i = 0; i < data.length; i++) {
      var responses = data[i].responses;
      for (var j = 0; j < responses.length; j++) {
        for (var k = 0; k < responses[j].benchmarks.length; k++) {
          if (responses[j].benchmarks[k].name == this.context.benchmark) {
            details.push([responses[j], responses[j].benchmarks[k].value]);
          }
        }
      }
    }

    if (this.lineCount > maxPointLineCount) {
      this.hiddenLocations = this.hiddenLocations + 1;
      this.hiddenIncidents = this.hiddenIncidents + details.length;
    }

    var output = (this.lineCount <= maxPointLineCount && (
      <div key={uuidv4()}>
        <br />
        <div style={{ marginTop: "-1em" }}>
          <b>{locationName}</b>
          <hr className="no-margin" />
          {details.map(response => this.getResponseDetails(response))}
        </div>
      </div>
    )) || <div key={uuidv4()} />;

    this.lineCount = this.lineCount + 2;

    return output;
  };

  getResponseDetails = details => {
    var response = details[0];
    var benchmark = details[1];

    var unit = (response.data && response.data["Unit"]) || "Unknown Unit";

    if (this.lineCount > maxPointLineCount) {
      this.hiddenIncidents = this.hiddenIncidents + 1;
      return;
    }

    if (benchmark == undefined) {
      return <div key={uuidv4()} />; //<div key={uuidv4()}>No Benchmarks</div>;
    }

    var output = (this.lineCount <= maxPointLineCount && (
      <div key={uuidv4()}>
        <b>{unit}</b> - {benchmark.toFixed(2)}
        <br />
      </div>
    )) || <div key={uuidv4()} />;

    this.lineCount = this.lineCount + 1;

    return output;
  };

  render = () => {
    const { classes } = this.props;
    this.lineCount = 0;
    this.hiddenIncidents = 0;
    this.hiddenLocations = 0;
    return (
      <Paper>
        <div className={classes.paperDiv}>{this.getTooltipContents()}</div>
      </Paper>
    );
  };
}
PerformanceMapTooltipComponent.contextType = ComponentContext;

export const PerformanceMapTooltip = withStyles(styles)(
  withTheme(PerformanceMapTooltipComponent)
);

export default PerformanceMapTooltip;
