import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles, withTheme, Paper } from "@material-ui/core";

import { ComponentContext } from "../../DashboardComponent";

import { getMostCommonFeatureType, getMostCommonIncidentType } from "./TypeMap";

import uuidv4 from "uuid";

const styles = theme => ({
  paperDiv: { padding: "8px", fontSize: "0.9em" }
});

const maxPointLineCount = 10;

class TypeMapTooltipComponent extends Component {
  constructor(props) {
    super(props);
  }

  lineCount = 0;
  hiddenLocations = 0;
  hiddenIncidents = 0;

  getTooltipContents = () => {
    if (!this.props.features) {
      return <div />;
    }

    var features = this.props.features;
    var locations = {};
    var data, type;

    var hexagonDetails, regionDetails;
    var regionFeatures = [];

    for (var i = 0; i < features.length; i++) {
      data = features[i].get("data");
      type = features[i].get("type");
      if (!data) {
        if (type === "heat") {
          continue;
        }
        var subFeatures = features[i].get("features");
        var isHexagon = false;
        for (var j = 0; j < subFeatures.length; j++) {
          var type = subFeatures[j].get("type");
          if (type == "hexagon") {
            isHexagon = true;
            break;
          }
        }
        if (isHexagon) {
          hexagonDetails = this.getHexagonDetails(features[i]);
          continue;
        }
      }
      if (type === "region") {
        regionFeatures.push(features[i]);
      } else {
        for (var j = 0; j < data.length; j++) {
          if (!locations[data[j].location]) {
            locations[data[j].location] = [];
          }
          locations[data[j].location].push(data[j]);
        }
      }
    }

    regionDetails = this.getRegionDetails(regionFeatures);

    var locationNames = Object.keys(locations);

    var output;
    var firstLocation;
    if (locationNames.length > 0) {
      firstLocation = locationNames.shift();
    }

    return (
      <div style={{ textAlign: "left" }}>
        {regionDetails !== undefined ? regionDetails : null}
        {regionDetails !== undefined &&
        (hexagonDetails !== undefined || firstLocation) ? (
          <div style={{ marginBottom: "1em" }} />
        ) : null}
        {hexagonDetails !== undefined ? hexagonDetails : null}
        {hexagonDetails !== undefined && firstLocation ? (
          <div style={{ marginBottom: "1em" }} />
        ) : null}
        {firstLocation &&
          this.getFirstLocationDetails(locations, firstLocation)}
        {locationNames.length > 0 &&
          locationNames.map(location =>
            this.getNextLocationDetails(locations, location)
          )}
        {this.hiddenIncidents > 0 && (
          <React.Fragment>
            <br />
            <div style={{ marginTop: "-1em" }}>
              <b>
                Plus {this.hiddenIncidents}{" "}
                {this.hiddenIncidents.length == 1 ? "incident" : "incidents"} at
                <br />
                {this.hiddenLocations + 1}{" "}
                {this.hiddenLocations + 1 == 1 ? "location" : "locations"} not
                displayed
              </b>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  getRegionDetails = regions => {
    if (regions.length == 0) {
      return undefined;
    }

    var additionalRegions = [];
    for (var i = 1; i < regions.length; i++) {
      additionalRegions.push(regions[i]);
    }

    var label;
    if (this.context.regionCalculation === "count") {
      label = "Incidents in Region";
    } else {
      label = "Most Common in Region";
    }
    return (
      <React.Fragment>
        <b>{label}</b>
        <hr className="no-margin" />
        {this.getFirstRegionDetails(regions[0])}
        {additionalRegions.map(region => {
          this.getAdditionalRegionDetails(region);
        })}
      </React.Fragment>
    );
  };

  getFirstRegionDetails = region => {
    var name = region.get("name");
    name = name || "Unnamed Region";
    var incidents = region.get("data");
    var value;
    if (this.context.regionCalculation == "count") {
      value = incidents ? incidents.length : 0;
    } else {
      value = getMostCommonIncidentType(incidents, this.context.regionDataType);
    }

    return (
      <div>
        <b>{name}</b>: {value}
      </div>
    );
  };

  getAdditionalRegionDetails = region => {
    var name = region.get("name");
    name = name || "Unnamed Region";
    var incidents = region.get("data");
    var value;
    if (this.context.regionCalculation == "count") {
      value = incidents ? incidents.length : 0;
    } else {
      value = getMostCommonIncidentType(incidents, this.context.regionDataType);
    }

    return (
      <div style={{ marginTop: "1em" }}>
        <b>{name}</b>: {value}
      </div>
    );
  };

  getHexagonDetails = feature => {
    if (this.context.hexCalculation == "count") {
      var featureCount = feature.get("featureCount");
      return (
        <React.Fragment>
          <b>Hexagon Contains</b>
          <hr className="no-margin" />
          <div>{featureCount} incidents</div>
        </React.Fragment>
      );
    } else {
      var features = feature.get("features");
      var mostCommon = getMostCommonFeatureType(
        features,
        this.context.hexDataType
      );
      return (
        <React.Fragment>
          <b>Most Common in Hexagon</b>
          <hr className="no-margin" />
          <div>
            {this.context.hexDataType}: {mostCommon}
          </div>
        </React.Fragment>
      );
    }
  };

  getFirstLocationDetails = (locations, locationName) => {
    var data = [];
    for (var i = 0; i < locations[locationName].length; i++) {
      data.push(locations[locationName][i]);
    }

    this.lineCount = this.lineCount + 1;
    return (
      <React.Fragment>
        <b>{locationName}</b>
        <hr className="no-margin" />
        {data.map(data => this.getLocationDetails(data))}
      </React.Fragment>
    );
    this.lineCount = this.lineCount + 2;
  };

  getNextLocationDetails = (locations, locationName) => {
    var data = [];
    for (var i = 0; i < locations[locationName].length; i++) {
      data.push(locations[locationName][i]);
    }

    if (this.lineCount > maxPointLineCount) {
      this.hiddenLocations = this.hiddenLocations + 1;
      this.hiddenIncidents = this.hiddenIncidents + data.length;
    }

    var output = (this.lineCount <= maxPointLineCount && (
      <div key={uuidv4()}>
        <br />
        <div style={{ marginTop: "-1em" }}>
          <b>{locationName}</b>
          <hr className="no-margin" />
          {data.map(data => this.getLocationDetails(data))}
        </div>
      </div>
    )) || <div key={uuidv4()} />;

    this.lineCount = this.lineCount + 2;

    return output;
  };

  getLocationDetails = info => {
    var data;
    if (info.data && info.data[this.context.pointDataType]) {
      data = info.data[this.context.pointDataType].toString().trim();
    } else {
      data = "Unknown";
    }

    this.lineCount = this.lineCount + 1;

    if (this.lineCount > maxPointLineCount) {
      this.hiddenIncidents = this.hiddenIncidents + 1;
    }

    return (
      (this.lineCount <= maxPointLineCount && (
        <div key={uuidv4()}>
          {data.slice(0, 25)}
          <br />
        </div>
      )) || <div key={uuidv4()} />
    );
  };

  render = () => {
    const { classes } = this.props;
    this.lineCount = 0;
    this.hiddenIncidents = 0;
    this.hiddenLocations = 0;
    return (
      <Paper>
        <div className={classes.paperDiv}>{this.getTooltipContents()}</div>
      </Paper>
    );
  };
}
TypeMapTooltipComponent.contextType = ComponentContext;

export const TypeMapTooltip = withStyles(styles)(
  withTheme(TypeMapTooltipComponent)
);

export default TypeMapTooltip;
