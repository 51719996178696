import React, { Component } from "react";
import CustomContainer from "./CustomContainer";
import NavMenu from "./NavMenu";
import { isUserLoggedIn } from "../helpers/Utilities";

import { connect } from "react-redux";
import { getLoggedIn } from "../redux/selectors";

class Layout extends Component {
  constructor(props) {
    super(props);
  }

  static displayName = Layout.name;

  render() {
    return (
      <div className="flex-fullHeight-container">
        <NavMenu userIsLoggedIn={this.props.isLoggedIn} />
        <div className="flex-fullHeight-container no-overflowx navMenu-subcontainer">
          <CustomContainer>{this.props.children}</CustomContainer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const isLoggedIn = getLoggedIn(state);
  return { isLoggedIn };
};

const LayoutContainer = connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(Layout);

export default LayoutContainer;
